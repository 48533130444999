import React from 'react';
import { Page, Button, ButtonGroup, Box, BlockStack, Layout, Text, Card, Divider, Select, Checkbox, InlineStack, Icon, TextField, InlineGrid } from '@shopify/polaris';
import { DnsSettingsIcon } from '@shopify/polaris-icons';
import { socialpushpreview1, socialpushpreview2, fb_icon, insta_icon } from '../../img';
import Parser from 'html-react-parser';


function SocialPush(props) {
  const { state } = props;

  const optionsRating = [
    { label: '1 Star', value: 1 },
    { label: '2 Star', value: 2 },
    { label: '3 Star', value: 3 },
    { label: '4 Star', value: 4 },
    { label: '5 Star', value: 5 },
  ];

  const tmplVariables = (template) => {
    const regex = /{{(.*?)}}/g;
    let match;
    const variables = [];
    while ((match = regex.exec(template)) !== null) {
      variables.push(match[1].trim());
    }
    return variables;
  };

  const review = {
    review_rating_emoji: "⭐️⭐️⭐️⭐️⭐️",
    review_rating: "5",
    reviewer_name: "John",
    review_title: "Lorem ipsum dolor sit amet",
    review_body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  };

  const generateFilledTemplate = (template, review) => {
    if (!template || template.trim() === '') {
      return '';
    }
    const variables = tmplVariables(template);
    let filledTemplate = template;

    variables.forEach(key => {
      const value = review[key] || '';
      const regex = new RegExp(`{{\\s*${key}\\s*}}`, 'g');
      filledTemplate = filledTemplate.replace(regex, value);
    });

    filledTemplate = filledTemplate.replace(/\n/g, '<br />');

    return filledTemplate;
  };

  const filledFbTemplate = generateFilledTemplate(state.fbtmpl, review);
  const filledInstaTemplate = generateFilledTemplate(state.instmpl, review);
  const fbVariables = tmplVariables(state.fbtmplDisplay);
  const instaVariables = tmplVariables(state.instmplDisplay);

  return (
    <div className='brand_pge'>
      <Page
        title="Social Push"
        backAction={{ onAction: () => props.handleBackToMain() }}
        secondaryActions={
          state.isShowMessage ? (
            <ButtonGroup>
              <Button onClick={() => props.cancelSocialPush()}>Cancel</Button>
              <Button id='primary_btn' onClick={() => props.handleSaveSocialPush()}>Save</Button>
            </ButtonGroup>
          ) : (
            <Box paddingBlockStart={400} paddingBlockEnd={300}></Box>
          )
        }
      >
        <Box paddingBlockStart={400} paddingInlineStart={100}>
          <BlockStack gap={600}>
            <div>
              <Layout>
                <Layout.Section variant="oneThird">
                  <BlockStack>
                    <InlineStack gap={200}>
                      <Box>
                        <Icon source={DnsSettingsIcon}></Icon>
                      </Box>
                      <Text variant="headingMd" as="h3" fontWeight='semibold'>Automatic Sharing Criteria</Text>
                    </InlineStack>
                    <Box paddingInlineStart={600}>
                      <Text variant='headingSm' as="p" fontWeight='regular'>
                        Set conditions which reviews will be published automatically.
                      </Text>
                    </Box>

                  </BlockStack>
                </Layout.Section>

                <Layout.Section>
                  <Card padding={0}>
                    <Box padding={400}>
                      <BlockStack gap={300}>
                        <Box paddingBlockStart={400}>
                          <Text variant='headingMd' as='h4'>Minimum Rating</Text>
                        </Box>
                        <Text>Set the threshold rating that a review must meet to be shared automatically</Text>
                        <Select
                          options={optionsRating}
                          value={state.mnrtng}
                          onChange={(e) => props.handleIncTxtChange({ 'mnrtng': Number(e) })}
                        />
                        <Divider />

                      </BlockStack>

                      <BlockStack gap={300}>
                        <Box paddingBlockStart={400}>
                          <Text variant='headingMd' as='h4'>Time Interval (24 Hours)</Text>
                        </Box>

                      </BlockStack>

                      <BlockStack gap={300}>
                        <Box paddingBlockStart={400}>
                          <Text variant='headingMd' as='h4'>Review photo</Text>
                        </Box>
                        <Checkbox
                          label="A photo is necessary for sharing"
                          checked={state.iimg}
                          onChange={() => props.handleIncTxtChange({ iimg: !state.iimg })}
                        />
                        <Divider />
                      </BlockStack>

                      <BlockStack gap={300}>
                        <Box paddingBlockStart={400}>
                          <Text variant='headingMd' as='h4'>Verified buyer</Text>
                        </Box>
                        <Checkbox
                          label="Only share reviews from verified buyers"
                          checked={state.ivrfd}
                          onChange={() => props.handleIncTxtChange({ ivrfd: !state.ivrfd })}
                        />
                      </BlockStack>
                    </Box>
                  </Card>
                </Layout.Section>
              </Layout>

              <Box paddingBlockStart={600}>
                <Divider borderColor="border" />
              </Box>

              <Layout>
                <Layout.Section variant="oneThird">
                  <BlockStack gap={200}>
                    <Box paddingBlockStart={500}>
                      <InlineStack gap={200}>
                        <img src={fb_icon} alt="" />
                        <Text variant="headingMd" as="h3" fontWeight='semibold'>Facebook Review Post Template</Text>
                      </InlineStack>
                      <Box paddingInlineStart={600}>
                        <Text variant='headingSm' as="p" fontWeight='regular'>
                          Personalize how customer reviews appear on your Facebook Page. Use template Editor to insert specific review details automatically.
                        </Text>
                      </Box>
                    </Box>
                  </BlockStack>
                </Layout.Section>

                <Layout.Section>
                  <Box paddingBlockStart={500}>
                    <Card padding={0}>
                      <Box padding={400}>
                        <BlockStack gap={300}>
                          <Text variant='headingSm' as='p'>Template Editor [{fbVariables.map(variable => `{{${variable}}}`).join(', ')}]</Text>
                          <TextField
                            multiline={5}
                            autoComplete='off'
                            value={state.fbtmpl}
                            onChange={(e) => props.handleIncTxtChange({ 'fbtmpl': e })}
                            error={state.isFbValidator ? "Please require one Field in Facebook Editor." : ""}
                          />
                          <Box paddingInlineStart={100} paddingBlockStart={400}>
                            <Text variant='headingMd' as='h4'>Template Preview:</Text>
                          </Box>
                          <Box>
                            <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} gap={400}>
                              <Card sectioned>
                                <div style={{ wordWrap: 'break-word' }}>
                                  <Text>{Parser(filledFbTemplate)}</Text>
                                </div>
                                <Box paddingBlockStart={500}>
                                  <img src={socialpushpreview1} alt="Static preview" />
                                </Box>
                              </Card>
                              <Card sectioned>
                                <div style={{ wordWrap: 'break-word' }}>
                                  <Text>{Parser(filledFbTemplate)}</Text>
                                </div>
                                <Box paddingBlockStart={500}>
                                  <img src={socialpushpreview2} alt="Static preview" />
                                </Box>
                              </Card>
                            </InlineGrid>
                          </Box>
                        </BlockStack>
                      </Box>
                    </Card>
                  </Box>
                </Layout.Section>
              </Layout>

              <Box paddingBlockStart={600}>
                <Divider borderColor="border" />
              </Box>

              <Layout>
                <Layout.Section variant="oneThird">
                  <BlockStack gap={200}>
                    <Box paddingBlockStart={500}>
                      <InlineStack gap={200}>
                        <img src={insta_icon} alt="" />
                        <Text variant="headingMd" as="h3" fontWeight='semibold'>Instagram Review Post Template</Text>

                      </InlineStack>
                      <Box paddingInlineStart={600}>
                        <Text variant='headingSm' as="p" fontWeight='regular'>
                          Personalize how customer reviews appear on your Instagram. Use template Editor to insert specific review details automatically.
                        </Text>
                      </Box>
                    </Box>
                  </BlockStack>
                </Layout.Section>

                <Layout.Section>
                  <Box paddingBlockStart={500}>
                    <Card padding={0}>
                      <Box padding={400}>
                        <BlockStack gap={300}>
                          <Text variant='headingSm' as='p'>Template Editor [{instaVariables.map(variable => `{{${variable}}}`).join(', ')}]</Text>
                          <TextField
                            multiline={5}
                            autoComplete='off'
                            value={state.instmpl}
                            onChange={(e) => props.handleIncTxtChange({ 'instmpl': e })}
                            error={state.isInstaValidator ? "Please require one Field in Instagram Editor." : ""}
                          />
                          <Box paddingInlineStart={100} paddingBlockStart={400}>
                            <Text variant='headingMd' as='h4'>Template Preview:</Text>
                          </Box>
                          <Box>
                            <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} gap={400}>
                              <Card sectioned>
                                <div style={{ wordWrap: 'break-word' }}>
                                  <Text>{Parser(filledInstaTemplate)}</Text>
                                </div>
                                <Box paddingBlockStart={500}>
                                  <img src={socialpushpreview1} alt="Static preview" />
                                </Box>
                              </Card>
                              <Card sectioned>
                                <div style={{ wordWrap: 'break-word' }}>
                                  <Text>{Parser(filledInstaTemplate)}</Text>
                                </div>
                                <Box paddingBlockStart={500}>
                                  <img src={socialpushpreview2} alt="Static preview" />
                                </Box>
                              </Card>
                            </InlineGrid>
                          </Box>
                        </BlockStack>
                      </Box>
                    </Card>
                  </Box>
                </Layout.Section>
              </Layout>
            </div>
          </BlockStack>
        </Box>
      </Page>
    </div>
  );
}

export default SocialPush;