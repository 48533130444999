import React, { useCallback, useEffect, useState } from 'react';
import { Navigation } from '@shopify/polaris';
import { HomeIcon, DiscountIcon, AffiliateIcon, ArrowLeftIcon, FileFilledIcon, RefreshIcon, DatabaseAddIcon, PackageIcon, SettingsFilledIcon, MarketsIcon, BlogIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router';

function Sidebar() {
  const navigate = useNavigate();

  const [menu, setMenu] = useState({
    selectedParent: ''
  });

  const handleBackShopify = () => {
    if (localStorage.getItem('shp')) {
      let shpName = localStorage.getItem('shp');
      let _url = 'https://' + shpName + '/admin';
      window.open(_url);
    }
  }

  const path = window.location.pathname;
  const [isShowLert, setIsFlag] = useState(false);
  const [pathLbl, setPath] = useState('');

  useEffect(() => { //on page change alert  
    if (isShowLert === true && localStorage.getItem('isShowLert') === 'true') {
      const handlePopState = () => {
        const userConfirmed = window.confirm('Are you sure you want to leave this page?');
        if (!userConfirmed) {
          setIsFlag(false);
        }
        else {
          setIsFlag(true);
          navigate(pathLbl);
          localStorage.removeItem('isShowLert');
        }
      }
      window.addEventListener('popstate', handlePopState);
      return () => window.removeEventListener('popstate', handlePopState);
    };
  }, [isShowLert, navigate, pathLbl]);

  const changeRoute = useCallback((pathName, isParent, isSubMenu) => {
    //If the clicked menu is a parent menu, we open page and open the submenu section
    if (isParent) {
      setMenu({ selectedParent: pathName });
    }

    if (localStorage.getItem('isShowLert') === 'true') {
      setPath(pathName);
      setIsFlag(true);
    }
    else {
      navigate(pathName);
      setIsFlag(false);
    }

    //If the clicked menu is not a sub-menu, close the parent menu section.
    if (!isParent && !isSubMenu) {
      setMenu({ selectedParent: '' });
    }
  }, [navigate]);

  // useEffect(() => { //for submenu
  //   if (path === '/reviewmoderate' || path === '/product' || path === '/customer' || path === '/reviewgrouppr')
  //     setMenu({ selectedParent: 'reviewmoderate' });
  // }, [path]);

  return (
    <Navigation location={path}>
      {/* <Navigation.Section
        items={[
          {
            label: 'Back to shopify',
            icon: ArrowLeftIcon,
            onClick: handleBackShopify
          },
        ]}
      /> */}

      <Navigation.Section
        items={[
          {
            url: '#',
            label: 'Dashboard',
            icon: HomeIcon,
            selected: path === '/dashboard',
            onClick: () => changeRoute('dashboard')
          },
          {
            url: '#',
            label: 'Manage reviews',
            icon: FileFilledIcon,
            selected: path === '/reviewmoderate',
            onClick: () => changeRoute('reviewmoderate', true)
          }
        ]} />

      <Navigation.Section
        title="Collect reviews"
        items={[
          {
            url: '#',
            label: 'Automate',
            icon: RefreshIcon,
            selected: (path === '/sequence'),
            onClick: () => changeRoute('sequence')
          },
          {
            url: '#',
            label: 'Queue',
            icon: DatabaseAddIcon,
            selected: path === '/currentQueue',
            onClick: () => changeRoute('currentQueue')
          },
          {
            url: '#',
            label: 'Forms',
            icon: BlogIcon,
            selected: path === '/forms',
            onClick: () => changeRoute('forms')
          },
          {
            url: '#',
            label: 'Incentives',
            icon: DiscountIcon,
            selected: path === '/incentives',
            onClick: () => changeRoute('incentives')
          },
        ]}
      />
      <Navigation.Section
        separator
        fill
        title="Display reviews"
        items={[
          {
            url: '#',
            label: 'Widgets',
            icon: PackageIcon,
            selected: path.includes('reviewWidget'),
            onClick: () => changeRoute('reviewWidget')
          },
          {
            url: '#',
            label: 'Integration',
            icon: AffiliateIcon,
            selected: path.includes('integration'),
            onClick: () => changeRoute('integration')
          },
          {
            url: '#',
            label: 'UGC',
            icon: MarketsIcon,
            selected: path === '/ugc',
            onClick: () => changeRoute('ugc')
          },
        ]}
      />

      <Navigation.Section
        items={[
          {
            url: '#',
            label: 'Settings',
            icon: SettingsFilledIcon,
            selected: path.includes('setting'),
            onClick: () => changeRoute('setting')
          }
        ]} />












      {/* <Navigation.Section
        items={[
          {
            url: '#',
            label: 'Dashboard',
            icon: HomeIcon,
            selected: window.location.pathname === '/dashboard' ? true : false,
            onClick: () => changeRoute('dashboard')
          },
          {
            url: '#',
            label: 'Manage reviews',
            icon: FileFilledIcon,
            selected: path === '/reviewmoderate',
            onClick: () => changeRoute('reviewmoderate', true),
            // {
            //   url: '#',
            //   label: 'Manage reviews',
            //   icon: FileFilledIcon,
            //   selected: path === '/reviewmoderate',
            //   onClick: () => changeRoute('reviewmoderate', true),
            // subNavigationItems: menu.selectedParent === 'reviewmoderate' ?
            //   [
            //     // {
            //     //   url: path === '/customer' ? '#' : '/customer',
            //     //   label: 'Customer',
            //     //   selected: path === '/customer',
            //     //   onClick: () => changeRoute('customer', false, true)
            //     // },
            //     {
            //       url: path === '/product' ? '#' : '/product',
            //       label: 'Product',
            //       selected: path === '/product',
            //       onClick: () => changeRoute('product', false, true)
            //     },
            //     {
            //       url: path === '/reviewgrouppr' ? '#' : ' / reviewgrouppr',
            //       label: 'Grouping',
            //       selected: path === '/reviewgrouppr',
            //       onClick: () => changeRoute('reviewgrouppr', false, true)
            //     },
            //   ]
            //   :
            //   []
          },
          // {
          //   url: '#',
          //   label: 'Analytics',
          //   icon: ChartVerticalFilledIcon,
          //   selected: window.location.pathname === '/analytic' ? true : false,
          //   onClick: () => changeRoute('analytic')
          // },
          // {
          //   url: '#',
          //   label: 'Display Reviews',
          //   icon: ViewIcon,
          //   selected: path === '/reviewdisplay',
          //   onClick: () => changeRoute('reviewdisplay', true),
          //   subNavigationItems: menu.selectedParent === 'reviewdisplay' ?
          //     [
          //       {
          //         url: path === '/reviewWidget' ? '#' : '/reviewWidget',
          //         label: 'Display widgets',
          //         selected: window.location.pathname === '/reviewWidget' ? true : false,
          //         onClick: () => changeRoute('reviewWidget', false, true)
          //       }
          //     ]
          //     :
          //     []
          // },
          // {
          //   url: '#',
          //   label: 'QnA',
          //   icon: ChatIcon,
          //   selected: window.location.pathname === '/qna' ? true : false,
          //   onClick: () => changeRoute('qna')
          // },
          // {
          //   url: '#',
          //   label: 'Check Out review',
          //   icon: CartFilledIcon,
          //   selected: window.location.pathname === '/checkoutReview' ? true : false,
          //   onClick: () => changeRoute('checkoutReview')
          // },
          // {
          //   url: '#',
          //   label: 'Collect Reviews',
          //   icon: EmailIcon,
          //   selected: path === '/sequence',
          //   onClick: () => changeRoute('sequence', true),
          //   subNavigationItems: menu.selectedParent === 'sequence' ?
          //     [{
          //       url: path === '/currentQueue' ? '#' : '/currentQueue',
          //       label: 'Outbox',
          //       selected: window.location.pathname === '/currentQueue' ? true : false,
          //       onClick: () => changeRoute('currentQueue', false, true)
          //     }, {
          //       url: path === '/forms' ? '#' : '/forms',
          //       label: 'Forms',
          //       selected: window.location.pathname === '/forms' ? true : false,
          //       onClick: () => changeRoute('forms', false, true)
          //     },]
          //     :
          //     []
          // },
          // {
          //   url: '#',
          //   label: 'Branding',
          //   icon: CustomersIcon,
          //   selected: window.location.pathname === '/branding' ? true : false,
          //   onClick: () => changeRoute('branding')
          // },
          // {
          //   url: '#',
          //   label: 'Integration',
          //   icon: AffiliateIcon,
          //   selected: window.location.pathname === '/integration' ? true : false,
          //   onClick: () => changeRoute('integration')
          // },
          // {
          //   url: '#',
          //   label: 'Incentives',
          //   icon: DiscountIcon,
          //   selected: window.location.pathname === '/incentives' ? true : false,
          //   onClick: () => changeRoute('incentives')
          // },
          // {
          //   url: '#',
          //   label: 'Marketing',
          //   icon: MarketingIcon,
          //   selected: window.location.pathname === '/marketing' ? true : false,
          //   onClick: () => changeRoute('marketing')
          // },
          // {
          //   url: '#',
          //   label: 'SEO',
          //   icon: GlobeIcon,
          //   selected: window.location.pathname === '/seo' ? true : false,
          //   onClick: () => changeRoute('seo')
          // },
          // {
          //   url: '#',
          //   label: 'UGC',
          //   icon: ProductsIcon,
          //   selected: window.location.pathname === '/ugc' ? true : false,
          //   onClick: () => changeRoute('ugc')
          // },
        ]} /> */}

      {/* <Navigation.Section
          separator
          title="Wiser Review App"
          items={[
            // {
            //   label: 'QQQ',
            //   icon: ArrowLeftIcon,
            // },
            {
              url: '#',
              label: 'Analytic',
              icon: AnalyticsFilledIcon,
              selected: false,
            },
          ]}
        />
        <Navigation.Section
          title="Sales channels"
          items={[
            {
              url: '#',
              label: 'Online Store',
              icon: AnalyticsFilledIcon,
            },
          ]}
          action={{
            accessibilityLabel: 'Add sales channel',
            icon: AnalyticsFilledIcon,
            onClick: () => { },
          }}
        /> */}

    </Navigation>
  );
}

export default Sidebar;