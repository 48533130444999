import React, { useEffect } from 'react';
import { HeaderCard } from '..';
import { createApp } from '@shopify/app-bridge';
import { AppLink, NavigationMenu, Redirect } from '@shopify/app-bridge/actions';
import { useNavigate } from 'react-router-dom';
import * as authDucks from '../../ducks/auth';
import { useDispatch } from 'react-redux';

function EntryCard(props) {
  const navigate = useNavigate();

  const paramsShop = new URLSearchParams(window.location.search);
  let instance = paramsShop.get('instance');
  const dispatch = useDispatch();
  if (instance && instance !== '' && instance !== undefined) {
    let obj = {
      instanceEncode: instance,
      path: '/plan'
    };
    dispatch(authDucks.loginWithWix(obj));
  }

  useEffect(() => {
    //subscribe shopify admin menu navigation click event
    const strObjConfig = localStorage.getItem('store_config');
    if (strObjConfig) {
      try {
        const appShopify = createApp(JSON.parse(strObjConfig));

        //Navigation menu for shopify
        NavigationMenu.create(appShopify, {
          items: [
            AppLink.create(appShopify, { label: 'Manage Reviews', destination: '/reviewmoderate' }),
            AppLink.create(appShopify, { label: 'Collect Review', destination: '/collectReview' }),
            AppLink.create(appShopify, { label: 'Widgets', destination: '/reviewWidget' }),
            AppLink.create(appShopify, { label: 'Integration', destination: '/integration' }),
            AppLink.create(appShopify, { label: 'Pricing', destination: '/plan' }),
            AppLink.create(appShopify, { label: 'UGC', destination: '/ugc' }),
            AppLink.create(appShopify, { label: 'Setting', destination: '/setting' }),
            AppLink.create(appShopify, { label: 'Up Sell', destination: '/upsell' })
          ]
        });

        const unsubscribe = appShopify.subscribe(Redirect.Action.APP, (payload) => {
          // console.log('=', payload.path);
          window._cio.page(payload.path);
          navigate(payload.path);
        });

        return () => {
          unsubscribe();
        }
      } catch (error) {
        console.error('Error initializing Shopify App Bridge:', error);
      }
    }
    //end subscribe 
  }, [navigate])

  return (
    <div>
      <HeaderCard component={props.component} />
    </div>
  );
}

export default EntryCard;