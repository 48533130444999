import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/upsell';
import { toastify } from '../ducks/toast';

function* getUpsell(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getUpsell', action.payload);

    if (res.status === 'success') {
      yield put(actions.getUpsellSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createUpsell(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createUpsell', action.payload);

    if (res.status === 'success') {
      yield put(actions.getUpsell());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateUpsell(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateUpsell', action.payload);

    if (res.status === 'success') {
      yield put(actions.getUpsell());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteUpsell(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteUpsell', action.payload);

    if (res.status === 'success') {
      yield put(actions.getUpsell());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetUpsell() {
  yield takeLatest(actions.getUpsell.type, getUpsell);
  // yield takeLatest("GET_UPSELL", getUpsell);
}

export function* watchCreateUpsell() {
  yield takeLatest(actions.createUpsell.type, createUpsell);
  // yield takeLatest("CREATE_UPSELL", createUpsell);
}

export function* watchUpdateUpsell() {
  yield takeLatest(actions.updateUpsell.type, updateUpsell);
  // yield takeLatest("UPDATE_UPSELL", updateUpsell);
}

export function* watchDeleteUpsell() {
  yield takeLatest(actions.deleteUpsell.type, deleteUpsell);
  // yield takeLatest("DELETE_UPSELL", deleteUpsell);
}

export default function* rootSaga() {
  yield fork(watchGetUpsell);
  yield fork(watchCreateUpsell);
  yield fork(watchUpdateUpsell);
  yield fork(watchDeleteUpsell);
}