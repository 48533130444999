import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const currentQueue = createSlice({
  name: 'currentQueue',
  initialState,
  reducers: {
    getEmailQueue: (state, action) => { },
    getEmailQueueSuccess: (state, action) => {
      return { ...state, QueueList: action.payload };
    },
    deleteEmailQueue: (state, action) => { },
    retryEmailQueue: (state, action) => { }
  }
});

export const {
  getEmailQueue,
  getEmailQueueSuccess,
  deleteEmailQueue,
  retryEmailQueue,
} = currentQueue.actions;

export default currentQueue.reducer;