import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/import';
import { toastify } from '../ducks/toast';

function* getImportData(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getImportHistory', action.data);
    if (res.status === 'success') {
      yield put(actions.getImportDataSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetImportData() {
  yield takeLatest(actions.getImportData.type, getImportData);
}

export default function* rootSaga() {
  yield fork(watchGetImportData);
}


