import { Text, Checkbox, Button, InlineGrid, Popover, OptionList, Box, Tooltip, InlineStack, Thumbnail, TextField, Select, Card } from '@shopify/polaris';
import { ThemeTemplateIcon, StarIcon, OrderFulfilledIcon, EyeCheckMarkIcon, ImageWithTextOverlayIcon, CodeIcon, LanguageIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const dateOpt = [
  { label: 'mm-dd-yyyy', value: 'mm-dd-yyyy' },
  { label: 'dd-mm-yyyy', value: 'dd-mm-yyyy' }
];

const nameOpt = [
  { label: 'Jenny Karl', value: 0 },
  { label: 'J. karl', value: 1 },
  { label: 'Jenny K.', value: 2 },
  { label: 'J. K.', value: 3 }
];

const TextWall = ({ props }) => {
  let { state, handleTextChange, changeNameValue } = props;
  let cnf = state.cnf;

  let verticalTabsArr = [
    { title: 'Theme layouts', img: ThemeTemplateIcon },
    { title: 'Reviewer cards', img: ImageWithTextOverlayIcon },
    { title: 'Star rating summary', img: StarIcon },
    { title: 'Color combination', img: EyeCheckMarkIcon },
    { title: 'No reviews available', img: OrderFulfilledIcon },
    { title: 'Add custom css', img: CodeIcon },
    { title: 'Language', img: LanguageIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <Box>

          <InlineGrid columns={2} gap={300}>
            {state.mainLayoutArr && state.mainLayoutArr.length > 0 && state.mainLayoutArr.map((val, i) => {
              return (
                <div key={i} onClick={() => changeNameValue({ currLayoutId: val._id }, 'isEditable')}
                  className={val._id === state.currLayoutId ? 'select_block active' : 'select_block'}>
                  <InlineStack align='center' blockAlign='center'>
                    <Checkbox tone={val._id === state.currLayoutId ? 'magic' : ''}
                      checked={val._id === state.currLayoutId} />
                    <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                      {val.dn}</Text>
                  </InlineStack>
                  <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                </div>
              );
            })}
          </InlineGrid>

          {cnf && cnf.isrtby !== undefined &&
            <Box paddingBlockStart={300} paddingBlock={400}>
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Show sorting dropdown</Text>}
                checked={cnf.isrtby}
                onChange={(e) => handleTextChange({ isrtby: e })}
              />
            </Box>
          }

          {cnf && cnf.isrchbx !== undefined &&
            <Checkbox
              label={<Text fontWeight='medium' as='span'>Show review search box</Text>}
              checked={cnf.isrchbx}
              onChange={(e) => handleTextChange({ isrchbx: e })}
            />
          }

          {cnf && cnf.ildmr !== undefined &&
            <Box paddingBlock={400}>
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Show load more button</Text>}
                checked={cnf.ildmr}
                onChange={(e) => handleTextChange({ ildmr: e })}
              />
              <Box paddingInlineStart={600} paddingBlockStart={100}>
                <TextField
                  disabled={!cnf.ildmr}
                  value={cnf.ldmrtxt}
                  onChange={(e) => handleTextChange({ ldmrtxt: e })}
                  autoComplete="off" />
              </Box>
            </Box>
          }

          {cnf && cnf.ibrnd !== undefined &&
            <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
              <Checkbox
                disabled={state.isFreePln === true}
                label={<Text fontWeight='medium' as='span'>Branding</Text>}
                checked={cnf.ibrnd}
                onChange={(e) => handleTextChange({ ibrnd: e })} />
            </Tooltip>
          }

          {cnf && cnf.iglry !== undefined &&
            <Box paddingBlockStart={500}>
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Show gallery</Text>}
                checked={cnf.iglry}
                onChange={(e) => handleTextChange({ iglry: e })}
              />
              <Box paddingInlineStart={600} paddingBlockStart={100}>
                <TextField
                  disabled={!cnf.iglry}
                  value={cnf.glrytxt}
                  onChange={(e) => handleTextChange({ glrytxt: e })}
                  autoComplete="off" />
              </Box>
            </Box>
          }

        </Box>
        :
        state.sidebarActiveIndex === 1 ?
          <Box>

            <InlineGrid columns={2} gap={300}>
              {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
                return (
                  <div key={i} className={val._id === state.currCardId ? 'select_block active' : 'select_block'}
                    onClick={() => changeNameValue({ currCardId: val._id }, 'isEditable')}>
                    <InlineStack align='center' blockAlign='center'>
                      <Checkbox tone={val._id === state.currCardId ? 'magic' : ''}
                        checked={val._id === state.currCardId} />
                      <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                        {val.dn}</Text>
                    </InlineStack>
                    <Thumbnail source={val.iu} size="large" alt="Layout" />
                  </div>
                );
              })}
            </InlineGrid>

            {cnf && cnf.crdirvwnm !== undefined &&
              <Box paddingBlock={400}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show reviewer name</Text>}
                  checked={cnf.crdirvwnm}
                  onChange={(e) => handleTextChange({ crdirvwnm: e })}
                />
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <Text as='span'>Select name format</Text>
                  <Box paddingBlockStart={100}>
                    {cnf.crdirvwnm !== true ?
                      <TextField value={nameOpt[cnf.crdrvwnmfrmt].label} disabled={!cnf.crdirvwnm} />
                      :
                      <Popover
                        fullWidth
                        active={state.isNmFrmt}
                        onClose={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                        activator={<Button textAlign="left" fullWidth disclosure
                          onClick={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}>
                          <Text variant='bodyMd' tone='subdued'>
                            {nameOpt.find(({ value }) => value === cnf.crdrvwnmfrmt)?.label || 'Select'}
                          </Text>
                        </Button>}>
                        <OptionList
                          options={nameOpt}
                          selected={[cnf.crdrvwnmfrmt]}
                          onChange={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                          onFocusOption={(e) => handleTextChange({ crdrvwnmfrmt: e })} />
                      </Popover>
                    }
                  </Box>
                </Box>
              </Box>
            }

            {cnf && cnf.crdittl !== undefined &&
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Show review title</Text>}
                checked={cnf.crdittl}
                onChange={(e) => handleTextChange({ crdittl: e })}
              />
            }

            {cnf && cnf.crdidt !== undefined &&
              <Box paddingBlock={400}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show review date</Text>}
                  checked={cnf.crdidt}
                  onChange={(e) => handleTextChange({ crdidt: e })}
                />
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <Text as='span'>Select date format</Text>
                  <Box paddingBlockStart={100}>
                    {cnf.crdidt !== true ?
                      <TextField value={cnf?.crddtfrmt || 'Select'} disabled={true} />
                      :
                      <Popover
                        fullWidth
                        active={state.isDtFrmt}
                        onClose={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                        activator={<Button textAlign="left" fullWidth disclosure
                          onClick={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}>
                          <Text variant='bodyMd' tone='subdued'>
                            {cnf?.crddtfrmt || 'Select'}
                          </Text>
                        </Button>}>
                        <OptionList
                          options={dateOpt}
                          selected={cnf.crddtfrmt}
                          onChange={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                          onFocusOption={(e) => handleTextChange({ crddtfrmt: e })} />
                      </Popover>
                    }
                  </Box>

                </Box>
              </Box>
            }

            {cnf && cnf.crdirdmr !== undefined &&
              <Box>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show trim content</Text>}
                  checked={cnf.crdirdmr}
                  onChange={(e) => handleTextChange({ crdirdmr: e })}
                />
                <Box paddingBlockStart={100} paddingInlineStart={600}>
                  <TextField
                    disabled={!cnf.crdirdmr}
                    onChange={(e) => handleTextChange({ crdrdmrtxt: e })}
                    value={cnf.crdrdmrtxt} />
                </Box>
              </Box>
            }

            <Box paddingInlineStart={600} paddingBlock={400}>
              <TextField
                value={cnf.dsplshpnm ? cnf.dsplshpnm : ''}
                label="Shop name"
                onChange={(e) => handleTextChange({ dsplshpnm: e })} />
            </Box>

            {cnf && cnf.ivrfd !== undefined &&
              <Box paddingBlock={400}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show verified badge</Text>}
                  checked={cnf.ivrfd}
                  onChange={(e) => handleTextChange({ ivrfd: e })}
                />
                <Box paddingBlockStart={400}>
                  <Checkbox
                    disabled={!cnf.ivrfd}
                    label={<Text fontWeight='medium' as='span'>Show verified text on hover</Text>}
                    checked={cnf.ivrfdtxthvr}
                    onChange={(e) => handleTextChange({ ivrfdtxthvr: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.ivrfd}
                      onChange={(e) => handleTextChange({ vrfdtxt: e })}
                      value={cnf.vrfdtxt} />
                  </Box>
                </Box>
              </Box>
            }

            {cnf && cnf.irvwvtng !== undefined &&
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Show review upvotes/downvotes</Text>}
                checked={cnf.irvwvtng}
                onChange={(e) => handleTextChange({ irvwvtng: e })}
              />
            }

            {cnf && cnf.crdilctn !== undefined &&
              <Box paddingBlockStart={400}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show location</Text>}
                  checked={cnf.crdilctn}
                  onChange={(e) => handleTextChange({ crdilctn: e })}
                />
                <Box paddingBlockStart={100} paddingInlineStart={600}>
                  <TextField
                    disabled={!cnf.crdilctn}
                    onChange={(e) => handleTextChange({ crdlctnstr: e })}
                    value={cnf.crdlctnstr}
                  />
                </Box>
              </Box>
            }

          </Box>
          :
          state.sidebarActiveIndex === 2 ?
            <Box>

              {cnf && cnf.hdrittl !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show title text</Text>}
                    checked={cnf.hdrittl}
                    onChange={(e) => handleTextChange({ hdrittl: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.hdrittl}
                      value={cnf.hdrttltxt}
                      onChange={(e) => handleTextChange({ hdrttltxt: e })}
                      autoComplete="off" />
                  </Box>
                </Box>
              }

              {cnf && cnf.hdristrrtng !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show average star rating</Text>}
                    checked={cnf.hdristrrtng}
                    onChange={(e) => handleTextChange({ hdristrrtng: e })}
                  />
                  <Box paddingInlineStart={600}>
                    <Box paddingBlockStart={100} paddingBlockEnd={200}>
                      <TextField
                        disabled={!cnf.hdristrrtng}
                        value={cnf.hdravgtxt1}
                        onChange={(e) => handleTextChange({ hdravgtxt1: e })}
                        autoComplete="off" />
                    </Box>
                    <TextField
                      disabled={!cnf.hdristrrtng}
                      value={cnf.hdravgtxt2}
                      onChange={(e) => handleTextChange({ hdravgtxt2: e })}
                      autoComplete="off" />
                  </Box>
                </Box>
              }

              {cnf && cnf.hdristrdst !== undefined &&
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show star distribution analysis</Text>}
                  checked={cnf.hdristrdst}
                  onChange={(e) => handleTextChange({ hdristrdst: e })}
                />
              }

              {cnf && cnf.hdriwrtbtn !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show the “Write a review” button</Text>}
                    checked={cnf.hdriwrtbtn}
                    onChange={(e) => handleTextChange({ hdriwrtbtn: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.hdriwrtbtn}
                      value={cnf.hdrwrttxt}
                      onChange={(e) => handleTextChange({ hdrwrttxt: e })}
                      autoComplete="off" />
                  </Box>
                </Box>
              }

              {cnf && cnf.hdrircmnd !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show recommend analysis</Text>}
                    checked={cnf.hdrircmnd}
                    onChange={(e) => handleTextChange({ hdrircmnd: e })}
                  />
                  <Box paddingInlineStart={600}>
                    <Box paddingBlockStart={100} paddingBlockEnd={200}>
                      <TextField
                        disabled={!cnf.hdrircmnd}
                        value={cnf.hdrrcmndtxt1}
                        onChange={(e) => handleTextChange({ hdrrcmndtxt1: e })}
                        autoComplete="off" />
                    </Box>
                    <TextField
                      disabled={!cnf.hdrircmnd}
                      value={cnf.hdrrcmndtxt2}
                      onChange={(e) => handleTextChange({ hdrrcmndtxt2: e })}
                      autoComplete="off" />
                  </Box>
                </Box>
              }

            </Box>
            :
            state.sidebarActiveIndex === 3 ?
              <Box>

                <Text variant="bodyMd" fontWeight='bold'>Star rating summary</Text>
                {cnf &&
                  <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                    <InlineStack direction={'column'} gap={500}>
                      {cnf.hdrstrclr !== undefined &&
                        <ColorPick
                          labelName='Star color'
                          colorKey="hdrstrclr"
                          colorValue={cnf.hdrstrclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.hdrfntclr !== undefined &&
                        <ColorPick
                          labelName='Font color'
                          colorKey="hdrfntclr"
                          colorValue={cnf.hdrfntclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.hdrwrtbtnbgclr !== undefined &&
                        <ColorPick
                          labelName='Write review button'
                          colorKey="hdrwrtbtnbgclr"
                          colorValue={cnf.hdrwrtbtnbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.hdrwrtbtntxtclr !== undefined &&
                        <ColorPick
                          labelName='Write review text'
                          colorKey="hdrwrtbtntxtclr"
                          colorValue={cnf.hdrwrtbtntxtclr}
                          changeNameValue={handleTextChange} />
                      }
                    </InlineStack>
                  </Box>
                }

                <Text variant="bodyMd" fontWeight='bold'>Reviewer card</Text>
                {cnf &&
                  <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                    <InlineStack direction={'column'} gap={500}>
                      {cnf.crdbgclr !== undefined &&
                        <ColorPick
                          labelName='Background color'
                          colorKey="crdbgclr"
                          colorValue={cnf.crdbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdstrclr !== undefined &&
                        <ColorPick
                          labelName='Star color'
                          colorKey="crdstrclr"
                          colorValue={cnf.crdstrclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crddtclr !== undefined &&
                        <ColorPick
                          labelName='Date color'
                          colorKey="crddtclr"
                          colorValue={cnf.crddtclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdrvwnmclr !== undefined &&
                        <ColorPick
                          labelName='Name color'
                          colorKey="crdrvwnmclr"
                          colorValue={cnf.crdrvwnmclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vrfdtxtclr !== undefined &&
                        <ColorPick
                          labelName='Verified text color'
                          colorKey="vrfdtxtclr"
                          colorValue={cnf.vrfdtxtclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vrfdbgclr !== undefined &&
                        <ColorPick
                          labelName='Verified bg color'
                          colorKey="vrfdbgclr"
                          colorValue={cnf.vrfdbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdlctnstrclr !== undefined &&
                        <ColorPick
                          labelName='Location label text color'
                          colorKey="crdlctnstrclr"
                          colorValue={cnf.crdlctnstrclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdttlclr !== undefined &&
                        <ColorPick
                          labelName='Review title color'
                          colorKey="crdttlclr"
                          colorValue={cnf.crdttlclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdtxtclr !== undefined &&
                        <ColorPick
                          labelName='Review text color'
                          colorKey="crdtxtclr"
                          colorValue={cnf.crdtxtclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vtngclrfll !== undefined &&
                        <ColorPick
                          labelName='Vote fill color'
                          colorKey="vtngclrfll"
                          colorValue={cnf.vtngclrfll}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vtngclrntfll !== undefined &&
                        <ColorPick
                          labelName='Vote not-fill color'
                          colorKey="vtngclrntfll"
                          colorValue={cnf.vtngclrntfll}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crslpnclr !== undefined &&
                        <ColorPick
                          labelName='Product-name color'
                          colorKey="crslpnclr"
                          colorValue={cnf.crslpnclr}
                          changeNameValue={handleTextChange} />
                      }
                    </InlineStack>
                  </Box>
                }

                <Text variant="bodyMd" fontWeight='bold'>Load more button</Text>
                {cnf &&
                  <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={2400}>
                    <InlineStack direction={'column'} gap={500}>
                      {cnf.ldmrbgclr &&
                        <ColorPick
                          labelName='Background color'
                          colorKey="ldmrbgclr"
                          colorValue={cnf.ldmrbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.ldmrtxtclr &&
                        <ColorPick
                          labelName='Text color'
                          colorKey="ldmrtxtclr"
                          colorValue={cnf.ldmrtxtclr}
                          changeNameValue={handleTextChange} />
                      }
                    </InlineStack>
                  </Box>
                }

              </Box>
              :
              state.sidebarActiveIndex === 4 ?
                <Box>

                  {cnf && cnf.emtytxt !== undefined &&
                    <TextField
                      value={cnf.emtytxt}
                      label={<Text fontWeight='medium' as='span'>Message</Text>}
                      onChange={(e) => handleTextChange({ emtytxt: e })} />
                  }
                  {cnf && cnf.emtytxtclr !== undefined &&
                    <Box paddingBlockStart={400}>
                      <ColorPick
                        labelName='Message color'
                        colorKey="emtytxtclr"
                        colorValue={cnf.emtytxtclr}
                        changeNameValue={handleTextChange} />
                    </Box>
                  }
                  {cnf && cnf.emtybtntxt !== undefined &&
                    <Box paddingBlockStart={500}>
                      <TextField
                        value={cnf.emtybtntxt}
                        label={<Text fontWeight='medium' as='span'>Button text</Text>}
                        onChange={(e) => handleTextChange({ emtybtntxt: e })} />
                    </Box>
                  }
                  {cnf && cnf.emtybtnbgclr !== undefined &&
                    <Box paddingBlockStart={400} paddingBlockEnd={500}>
                      <ColorPick
                        labelName='Button color'
                        colorKey="emtybtnbgclr"
                        colorValue={cnf.emtybtnbgclr}
                        changeNameValue={handleTextChange} />
                    </Box>
                  }
                  {cnf && cnf.emtybtntxtclr !== undefined &&
                    <ColorPick
                      labelName='Button text color'
                      colorKey="emtybtntxtclr"
                      colorValue={cnf.emtybtntxtclr}
                      changeNameValue={handleTextChange} />
                  }

                </Box>
                :
                state.sidebarActiveIndex === 5 ?
                  <Box>

                    {cnf && cnf.icstcss !== undefined &&
                      <Box>
                        <Checkbox
                          label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                          checked={cnf.icstcss}
                          onChange={(e) => handleTextChange({ icstcss: e })}
                        />
                        <Box paddingBlockStart={200} paddingInlineStart={600}>
                          <TextField
                            label={<Text tone='subdued' as='span'>Custom css block</Text>}
                            disabled={!cnf.icstcss}
                            multiline={4}
                            value={cnf.cstcss}
                            onChange={(e) => handleTextChange({ cstcss: e })}
                            autoComplete="off" />
                        </Box>
                      </Box>
                    }

                  </Box>
                  :
                  state.sidebarActiveIndex === 6 &&
                  <Box>
                    {cnf && cnf.lng !== undefined &&
                      <Card>
                        <Select
                          label="Select language"
                          options={state.lngOptions}
                          onChange={props.handleLanguageChange}
                          value={cnf.lng}
                        />
                      </Card>
                    }
                  </Box>
      }

    </Box >
  );
}

export default TextWall;