import { fromJS } from 'immutable';

export const getCustmQue = (data) => ({ type: "GET_CUSTM_QUE", data });
export const getCustmQueSuccess = (data) => ({ type: "GET_CUSTM_QUE_SUCCESS", data });

export const createCustmQue = (data) => ({ type: "CREATE_CUSTM_QUE", data });

export const updateCustmQue = (data) => ({ type: "UPDATE_CUSTM_QUE", data });

export const deleteCustmQue = (data) => ({ type: "DELETE_CUSTM_QUE", data });

const initialState = fromJS({

});

function customQuestion(state = initialState, action) {
  switch (action.type) {

    case "GET_CUSTM_QUE_SUCCESS":
      return { ...state, QuestionList: action.data };

    default:
      return { ...state };
  }
}

export default customQuestion;