// import { fromJS } from 'immutable';

// export const getProduct = (data) => ({ type: "GET_PRODUCT", data });
// export const getProductSuccess = (data) => ({ type: "GET_PRODUCT_SUCCESS", data });

// export const getReviewByProduct = (data) => ({ type: "GET_REVIEW_BY_PRODUCT", data });
// export const getReviewByProductSuccess = (data) => ({ type: "GET_REVIEW_BY_PRODUCT_SUCCESS", data });

// export const updProductDetail = (data) => ({ type: "UPD_PRODUCT_DETAIL", data });

// const initialState = fromJS({

// });

// function product(state = initialState, action) {
//   switch (action.type) {

//     case "GET_PRODUCT_SUCCESS":
//       return { ...state, ProductList: action.data };

//     case "GET_REVIEW_BY_PRODUCT_SUCCESS":
//       return { ...state, ProductReviews: action.data };

//     default:
//       return { ...state };
//   }
// }

// // export default product;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const product = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProduct: (state, action) => { },
    getProductSuccess: (state, action) => {
      return { ...state, ProductList: action.payload };
    },
    getReviewByProduct: (state, action) => { },
    getReviewByProductSuccess: (state, action) => {
      return { ...state, ProductReviews: action.payload };
    },
    updProductDetail: (state, action) => { },
  }
});

export const {
  getProduct,
  getProductSuccess,
  getReviewByProduct,
  getReviewByProductSuccess,
  updProductDetail
} = product.actions;

export default product.reducer;