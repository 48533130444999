// import { fromJS } from 'immutable';

// export const getProductName = (data) => ({ type: "GET_PRODUCT_NAME", data });
// export const getProductNameSuccess = (data) => ({ type: "GET_PRODUCT_NAME_SUCCESS", data });

// export const getGroupList = (data) => ({ type: "GET_GROUP_LIST", data });
// export const getGroupListSuccess = (data) => ({ type: "GET_GROUP_LIST_SUCCESS", data });

// export const createGroup = (data) => ({ type: "CREATE_GROUP", data });
// export const createGroupSuccess = (data) => ({ type: "CREATE_GROUP_SUCCESS", data });

// export const updateGroup = (data) => ({ type: "UPDATE_GROUP", data });
// export const updateGroupSuccess = (data) => ({ type: "UPDATE_GROUP_SUCCESS", data });

// export const deleteGroup = (data) => ({ type: "DELETE_GROUP", data });
// export const deleteGroupSuccess = (data) => ({ type: "DELETE_GROUP_SUCCESS", data });

// const initialState = fromJS({

// });

// function groupProduct(state = initialState, action) {
//   switch (action.type) {

//     case "GET_PRODUCT_NAME_SUCCESS":
//       return { ...state, ProductName: action.data };

//     case "GET_GROUP_LIST_SUCCESS":
//       return { ...state, groupList: action.data };

//     case "CREATE_GROUP_SUCCESS":
//       return { ...state, createSuccess: action.data };

//     case "UPDATE_GROUP_SUCCESS":
//       return { ...state, updateSuccess: action.data };

//     case "DELETE_GROUP_SUCCESS":
//       return { ...state, deleteSuccess: action.data };

//     default:
//       return { ...state };
//   }
// }

// export default groupProduct;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

const groupProduct = createSlice({
  name: 'groupProduct',
  initialState,
  reducers: {
    setLoading: (state, action) => { },
    setLoadingSuccess: (state, action) => {
      return { ...state, loading: action.payload };
    },

    getProductName: (state, action) => { },
    getProductNameSuccess: (state, action) => {
      return { ...state, ProductName: action.payload };
    },
    getGroupList: (state, action) => { },
    getGroupListSuccess: (state, action) => {
      return { ...state, groupList: action.payload };
    },
    createGroup: (state, action) => { },
    createGroupSuccess: (state, action) => {
      return { ...state, createSuccess: action.payload };
    },
    updateGroup: (state, action) => { },
    updateGroupSuccess: (state, action) => {
      return { ...state, updateSuccess: action.payload };
    },
    deleteGroup: (state, action) => { },
    deleteGroupSuccess: (state, action) => {
      return { ...state, deleteSuccess: action.payload };
    }
  }
});

export const {
  setLoading,
  setLoadingSuccess,
  getProductName,
  getProductNameSuccess,
  getGroupList,
  getGroupListSuccess,
  createGroup,
  createGroupSuccess,
  updateGroup,
  updateGroupSuccess,
  deleteGroup,
  deleteGroupSuccess
} = groupProduct.actions;

export default groupProduct.reducer;