import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/currentQueue';
import { toastify } from '../ducks/toast';

function* getEmailQueue(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getEmailQueue', action.payload);
    if (res.status === 'success') {
      yield put(actions.getEmailQueueSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteEmailQueue(action) {
  try {
    const res = yield call(api.POST, '/deleteEmailQueue', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* retryEmailQueue(action) {
  try {
    const res = yield call(api.POST, '/retryEmailQueue', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetEmailQueue() {
  yield takeLatest(actions.getEmailQueue.type, getEmailQueue);
}
export function* watchDeleteEmailQueue() {
  yield takeLatest(actions.deleteEmailQueue.type, deleteEmailQueue);
}
export function* watchRetryEmailQueue() {
  yield takeLatest(actions.retryEmailQueue.type, retryEmailQueue);
}

export default function* rootSaga() {
  yield fork(watchGetEmailQueue);
  yield fork(watchDeleteEmailQueue);
  yield fork(watchRetryEmailQueue);
}