import { Page } from '@shopify/polaris';
import { CommingSoon } from '..';

function List(props) {
  return (
    <Page
      fullWidth
      title={"Check Out Review"}>

      <CommingSoon />
    </Page>
  );
}

export default List;