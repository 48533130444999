import { InlineStack, Card, Spinner, Text, InlineGrid, Icon, Page, Button, Box } from '@shopify/polaris';
import MainTab from './MainTab';
import { EditIcon } from '@shopify/polaris-icons';
import ConfirmationAlert from '../ConfirmationModal/ConfirmationAlert';
import { useState, useCallback, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as emailDucks from '../../ducks/email';
import * as authDucks from '../../ducks/auth';
import * as integrationDucks from '../../ducks/integration';
import * as masterDucks from '../../ducks/master';
import * as settingDucks from '../../ducks/setting';
import { toastify } from '../../ducks/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ButtonPressIcon, RefreshIcon } from '@shopify/polaris-icons';
import { changePathForShopifyAppEmbed } from "../../utils/utils";
const moment = require('moment');

function momentToDate(dt) {
  return moment(new Date(dt)).toDate().toDateString();
}

const templatesArr = ['on_followup', 'on_order_fulfill', 'one_time_campaign', 'media_request'];

const import_obj = {
  typ: 'imprt_review',
  dspttl: 'Import reviews with CSV',
  dspdsc: 'Upload your CSV file of reviews and map your review data'
}

let MainPageArr = [
  {
    typ: 'automation',
    img: RefreshIcon,
    activeTyp: 'on_order_fulfill',
    rmk: 'Set up automate review requests',
    title: 'Automate review campaign',
    desc: 'Set follow-up, channels, and message templates for collecting reviews, ideal for new buyer engagement'
  },
  {
    typ: 'manual',
    img: ButtonPressIcon,
    activeTyp: 'one_time_campaign',
    rmk: 'Manual review campaign setup',
    title: 'Manual campaign',
    desc: 'Gather reviews by uploading customer details from CSV files, utilizing existing order information'
  }
];

function List(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    ddValue: 1,
    CampgnCount: 0,
    minStarRate: '',
    proIdUpd: '', //on updateClick id
    emailTyp: '',
    sbtyp: '',
    selSeqName: '',  //title of innerpage
    subTtl: '',  //subtitle of innerpage
    htmltmpl: '',  //html
    emlsqmstid: '',
    emailTest: '',
    htmlcrd: '',
    temp_id: '',
    dataTxt: '',   // condition data
    ctyp: '',  // condition typ
    cnd: '',   // condition
    emlTypName: '',
    searchParam: '',
    searchPath: '',
    mnrtng: '',
    mainTtl: 'Set up automate review requests',
    tabTyp: '',
    campTyp: '',
    confirmOpen: false,
    isNewEditPanel: false,
    iarrcnd: false,
    isLaunchCmp: false,
    iexclitem: false,
    isConfiguration: false, //is show configuration screen
    isMobile: false,
    resetOpen: false,
    testEmailOpen: false,
    launchOpen: false,
    filterCollapseOpen: false,
    emailErr: false,
    isShwEditPanel: false, //show edit panel of emailTemplate
    isddValue: false,
    isEdit: false,  //is show "save"
    isSelectTypeOn: false,
    isSelectCondtOn: false,
    isShowMessage: false,
    isAutoConfiguration: false,
    errMessage: {},
    configStateData: {},
    ProfileObj: {},
    emailObj: {},  //obj same as cnf (lgurl,sbjct,msg,rtngtxt,irttl,rttltxt,irdsc,rdsctxt,rbtntxt,clstmnt)
    emailMSTArr: [],  //latest new master data
    emlNewSeqData: [],
    newSeqData: [],
    arrcnd: [],
    userIntegration: [],
    integrationList: [],
    HistoryData: [],
    MainPageArr: [],
    frmdt: momentToDate(moment(new Date()).subtract(24, 'hour').format('YYYY-MM-DD')),
    tdt: momentToDate(moment(new Date()).format('YYYY-MM-DD'))
  });

  // main common function for update state & value
  const changeNameValue = useCallback((obj, isShowMessage) => {
    if (isShowMessage)
      changeNameValue({ isShowMessage: true });

    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    dispatch(emailDucks.getSeqData());
    changeNameValue({ MainPageArr: MainPageArr });
  }, []);

  // seq api data
  const masterState = useSelector(state => state.master.MasterData);
  const seqState = useSelector(state => state.email);
  const UserIntegration = useSelector(state => state.integration.UserIntegration);
  const historyState = useSelector(state => state.email.HistoryList);
  const ProfileData = useSelector(state => state.auth.profile);
  const CmpgnCnt = useSelector(state => state.email.CampgnCount);

  useEffect(() => {
    if (seqState.SeqList && seqState.SeqList.data) {
      let _filterSeqData = [], SeqFilterData = [];

      const seqCopyState = seqState.SeqList.data.map(item => ({ ...item }));
      _filterSeqData = [import_obj, ...seqCopyState.filter((data) => templatesArr.includes(data.typ))];

      // followup/fullfill get unique array
      SeqFilterData = _filterSeqData.filter((obj, i) => i === _filterSeqData.findIndex(x => obj.typ === x.typ));
      changeNameValue({ newSeqData: _filterSeqData, SeqFilterData });
    }
  }, [seqState]);

  useEffect(() => { //store emlSequence data in arr
    let _seqEml = [];

    if (state.newSeqData && state.newSeqData.length > 0) {
      state.newSeqData.filter(data => {
        if (data.typ === state.emailTyp) {
          _seqEml.push(data);
          if (data.sbtyp && data.sbtyp === state.sbtyp) {
            changeNameValue({ proIdUpd: data._id });
          }
        }
      });

      let obj = {
        emailSeqArr: _seqEml,
        emlNewSeqData: state.newSeqData
      };
      changeNameValue(obj);
    }
  }, [seqState, state.sbtyp, state.emailTyp, state.newSeqData]);

  useEffect(() => {
    let obj = {
      isCmpgnLoad: CmpgnCnt !== undefined ? false : true,
      CampgnCount: CmpgnCnt ? CmpgnCnt : 0
    }
    changeNameValue(obj);
  }, [CmpgnCnt]);

  useEffect(() => { //get objshp from userData
    if (ProfileData !== null && ProfileData !== undefined && ProfileData.objshp)
      changeNameValue({ ProfileObj: ProfileData });
    else dispatch(authDucks.getProfile());
  }, [ProfileData]);

  useEffect(() => {
    if (!masterState || masterState === undefined) {
      let masterDT = setTimeout(() => dispatch(masterDucks.getMasterData()), 700);
      return () => clearTimeout(masterDT);
    }
  }, [masterState]);

  useEffect(() => {
    if (historyState?.data?.length > 0) {
      changeNameValue({ HistoryData: historyState.data });
    }
  }, [historyState]);

  useEffect(() => {  // store emlMaster values in arr
    let _arrEml = [], obj = {};

    if (masterState && masterState.emailSequenceData && masterState.emailSequenceData.length > 0) {
      masterState.emailSequenceData.filter(y => {
        if (templatesArr.includes(y.typ)) {
          _arrEml.push(y);
          if (y.typ === state.emailTyp) {
            if (y.sbtyp === state.sbtyp) {
              obj['htmltmpl'] = y.htmltmpl;
              if (y.htmlcrd)
                obj['htmlcrd'] = y.htmlcrd;
            }
          }
        }
      });
      obj['emailMSTArr'] = _arrEml;
      changeNameValue(obj);
    }
  }, [masterState, state.emailTyp, state.emlsqmstid, state.sbtyp]);

  useEffect(() => {
    if (seqState && seqState.configData) {
      changeNameValue({ configStateData: seqState.configData });
    }
  }, [seqState.configData]);

  useEffect(() => {
    if (state.configStateData) {
      let { configStateData } = state;

      let obj = {
        iarrcnd: configStateData.iarrcnd ? configStateData.iarrcnd : false,
        ia: configStateData.ia ? configStateData.ia : false,
        ddValue: configStateData.dd ? configStateData.dd : 1,
        arrcnd: configStateData.arrcnd,
        iexclitem: configStateData.iexclitem ? configStateData.iexclitem : false,
        frmdtState: configStateData.frmdt,
        tdtState: configStateData.tdt,
        frmdt: momentToDate(configStateData.frmdt),
        tdt: momentToDate(configStateData.tdt),
        mnrtng: configStateData.mnrtng ? configStateData.mnrtng : ''
      };
      changeNameValue(obj);
    }
  }, [state.configStateData]);

  useEffect(() => {
    const { integrationData } = masterState || {};
    const { userIntegration } = state;

    if (integrationData?.length > 0)
      changeNameValue({ integrationList: masterState?.integrationData });

    if (UserIntegration?.length > 0 && integrationData?.length > 0) {
      const updatedData = userIntegration.map(dataItem => {
        const matchingIntegration = integrationData.find(integration => integration.in === dataItem.inm);
        if (matchingIntegration) {
          return {
            ...dataItem,
            img: matchingIntegration.img,
            dn: matchingIntegration.dn,
            dsc: matchingIntegration.dsc,
            ctg: matchingIntegration.ctg
          };
        }
        return dataItem;
      });

      if (updatedData !== userIntegration) {
        changeNameValue({ userIntegration: updatedData });
      }
    }
  }, [masterState, masterState?.integrationData, UserIntegration, state.userIntegration]);

  useEffect(() => {
    let mnthYear = {
      month: new Date(state.frmdt).getMonth(),
      year: new Date(state.frmdt).getFullYear()
    };
    changeNameValue({ mnthYear });
  }, [state.frmdt]);

  useEffect(() => { //if api call data & current state data not matched, disable launch btn
    let _frm = moment(new Date(state.frmdt)).format('YYYY-MM-DD') + " 00:00:00";
    let _to = moment(new Date(state.tdt)).format('YYYY-MM-DD') + " 23:59:59";

    if (state.configStateData) {
      const isMatch = state.configStateData.frmdt === _frm &&
        state.configStateData.tdt === _to &&
        state.configStateData.iexclitem === state.iexclitem || false;

      changeNameValue({ isLaunchCmp: isMatch });
    }
  }, [state.configStateData, state.frmdt, state.tdt, state.iexclitem, state.frmdtState, state.tdtState]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  state.searchParam = queryParams.get("typ") && queryParams.get("typ").split('_')[0];
  state.searchPath = props.searchPath ? props.searchPath : '';

  useEffect(() => {
    switch (state.searchParam || state.searchPath) {
      case 'import':
        let impObj = {
          isAutoConfiguration: true,
          emailTyp: 'imprt_review',
          typArr: ['imprt_review'],
          mainTtl: 'Manual review campaign setup',
          selSeqName: 'Import reviews with CSV',
          subTtl: 'Upload your CSV file of reviews and map your review data'
        }
        changeNameValue(impObj);
        global.SeqEmailTyp = 'imprt_review';
        break;
      case 'automation':
        let autoMateObj = {
          isAutoConfiguration: true,
          emailTyp: 'on_order_fulfill',
          typArr: ['on_order_fulfill', 'on_followup', 'media_request'],
          mainTtl: 'Set up automate review requests',
          selSeqName: 'Ask for reviews',
          subTtl: 'Optimize your review strategy with automation settings',
          campTyp: 'automation',
        }
        changeNameValue(autoMateObj);
        global.SeqEmailTyp = 'on_order_fulfill';
        dispatch(emailDucks.getConfigByType({ typ: 'on_order_fulfill' }));
        dispatch(integrationDucks.getUserIntegration({ shp: localStorage.getItem('shp'), typ: 'automation' }));
        break;
      case 'manual':
        let manualObj = {
          isAutoConfiguration: true,
          emailTyp: 'one_time_campaign',
          typArr: ['one_time_campaign', 'imprt_review'],
          mainTtl: 'Manual review campaign setup',
          selSeqName: 'Campaign for past customers',
          subTtl: 'Run a one-time review request campaign for existing buyers',
          campTyp: 'manual',
        }
        changeNameValue(manualObj);
        global.SeqEmailTyp = 'one_time_campaign';
        dispatch(emailDucks.getConfigByType({ typ: 'one_time_campaign' }));
        dispatch(emailDucks.getCampaignHistory({ shp: localStorage.getItem('shp') }));
        dispatch(integrationDucks.getUserIntegration({ shp: localStorage.getItem('shp'), typ: 'automation' }));
        break;
      default:
        break;
    }

    if (!state.searchParam && !state.searchPath) //onclick sidebar menu, back to main page
      changeNameValue({ isAutoConfiguration: false });

  }, [state.searchParam, state.searchPath]);

  useEffect(() => { //on refresh
    if (state.isShowMessage === true || state.isEdit === true) //on sidebar click alert
      localStorage.setItem('isShowLert', true)
    else
      localStorage.removeItem('isShowLert');

    const handleBeforeUnload = (e) => {
      if (localStorage.getItem('isShowLert')) {
        // Display a confirmation dialog
        const confirmationMessage = 'Are you sure you want to leave this page?';
        e.preventDefault();
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [state.isShowMessage, state.isEdit]);

  const saveChanges = () => {
    openCloseModal('confirmOpen', state.confirmOpen, 'close');
  }

  const discardChanges = () => {
    let obj = {
      isShowMessage: false,
      isEdit: false,
    }
    changeNameValue(obj);
    openCloseModal('confirmOpen', state.confirmOpen, 'close');

    if (state.eventName === 'backToTempList')
      backTempList('isActive');
    else if (state.eventName === 'backToTab')
      handleInnerTabClick(state.tabTyp, 'isActive');
    else //go to main page
      handleBackToMain('isActive');
  }

  const clickOnCampaign = useCallback((typ) => {
    if (typ && state.MainPageArr && state.MainPageArr.length > 0) {
      state.MainPageArr.map((data) => {
        if (data.typ === typ) {
          let obj = {
            campTyp: data.typ,
            isAutoConfiguration: true,
            mainTtl: data.rmk,
            isShowMessage: false,
            emailTyp: data.activeTyp
          }
          changeNameValue(obj);
          handleInnerTabClick(data.activeTyp, 'isActive');
          // if (state.userIntegration && state.userIntegration.length <= 0)
          //   dispatch(integrationDucks.getUserIntegration({ shp: localStorage.getItem('shp'), typ: 'automation' }));

          if (localStorage.getItem('is_shopify_embed')) {
            changePathForShopifyAppEmbed('/sequence/' + data.typ);
          }
          else {
            navigate('?typ=' + data.typ);
          }
        }
      });
    }
  }, [state.emailMSTArr, state.MainPageArr]);

  const handleInnerTabClick = useCallback((typ, isActive) => {
    changeNameValue({ tabTyp: typ });

    if ((state.isShowMessage !== true && typ) || isActive) {
      if (typ !== 'imprt_review' && state.emailTyp !== typ) //call config api except 'import review'
        dispatch(emailDucks.getConfigByType({ typ: typ }));

      //if typ don't match then call fun. without data & matching typ
      if (state.SeqFilterData && state.SeqFilterData.length > 0 && state.configStateData && state.configStateData.typ) {
        state.SeqFilterData.map((data) => { if (data.typ === typ) getTabClkDetail(typ, data); });
      }
      //calling this fun. without passing data, for create template
      getTabClkDetail(typ);
    }
    else {
      changeNameValue({ eventName: 'backToTab' });
      openCloseModal('confirmOpen', state.confirmOpen, 'open');
    }
  }, [state.emailMSTArr, state.SeqFilterData, state.isShowMessage, masterState, state.configStateData]);

  const getTabClkDetail = (typ, data) => {
    let dataObj = {};
    if (data) dataObj = data;
    state.emailMSTArr.push(import_obj);

    state.emailMSTArr && state.emailMSTArr.length > 0 && state.emailMSTArr.map((val) => {
      if (val.typ === typ) {
        dataObj['dspttl'] = val.dspttl;
        dataObj['dspdsc'] = val.dspdsc;
      }
    });

    let obj = {
      errMessage: {}, //remove all err on tab click 
      emailTyp: typ,
      selSeqName: dataObj.dspttl,
      subTtl: dataObj.dspdsc,
      proIdUpd: dataObj._id ? dataObj._id : '',
      emlsqmstid: dataObj.emlsqmstid ? dataObj.emlsqmstid : '',
      sbtyp: dataObj.sbtyp ? dataObj.sbtyp : '',
      ctyp: '',
      cnd: '',
      dataTxt: ''
    }
    changeNameValue(obj);
    global.SeqEmailTyp = typ;

    if (typ === 'one_time_campaign' && state.HistoryData && state.HistoryData.length <= 0 &&
      state.emailTyp !== typ)
      dispatch(emailDucks.getCampaignHistory({ shp: localStorage.getItem('shp') }));

    setTimeout(() => changeNameValue({ isAutoConfiguration: true }), 100);
  }

  // only for template edit field
  const handleTextChange = (obj, isEditable) => {
    let currentState = { ...state.emailObj, ...obj };

    changeNameValue({
      emailObj: currentState,
      isEdit: isEditable ? true : false
    });
  }

  const handleStatusUpdate = (_id, ia) => {
    if (_id) {
      let obj = { _id: _id, ia: ia };
      dispatch(emailDucks.updateSequence(obj));
    }
  }

  const updateTmpStatus = (mainData, data, ia) => {
    let obj = {
      id: mainData._id,
      tid: data._id
    };
    if (state.emailTyp === 'on_order_fulfill')
      obj['iordfulfil'] = ia;
    else if (state.emailTyp === 'on_followup')
      obj['iflwup'] = ia;
    else if (state.emailTyp === 'one_time_campaign')
      obj['imnlcmpgn'] = ia;
    else if (state.emailTyp === 'media_request')
      obj['imda'] = ia;

    dispatch(emailDucks.updTmpTypeStatus(obj));
  }

  const updDaysField = (typ) => {
    let obj = {};
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {} });

      let _frmDt = moment(new Date(state.frmdt)).format('YYYY-MM-DD') + " 00:00:00";
      let _toDt = moment(new Date(state.tdt)).format('YYYY-MM-DD') + " 23:59:59";

      if (typ === 'one_time_campaign') {
        obj = {
          frm: 'config',
          typ: state.emailTyp,
          frmdt: _frmDt,
          tdt: _toDt,
          iexclitem: state.iexclitem
        }
      }
      else { //for upd 'dd' field screen 
        obj = {
          frm: 'config',
          typ: state.emailTyp,
          dd: state.ddValue ? state.ddValue : 0
        }
      }

      if (Object.keys(obj).length > 0)
        dispatch(emailDucks.updConfig(obj));
    }

    changeNameValue(obj);
    changeNameValue({ isShowMessage: false });
  }

  const openCloseModal = useCallback((name, value, type) => { //use of type is don't close modal on click of outside
    if (type !== undefined) changeNameValue({ [name]: !value });
  }, [changeNameValue]);

  const editTemplateClk = (data) => {
    let obj = {
      isConfiguration: true,
      isNewEditPanel: true,
      proIdUpd: data._id,
      emailTyp: data.typ,
      emlTypName: data.nm,
      emlsqmstid: data.emlsqmstid,
      sbtyp: data.sbtyp,
      tabTyp: ''
    }
    changeNameValue(obj);

    let stateObj = {};
    if (data.lgurl) stateObj['lgurl'] = data.lgurl;
    if (data.sbjct) stateObj['sbjct'] = data.sbjct;
    if (data.msg) stateObj['msg'] = data.msg;
    if (data.rtngtxt) stateObj['rtngtxt'] = data.rtngtxt;
    if (data.irttl) stateObj['irttl'] = data.irttl;
    if (data.rttltxt) stateObj['rttltxt'] = data.rttltxt;
    if (data.irdsc) stateObj['irdsc'] = data.irdsc;
    if (data.rdsctxt) stateObj['rdsctxt'] = data.rdsctxt;
    if (data.rbtntxt) stateObj['rbtntxt'] = data.rbtntxt;
    if (data.clstmnt) stateObj['clstmnt'] = data.clstmnt;
    handleTextChange(stateObj);
  }

  const createSeqTemplate = (data) => {
    if (data) {
      let obj = {
        selSeqName: data.dspttl,
        subTtl: data.dspdsc,
        emailTyp: data.typ,
        sbtyp: data.sbtyp,
        emlTypName: data.nm
      };
      changeNameValue(obj);

      let stateObj = {};
      if (data.lgurl) stateObj['lgurl'] = data.lgurl;
      if (data.sbjct) stateObj['sbjct'] = data.sbjct;
      if (data.msg) stateObj['msg'] = data.msg;
      if (data.rtngtxt) stateObj['rtngtxt'] = data.rtngtxt;
      if (data.irttl) stateObj['irttl'] = data.irttl;
      if (data.rttltxt) stateObj['rttltxt'] = data.rttltxt;
      if (data.irdsc) stateObj['irdsc'] = data.irdsc;
      if (data.rdsctxt) stateObj['rdsctxt'] = data.rdsctxt;
      if (data.rbtntxt) stateObj['rbtntxt'] = data.rbtntxt;
      if (data.clstmnt) stateObj['clstmnt'] = data.clstmnt;
      handleTextChange(stateObj);

      global.SeqEmailTyp = data.typ;

      setTimeout(() => {
        let obj = {
          isNewEditPanel: true,
          isConfiguration: true
        }
        changeNameValue(obj);
      }, 100);

      dispatch(settingDucks.createTemplate({ id: data._id }));
    }
  }

  const handleDelCondition = (data) => {
    let obj = {
      _id: data._id,
      typ: state.emailTyp
    }
    dispatch(emailDucks.removeCondition(obj));
  }

  const handleLaunchCampClk = () => {
    openCloseModal('launchOpen', state.launchOpen, 'open');
    dispatch(emailDucks.getCampaignCnt());
  }

  const launchCampaign = () => {
    let obj = {
      shp: localStorage.getItem('shp')
    }
    dispatch(emailDucks.createCampaign(obj));
    setTimeout(() => openCloseModal('launchOpen', state.launchOpen, 'close'), 100);
  }

  const updConfigData = (stateObj, frm) => {
    // 'turn-on-automation(frm-'main)' & 'minimum-rate(frm-media)'
    let obj = {
      frm: frm,
      typ: state.emailTyp,
      ...stateObj
    }
    dispatch(emailDucks.updConfig(obj));
    changeNameValue(stateObj);
  }

  const saveMediaConfig = () => {
    let obj = {
      frm: 'config',
      typ: state.emailTyp,
      mnrtng: state.mnrtng
    }
    dispatch(emailDucks.updConfig(obj));
  }

  const handleConditionAdd = (stateObj, typ) => {
    if (typ === 'radio') {
      let payLoadObj = {
        frm: 'condition',
        typ: state.emailTyp,
        ...stateObj
      }
      dispatch(emailDucks.updConfig(payLoadObj));
      changeNameValue(stateObj);
    }
    else {
      if (state.ctyp && state.cnd && state.dataTxt) {
        let payLoadObj = {
          frm: 'condition',
          typ: state.emailTyp,
          ctyp: state.ctyp,
          cnd: state.cnd,
          data: state.dataTxt
        }
        dispatch(emailDucks.updConfig(payLoadObj));
        let stateObj = {
          ctyp: '',
          cnd: '',
          dataTxt: '',
          isShowMessage: false
        }
        changeNameValue(stateObj);
      }
      else dispatch(toastify({ type: 'error', msg: 'Please fill out all the fields' }));
    }
  }

  const backTempList = (isActive) => {
    if (state.isEdit !== true || isActive)
      changeNameValue({ isNewEditPanel: false, emailObj: {}, proIdUpd: '' });
    else {
      changeNameValue({ eventName: 'backToTempList' });
      openCloseModal('confirmOpen', state.confirmOpen, 'open');
    }
  }

  const handleBackToMain = (isActive) => {
    if (state.isShowMessage !== true || isActive) {

      let rdctPage = '/sequence', shopPage = '/shopifyAuth'; //default back to main page
      if (props.isShwBack === true) //comes from other page
        rdctPage = props.frm; shopPage = props.frm;

      if (window.location.search !== '') {
        if (window.location.search && window.location.search.split('_')[1]) {
          let typ = window.location.search.split('_')[1];
          if (typ === 'ds') shopPage = 'shopifyAuth'; //from dashboard
          else if (typ === 'mn') shopPage = 'reviewmoderate';  //from manageReview 
        }

        if (localStorage.getItem('is_shopify_embed')) {
          changePathForShopifyAppEmbed(shopPage);
        }
        else navigate(-1);
      }
      else {
        if (localStorage.getItem('is_shopify_embed'))
          changePathForShopifyAppEmbed(shopPage);
        else navigate(rdctPage);
      }

      let obj = {
        isAutoConfiguration: false,
        isShowMessage: false
      }
      changeNameValue(obj);
    }
    else {
      changeNameValue({ eventName: 'backToMain' });
      openCloseModal('confirmOpen', state.confirmOpen, 'open');
    }
  }

  useEffect(() => {
    changeNameValue({ isShowMessage: false });
  }, []);

  return (
    <div className='Seq_page'>

      {state.isAutoConfiguration !== true ?
        <Page
          title={'Configure review collection flow'}
          subtitle={'Gather reviews through email, SMS, and WhatsApp via both automated and manual campaigns'}>

          <Box padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockStart={400}>
            <Text variant='headingMd' fontWeight='bold' as='h3'>Collect reviews</Text>
            <Box paddingBlockStart={300}>

              {state.emailMSTArr && state.emailMSTArr.length > 0 &&
                state.SeqFilterData && state.SeqFilterData.length > 0 ?
                <InlineGrid columns={{ md: 2, sm: 1 }} gap={400}>
                  {state.MainPageArr && state.MainPageArr.length > 0 && state.MainPageArr.map((data, i) => {
                    return (
                      <div key={i} id='cursorPoint' onClick={() => clickOnCampaign(data.typ)}>
                        <Card>
                          <InlineStack align='space-between' blockAlign='center'>
                            <div className='automate_img'><Icon source={data.img} /></div>
                            <Button variant='secondary' icon={EditIcon} onClick={() => clickOnCampaign(data.typ)}>Edit</Button>
                            <Box paddingBlockStart={400}>
                              <InlineStack gap={200}>
                                <Text variant='bodyLg' fontWeight='semibold' as='h4'>{data.title}</Text>
                                <Text variant='bodyMd' fontWeight='regular' as='p' tone='subdued'>{data.desc}</Text>
                              </InlineStack>
                            </Box>
                          </InlineStack>
                        </Card>
                      </div>
                    );
                  })
                  }
                </InlineGrid>
                :
                <Box paddingBlockStart={600}>
                  <InlineStack align='center'>
                    <Spinner accessibilityLabel="Spinner example" size="large" />
                  </InlineStack>
                </Box>
              }

            </Box>
          </Box>
        </Page>
        :
        <MainTab
          state={state}
          updConfigData={updConfigData}
          saveMediaConfig={saveMediaConfig}
          validator={validator}
          handleInnerTabClick={handleInnerTabClick}
          changeNameValue={changeNameValue}
          handleStatusUpdate={handleStatusUpdate}
          updateTmpStatus={updateTmpStatus}
          handleTextChange={handleTextChange}
          editTemplateClk={editTemplateClk}
          updDaysField={updDaysField}
          handleConditionAdd={handleConditionAdd}
          handleDelCondition={handleDelCondition}
          openCloseModal={openCloseModal}
          launchCampaign={launchCampaign}
          clickOnCampaign={clickOnCampaign}
          backTempList={backTempList}
          handleBackToMain={handleBackToMain}
          saveChanges={saveChanges}
          discardChanges={discardChanges}
          createSeqTemplate={createSeqTemplate}
          handleLaunchCampClk={handleLaunchCampClk} />
      }

      <ConfirmationAlert
        state={state}
        openCloseModal={openCloseModal}
        saveChanges={saveChanges}
        discardChanges={discardChanges}
      />

    </div >
  );
}

export default memo(List);