import { Box, Button, Page, Text, ButtonGroup, Card, Icon } from '@shopify/polaris';
import { FileFilledIcon, SlideshowIcon, MegaphoneFilledIcon, MegaphoneIcon, StarFilledIcon, ClockIcon, StarIcon } from '@shopify/polaris-icons';
import AllCards from './AllCards';
import ImportTab from './ImportTab';
import MainEditPanel from './MainEditPanel';

const MainTab = ({
  state,
  validator,
  handleInnerTabClick,
  changeNameValue,
  handleStatusUpdate,
  updConfigData,
  updateTmpStatus,
  handleTextChange,
  editTemplateClk,
  updDaysField,
  handleConditionAdd,
  handleDelCondition,
  openCloseModal,
  launchCampaign,
  clickOnCampaign,
  backTempList,
  handleBackToMain,
  saveChanges,
  discardChanges,
  createSeqTemplate,
  saveMediaConfig,
  handleLaunchCampClk
}) => {

  let automationArr = [
    {
      typ: 'on_order_fulfill',
      icon: StarIcon,
      activeIcon: StarFilledIcon,
      label: 'Ask for reviews'
    },
    {
      typ: 'on_followup',
      icon: ClockIcon,
      activeIcon: ClockIcon,
      label: 'Reminder'
    },
    {
      typ: 'media_request',
      icon: SlideshowIcon,
      activeIcon: SlideshowIcon,
      label: 'Media'
    }
  ];

  let manualArr = [
    {
      typ: 'one_time_campaign',
      icon: MegaphoneIcon,
      activeIcon: MegaphoneFilledIcon,
      label: 'Campaign for past customers'
    },
    {
      typ: 'imprt_review',
      icon: FileFilledIcon,
      activeIcon: FileFilledIcon,
      label: 'Import reviews with CSV'
    }
  ];

  return (
    <div>
      {state.isNewEditPanel !== true ?
        <Page
          title={state.mainTtl}
          backAction={{ onAction: () => handleBackToMain() }}>

          <Box paddingInlineStart={200}>
            <Box paddingBlockEnd={400}>
              <div className='subdubd_bg cutsm_Poltab  '>
                <Card padding={100} roundedAbove='md'>

                  {/* automation typ tabs */}
                  {state.campTyp === 'automation' ?
                    <ButtonGroup>
                      {automationArr && automationArr.length > 0 && automationArr.map((data, i) => {
                        return (
                          <Button key={i} variant={state.emailTyp === data.typ ? 'tertiary' : ''}
                            icon={<Icon source={state.emailTyp === data.typ ? data.activeIcon : data.icon}
                              tone={state.emailTyp === data.typ ? '' : 'subdued'} />}
                            onClick={() => handleInnerTabClick(data.typ)}>
                            <Text fontWeight='medium'
                              tone={state.emailTyp === data.typ ? '' : 'subdued'}>{data.label}</Text>
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                    :  //manual typ tabs
                    <ButtonGroup>
                      {manualArr && manualArr.length > 0 && manualArr.map((data, i) => {
                        return (
                          <Button key={i} variant={state.emailTyp === data.typ ? 'tertiary' : ''}
                            icon={<Icon source={state.emailTyp === data.typ ? data.activeIcon : data.icon}
                              tone={state.emailTyp === data.typ ? '' : 'subdued'} />}
                            onClick={() => handleInnerTabClick(data.typ)}>
                            <Text fontWeight='medium'
                              tone={state.emailTyp === data.typ ? '' : 'subdued'}>{data.label}</Text>
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  }

                </Card>
              </div>
            </Box>

            {state.emailTyp === 'imprt_review' ?
              <ImportTab
                state={state}
              />
              :
              <AllCards
                state={state}
                updConfigData={updConfigData}
                validator={validator}
                changeNameValue={changeNameValue}
                handleStatusUpdate={handleStatusUpdate}
                updateTmpStatus={updateTmpStatus}
                handleTextChange={handleTextChange}
                editTemplateClk={editTemplateClk}
                updDaysField={updDaysField}
                handleConditionAdd={handleConditionAdd}
                handleDelCondition={handleDelCondition}
                openCloseModal={openCloseModal}
                launchCampaign={launchCampaign}
                clickOnCampaign={clickOnCampaign}
                saveMediaConfig={saveMediaConfig}
                backTempList={backTempList}
                saveChanges={saveChanges}
                discardChanges={discardChanges}
                createSeqTemplate={createSeqTemplate}
                handleLaunchCampClk={handleLaunchCampClk}
              />
            }

          </Box>
        </Page>
        :   //email template edit panel | preview
        <MainEditPanel
          state={state}
          changeNameValue={changeNameValue}
          handleTextChange={handleTextChange}
          openCloseModal={openCloseModal}
          backTempList={backTempList}
        />
      }
    </div>
  );
}

export default MainTab;