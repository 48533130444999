import { useState, useEffect, useCallback } from 'react';
import { ColorPicker, Popover, InlineStack, Label, Text, TextField, Box } from '@shopify/polaris';

const ColorPick = (props) => {
  const rgb_num = 255, hue_num = 360, sv_num = 100;
  const { labelTag, labelVariant, colorKey, labelName, colorValue, changeNameValue, shwDcrdSave } = props;

  const [popActive, setPopActive] = useState(false);
  const [dfltColor, setDfltColor] = useState({
    hue: 0,
    saturation: 0,
    brightness: 1,
    alpha: 1
  });

  const toggleActive = useCallback(() =>
    setPopActive((popActive) => !popActive), []
  );

  const handleTextChange = (value, field) => {
    value = value.replace(/[^A-Fa-f0-9#]/g);

    if (!value.includes('undefined')) {
      if (shwDcrdSave)
        changeNameValue({ [field]: value }, shwDcrdSave);
      else
        changeNameValue({ [field]: value });
    }
  }

  // set default color
  useEffect(() => {
    if (colorValue) {
      const hsb = hexToHsb(colorValue);
      setDfltColor(hsb);
    }
  }, [colorValue]);

  const handlePickerChange = (color, field) => {
    if (!color) color = {};
    setDfltColor(color);

    let h = color.hue || 0;
    let s = color.saturation * 100 || 0;
    let v = color.brightness * 100 || 0;
    let a = color.alpha * 100 || 0;
    let hex = hsbToHex(h, s, v, a);

    if (shwDcrdSave)
      changeNameValue({ [field]: hex }, shwDcrdSave);
    else
      changeNameValue({ [field]: hex });
  };

  // const convertHsb = (color) => {
  //   if (!color) color = "#000000";

  //   let { h, s, v } = hexToHsb(color);
  //   return { hue: h, saturation: s / 100, brightness: v / 100 };
  // };

  function hexToHsb(hex) {
    hex = hex.replace('#', '');

    // Extract RGB and Alpha if present
    const r = parseInt(hex.slice(0, 2), 16) / 255;
    const g = parseInt(hex.slice(2, 4), 16) / 255;
    const b = parseInt(hex.slice(4, 6), 16) / 255;
    const alpha = hex.length === 8 ? parseInt(hex.slice(6, 8), 16) / 255 : 1; // Default alpha to 1 if not provided

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const delta = max - min;

    const brightness = max;

    let saturation = 0;
    if (max !== 0) {
      saturation = delta / max;
    }

    let hue = 0;
    if (delta !== 0) {
      if (max === r) {
        hue = (g - b) / delta;
      } else if (max === g) {
        hue = (b - r) / delta + 2;
      } else {
        hue = (r - g) / delta + 4;
      }
      hue *= 60;
      if (hue < 0) hue += 360;
    }

    return {
      hue: hue,
      saturation: saturation,
      brightness: brightness,
      alpha: alpha, // Include alpha in the result
    };
  }

  function hsbToHex(h, s, v, a) {
    if (typeof h === 'object') {
      const args = h;
      h = args.h; s = args.s; v = args.v; a = args.a;
    }

    h = (h % 360 + 360) % 360;
    h = (h === hue_num) ? 1 : (h % hue_num / parseFloat(hue_num) * 6);
    s = (s === sv_num) ? 1 : (s % sv_num / parseFloat(sv_num));
    v = (v === sv_num) ? 1 : (v % sv_num / parseFloat(sv_num));
    a = (a === sv_num) ? 1 : (a % sv_num / parseFloat(sv_num));

    let i = Math.floor(h);
    let f = h - i;
    let p = v * (1 - s);
    let q = v * (1 - f * s);
    let t = v * (1 - (1 - f) * s);
    let mod = i % 6;
    let r = [v, q, p, p, t, v][mod];
    let g = [t, v, v, q, p, p][mod];
    let b = [p, p, t, v, v, q][mod];

    r = Math.floor(r * rgb_num);
    g = Math.floor(g * rgb_num);
    b = Math.floor(b * rgb_num);

    if (typeof r === 'object') {
      const args = r;
      r = args.r; g = args.g; b = args.b;
    }

    r = Math.round(r).toString(16);
    g = Math.round(g).toString(16);
    b = Math.round(b).toString(16);

    r = r.length === 1 ? '0' + r : r;
    g = g.length === 1 ? '0' + g : g;
    b = b.length === 1 ? '0' + b : b;
    a = typeof a === 'number' ? Math.min(1, Math.max(0, a)).toFixed(2) : '1.00';

    return `#${r}${g}${b}${Math.round(a * 255).toString(16).padStart(2, '0')}`;
  }

  return (
    <Box id='cursorPoint'>
      <InlineStack blockAlign="center" gap="200">
        <Popover
          active={popActive}
          onClose={toggleActive}
          activator={<div onClick={toggleActive}>
            <div style={{ background: colorValue, filter: 'drop-shadow(0px 0px 4px rgb(0 0 0 / 10%))' }}>
              <Box padding={400}></Box>
            </div>
          </div>}
        >
          <Box padding={400}>

            <ColorPicker onChange={(e) => handlePickerChange(e, colorKey)} color={dfltColor} allowAlpha />
            {/* <ColorPicker onChange={(e) => handlePickerChange(e, colorKey)} color={convertHsb(colorValue)} /> */}

            <InlineStack>
              <Box paddingBlockStart={200} maxWidth='93%'>
                <TextField
                  type="text"
                  // label={<Text fontWeight='medium' as='span'>{labelName}</Text>}
                  value={colorValue}
                  onChange={(e) => handleTextChange(e, colorKey)}
                />
              </Box>
            </InlineStack>
          </Box>
        </Popover>

        <div onClick={toggleActive}>
          <InlineStack gap={100} direction={'column'} align='start' blockAlign='start'>
            <Text variant={labelVariant ? labelVariant : 'bodySm'} as={labelTag ? labelTag : 'span'}>
              <Label><Text fontWeight='medium' as='span'>{labelName}</Text></Label>
            </Text>
            <Text variant='bodySm' as='span'>{colorValue}</Text>
          </InlineStack>
        </div>
      </InlineStack>
    </Box >
  );
};

export default ColorPick;
