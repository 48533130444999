import React, { useEffect, useState } from 'react'
import { Card, Divider, Text, BlockStack, Page, Box, ChoiceList, Badge } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';

function Wisernotify({ props }) {
	const { state, changeNameValue, validator, handleBackToMain } = props;
	const dispatch = useDispatch();
	const [selected, setSelected] = useState(['disable']);
	const [curid, setCurid] = useState(undefined);
	let _ia = false;
	let userIntId = "";

	useEffect(() => {
		if (state.UserIntegration && state.UserIntegration.length > 0) {
			state.UserIntegration.map(x => {
				if (x.inm === state.currTyp) {
					_ia = x.ia;
					userIntId = x._id;
					setCurid(userIntId);
				}
			});
		}

		if (userIntId === "") {
			handleSavenotify(false);
		}
		if (state.UserIntegration && state.UserIntegration.length > 0) {
			state.UserIntegration.map(x => { if (x.inm === state.currTyp) { _ia = x.ia; setCurid(x._id); } });
		}
		if (_ia === false) setSelected(['disable']);
		if (_ia === true) setSelected(['enable']);
	}, [])

	const handleChange = (val) => {
		if (!validator.allValid()) {
			validator.showMessages();
			validator.helpers.forceUpdateIfNeeded();
			changeNameValue({ 'errMessage': validator.errorMessages });
		}
		else {
			changeNameValue({ 'errMessage': {} });
			let obj = {
				_id: curid,
				ia: val[0] === 'disable' ? false : true,
			}
			dispatch(integrationDucks.updateIntegrationStatus(obj));
		}
		setSelected(val);
	}

	const handleSavenotify = (_ia) => {
		if (!validator.allValid()) {
			validator.showMessages();
			validator.helpers.forceUpdateIfNeeded();
			changeNameValue({ 'errMessage': validator.errorMessages });
		}
		else {
			changeNameValue({ 'errMessage': {} });
			let obj = {
				_id: state.currIntId,
				shp: localStorage.getItem('shp'),
				iid: state.currMasterObj._id ? state.currMasterObj._id : '',
				inm: state.currMasterObj.in ? state.currMasterObj.in : '',
				d: { "arrstr": [], "_ia": _ia },
				ia: _ia !== undefined ? _ia : state.currMasterObj.ia,
				icltrvw: state.currMasterObj.icltrvw
			}
			dispatch(integrationDucks.addUpdIntegration(obj));
			let intObj = {
				dObj: {},
				isDelWbHook: false,
			}
			changeNameValue(intObj);
		}
	}

	return (
		<Page
			title={(state.currMasterObj && state.currMasterObj.dn) && state.currMasterObj.dn}
			titleMetadata={selected[0] === 'enable' ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">Active</Text></Badge>
				: <Badge><Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text></Badge>}
			subtitle={
				<Text tone='base' >
					{state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
				</Text>}
			backAction={{ onAction: () => handleBackToMain() }}>

			<Box paddingInline={800}>
				<Card>
					<BlockStack gap="300">
						<Text as="h1" variant="headingSm">Connect</Text>
						<Divider />
						<ChoiceList
							choices={[
								{ label: 'Enable wisernotify', value: 'enable' },
								{ label: 'Disable', value: 'disable' },
							]}
							selected={selected}
							onChange={handleChange}
						/>
					</BlockStack>
				</Card>
			</Box>
		</Page>
	)
}

export default Wisernotify