import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import Routes from './Routes';
import 'react-toastify/dist/ReactToastify.css';

const rootEl = document.getElementById('root');

const root = createRoot(rootEl);

//If a call from Shopify authentication is detected, we need to redirect to the backend for authentication
if (window.location.pathname === '/shopifyAuth') {
  const _path = process.env.REACT_APP_API_URL + "/auth/shopifyAuth" + window.location.search;
  window.location.href = _path;
}
else {
  root.render(
    <App Component={Routes} />
  );
}