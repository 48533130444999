export const base = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:1144/api';

export const GET = (url) => {
  const authToken = localStorage.getItem('authToken');
  const token = authToken ? authToken : null;
  let headers = { Authorization: `Bearer ${token}` };

  return fetch(base + url, {
    method: 'GET',
    headers: headers,
  })
    .then(res => res.json())
    .then(res => res);
};

export const GETAUTH = (url) => {
  return fetch(base + url, {
    method: 'GET',
    headers: {}
  })
    .then(res => res.json())
    .then(res => res);
};

export const GETFILE = (url) => {
  const authToken = JSON.parse(localStorage.getItem('authToken'));
  const token = authToken ? authToken : null;
  return fetch(base + url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(res => res);
};

export const POST = (url, body) => {
  const authToken = localStorage.getItem('authToken');
  const token = authToken ? authToken : null;

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return fetch(base + url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  })
    .then(res => res.json())
    .then(res => res);
};

export const PUT = (url, body) => {
  const authToken = JSON.parse(localStorage.getItem('authToken'));
  const token = authToken ? authToken : null;

  return fetch(base + url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then(res => res.json())
    .then(res => res);
};

export const DELETE = (url) => {
  const authToken = localStorage.getItem('authToken');
  const token = authToken ? authToken : null;

  return fetch(base + url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => res.json())
    .then(res => res);
};

// TODO refactor
export const POSTFILE = (url, body) => {
  const authToken = localStorage.getItem('authToken');
  const token = authToken ? authToken : null;

  return fetch(base + url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body,
  })
    .then(res => res.json())
    .then(res => res);
};

export const POSTELASTIC = (url, body) => {
  const headers = {
    'Content-Type': 'application/json',
    'kbn-xsrf': 'reporting'
  };
  return fetch('http://35.202.85.190:9200/' + url, {
    method: 'POST',
    headers: headers,
    body: body,
    mode: 'no-cors'
  })
    .then(res => res.json())
    .then(res => res);
};
