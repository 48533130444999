import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const analytics = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    testingApi: (state, action) => { },
    testingApiSuccess: (state, action) => {
      return { ...state, testData: action.payload }
    }
  }
});

export const {
  testingApi,
  testingApiSuccess
} = analytics.actions;

export default analytics.reducer;