import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/incentive';
import { toastify } from '../ducks/toast';

function* getIncentive(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getIncentive', action.payload);

    if (res.status === 'success') {
      yield put(actions.getIncentiveSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createIncentive(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createIncentive', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getIncentive());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateIncentive(action) {
  try {
    const res = yield call(api.POST, '/updateIncentive', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getIncentive());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteIncentive(action) {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/deleteIncentive?id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getIncentive());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetIncentive() {
  yield takeLatest(actions.getIncentive.type, getIncentive);
  //yield takeLatest("GET_INCENTIVE", getIncentive);
}

export function* watchCreateIncentive() {
  yield takeLatest(actions.createIncentive.type, createIncentive);
  //yield takeLatest("CREATE_INCENTIVE", createIncentive);
}

export function* watchUpdateIncentive() {
  yield takeLatest(actions.updateIncentive.type, updateIncentive);
  //yield takeLatest("UPDATE_INCENTIVE", updateIncentive);
}

export function* watchDeleteIncentive() {
  yield takeLatest(actions.deleteIncentive.type, deleteIncentive);
  //yield takeLatest("DELETE_INCENTIVE", deleteIncentive);
}

export default function* rootSaga() {
  yield fork(watchGetIncentive);
  yield fork(watchCreateIncentive);
  yield fork(watchUpdateIncentive);
  yield fork(watchDeleteIncentive);
}
