import { Button, Divider, Card, Checkbox, Badge, InlineStack, Box, Text } from '@shopify/polaris';
import { ExternalIcon, EditIcon } from '@shopify/polaris-icons';
import { wisernotify, error } from '../../img';
import { Link } from 'react-router-dom';

const TemplateCard = ({ props }) => {
  let { state } = props;

  let createMstArr = state.emailMSTArr && state.emailMSTArr.length > 0 && state.emailMSTArr.filter(
    (data) => !state.emlNewSeqData.some(val => (val.typ === data.typ && val.sbtyp === data.sbtyp)));

  const getTitle = (typ) => {
    let ttl = 'Single product review template';
    if (typ === 'multi')
      ttl = 'Multiple product review template';
    return ttl;
  }

  return (
    <Card padding={0}>
      <Box background='bg-surface-secondary' paddingInline={400} paddingBlock={300}>
        <Text variant='bodyLg' fontWeight='medium' as='h1'>Email & message templates</Text>
      </Box>
      <Divider borderColor='border-tertiary' />

      <Box padding={400}>
        <Text variant='headingSm' fontWeight='medium' as='h6'>
          Choose templates for requesting reviews via email, SMS, and whatsApp.</Text>

        <Box paddingBlockStart={400}>
          <div className='info_banner' style={{ width: '100%' }}>
            <InlineStack gap={200} wrap={false}>
              <img src={error} alt='error' />
              <Text variant='bodyMd' fontWeight='medium' as='p'>If you select multiple templates, then we will randomly pick a template.</Text>
            </InlineStack>
          </div>
        </Box>

        <Box paddingBlockStart={600}>
          <InlineStack gap={200} blockAlign='start'>
            <Box paddingBlockStart={'050'}>
              <img src={wisernotify} alt="wisernotify" />
            </Box>
            <InlineStack direction={'column'}>
              <Text variant='headingSm' fontWeight='medium' as='h6'>Default templates</Text>
              <Box paddingBlockStart={100}>
                <Text variant='bodyMd' fontWeight='regular' tone='subdued'>Choose email template to collect reviews</Text>
              </Box>
            </InlineStack>
          </InlineStack>

          <Box paddingBlockEnd={100} paddingInlineStart={{ xs: '0', sm: '0', md: '800', lg: '800', xl: '800' }}>

            {/* create template section */}
            {createMstArr && createMstArr.length > 0 &&
              createMstArr.map((data, i) => {
                if (data.typ === state.emailTyp)
                  return (
                    <Box key={i} paddingBlockStart={400}>
                      <InlineStack align="space-between" blockAlign='center' gap={200}>
                        <InlineStack>
                          <Checkbox disabled label={<InlineStack gap={200}>
                            <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodySm' >
                              {getTitle(data.sbtyp)}</Text>
                            <Badge tone="attention"><Text fontWeight='medium'>Email</Text></Badge>
                          </InlineStack>}
                          />
                        </InlineStack>
                        <Button icon={EditIcon} variant='primary' onClick={() => props.createSeqTemplate(data)}>Create Template</Button>
                      </InlineStack>
                    </Box>
                  )
              })}
            {/* edit template section */}
            {state.emailSeqArr && state.emailSeqArr.length > 0 &&
              state.emailSeqArr.map((data, i) => {
                return (
                  <Box key={i} paddingBlockStart={400}>
                    <InlineStack align="space-between" blockAlign='center' gap={'050   '}>
                      <InlineStack>
                        <Checkbox checked={data.ia} onChange={(e) => props.handleStatusUpdate(data._id, e)}
                          label={<InlineStack gap={200}>
                            <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodyMd'>
                              {getTitle(data.sbtyp)}
                            </Text>
                            <Badge tone="attention"><Text fontWeight='medium'>Email</Text></Badge>
                          </InlineStack>}
                        />
                      </InlineStack>
                      <Button icon={EditIcon} onClick={() => props.editTemplateClk(data)}>Edit</Button>
                    </InlineStack>
                  </Box>
                );
              })}

          </Box>
        </Box>

        {/* integration parts */}
        {state.userIntegration && state.userIntegration.length > 0 &&
          state.userIntegration.map((mainData, j) => {
            return (
              <Box key={j}>
                <Box paddingBlock={400}><Divider /></Box>

                <InlineStack align='space-between' gap={300}>
                  <InlineStack gap={200} blockAlign='start'>
                    <Box paddingBlockStart={'050'}>
                      <img src={mainData.img ? mainData.img : wisernotify} alt="Integration" />
                    </Box>
                    <InlineStack direction={'column'}>
                      <Text variant='headingSm' fontWeight='medium' as='h6'>{mainData.dn}</Text>
                      <Box paddingBlockStart={100}>
                        <Text variant='bodySm' as='span' tone='subdued'>Ideal for requesting reviews for individual product</Text>
                      </Box>
                    </InlineStack>
                  </InlineStack>
                  <Link to={`/integration?name=${mainData.inm}`}><Button icon={ExternalIcon} variant='plain'>Integration</Button></Link>
                </InlineStack>

                <Box paddingBlockStart={400} paddingInlineStart={800}>
                  {mainData && mainData.d && mainData.d.arr && mainData.d.arr.length > 0 &&
                    mainData.d.arr.map((data, i) => {
                      if (data.ia === true) {
                        return (
                          <Box key={i} paddingBlockEnd={i !== mainData.d.arr.length - 1 ? 400 : 0}>
                            <InlineStack align="space-between" blockAlign='center'>
                              <InlineStack align='center'>

                                {state.emailTyp === 'on_order_fulfill' ?
                                  <Checkbox checked={data.iordfulfil}
                                    onChange={(e) => props.updateTmpStatus(mainData, data, e)} />
                                  :
                                  state.emailTyp === 'on_followup' ?
                                    <Checkbox checked={data.iflwup}
                                      onChange={(e) => props.updateTmpStatus(mainData, data, e)} />
                                    :
                                    state.emailTyp === 'media_request' ?
                                      <Checkbox checked={data.imda}
                                        onChange={(e) => props.updateTmpStatus(mainData, data, e)} />
                                      :
                                      state.emailTyp === 'one_time_campaign' &&
                                      <Checkbox checked={data.imnlcmpgn}
                                        onChange={(e) => props.updateTmpStatus(mainData, data, e)} />
                                }

                                <div id='cursorPoint'
                                  onClick={() =>
                                    state.emailTyp === 'on_order_fulfill' ?
                                      props.updateTmpStatus(mainData, data, !data.iordfulfil)
                                      : state.emailTyp === 'on_followup' ?
                                        props.updateTmpStatus(mainData, data, !data.iflwup)
                                        : state.emailTyp === 'media_request' ?
                                          props.updateTmpStatus(mainData, data, !data.imda)
                                          : state.emailTyp === 'one_time_campaign' &&
                                          props.updateTmpStatus(mainData, data, !data.imnlcmpgn)
                                  } >

                                  <InlineStack direction={'column'} align='start' blockAlign='start' gap={100}>
                                    <InlineStack gap={200} align='start'>
                                      <Text id='clrsecondary' as='p' fontWeight='regular' variant='bodymd'>
                                        {data.rmk ? data.rmk : '-'}</Text>
                                      {mainData.ctg &&
                                        <Badge tone={mainData.ctg === 'Whatsapp' ? 'success' : 'info'}>
                                          <Text fontWeight='medium'>{mainData.ctg}</Text>
                                        </Badge>
                                      }
                                    </InlineStack>
                                  </InlineStack>

                                </div>

                              </InlineStack>
                            </InlineStack>
                          </Box>
                        );
                      }
                    })
                  }
                </Box>

              </Box>
            );
          })
        }

      </Box>
    </Card>
  );
}

export default TemplateCard;