import { Box, Button, Card, Icon, InlineGrid, InlineStack, Page, Text } from '@shopify/polaris';
import { BlogIcon, ButtonPressIcon, DatabaseIcon, DiscountIcon, QuestionCircleIcon, RefreshIcon } from '@shopify/polaris-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import Forms from '../Forms/Main';
import CurrentQueue from '../CurrentQueue/List';
import Incentives from '../Incentives/Incentives';
import Sequence from '../Sequence/List';
import { changePathForShopifyAppEmbed } from "../../utils/utils";
import CustomQuestion from '../CustomQuestion/CustomQuestion';

function ReviewCollection(props) {

  let MainPageArr = [
    {
      title: 'Automate',
      desc: 'Ask for reviews from new buyers with our automated follow-up system and multi-channel messaging.',
      rmk: 'Set up automate review requests',
      icon: RefreshIcon,
      url: '/collectReview/automation'
    },
    {
      title: 'Manual Campaign',
      desc: 'Gather reviews by uploading customer details from CSV files & invite existing buyers to write reviews',
      rmk: 'Manual review campaign setup',
      icon: ButtonPressIcon,
      url: '/collectReview/manual'
    },
    {
      title: 'Outbox',
      desc: 'Monitor the status of review request emails, including those pending, sent, and failed',
      icon: DatabaseIcon,
      url: '/collectReview/currentQueue'
    },
    {
      title: 'Incentives',
      desc: 'Offer incentives, such as discounts and free shipping, in exchange for reviews.',
      icon: DiscountIcon,
      url: '/collectReview/incentives'
    },
    {
      title: 'Forms',
      desc: 'A dedicated place for customers to review past purchases and modify previous reviews.',
      icon: BlogIcon,
      url: '/collectReview/forms'
    },
    {
      title: 'Custom Questions',
      desc: `Include a custom question to obtain more detailed and relevant reviews specifically focused on the product's features.`,
      icon: QuestionCircleIcon,
      url: '/collectReview/customQuestion'
    }
  ]

  const navigate = useNavigate();
  const location = useLocation();
  let pathName = location.pathname.split('/');
  let searchPath = '';
  if (pathName) searchPath = pathName.length > 2 ? pathName[2] : '';

  const handleRedirectShp = (path) => {
    if (localStorage.getItem('is_shopify_embed')) {
      changePathForShopifyAppEmbed(path);
    }
    else navigate(path);
  }

  return (
    <div>

      {searchPath === 'forms' ?
        <Forms isShwBack={true} />
        :
        searchPath === 'currentQueue' ?
          <CurrentQueue isShwBack={true} />
          :
          searchPath === 'incentives' ?
            <Incentives isShwBack={true} />
            :
            searchPath === 'customQuestion' ?
              <CustomQuestion isShwBack={true} />
              :
              searchPath === 'automation' ?
                <Sequence
                  frm='/collectReview'
                  searchPath='automation'
                  isShwBack={true} />
                :
                searchPath === 'manual' ?
                  <Sequence
                    frm='/collectReview'
                    searchPath='manual'
                    isShwBack={true} />
                  :
                  <Page
                    title={"Review Collection"}>
                    <InlineGrid columns={{ md: 2, sm: 1 }} gap={400}>
                      {MainPageArr && MainPageArr.length > 0 && MainPageArr.map((data, i) => {
                        return (
                          <div id='cursorPoint' key={i} onClick={() => handleRedirectShp(data.url)} style={{ textDecoration: "none" }}>
                            <Card>
                              <InlineStack align='space-between' blockAlign='center'>
                                <div className='automate_img'><Icon source={data.icon} tone='base' /></div>
                                <Button variant='primary' onClick={() => handleRedirectShp(data.url)}>View</Button>
                              </InlineStack>
                              <Box paddingBlockStart={400}>
                                <InlineStack gap={200}>
                                  <Text variant='headingMd' fontWeight='semibold' as='h3'>{data.title}</Text>
                                  <Text variant='headingSm' fontWeight='regular' as='p'>{data.desc}</Text>
                                </InlineStack>
                              </Box>
                            </Card>
                          </div>
                        );
                      })}
                    </InlineGrid>
                  </Page>
      }

    </div>
  );
}

export default ReviewCollection;