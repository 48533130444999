import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as emailActions from '../ducks/email';
import { toastify } from '../ducks/toast';

function* createTemplate(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createEmailTemplate', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(emailActions.getSeqData());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchCreateTemplate() {
  yield takeLatest("CREATE_TEMPLATE", createTemplate);
}

export default function* rootSaga() {
  yield fork(watchCreateTemplate);
}