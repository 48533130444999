import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isToast: false
};

const toast = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    toastify: (state, action) => {
      return { ...state, data: action.payload };
    },
  }
});

export const {
  toastify
} = toast.actions;

export default toast.reducer;

// import { Map } from 'immutable';

// export const toastify = (data) => ({ type: "TOASTIFY", data })

// const initialState = Map({
//   isToast: false,
// });

// const toast = (state = initialState, action) => {
//   switch (action.type) {
//     case "TOASTIFY":
//       return { ...state, data: action.data };

//     default:
//       return { ...state };
//   }
// };

// export default toast;