import { Card, Badge, Box, IndexTable, Text, Button, InlineStack } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';

const opt = { day: 'numeric', month: 'numeric', year: 'numeric' };
const timeStamp = (dt) => {
  const d = new Date(dt);
  return d.toLocaleDateString("es-CL", opt) + ' | ' + d.toLocaleTimeString();
}

const HistoryCard = ({ props }) => {
  let { state } = props;
  let navigate = useNavigate();

  const rdctQueuePage = (cmpgnid) => {
    navigate('/currentQueue?cid=' + cmpgnid);
  }
  return (
    <Box>
      <Box paddingBlockStart={600} paddingBlockEnd={400}>
        <InlineStack direction={'column'} gap={200}>
          <Text variant='bodyLg' fontWeight='bold' as='h4'>History</Text>
          <Text variant='bodySm' fontWeight='medium' as='h6' tone='subdued'>Campaign history & status.</Text>
        </InlineStack>
      </Box>
      <Card padding={0}>
        <IndexTable
          itemCount={state.HistoryData ? state.HistoryData.length : 0}
          selectable={false}
          headings={[
            { title: <Text as='p' fontWeight='medium' tone='subdued'> Date created </Text> },
            { title: <Text as='p' fontWeight='medium' tone='subdued'> Campaign</Text> },
            { title: <Text as='p' fontWeight='medium' tone='subdued'> Campaign ID</Text> },
            { title: <Text as='p' fontWeight='medium' tone='subdued'> Status</Text> },
            { title: <Text as='p' fontWeight='medium' tone='subdued'> Count</Text> }
          ]}
          pagination={{
            hasPrevious: props.state.paggiActiveHstry > 1 ? true : false,
            // label:props.paggiLabelHstry
            hasNext: (props.state.paggiActiveHstry < props.pageNumberHstry) ? true : false,
            onPrevious: () => props.onPaggiCallHstry('-1'),
            onNext: () => props.onPaggiCallHstry('+1')
          }}>

          {state.HistoryData && state.HistoryData.map((data, i) => (
            <IndexTable.Row
              key={i}
              id={data._id}
              position={i}>
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="medium" as="span">{data.cdt ? timeStamp(data.cdt) : '-'}</Text>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="medium" as="span">
                  {`${(new Date(data.frmdt)).toLocaleDateString("en-US", opt)} - ${(new Date(data.tdt)).toLocaleDateString("en-US", opt)}`}
                </Text>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="medium" as="span">{data.cmpgnid ? data.cmpgnid : '-'}</Text>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Badge size='large' progress={data.sts === 1 ? "incomplete" : 'complete'} tone={data.sts === 1 ? '' : 'success'}>
                  {data.sts === 1 ? <Text variant="bodySm" fontWeight="medium" as="span">Pending</Text>
                    : <Text variant="bodySm" fontWeight="medium" as="span">Completed</Text>}
                </Badge>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="medium" as="span">
                  {(data.cnt && data.cnt > 1) ?
                    <Button onClick={() => rdctQueuePage(data.cmpgnid)} variant='plain'>{data.cnt}</Button>
                    : 0}
                </Text>
              </IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
      </Card>
    </Box>
  );
}

export default HistoryCard;