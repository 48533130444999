import { Page, Text, Badge, InlineStack, Box, Tooltip, ButtonGroup, Button, Divider, Card, BlockStack } from '@shopify/polaris';
import { EditIcon } from '@shopify/polaris-icons';
import { wiser_icon } from '../../img';
import IntegrationDetail from './IntegrationDetail';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import { toastify } from '../../ducks/toast';
import copy from 'copy-to-clipboard';
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from 'react-router-dom';
import { changePathForShopifyAppEmbed } from "../../utils/utils";

function List() {

  const dispatch = useDispatch();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    IntegrationMSTArr: [],
    SyndicationList: [],
    IntegrationList: [],
    UserIntegration: [],
    isShwDetail: false, //isShow integration detail page,
    currMasterObj: {}, //current master obj 
    currUserInt: {}, //current userIntegration obj
    dObj: {}, //integration detail object
    newDObj: {},
    errMessage: {}, //store all err messages
    currIntId: '', //current integration id
    isLisDtSuc: false,
    ampArray: [],
    currTyp: '',
    isDelWbHook: false,
    searchParam: '',
    searchPage: '',
    searchPath: '',
    syncArray: [],
    tempArry: [],
    isDelKlaviyo: false,
    tabEvent: 'firstTabCollapse'
  });

  useEffect(() => {
    dispatch(integrationDucks.getUserIntegration({ shp: localStorage.getItem('shp') }));
  }, []);

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //api data  
  const masterState = useSelector(state => state.master.MasterData);
  const UserIntegration = useSelector(state => state.integration.UserIntegration);

  useEffect(() => {
    if (masterState && masterState.integrationData && masterState.integrationData.length > 0)
      changeNameValue({ IntegrationMSTArr: masterState.integrationData })
    else
      dispatch(integrationDucks.getUserIntegration({ shp: localStorage.getItem('shp') }));
  }, [masterState, state.IntegrationMSTArr, changeNameValue]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  state.searchPage = queryParams.get("p");
  let integrationNm = queryParams.get("name");
  const navigate = useNavigate();

  let pathName = location.pathname.split('/');
  if (queryParams.size !== 0)
    state.searchPath = queryParams.get("typ");
  else if (pathName) state.searchPath = pathName.length > 2 ? pathName[2] : '';

  useEffect(() => {
    let obj = {};
    let { integrationData } = masterState || [];

    if (state.searchPath !== '' || state.searchPath !== null) {
      if (integrationData?.length > 0) {
        integrationData.filter(item => {
          if (item.in === state.searchPath)
            obj['currMasterObj'] = item;
        });
      }

      obj['currTyp'] = state.searchPath;
      obj['isShwDetail'] = true;
      changeNameValue(obj);
    }

    if (!state.searchParam && !state.searchPath) //onclick sidebar menu, back to main page
      changeNameValue({ isShwDetail: false });
  }, [state.searchParam, state.searchPath, masterState, changeNameValue]);

  useEffect(() => { //get installed Array
    const filteredSyndArr = state.IntegrationMSTArr?.filter(item => ["google_shopping", "google_review_collector", "multi_store_syndication", "meta_shopping"].includes(item.in));
    const filteredIntArr = state.IntegrationMSTArr?.filter(item => ["webhook_data_import", "webhook_data_export", "wp_interakt", "klaviyo", "webhook_review_request_export", "wati", "wisernotify"].includes(item.in));
    let obj = {
      SyndicationList: filteredSyndArr,
      IntegrationList: filteredIntArr,
      UserIntegration: UserIntegration
    }
    changeNameValue(obj);

    if (UserIntegration && UserIntegration.length > 0) {
      let listArr = [];
      state.IntegrationMSTArr && state.IntegrationMSTArr.forEach(function (list) {
        UserIntegration.forEach(data => {
          if (list.in === data.inm)
            listArr.push(list);
        });
      });
    }
  }, [state.IntegrationMSTArr, UserIntegration, changeNameValue]);

  useEffect(() => { //query from Sequence page on integration click
    if (integrationNm) {
      let obj = {
        currTyp: integrationNm,
        isShwDetail: true,
      }
      changeNameValue(obj);

      state.IntegrationList && state.IntegrationList.forEach((x, i) => {
        if (x.in === integrationNm)
          changeNameValue({ currMasterObj: x });
      });
    }
  }, [integrationNm, state.IntegrationList, changeNameValue]);

  useEffect(() => { //get current userIntegration data
    if (state.currMasterObj && state.currMasterObj.in && UserIntegration && UserIntegration.length > 0) {
      let dataArr = [];

      UserIntegration && UserIntegration.length > 0 && UserIntegration.forEach(function (list) {
        if (list.inm === state.currMasterObj.in) {
          dataArr.push(list);
        }
      });

      if (dataArr && dataArr.length > 0) {
        let obj = {
          currUserInt: dataArr[0],
          currIntId: dataArr[0]._id && dataArr[0]._id,
          ampArray: dataArr[0].amp && dataArr[0].amp,
          syncArray: dataArr[0].d.arrStr && dataArr[0].d.arrStr,
          tempArry: dataArr[0].d.arr && dataArr[0].d.arr
        }
        changeNameValue(obj);
      }
    }
  }, [state.currMasterObj, UserIntegration, state.currUserInt, state.syncArray, changeNameValue]);

  useEffect(() => { //get & save 'd' object
    let obj = {};

    if (state.currUserInt && !state.isDelWbHook && !state.isDelKlaviyo) {
      if (state.currUserInt.d)
        obj = state.currUserInt.d;

      if (state.currUserInt._id)
        changeNameValue({ 'currIntId': state.currUserInt._id });
    }

    let intObj = {
      dObj: obj,
      newDObj: obj,
    }
    changeNameValue(intObj);
  }, [state.currUserInt, state.isDelWbHook, state.isDelKlaviyo, changeNameValue]);

  const handleTextChange = (name, value) => {
    if (name === 'estdd' && value !== '')  //for all 'number' field, add condition here. for save as a number
      value = parseInt(value)

    if (name === 'gtin' || name === 'mpn') {
      const currentObj = { ...state.dObj, [name]: Number(value) };
      changeNameValue({ 'dObj': currentObj });
    } else {
      const currentObj = { ...state.dObj, [name]: value };
      changeNameValue({ 'dObj': currentObj });
    }
  }

  const handleIntegrationClk = (data) => {
    if (data) {
      let obj = {
        currMasterObj: data,
        currTyp: data.in,
        isShwDetail: true,
        isLisDtSuc: false
      }
      changeNameValue(obj);

      let path = '/integration/' + data.in;

      if (localStorage.getItem('is_shopify_embed')) {
        changePathForShopifyAppEmbed(path);
      }
      else {
        navigate(path);
      }
    }
  }

  const openCloseModal = useCallback((name, value, typ) => {
    if (typ !== undefined) changeNameValue({ [name]: !value });
  }, [changeNameValue]);

  const handleSave = (_ia) => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    }
    else {
      let stateObj = { errMessage: {} };

      let obj = {
        _id: state.currIntId,
        shp: localStorage.getItem('shp'),
        iid: state.currMasterObj._id ? state.currMasterObj._id : '',
        inm: state.currMasterObj.in ? state.currMasterObj.in : '',
        d: state.dObj,
        ia: _ia !== undefined ? _ia : state.currMasterObj.ia,
        icltrvw: state.currMasterObj.icltrvw
      }
      if (state.currMasterObj.in === 'google_shopping' || state.currMasterObj.in === 'meta_shopping') {
        dispatch(integrationDucks.addUpdateSyndication(obj));
      } else {
        dispatch(integrationDucks.addUpdIntegration(obj));
      }

      stateObj['dObj'] = {};
      stateObj['isDelWbHook'] = false;
      changeNameValue(stateObj);
    }
  }

  const handleCopyCode = (code, _msg) => {
    copy(code, { debug: true });
    dispatch(toastify({ type: 'success', msg: _msg ? _msg : 'code' + ' is copied.' }));
  }

  const handleBackToMain = () => {
    const isShopifyEmbed = localStorage.getItem('is_shopify_embed');
    const queryParamKey = window.location.search ? queryParams.keys().next().value : '';

    if (window.location.search) {
      if (isShopifyEmbed) {
        const path = queryParamKey === 'name' ? '/sequence' : '/shopifyAuth';
        changePathForShopifyAppEmbed(path);
      }
      else navigate(-1);
    }
    else {
      if (isShopifyEmbed)
        changePathForShopifyAppEmbed('/integration');
      else navigate('/integration');
    }

    let obj = {
      isShwDetail: false,
      currUserInt: {},
      currIntId: '',
      ampArray: [],
      currMasterObj: {},
      newDObj: {},
      dObj: {},
      currTyp: '',
      errMessage: {},
      isLisDtSuc: false,
      isDelWbHook: false,
      isDelKlaviyo: false,
      tabEvent: 'firstTabCollapse'
    }
    changeNameValue(obj);
    // dispatch(integrationDucks.getUserIntegration({ shp: localStorage.getItem('shp') }));
  }

  const getIa = (typ) => {
    let _ia = false;
    if (state.UserIntegration && state.UserIntegration.length > 0) {
      state.UserIntegration.map(x => { if (x.inm === typ) _ia = x.ia; });
    }
    return _ia;
  }

  // disable widgets for free plan.
  let disbleFrPlnArr = ["google_shopping", "multi_store_syndication", "klaviyo"];

  return (
    <Box paddingBlockEnd={500}>

      {state.isShwDetail !== true ?
        <Page
          title={'Review syndication & integration'}
          subtitle={'Manage settings that apply to your organization.'}
          backAction={state.searchPage === 'intg' ? { onAction: () => handleBackToMain() } : false}>
          <Box paddingInlineStart={state.searchPage === 'intg' ? 400 : 0} padding={{ xs: 400, sm: 400, md: 0 }}>
            <BlockStack gap={400}>
              {state.SyndicationList && state.SyndicationList.length > 0 &&  //all syndication list
                <Card padding={0}>
                  <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                    <Text variant='headingMd' fontWeight='semibold' as='h5'>Syndication</Text>
                  </Box>
                  <Divider borderColor='border-tertiary' />

                  <Box paddingInline={400} paddingBlockEnd={200} paddingBlockStart={200}>
                    {state.SyndicationList.map((val, i) => {
                      return (
                        <div key={i} id='cursorPoint'
                          onClick={localStorage.getItem('csub') === 'free' && disbleFrPlnArr.includes(val.in) ?
                            () => { } :
                            () => { handleIntegrationClk(val) }}>
                          <Box paddingBlock={400} paddingInline={100}
                            paddingBlockEnd={i === state.SyndicationList.length - 1 && 200}>
                            <InlineStack align="space-between" blockAlign='center'>
                              <InlineStack blockAlign='center'>
                                <Box paddingInlineEnd={400}>
                                  {val.img ? <img src={val.img} alt='Integration' />
                                    : <img src={wiser_icon} alt='Integration' />}
                                </Box>
                                <Box paddingBlockEnd={100}>
                                  <InlineStack align='start' gap={200}>
                                    <Text as='p' fontWeight='semibold' variant='bodySm'>{val.dn ? val.dn : '-'}</Text>
                                    {getIa(val.in) === true ?
                                      <Badge tone='success'>
                                        <Text variant='headingXs' fontWeight='medium' as='span'>Active</Text>
                                      </Badge>
                                      : <Badge>
                                        <Text variant='headingXs' fontWeight='medium' as='span'>Disabled</Text>
                                      </Badge>
                                    }
                                  </InlineStack>
                                  <Box paddingBlockStart={100}>
                                    <Text variant='bodySm' as='p' tone='subdued'>{val.dsc}</Text>
                                  </Box>
                                </Box>
                              </InlineStack>
                              <ButtonGroup>
                                {(localStorage.getItem('csub') === 'free' && disbleFrPlnArr.includes(val.in)) ?
                                  <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                                    <Button disabled icon={EditIcon}>Edit</Button>
                                  </Tooltip>
                                  :
                                  <Button icon={EditIcon}>Edit</Button>
                                }
                              </ButtonGroup>

                            </InlineStack>
                          </Box>
                          {i !== state.SyndicationList.length - 1 &&
                            <Divider />
                          }
                        </div>
                      );
                    })}
                  </Box>
                </Card>
              }

              {/* all integration list */}
              {state.IntegrationList && state.IntegrationList.length > 0 &&
                <Card padding={0}>
                  <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                    <Text variant='headingMd' fontWeight='semibold' as='h5'>Integration</Text>
                  </Box>
                  <Divider borderColor='border-tertiary' />
                  <Box paddingInline={400} paddingBlockEnd={200} paddingBlockStart={200}>

                    {state.IntegrationList.map((val, i) => {
                      return (
                        <div key={i} id='cursorPoint'
                          // onClick={() => handleIntegrationClk(val)}
                          onClick={localStorage.getItem('csub') === 'free' && disbleFrPlnArr.includes(val.in) ?
                            () => { } :
                            () => { handleIntegrationClk(val) }}>
                          <Box paddingBlock={400} paddingInline={100}
                            paddingBlockEnd={i === state.IntegrationList.length - 1 && 200}>
                            <InlineStack align="space-between" blockAlign='center'>
                              <InlineStack blockAlign='center'>
                                <Box paddingInlineEnd={400}>
                                  {val.img ? <img src={val.img} alt='Integration' />
                                    : <img src={wiser_icon} alt='Integration' />}
                                </Box>
                                <Box paddingBlockEnd={100}>
                                  <InlineStack align='start' gap={200}>
                                    <Text as='p' fontWeight='semibold' variant='bodySm'>{val.dn ? val.dn : '-'}</Text>
                                    {getIa(val.in) === true ?
                                      <Badge tone='success'>
                                        <Text variant='headingXs' fontWeight='medium' as='span'>Active</Text>
                                      </Badge>
                                      : <Badge>
                                        <Text variant='headingXs' fontWeight='medium' as='span'>Disabled</Text>
                                      </Badge>
                                    }
                                  </InlineStack>
                                  <Box paddingBlockStart={100}>
                                    <Text variant='bodySm' as='p' tone='subdued'>{val.dsc}</Text>
                                  </Box>
                                </Box>
                              </InlineStack>
                              <ButtonGroup>
                                {(localStorage.getItem('csub') === 'free' && disbleFrPlnArr.includes(val.in)) ?
                                  <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                                    <Button disabled icon={EditIcon}>Edit</Button>
                                  </Tooltip>
                                  :
                                  <Button icon={EditIcon}>Edit</Button>
                                }
                              </ButtonGroup>

                            </InlineStack>
                          </Box>
                          {i !== state.IntegrationList.length - 1 &&
                            <Divider />
                          }
                        </div>
                      );
                    })}
                  </Box>
                </Card>
              }
            </BlockStack>

          </Box>
        </Page>
        :
        <IntegrationDetail
          state={state}
          setState={setState}
          validator={validator}
          changeNameValue={changeNameValue}
          handleBackToMain={handleBackToMain}
          handleIntegrationClk={handleIntegrationClk}
          openCloseModal={openCloseModal}
          handleSave={handleSave}
          handleCopyCode={handleCopyCode}
          handleTextChange={handleTextChange}
        />
      }

    </Box>
  );
}

export default List;