import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false
};

const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    load: (state, action) => {
      return { ...state, isLoading: true };
    },
    loaded: (state, action) => {
      return { ...state, isLoading: false };
    }
  }
});

export const {
  load,
  loaded
} = loading.actions;

export default loading.reducer;

// import { Map } from 'immutable';

// export const load = () => ({ type: "LOAD" })
// export const loaded = () => ({ type: "LOADED" })


// const initialState = Map({
//   isLoading: false,
// });

// const loading = (state = initialState, action) => {
//   switch (action.type) {
//     case "LOAD":
//       return { ...state, isLoading: true };

//     case "LOADED":
//       return { ...state, isLoading: false };

//     default:
//       return { ...state };
//   }
// };

// export default loading;