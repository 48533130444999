import { Box, InlineGrid, InlineStack, Thumbnail, Text, DropZone, Button, ButtonGroup, Divider, BlockStack } from '@shopify/polaris';
import { NoteIcon, ArrowLeftIcon, CornerPillIcon, CornerRoundIcon, CornerSquareIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { dropimg } from '../../img';

const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg', 'image/svg+xml'];

function Branding({
  state,
  changeNameValue,
  handleDropZone,
  handleNextStep,
  handleSaveContinue
}) {
  return (
    <div className='page-branding-wrap'>
      <Box paddingBlockEnd={800}>
        <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
          <div className='border-right'>
            <div className='preview_wrap_bg onboard-image'>
              <Box padding={400}>
                <Text variant='headingMd' as='p' fontWeight='medium' alignment='center'>Preview</Text>
              </Box>
              <div style={{ paddingTop: "70px" }}>
                <BlockStack align='center' inlineAlign='center'>
                  <span style={{ color: state.prmclr }} className='star_text'>4.3</span>
                  <Box paddingBlockStart={400} paddingBlockEnd={200}>
                    <svg width="156" height="28" viewBox="0 0 156 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.0489 2.92705C13.3483 2.00574 14.6517 2.00574 14.9511 2.92705L16.9187 8.98278C17.0526 9.3948 17.4365 9.67376 17.8697 9.67376H24.2371C25.2058 9.67376 25.6086 10.9134 24.8249 11.4828L19.6736 15.2254C19.3231 15.4801 19.1764 15.9314 19.3103 16.3435L21.2779 22.3992C21.5773 23.3205 20.5228 24.0866 19.7391 23.5172L14.5878 19.7746C14.2373 19.5199 13.7627 19.5199 13.4122 19.7746L8.2609 23.5172C7.47719 24.0866 6.42271 23.3205 6.72206 22.3992L8.68969 16.3435C8.82356 15.9314 8.6769 15.4801 8.32642 15.2254L3.17511 11.4828C2.39139 10.9134 2.79417 9.67376 3.76289 9.67376H10.1303C10.5635 9.67376 10.9474 9.3948 11.0813 8.98278L13.0489 2.92705Z" fill={state.strclr} />
                      <path d="M45.0489 2.92705C45.3483 2.00574 46.6517 2.00574 46.9511 2.92705L48.9187 8.98278C49.0526 9.3948 49.4365 9.67376 49.8697 9.67376H56.2371C57.2058 9.67376 57.6086 10.9134 56.8249 11.4828L51.6736 15.2254C51.3231 15.4801 51.1764 15.9314 51.3103 16.3435L53.2779 22.3992C53.5773 23.3205 52.5228 24.0866 51.7391 23.5172L46.5878 19.7746C46.2373 19.5199 45.7627 19.5199 45.4122 19.7746L40.2609 23.5172C39.4772 24.0866 38.4227 23.3205 38.7221 22.3992L40.6897 16.3435C40.8236 15.9314 40.6769 15.4801 40.3264 15.2254L35.1751 11.4828C34.3914 10.9134 34.7942 9.67376 35.7629 9.67376H42.1303C42.5635 9.67376 42.9474 9.3948 43.0813 8.98278L45.0489 2.92705Z" fill={state.strclr} />
                      <path d="M77.0489 2.92705C77.3483 2.00574 78.6517 2.00574 78.9511 2.92705L80.9187 8.98278C81.0526 9.3948 81.4365 9.67376 81.8697 9.67376H88.2371C89.2058 9.67376 89.6086 10.9134 88.8249 11.4828L83.6736 15.2254C83.3231 15.4801 83.1764 15.9314 83.3103 16.3435L85.2779 22.3992C85.5773 23.3205 84.5228 24.0866 83.7391 23.5172L78.5878 19.7746C78.2373 19.5199 77.7627 19.5199 77.4122 19.7746L72.2609 23.5172C71.4772 24.0866 70.4227 23.3205 70.7221 22.3992L72.6897 16.3435C72.8236 15.9314 72.6769 15.4801 72.3264 15.2254L67.1751 11.4828C66.3914 10.9134 66.7942 9.67376 67.7629 9.67376H74.1303C74.5635 9.67376 74.9474 9.3948 75.0813 8.98278L77.0489 2.92705Z" fill={state.strclr} />
                      <path d="M109.049 2.92705C109.348 2.00574 110.652 2.00574 110.951 2.92705L112.919 8.98278C113.053 9.3948 113.437 9.67376 113.87 9.67376H120.237C121.206 9.67376 121.609 10.9134 120.825 11.4828L115.674 15.2254C115.323 15.4801 115.176 15.9314 115.31 16.3435L117.278 22.3992C117.577 23.3205 116.523 24.0866 115.739 23.5172L110.588 19.7746C110.237 19.5199 109.763 19.5199 109.412 19.7746L104.261 23.5172C103.477 24.0866 102.423 23.3205 102.722 22.3992L104.69 16.3435C104.824 15.9314 104.677 15.4801 104.326 15.2254L99.1751 11.4828C98.3914 10.9134 98.7942 9.67376 99.7629 9.67376H106.13C106.563 9.67376 106.947 9.3948 107.081 8.98278L109.049 2.92705Z" fill={state.strclr} />
                      <path d="M141.049 2.92705C141.348 2.00574 142.652 2.00574 142.951 2.92705L144.919 8.98278C145.053 9.3948 145.437 9.67376 145.87 9.67376H152.237C153.206 9.67376 153.609 10.9134 152.825 11.4828L147.674 15.2254C147.323 15.4801 147.176 15.9314 147.31 16.3435L149.278 22.3992C149.577 23.3205 148.523 24.0866 147.739 23.5172L142.588 19.7746C142.237 19.5199 141.763 19.5199 141.412 19.7746L136.261 23.5172C135.477 24.0866 134.423 23.3205 134.722 22.3992L136.69 16.3435C136.824 15.9314 136.677 15.4801 136.326 15.2254L131.175 11.4828C130.391 10.9134 130.794 9.67376 131.763 9.67376H138.13C138.563 9.67376 138.947 9.3948 139.081 8.98278L141.049 2.92705Z" fill="url(#paint0_linear_1028_15852)" />
                      <defs>
                        <linearGradient id="paint0_linear_1028_15852" x1="131" y1="10.5" x2="153" y2="10.5" gradientUnits="userSpaceOnUse">
                          <stop offset="0.5" stopColor={state.strclr} />
                          <stop offset="0.5001" stopColor="#C0C0C0" stopOpacity="0.79" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Box>
                  <Text variant='headingMd' as='h3' fontWeight='regular'>(2000 reviews)</Text>

                  <Box paddingBlockStart={800}>
                    <button className='smpl_btn'
                      style={{
                        color: state.btntxtclr,
                        backgroundColor: state.btnclr,
                        borderRadius: state.btnshp === 'shaved' ? '8px'
                          : state.btnshp === 'rounded' ? '16px'
                            : state.btnshp === 'circular' ? '50px' : '0'
                      }}>
                      Write a Review
                    </button>
                  </Box>
                </BlockStack>
              </div>
            </div>
          </div>
          <Box paddingInlineEnd={300}>
            <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
              <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 4 / 6</Text>
              <Text variant="headingLg" as="h5" fontWeight='medium'>Collect & display reviews with your branding</Text>
            </BlockStack>
            <Box paddingBlockStart={300}>
              <div className='dropdragbox-wrap'>
                <Box id='ob_img_drops' paddingBlockStart={300} paddingBlockEnd={500}>
                  <DropZone accept="image/*" type='image' onDrop={handleDropZone} onChange={() => handleDropZone()}>
                    <InlineStack align='center'>

                      {state.images && state.images.length > 0 ? state.images.map((img, i) => (
                        <InlineStack align="center" key={i}>
                          <Thumbnail size="large" alt={'Logo'}
                            source={validImageTypes.includes(img.type)
                              ? img
                                ? window.URL.createObjectURL(img)
                                : `https://storage.googleapis.com/shopify_review_app/user_media/${state.lg}?q=${new Date().getTime()}`
                              : NoteIcon} />
                        </InlineStack>))
                        :
                        state.lg ?
                          <InlineStack>
                            <Thumbnail size="large" alt={'Logo'}
                              source={`https://storage.googleapis.com/shopify_review_app/user_media/${state.lg}?q=${new Date().getTime()}`} />
                          </InlineStack>
                          :
                          <BlockStack inlineAlign="center" gap={200}>
                            <img src={dropimg} alt='' />
                            <Text fontWeight='medium'>Drag and drop an image(logo)</Text>
                            <div className='or-divider'>
                              <div className='or-text'>or</div>
                            </div>
                            <Box>
                              <Button id='primary_btn' variant="primary">Brows</Button>
                            </Box>
                          </BlockStack>
                      }
                    </InlineStack>
                  </DropZone>
                </Box>
              </div>
            </Box>

            <Text variant='headingMd' fontWeight="medium" as='h6'>Set Colors</Text>
            <Box paddingBlockStart={300} paddingBlockEnd={500}>
              <div className='setcolor-block'>
                <InlineStack align='space-between' >
                  <ColorPick
                    labelName='Primary color'
                    colorKey="prmclr"
                    colorValue={state.prmclr}
                    changeNameValue={changeNameValue}
                    shwDcrdSave={true} />
                  <ColorPick
                    labelName='Star color'
                    colorKey="strclr"
                    colorValue={state.strclr}
                    changeNameValue={changeNameValue}
                    shwDcrdSave={true} />
                  <ColorPick
                    labelName='Button color'
                    colorKey="btnclr"
                    colorValue={state.btnclr}
                    changeNameValue={changeNameValue}
                    shwDcrdSave={true} />
                </InlineStack>
              </div>
            </Box>
            <Box>
              <Text variant='bodyMd' fontWeight='regular' as='h6'>Button shape</Text>
              <Box paddingBlockStart={300}>
                <InlineStack gap={400}>
                  <Button icon={CornerPillIcon} variant={state.btnshp === 'circular' ? 'primary' : 'secondary'}
                    onClick={() => changeNameValue({ 'btnshp': 'circular' }, true)}></Button>
                  <Button icon={CornerRoundIcon} variant={state.btnshp === 'shaved' ? 'primary' : 'secondary'}
                    onClick={() => changeNameValue({ 'btnshp': 'shaved' }, true)}></Button>
                  <Button icon={CornerSquareIcon} variant={state.btnshp === 'sharp' ? 'primary' : 'secondary'}
                    onClick={() => changeNameValue({ 'btnshp': 'sharp' }, true)}></Button>
                </InlineStack>
              </Box>
            </Box>
          </Box>
        </InlineGrid>
      </Box>
      <Divider />

      <Box paddingBlockStart={500}>
        <InlineStack align="space-between">
          <Button id='plain_clr' variant={'plain'} icon={ArrowLeftIcon} onClick={() => handleNextStep('step3', 'Back_Step4')}>Back</Button>
          <ButtonGroup>
            <Button variant={'plain'} onClick={() => handleNextStep('step5', 'Skip_Step4')}>Skip</Button>
            <Button disabled={state.isDiabledCta} variant={'primary'} onClick={() => handleSaveContinue()}>Save & Continue</Button>
          </ButtonGroup>
        </InlineStack>
      </Box>
    </div>
  );
}

export default Branding;