// import { fromJS } from 'immutable';

// export const fbauth = (data) => ({ type: "FB_AUTH", data });

// export const getUGCInt = (data) => ({ type: "GET_UGC_INTEGRATION", data });
// export const getUGCIntSuccess = (data) => ({ type: "GET_UGC_INTEGRATION_SUCCESS", data });

// export const updPageStatus = (data) => ({ type: "UPDATE_PAGE_STATUS", data });

// export const delFbInt = (data) => ({ type: "DEL_FB_INTEGRATION", data });

// export const getInstaPost = (data) => ({ type: "GET_INSTA_POST", data });
// export const getInstaPostSuccess = (data) => ({ type: "GET_INSTA_POST_SUCCESS", data });

// export const ugcAddProduct = (data) => ({ type: "UGC_ADD_PRODUCT", data });

// export const changePostStatus = (data) => ({ type: "CHANGE_POST_STATUS", data });

// export const updtInstaPost = (data) => ({ type: "UPDATE_INSTA_POST", data });

// export const getSocialPush = (data) => ({ type: "GET_SOCIAL_PUSH", data });
// export const getSocialPushSuccess = (data) => ({ type: "GET_SOCIAL_PUSH_SUCCESS", data });

// export const updtSocialPush = (data) => ({ type: "UPDATE_SOCIAL_PUSH", data });
// export const updtSocialPushSuccess = (data) => ({ type: "UPDATE_SOCIAL_PUSH_SUCCESS", data });

// const initialState = fromJS({

// });

// function ugc(state = initialState, action) {
//     switch (action.type) {

//         case "GET_UGC_INTEGRATION_SUCCESS":
//             return { ...state, UserFbInstaIntegration: action.data };

//         case "GET_INSTA_POST_SUCCESS":
//             return { ...state, InstaPostData: action.data };

//             case "GET_SOCIAL_PUSH_SUCCESS":
//                 return { ...state, SocialPushData: action.data };

//         default:
//             return { ...state };
//     }
// }

// export default ugc;


import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const ugc = createSlice({
    name: 'ugc',
    initialState,
    reducers: {
        fbauth: (state, action) => { },
        getUGCInt: (state, action) => { },
        getUGCIntSuccess: (state, action) => {
            return { ...state, UserFbInstaIntegration: action.payload };
        },
        updPageStatus: (state, action) => { },
        delFbInt: (state, action) => { },
        getInstaPost: (state, action) => { },
        getInstaPostSuccess: (state, action) => {
            return { ...state, InstaPostData: action.payload };
        },
        ugcAddProduct: (state, action) => { },
        changePostStatus: (state, action) => { },
        updtInstaPost: (state, action) => { },
        getSocialPush: (state, action) => { },
        getSocialPushSuccess: (state, action) => {
            return { ...state, SocialPushData: action.payload };
        },
        updtSocialPush: (state, action) => { },
        updtSocialPushSuccess: (state, action) => { },
    }
});

export const {
    fbauth,
    getUGCInt,
    getUGCIntSuccess,
    updPageStatus,
    delFbInt,
    getInstaPost,
    getInstaPostSuccess,
    ugcAddProduct,
    changePostStatus,
    updtInstaPost,
    getSocialPush,
    getSocialPushSuccess,
    updtSocialPush,
    updtSocialPushSuccess
} = ugc.actions;

export default ugc.reducer;
