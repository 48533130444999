import { Badge, Page, Box, Button, Text, ButtonGroup, BlockStack, Layout, Divider, Select, InlineStack, Icon, Collapsible, Card } from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';
import { copy_icon } from '../../img';
import { useEffect } from 'react';

const Options = [
  { label: "Don't include", value: 0 },
  { label: 'Include from "Barcode"', value: 1 },
  { label: 'Include from "SKU"', value: 2 },
  { label: 'Include from "Product ID"', value: 3 }
];

const GoogleShoping = ({ props }) => {
  const { state, handleTextChange, handleSave, changeNameValue, handleCopyCode, handleBackToMain } = props;

  let _shpName = localStorage.getItem('shp').replace('.myshopify.com', '');

  let xmlUrl = `https://storage.googleapis.com/shopify_review_app/google_shop_xml/${_shpName}.xml`;
  let fileNm = `${_shpName}.xml`;

  let _ia = false;
  if (state.currUserInt && state.currUserInt.ia !== undefined) {
    _ia = state.currUserInt.ia;
  }

  useEffect(() => { // if 'd' obj is empty, add custom 'd' obj 
    if (state.dObj && Object.keys(state.dObj).length === 0) {
      let obj = { gtin: 0, mpn: 0 };
      changeNameValue({ 'dObj': obj });
    }
  }, [state.dObj, changeNameValue]);

  let queArr = [
    {
      field: 'firstTabCollapse',
      question: 'How long does it typically take for reviews to show up on my google shopping feed?',
      answer: 'Reviews may take approximately 2-3 weeks to appear on your Google Shopping feed after they have been submitted and verified.'
    },
    {
      field: 'secondTabCollapse',
      question: 'What is the minimum requirement for participating in google shopping reviews?',
      answer: 'To participate in google shopping reviews, you need to have a minimum of 50 total verified product reviews.'
    },
    {
      field: 'thirdTabCollapse',
      question: 'Why is it important to ensure each product variant has a GTIN or MPN pairing matching the product feed?',
      answer: 'Having a GTIN (Global Trade Item Number) or MPN (Manufacturer Part Number) pairing that matches the GTIN or MPN pairing in your product feed ensures accuracy and visibility of your products in google shopping. This enhances the likelihood of your products being found and reviewed by customers.'
    }
  ];

  return (
    <Page
      title={(state.currMasterObj && state.currMasterObj.dn) || state.currMasterObj.dn || 'Google Shopping'}
      titleMetadata={_ia === true ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">Active</Text></Badge> : <Badge><Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text></Badge>}
      subtitle={
        <Text tone='base' >
          {state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
        </Text>
      }
      secondaryActions={
        <div className='btn-group btn-head-flex'>
          <ButtonGroup gap='extraTight'>
            <Button variant='plain'>View article</Button>
            {_ia === true ?
              <Button variant='primary' tone='critical' onClick={() => handleSave(false)}>Turn off</Button>
              :
              <Button variant='primary' tone='success' onClick={() => handleSave(true)}>Turn on</Button>
            }
          </ButtonGroup>
        </div>
      }
      backAction={{ onAction: () => handleBackToMain() }}>

      <Box padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockEnd={500} paddingInlineStart={800}>
        <BlockStack gap={600}>
          <Layout>
            <Layout.Section variant="oneThird">
              <BlockStack gap={200}>
                <Text variant="headingMd" as="h2" fontWeight='semibold'>Unique product identifiers</Text>
                <Text variant='headingSm' as="p" fontWeight='regular'>
                  Select your product identifiers for the XML feed, ensuring the values from your shopify fields are correctly pulled through for a seamless integration.
                </Text>
              </BlockStack>
            </Layout.Section>

            <Layout.Section>
              <Card>
                <BlockStack gap={300}>
                  <Select
                    label="GTIN"
                    options={Options}
                    value={state.dObj.gtin}
                    onChange={(e) => handleTextChange('gtin', e)}
                  />
                  <Select
                    label="MPN"
                    options={Options}
                    value={state.dObj.mpn}
                    onChange={(e) => handleTextChange('mpn', e)}
                  />
                </BlockStack>
                <Box paddingBlockStart={400}>
                  <InlineStack align='end'>
                    <Button id='primary_btn' variant='primary' onClick={() => handleSave(_ia)}>Save</Button>
                  </InlineStack>
                </Box>
              </Card>
            </Layout.Section>
          </Layout>

          <Divider borderColor="border" />
          <Layout>
            <Layout.Section variant="oneThird">
              <BlockStack gap={200}>
                <Text variant="headingMd" as="h2" fontWeight='semibold'>Upload to merchant center</Text>
                <Text variant='headingSm' as="p" fontWeight='regular'>
                  Copy your product review feed XML from Google merchant center and paste it into your review feeds.
                </Text>
              </BlockStack>
            </Layout.Section>

            <Layout.Section>
              <BlockStack gap={400}>
                <Card>
                  <Box paddingBlockStart={100} paddingBlockEnd={400}>
                    <Text variant="headingSm" as="p" fontWeight='semibold'>Product reviews XML file</Text>
                    <Box paddingBlockStart={200}>
                      <div className="copycode-box copcode_bg">
                        <InlineStack align='space-between' blockAlign='start' gap={200} wrap={false}>
                          <textarea className="textarea-copycode" readOnly={true} value={xmlUrl} onFocus={(e) => e.target.select()}
                            onClick={() => handleCopyCode(xmlUrl, 'XML URL')}>
                          </textarea>
                          <Button variant='monochromePlain' onClick={() => handleCopyCode(xmlUrl, 'XML URL')}><img src={copy_icon} alt='copy'></img></Button>
                        </InlineStack>
                      </div>
                    </Box>
                  </Box>

                  <Text variant="headingSm" as="p" fontWeight='semibold'>Filename:</Text>
                  <Box paddingBlockStart={200}>
                    <div className="copycode-box copcode_bg">
                      <InlineStack align='space-between' blockAlign='start' gap={200} wrap={false}>
                        <textarea rows={1} className="textarea-copycode" readOnly={true} value={fileNm} onFocus={(e) => e.target.select()}
                          onClick={() => handleCopyCode(fileNm, 'Filename')}>
                        </textarea>
                        <Button variant='monochromePlain' onClick={() => handleCopyCode(fileNm, 'Filename')}>
                          <img src={copy_icon} alt='copy'></img></Button>
                      </InlineStack>
                    </div>
                  </Box>
                </Card>

                <Card padding={0}>
                  <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                    <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
                  </Box>
                  <Divider borderColor='border-tertiary' />
                  <Box paddingInline={400} paddingBlockEnd={300}>

                    {queArr && queArr.map((data, i) => {
                      return (
                        <div key={i} id='cursorPoint' onClick={() => changeNameValue({ tabEvent: data.field })}>
                          <Box paddingBlock={300}>
                            <InlineStack wrap={false} blockAlign='center' align='space-between'>
                              <Text variant='headingSm' as='p' fontWeight='medium'>{data.question}</Text>
                              <Icon source={data.field === state.tabEvent ? ChevronUpIcon : ChevronDownIcon}></Icon>
                            </InlineStack>
                            <Collapsible
                              open={data.field === state.tabEvent}
                              id="db_collaspe"
                              transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                              <Box paddingBlockStart={100}>
                                <Text variant='headingSm' as='p' tone='subdued' fontWeight='regular'>{data.answer}</Text>
                              </Box>
                            </Collapsible>
                          </Box>
                          {i !== queArr.length - 1 && <Divider />}
                        </div>
                      );
                    })}

                  </Box>
                </Card>
              </BlockStack>
            </Layout.Section>
          </Layout>
        </BlockStack >
      </Box >

    </Page >
  );
}

export default GoogleShoping;