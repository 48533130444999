import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/customQuestion';
import { toastify } from '../ducks/toast';

function* getCustmQue(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getCustQueForm', action.data);

    if (res.status === 'success') {
      yield put(actions.getCustmQueSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCustmQue(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createCustQueForm', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getCustmQue());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateCustmQue(action) {
  try {
    const res = yield call(api.POST, '/updateCustQueForm', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getCustmQue());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteCustmQue(action) {
  try {
    const res = yield call(api.POST, '/deleteCustQueForm', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getCustmQue());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetCustmQue() {
  yield takeLatest("GET_CUSTM_QUE", getCustmQue);
}

export function* watchCreateCustmQue() {
  yield takeLatest("CREATE_CUSTM_QUE", createCustmQue);
}

export function* watchUpdateCustmQue() {
  yield takeLatest("UPDATE_CUSTM_QUE", updateCustmQue);
}

export function* watchDeleteCustmQue() {
  yield takeLatest("DELETE_CUSTM_QUE", deleteCustmQue);
}

export default function* rootSaga() {
  yield fork(watchGetCustmQue);
  yield fork(watchCreateCustmQue);
  yield fork(watchUpdateCustmQue);
  yield fork(watchDeleteCustmQue);
}
