import { Page, Button, Text, Box, InlineGrid, InlineStack, Divider, Icon, Collapsible, BlockStack, List, Badge, ButtonGroup, Card } from '@shopify/polaris';
import { ChevronUpIcon, ChevronDownIcon, FileIcon } from '@shopify/polaris-icons';
import { fb_icon, insta_icon, fbinsta_icon } from '../../img';
import UgcGrid from './UgcGrid';
import SocialPush from './SocialPush';

function ListUgc(props) {
  const { state, fbInstaIntegration } = props;

  let MainPageArr = [
    {
      typ: 'socialPush',
      title: 'Social push',
      desc: 'Automate the promotion of your best reviews on social channels, giving your products a trusted voice and driving engagement',
      icon: FileIcon,
      btndesc: 'Set up review sharing',
    },
    {
      typ: 'ugcGrid',
      title: 'UGC',
      desc: 'Pull your instagram feed into your store, creating a shoppable gallery that showcases real-life uses of your products',
      icon: FileIcon,
      btndesc: 'Integrate instagram feed'
    }
  ]

  const fbPageData = fbInstaIntegration && fbInstaIntegration.d && fbInstaIntegration.d.fb_page ? fbInstaIntegration.d.fb_page : [];

  const instaData = fbInstaIntegration && fbInstaIntegration.d && fbInstaIntegration.d.insta_page ? fbInstaIntegration.d.insta_page : [];

  const isActive = instaData.find(account => account.ia) ? true : false;

  const isActiveFB = fbPageData.find(account => account.ia) ? true : false;
  let isMaster = localStorage.getItem('is_master');

  return (
    <div>
      {state.isConfiguration !== true ?
        <Page
          title={'Marketing and social'}
          subtitle={'Maximize customer interaction with automated sharing of reviews and shoppable social feeds on your store.'}
          backAction={state.searchParam === 'ugc' ? { onAction: () => props.handleBackToMain() } : false}>

          <Card padding={0}>
            <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
              <div className='Connect_you_accounts'>
                <Text fontWeight='medium'>Connect you accounts</Text>
              </div>
            </Box>
            <Divider />
            <Box paddingInline={400} paddingBlockEnd={200} paddingBlockStart={200}>
              <div onClick={() => props.changeNameValue({ 'guideOpen': !state.guideOpen })} id='cursorPoint'>
                <Box paddingBlockStart={300} paddingBlockEnd={300}>
                  <InlineStack wrap={false} blockAlign='center' align='space-between'>
                    <Box>
                      <InlineStack gap={200}>
                        <img src={fbinsta_icon} alt='' />
                        <BlockStack gap={200}>
                          <Text variant='headingMd' as='h4' fontWeight='medium'>Facebook & instagram authentication</Text>
                        </BlockStack>
                      </InlineStack>
                    </Box>
                    <Box>
                      <Icon source={state.guideOpen ? ChevronUpIcon : ChevronDownIcon}></Icon>
                    </Box>
                  </InlineStack>
                </Box>
              </div>

              <Collapsible
                open={state.guideOpen}
                id="db_collaspe"
                transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                <Box>
                  {fbInstaIntegration ? (
                    <>
                      <Text variant='headingSm' fontWeight='medium' as='h5'>
                        Your Store is connected to <a href='https://www.facebook.com/' target="_blank" rel="noreferrer">{fbInstaIntegration.d.fb_name}</a> on Facebook and{' '}
                        {instaData.map((account, index) => (
                          <span key={index}>
                            <a href={`https://www.instagram.com/${account.name}`} target="_blank" rel="noreferrer">{`@${account.name}`}</a>
                            {index < instaData.length - 1 && ', '}
                          </span>
                        ))} on Instagram
                      </Text>


                      <Box paddingBlockStart={400}>
                        <Text>Select the page or account you want to use. Learn more in our <Button variant='plain' removeUnderline="true">help desk.</Button></Text>
                      </Box>

                      <Box paddingBlockStart={500}>
                        <div className='card'>
                          <Box paddingBlockStart={400} paddingInline={400}>
                            <div>
                              <InlineStack gap={200}>
                                <Text variant="headingXs" as="h6">Select Facebook page</Text>
                                <img src={fb_icon} alt='' />
                              </InlineStack>

                              <Box paddingBlockStart={200}>
                                {fbPageData && fbPageData.map((page, index) => (
                                  <Box key={index}>
                                    <InlineGrid columns={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}  blockAlign='center'>
                                      <InlineStack>
                                        {/* <Box paddingBlockStart={100}>
                                          <img src={page.puImg} alt={page.name} className='fbinsta_img' />
                                        </Box> */}
                                        <Box paddingInlineStart={{ xs: 0, sm: 400 }}  paddingBlockEnd={{ xs: 200, sm: 0 }}>
                                          <Text>{page.name}</Text>
                                        </Box>
                                      </InlineStack>

                                      {page.ia ? (
                                        <>
                                          <Box>
                                            <ButtonGroup>
                                              <Badge tone="success">Connected</Badge>
                                              <Button variant='plain' size='micro' tone='critical' removeUnderline='true' onClick={() => props.disconnectPage('fb', page.page_id)}>Disconnect</Button>
                                            </ButtonGroup>
                                          </Box>
                                        </>
                                      ) : (
                                        <Box>
                                          <Button variant='primary' size='slim' onClick={() => props.connectPage('fb', page.page_id)}>Use This Page</Button>
                                        </Box>
                                      )}
                                    </InlineGrid>
                                    <Box paddingBlock={200}>
                                      {index < fbPageData.length - 1 && <Divider borderColor='border' />}
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </div>
                          </Box>
                        </div>
                      </Box>

                      <Box paddingBlockStart={500}>
                        <div className='card'>
                          <Box paddingBlockStart={400} paddingInline={400} paddingBlockEnd={100}>
                            <div>
                              <InlineStack gap={200}>
                                <Text variant="headingXs" as="h6">Select Instagram page</Text>
                                <img src={insta_icon} alt='' />
                              </InlineStack>

                              <Box paddingBlockStart={200} paddingBlockEnd={200}>
                                {instaData && instaData.map((page, index) => (
                                  <Box key={index}>
                                    <InlineGrid columns={['oneThird', 'twoThirds']} blockAlign='center'>
                                      <InlineStack>
                                        {/* <Box paddingBlockStart={100}>
                                          <img src={page.puImg} alt={page.name} className='fbinsta_img' />
                                        </Box> */}
                                        <Box paddingInlineStart={400}>
                                          <Text>{page.name}</Text>
                                        </Box>
                                      </InlineStack>
                                      {page.ia ? (
                                        <>
                                          <Box>
                                            <ButtonGroup>
                                              <Badge tone="success">Connected</Badge>
                                              <Button variant='plain' size='micro' tone='critical' removeUnderline='true' paddingBlockStart={100} onClick={() => props.disconnectPage('insta', page.page_id)}>Disconnect</Button>
                                            </ButtonGroup>
                                          </Box>
                                        </>
                                      ) : (
                                        <Box >
                                          <Button variant='primary' size='slim' onClick={() => props.connectPage('insta', page.page_id)}>Use This Page</Button>
                                        </Box>
                                      )}

                                    </InlineGrid>
                                    <Box paddingBlock={200}>
                                      {index < instaData.length - 1 && <Divider borderColor='border' />}
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </div>
                          </Box>
                        </div>
                      </Box>

                      <Box paddingBlockStart={400}>
                        <Text>To disable integration and clear all authentication. <Button variant="plain" onClick={() => props.deleteFbInstaIntegration()}>Click here</Button></Text>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box paddingBlockStart={200}>
                        <Text variant='bodyMd' as='h4' fontWeight='medium'>Link accounts now</Text>
                      </Box>
                      <Box paddingBlockStart={400} paddingInline={200}>
                        <List>
                          <List.Item tone="subdued">Sync your facebook account to automatically publish your most compelling product reviews.</List.Item>
                          <List.Item tone="subdued">For a shoppable instagram feed in your store, connect your business account to facebook with this simple guide.</List.Item>
                        </List>
                      </Box>
                      <Box paddingBlockStart={400} paddingBlockEnd={200}>
                        {/* {isMaster ? ( */}
                        <Button id='fb_insta_btn' onClick={() => props.openFacebookInstagramModal()}>Login with facebook</Button>
                        {/* ) : (
                          <Button variant="primary" tone="critical">Coming soon</Button>
                        )} */}
                      </Box>
                    </>
                  )}

                </Box>
              </Collapsible>

            </Box>

          </Card>

          <div className='Seq_page'>
            <Box padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockStart={400} paddingBlockEnd={400}>
              <InlineGrid columns={{ md: 2, sm: 1 }} gap={400}>

                {MainPageArr && MainPageArr.length > 0 && MainPageArr.map((data, i) => {
                  return (
                    <div key={i} id='cursorPoint' onClick={() => (data.typ !== 'socialPush' || isActiveFB) && isActive && props.handleSettingClk(data)}>
                      <Card key={i}>
                        <InlineStack align='space-between' blockAlign='center'>
                          <div className='automate_img'>
                            <Icon source={data.icon} tone='base' />
                          </div>
                          <Button variant='primary' disabled={!isActive || (data.typ === 'socialPush' && !isActiveFB)} onClick={() => props.handleSettingClk(data)}>{data.btndesc}</Button>
                        </InlineStack>
                        <Box paddingBlockStart={400} paddingBlockEnd={200}>
                          <Text variant='bodyLg' fontWeight='medium' tone='' as='h4'>{data.title}</Text>
                        </Box>
                        <Box paddingInlineEnd={500}>
                          <Text variant='bodySm' fontWeight='regular' as='p' tone="subdued">{data.desc}</Text>
                        </Box>
                      </Card>
                    </div>
                  );
                })
                }

              </InlineGrid>
            </Box>
          </div>
        </Page>
        :
        props.state.ugcTyp === 'ugcGrid' ?
          <UgcGrid
            props={props}
            state={props.state}
            onPaggiCall={props.onPaggiCall}
            groupPagiCall={props.groupPagiCall}
            pageNumber={props.pageNumber}
            changeNameValue={props.changeNameValue}
            handleBackToMain={props.handleBackToMain}
            fbInstaIntegration={props.fbInstaIntegration}
            InstaData={props.InstaData}
            addProduct={props.addProduct}
            openCloseModal={props.openCloseModal}
            getProductNameList={props.getProductNameList}
            selectProdcuts={props.selectProdcuts}
            handleSearchUgcProduct={props.handleSearchUgcProduct}
            handleProductSave={props.handleProductSave}
            handleProductUpdate={props.handleProductUpdate}
            productupdtModal={props.productupdtModal}
            selectUpdtProdcuts={props.selectUpdtProdcuts}
            handleUpdateUgcProduct={props.handleUpdateUgcProduct}
            publishPost={props.publishPost}
            refreshPost={props.refreshPost}
            accountConnect={props.accountConnect}
            custWidget={props.custWidget}
          />
          :
          <SocialPush
            props={props}
            state={props.state}
            handleBackToMain={props.handleBackToMain}
            handleSaveSocialPush={props.handleSaveSocialPush}
            cancelSocialPush={props.cancelSocialPush}
            changeNameValue={props.changeNameValue}
            handleIncTxtChange={props.handleIncTxtChange}
          />
      }
    </div>
  );
}

export default ListUgc;