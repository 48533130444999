import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getProfile: (state, action) => { },
    getProfileSuccess: (state, action) => {
      return { ...state, profile: action.payload };
    },
    updSetupFlow: (state, action) => { },
    getDataCount: (state, action) => { },
    getDataCountSuccess: (state, action) => {
      return { ...state, dataCount: action.payload }
    },
    loginWithWix: (state, action) => { },
    skipOnBoard: (state, action) => { }
  }
});

export const {
  getProfile,
  getProfileSuccess,
  updSetupFlow,
  getDataCount,
  getDataCountSuccess,
  loginWithWix,
  skipOnBoard
} = auth.actions;

export default auth.reducer;