import {
  IndexTable, IndexFilters, Text, Card, Page, Button, Box, Badge, Icon, Modal, InlineStack, Thumbnail, Divider, TextField, ProgressBar, useSetIndexFiltersMode, BlockStack
} from '@shopify/polaris';
import { ExternalSmallIcon, ViewIcon, ImageIcon } from '@shopify/polaris-icons';
import { fillStar } from '../../img';
import { Link } from 'react-router-dom';
import React, { memo } from 'react';

const sortOptions = [
  { label: 'Product name', value: 'pn asc', directionLabel: 'A-Z' },
  { label: 'Product name', value: 'pn desc', directionLabel: 'Z-A' },
  { label: 'Average rating', value: 'avgrtng asc', directionLabel: 'Ascending' },
  { label: 'Average rating', value: 'avgrtng desc', directionLabel: 'Descending' },
  { label: 'Publish review', value: 'prtng asc', directionLabel: 'Ascending' },
  { label: 'Publish review', value: 'prtng desc', directionLabel: 'Descending' },
  { label: 'Unpublish review', value: 'uprtng asc', directionLabel: 'Ascending' },
  { label: 'Unpublish review', value: 'uprtng desc', directionLabel: 'Descending' },
];

function List({ props }) {
  const { mode, setMode } = useSetIndexFiltersMode();
  const { state, changeNameValue } = props;

  //function for get start Percentage
  const getStarPercentage = (starCount, totalStar) => {
    let count = (starCount / totalStar) * 100;
    if (isNaN(count)) count = 0;
    return count;
  };

  let _ti = localStorage.getItem('ti') ? window.btoa(localStorage.getItem('ti')) : ''
  let _shp = window.btoa(localStorage.getItem('shp'));
  let _pid = window.btoa(state.productPid ? state.productPid : '');
  let ProdLink = `https://wisernotify.com/form/writereview/?pid=${_pid}&ti=${_ti}&shp=${_shp}`;

  const ratings = [
    { star: 5, countField: 'str5' },
    { star: 4, countField: 'str4' },
    { star: 3, countField: 'str3' },
    { star: 2, countField: 'str2' },
    { star: 1, countField: 'str1' },
  ];

  return (
    <div className='product_page'>
      <Page
        title="Products"
        subtitle='See overall product-wise rating'>

        <Card padding="0">
          <IndexFilters
            sortOptions={sortOptions}
            sortSelected={state.sortProductValue}
            onSort={(e) => changeNameValue({ sortProductValue: e })}
            queryValue={state.searchProductValue}
            queryPlaceholder="Search product name...."
            onQueryChange={(e) => changeNameValue({ searchProductValue: e })}
            onQueryClear={() => changeNameValue({ searchProductValue: '' })}
            cancelAction={{
              onAction: () => changeNameValue({ searchProductValue: '' }),
            }}
            tabs={[]}
            filters={props.productFilters}
            appliedFilters={props.productAppliedFilters}
            onClearAll={props.handleProductFiltersClearAll}
            mode={mode}
            setMode={setMode}
          />

          <div id='product_tbl'>
            <IndexTable
              itemCount={props.state.ProductData ? props.state.ProductData.length : 0}
              selectable={false}
              headings={[
                { title: '' },
                { title: 'Product name' },
                { title: 'Average rating' },
                { title: 'Publish review' },
                { title: 'Unpublish review' },
                { title: 'Action' },
              ]}
              pagination={{
                hasPrevious: state.productPaggiActive > 1 ? true : false,
                hasNext: (state.productPaggiActive < state.productPageNumber) ? true : false,
                onPrevious: () => props.onProductPaggiCall('-1'),
                onNext: () => props.onProductPaggiCall('+1')
              }}>

              {props.state.ProductData && props.state.ProductData.length > 0 &&
                props.state.ProductData.map((data, index) => (
                  <IndexTable.Row
                    id={data._id}
                    key={index}
                    selected={false}
                    position={index}
                  >
                    <IndexTable.Cell>
                      <Thumbnail
                        size='small'
                        source={data.piu ? data.piu : ImageIcon}
                        alt="product" />
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Text variant="bodySm" fontWeight="medium" as="span" id='txt_limit'>{data.pn ? data.pn : '-'}</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <InlineStack alignItems='center' gap={200}>
                        <Text variant="bodySm" fontWeight="medium" as="span" alignment='end'>
                          {data.avgrtng ? data.avgrtng : '0'}</Text>
                        <img src={fillStar} alt="fillStar" />
                      </InlineStack>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Text variant="bodySm" fontWeight="medium" as="span">{data.prtng ? data.prtng : 0}</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Text variant="bodySm" fontWeight="medium" as="span">{data.uprtng ? data.uprtng : 0}</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Button variant='monochromePlain' onClick={() => props.handleProductViewAction(data)}>
                        <Icon source={ViewIcon} tone="base" />
                      </Button>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                ))}
            </IndexTable>
          </div>

        </Card>

        {/* view modal */}
        <Modal
          open={state.productViewOpen}
          onClose={(e) => props.openCloseModal('productViewOpen', state.productViewOpen, e)}>
          {state.productViewActionData.length > 0 && state.productViewActionData.map((data, i) => {
            return (
              <div className="moderate_rev_modal" key={i}>
                <div style={{ backgroundColor: "white", padding: "12px 16px" }}>
                  <InlineStack align="space-between" blockAlign='center' gap={400}>
                    <Text variant="headingMd" as="h5" fontWeight='bold'>Overview of Ratings</Text>
                    <Box paddingInlineEnd={800}>
                      <InlineStack align='center'>
                        <Button url={`https://${localStorage.getItem('shp')}/admin/products/${data.pid}`}
                          target='_blank' icon={ExternalSmallIcon}>Managed in Shopify</Button>
                      </InlineStack>
                    </Box>
                  </InlineStack>
                </div>
                <Box padding={400}>
                  <Box paddingBlockEnd={400}>

                    <Card>
                      <InlineStack align='space-between' blockAlign='center'>
                        <InlineStack blockAlign='center' gap={300}>
                          <Thumbnail size='small' source={data.piu ? data.piu : ImageIcon} alt="product" />
                          <InlineStack blockAlign='center' gap={200}>
                            <Text variant='bodyMd' id='txt_limit' as='span' fontWeight='semibold'>
                              {data.pn ? data.pn : '-'}</Text>

                            <Badge tone={state.idsblrvw === true ? "" : 'success'}>
                              <Text variant='headingXs' fontWeight='medium' as='span'>
                                {state.idsblrvw === true ? 'Disabled' : 'Enabled'}</Text>
                            </Badge>

                          </InlineStack>
                        </InlineStack>
                        <Button onClick={() => props.handleUpdProduct(state.productId, !state.idsblrvw)}>
                          <Text variant='bodyMd' as='span' fontWeight='medium'>
                            {state.idsblrvw === true ? 'Enable reviews' : 'Disable reviews'}</Text>
                        </Button>
                      </InlineStack>
                      <Box paddingBlockStart={200} paddingBlockEnd={400}>
                        <Divider />
                      </Box>
                      <InlineStack align='space-between'>
                        <BlockStack gap={400}>
                          <Text as='h3' variant='headingXl' fontWeight='semibold'>{data.avgrtng ? data.avgrtng : '0'}</Text>
                          <Box>{props.displyStarRate(data.avgrtng, 24)}</Box>
                          <Box>
                            {(data.prtng && data.prtng !== 0) ?
                              <Button variant='plain' target='_self' url={`/reviewmoderate?pid=${window.btoa(data.pid)}`}>{data.prtng} reviews</Button>
                              :
                              <Text variant='bodyMd' as='h6'>{data.prtng ? data.prtng : 0} customer reviews</Text>
                            }
                          </Box>
                        </BlockStack>
                        <Box paddingInlineEnd={100}>

                          <InlineStack gap={200} direction='column' align='start'>
                            {ratings.map((rating, i) => {
                              return (
                                <InlineStack key={i} blockAlign='center' gap={300} wrap={false}>
                                  <Box minWidth='10px'>
                                    <Text as='h5' variant='headingMd' fontWeight='medium'>{rating.star}</Text>
                                  </Box>
                                  <img src={fillStar} alt="fillStar" />
                                  <div className='progrss_div'>
                                    <ProgressBar size="small" progress={getStarPercentage(data[rating.countField], data.prtng)} animated tone="highlight" />
                                  </div>
                                  <Text as='h5' variant='headingMd' fontWeight='medium'>{data[rating.countField]}</Text>
                                </InlineStack>
                              )
                            })}
                          </InlineStack>

                          {/* <InlineStack gap={200} direction='column' align='start'>

                            <InlineStack blockAlign='center' gap={300} wrap={false}>
                              <Box minWidth='10px'><Text as='h5' variant='headingMd' fontWeight='medium'>5</Text></Box>
                              <img src={fillStar} alt="fillStar" />
                              <div className='progrss_div'>
                                <ProgressBar size="small" progress={getStarPercentage(data.str5, data.prtng)} animated tone="highlight" />
                              </div>
                              <Text as='h5' variant='headingMd' fontWeight='medium'>{data.str5}</Text>
                            </InlineStack>

                            <InlineStack blockAlign='center' gap={300} wrap={false}>
                              <Box minWidth='10px'><Text as='h5' variant='headingMd' fontWeight='medium'>4</Text></Box>
                              <img src={fillStar} alt="fillStar" />
                              <div className='progrss_div'>
                                <ProgressBar size="small" progress={getStarPercentage(data.str4, data.prtng)} animated tone="highlight" />
                              </div>
                              <Text as='h5' variant='headingMd' fontWeight='medium'>{data.str4}</Text>
                            </InlineStack>

                            <InlineStack blockAlign='center' gap={300} wrap={false}>
                              <Box minWidth='10px'><Text as='h5' variant='headingMd' fontWeight='medium'>3</Text></Box>
                              <img src={fillStar} alt="fillStar" />
                              <div className='progrss_div'>
                                <ProgressBar size="small" progress={getStarPercentage(data.str3, data.prtng)} animated tone="highlight" />
                              </div>
                              <Text as='h5' variant='headingMd' fontWeight='medium'>{data.str3}</Text>
                            </InlineStack>

                            <InlineStack blockAlign='center' gap={300} wrap={false}>
                              <Box minWidth='10px'><Text as='h5' variant='headingMd' fontWeight='medium'>2</Text></Box>
                              <img src={fillStar} alt="fillStar" />
                              <div className='progrss_div'>
                                <ProgressBar size="small" progress={getStarPercentage(data.str2, data.prtng)} animated tone="highlight" />
                              </div>
                              <Text as='h5' variant='headingMd' fontWeight='medium'>{data.str2}</Text>
                            </InlineStack>

                            <InlineStack blockAlign='center' gap={300} wrap={false}>
                              <Box minWidth='10px'><Text as='h5' variant='headingMd' fontWeight='medium'>1</Text></Box>
                              <img src={fillStar} alt="fillStar" />
                              <div className='progrss_div'>
                                <ProgressBar size="small" progress={getStarPercentage(data.str1, data.prtng)} animated tone="highlight" />
                              </div>
                              <Text as='h5' variant='headingMd' fontWeight='medium'>{data.str1}</Text>
                            </InlineStack>

                          </InlineStack> */}
                        </Box>
                      </InlineStack>
                      <Box paddingBlockStart={400}>
                        <Text variant='bodySm' fontWeight='medium'>
                          Get Detailed Feedback - <Link to={`/collectReview/customQuestion`} style={{ color: "#005bd3" }}>Add custom questions to your review form.</Link></Text>
                      </Box>
                    </Card>

                    <Box paddingBlockStart={400}>
                      <Card>
                        <InlineStack gap={100} direction={'column'}>
                          <Text variant='headingMd' as='h5' fontWeight='bold'>
                            Review Form Link - Easily collect reviews</Text>
                          <Text variant='bodySm'>
                            Utilize this specific review form link to gather review for this product</Text>
                        </InlineStack>
                        <Box paddingBlockStart={400}>
                          <TextField
                            readOnly
                            id='CopyKey'
                            value={ProdLink}
                            onFocus={(e) => e.target.select()}
                            connectedRight={<Button id='primary_btn' disabled={!data.pid ? true : false}
                              onClick={() => props.handleCopyCode(ProdLink, 'link')}>Copy link</Button>} />
                        </Box>
                      </Card>
                    </Box>

                  </Box>
                  <Text variant='bodySm' fontWeight='medium'>To explore more about review forms, visit the <Link to='/forms?p=frm' style={{ color: "#005bd3" }}>Forms section</Link></Text>
                </Box>
              </div>
            );
          })}
        </Modal>

      </Page>
    </div>
  );
}

export default memo(List);