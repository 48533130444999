import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/queAns';
import { toastify } from '../ducks/toast';

function* getQueAns(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getQueAns', action.payload);

    if (res.status === 'success') {
      yield put(actions.getQueAnsSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createQueAns(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createQueAns', action.payload);
    if (res.status === 'success') {
      yield put(actions.getQueAns(global.modeObj));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateQueAns(action) {
  try {
    const res = yield call(api.POST, '/updateQueAns', action.payload);
    if (res.status === 'success') {
      yield put(actions.getQueAns(global.modeObj));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* importQueAnsCSV(action) {
  try {
    const res = yield call(api.POST, '/importQueAnsCSV', action.payload);
    if (res.status === 'success') {
      yield put(actions.getQueAns(global.modeObj));
      yield put(toastify({ type: 'success', msg: 'Data is importing please refresh after some time!' }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.msg }));
    }
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetQueAns() {
  yield takeLatest(actions.getQueAns.type, getQueAns);
}

export function* watchCreateQueAns() {
  yield takeLatest(actions.createQueAns.type, createQueAns);
}

export function* watchUpdateQueAns() {
  yield takeLatest(actions.updateQueAns.type, updateQueAns);
}

export function* watchImportQueAnsCSV() {
  yield takeLatest(actions.importQueAnsCSV.type, importQueAnsCSV)
}

export default function* rootSaga() {
  yield fork(watchGetQueAns);
  yield fork(watchCreateQueAns);
  yield fork(watchUpdateQueAns);
  yield fork(watchImportQueAnsCSV);
}
