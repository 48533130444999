import { Text, Checkbox, InlineStack, Box, InlineGrid, TextField } from '@shopify/polaris';
import { ThemeTemplateIcon, ImageWithTextOverlayIcon, EyeCheckMarkIcon, HeartIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const WriteQueAns = ({ props }) => {
  let { state, changeNameValue, handleTextChange } = props;
  let cnf = state.cnf;

  var style = document.createElement('style');
  document.head.appendChild(style);

  let verticalTabsArr = [
    { title: 'Form layouts', img: ThemeTemplateIcon },
    { title: 'Form fields', img: ImageWithTextOverlayIcon },
    { title: 'Form color combination', img: EyeCheckMarkIcon },
    { title: 'Thank you message', img: HeartIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <InlineGrid columns={2} gap={300}>

          {state?.writeArr?.length > 0 && state.writeArr.map((val, i) => {
            return (
              <div key={i} className={val._id === state?.currQueWriteId ? 'select_block active' : 'select_block'}
                onClick={() => changeNameValue({ currQueWriteId: val._id }, 'isEditable')}>
                <InlineStack align='center' blockAlign='center'>
                  <Checkbox tone={val._id === state.currQueWriteId ? 'magic' : ''}
                    checked={val._id === state.currQueWriteId} />
                  <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                    {val.dn ? val.dn : 'form'}</Text>
                </InlineStack>
                <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
              </div>
            );
          })}
        </InlineGrid>
        :
        state.sidebarActiveIndex === 1 ?
          <Box>

            {cnf?.wrtfrminptcntlbltxt !== undefined &&
              <Box>
                <Text fontWeight='bold' as='h6'>Review content field</Text>
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <TextField
                    label={<Text>Label</Text>}
                    value={cnf.wrtfrminptcntlbltxt}
                    onChange={(e) => handleTextChange({ wrtfrminptcntlbltxt: e })}
                    autoComplete="off" />
                </Box>
                {cnf?.wrtfrminptcntplchdlrtxt !== undefined &&
                  <Box paddingBlockStart={300} paddingInlineStart={600}>
                    <TextField
                      label={<Text>Placeholder text</Text>}
                      value={cnf.wrtfrminptcntplchdlrtxt}
                      onChange={(e) => handleTextChange({ wrtfrminptcntplchdlrtxt: e })}
                      autoComplete="off" />
                  </Box>
                }
              </Box>
            }

            {cnf?.wrtfrminptemllbl !== undefined &&
              <Box paddingBlock={500}>
                <Text fontWeight='bold' as='h6'>Email field</Text>
                <Box paddingInlineStart={600} paddingBlockStart={200}>
                  <TextField
                    label={<Text>Label</Text>}
                    value={cnf.wrtfrminptemllbl}
                    onChange={(e) => handleTextChange({ wrtfrminptemllbl: e })}
                    autoComplete="off" />
                </Box>
                {cnf?.wrtfrminptemlplchldr !== undefined &&
                  <Box paddingInlineStart={600} paddingBlockStart={200}>
                    <TextField
                      label={<Text>Placeholder text</Text>}
                      value={cnf.wrtfrminptemlplchldr}
                      onChange={(e) => handleTextChange({ wrtfrminptemlplchldr: e })}
                      autoComplete="off" />
                  </Box>
                }
              </Box>
            }

            {cnf?.wrtfrminptunmlbl !== undefined &&
              <Box>
                <Text fontWeight='bold' as='h6'>Name field</Text>
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <TextField
                    label={<Text>Username label text</Text>}
                    value={cnf.wrtfrminptunmlbl}
                    onChange={(e) => handleTextChange({ wrtfrminptunmlbl: e })}
                    autoComplete="off" />
                </Box>
                {cnf?.wrtfrminptunmplchldr !== undefined &&
                  <Box paddingInlineStart={600} paddingBlockStart={200}>
                    <TextField
                      label={<Text>Username placeholder text</Text>}
                      value={cnf.wrtfrminptunmplchldr}
                      onChange={(e) => handleTextChange({ wrtfrminptunmplchldr: e })}
                      autoComplete="off" />
                  </Box>
                }
              </Box>
            }

            {cnf?.wrtfrmsbmtbtntxt !== undefined &&
              <Box paddingBlock={500}>
                <Text fontWeight='bold' as='h6'>Submit button</Text>
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <TextField
                    label={<Text>Label</Text>}
                    value={cnf.wrtfrmsbmtbtntxt}
                    onChange={(e) => handleTextChange({ wrtfrmsbmtbtntxt: e })}
                    autoComplete="off" />
                </Box>
              </Box>
            }

          </Box>
          :
          state.sidebarActiveIndex === 2 ?
            <Box>
              <InlineStack direction={'column'} gap={500}>
                {cnf?.wrtfrmistrrtng === true && cnf?.wrtfrmttltxtclr !== undefined &&
                  <ColorPick
                    labelName='Title font color'
                    colorKey="wrtfrmttltxtclr"
                    colorValue={cnf.wrtfrmttltxtclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf?.wrtfrmistrrtng === true && cnf?.wrtfrmbgclr !== undefined &&
                  <ColorPick
                    labelName='Title background color'
                    colorKey="wrtfrmbgclr"
                    colorValue={cnf.wrtfrmbgclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf?.wrtfrminptlblclr !== undefined &&
                  <ColorPick
                    labelName='label color'
                    colorKey="wrtfrminptlblclr"
                    colorValue={cnf.wrtfrminptlblclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf?.wrtfrmsbmtbtntxtclr !== undefined &&
                  <ColorPick
                    labelName='Submit text color'
                    colorKey="wrtfrmsbmtbtntxtclr"
                    colorValue={cnf.wrtfrmsbmtbtntxtclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf?.wrtfrmsbmtbtnbgclr !== undefined &&
                  <ColorPick
                    labelName='Submit background color'
                    colorKey="wrtfrmsbmtbtnbgclr"
                    colorValue={cnf.wrtfrmsbmtbtnbgclr}
                    changeNameValue={handleTextChange} />
                }
              </InlineStack>

            </Box>
            :
            state.sidebarActiveIndex === 3 &&
            <Box>

              {cnf?.thnktxt !== undefined &&
                <TextField
                  label={<Text>Text</Text>}
                  value={cnf.thnktxt}
                  onChange={(e) => handleTextChange({ thnktxt: e })}
                  autoComplete="off" />
              }
              {cnf?.thnktxtclr !== undefined &&
                <Box paddingBlock={600}>
                  <ColorPick
                    labelName='Color'
                    colorKey="thnktxtclr"
                    colorValue={cnf.thnktxtclr}
                    changeNameValue={handleTextChange} />
                </Box>
              }

            </Box>
      }

    </Box >
  );
}

export default WriteQueAns;