import { InlineGrid, BlockStack, Link, Modal, Collapsible, Badge, Spinner, Banner, Text, Card, Tooltip, Page, Button, ButtonGroup, Box, Icon, InlineStack } from '@shopify/polaris';
import { ExternalIcon, PaperCheckIcon, DnsSettingsIcon, AppExtensionIcon, CodeIcon, ChevronUpIcon, ChevronDownIcon, StarFilledIcon, StarIcon } from '@shopify/polaris-icons';
import { warning_icon, copy_icon } from '../../img';
import ConfirmationAlert from '../ConfirmationModal/ConfirmationAlert';
import { ArrowDiagonalIcon } from '@shopify/polaris-icons';
// import MainTab from './MainTab';
import AllReviewTab from './AllReviewTab';
import PreviewCard from './Preview';
import React, { memo, useEffect } from 'react';


let disableWdgtArr = ['carousel']; // disable widgets for free plan.
let hideMsterWdgt = ['checkout_review'];  // hide these widget, when localStorage's is_master has no value
const isMaster = localStorage.getItem("is_master");
const isShwMstWdgt = isMaster !== null && isMaster !== undefined && isMaster !== '';
let shpName = localStorage.getItem('shp') ? localStorage.getItem('shp') : '';


function List(props) {
  const {
    state,
    changeNameValue,
    handleCopyCode,
    tabEvent,
    setTabEvent,
    handleBackToMain,
    openCloseModal,
    handleTabClick,
    movingClass,
    handlePreviewDiscrd,
    handleSaveChange,
    callResetApi,
    handleTextChange
  } = props;

  const arr2Types = state.WidgetList && state.WidgetList.length > 0 ? state.WidgetList.map(data => data.wdtyp) : [];

  const addWidgets = (state.lstLayout || [])
    .filter(data => isShwMstWdgt || (Array.isArray(hideMsterWdgt) && !hideMsterWdgt.includes(data.typ)))
    .filter(data => !arr2Types.includes(data.typ))
    .map((data, i) => (
      <Box key={i} paddingBlockEnd={200}>
        <Card padding={0}>
          <div className='theme_div'>
            <div className='theme_img'>
              <img className='w-100' src={data.iu} alt="ThemeImage" />
            </div>
            <Box padding={400} paddingBlockStart={300}>
              <InlineStack gap={200}>
                <Text variant="headingMd" fontWeight="semibold" as="h5">{data.dn ? data.dn : ''}</Text>
                <Text variant="bodySm" as="p" id='fix_hght' fontWeight='regular' tone='subdued'>{data.rm ? data.rm : ''}</Text>
              </InlineStack>
              <ButtonGroup>
                {state.isFreePln === true && disableWdgtArr.includes(data.typ) ?
                  <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                    <Button variant="secondary" disabled>Add widget</Button>
                  </Tooltip>
                  :
                  <Button variant="secondary"
                    onClick={() => props.handleUpdateAction(data.typ, 'create')}>Add widget</Button>
                }
                <Button variant="primary" disabled>Manage</Button>
              </ButtonGroup>
            </Box>
          </div>
        </Card>
      </Box >
    ));

  const editWidgets = (state.lstLayout || [])
    .filter(data => isShwMstWdgt || (Array.isArray(hideMsterWdgt) && !hideMsterWdgt.includes(data.typ)))
    .filter(data => arr2Types.includes(data.typ))
    .map((data, i) => (
      <Box key={i} paddingBlockEnd={200}>
        <Card padding={0}>
          <div className='theme_div' id='cursorPoint'
            onClick={state.isFreePln === true && disableWdgtArr.includes(data.typ) ?
              () => { } :
              () => props.handleUpdateAction(data.typ)}
          >
            <div className='theme_img'><img className='w-100' src={data.iu} alt="ThemeImage" /></div>
            <Box padding={400} paddingBlockStart={300}>
              <InlineStack gap={200}>
                <Text variant="headingMd" fontWeight="semibold" as="h5">{data.dn ? data.dn : ''}</Text>
                <Text variant="bodySm" as="p" id='fix_hght' fontWeight='regular' tone='subdued'>{data.rm ? data.rm : ''}</Text>
              </InlineStack>

              {state.isFreePln === true && disableWdgtArr.includes(data.typ) ?
                <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                  <Button variant="primary" disabled={state.isFreePln === true && disableWdgtArr.includes(data.typ)}>Manage</Button>
                </Tooltip>
                :
                <Button variant="primary">Manage</Button>
              }
            </Box>
          </div>
        </Card>
      </Box >
    ));

  const widgets = [...addWidgets, ...editWidgets]; //showing "not created widgets" at top

  let InstallStepsArr = [
    {
      typ: 'main',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/pixel&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=templates/product.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      copyCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_main_widget', product: product %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_main_widget', product: product %}`
    },
    {
      typ: 'star_rating',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/starRating&target=mainSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=sections/product-template.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code under the <strong>&#123;&#123; product.title &#125;&#125; element.</strong></Text>
        </BlockStack>,
      copyCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_star_rating', product: product %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_star_rating', product: product %}`
    },
    {
      typ: 'carousel',
      enableUrl: `https://${shpName}/admin/themes/current/editor?addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/carousel&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=templates/product.liquid`}><strong>templates/product.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      copyCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_carousel' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_carousel' %}`
    },
    {
      typ: 'wall',
      enableUrl: `https://${shpName}/admin/themes/current/editor?addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/reviewWall&target=newAppsSection`,
      listItem:
        <InlineStack gap={400} direction={'column'}>
          <BlockStack gap={300}>
            <Text variant='headingMd' fontWeight='regular'>1. From your Shopify admin, go to <strong>Online Store &gt; Themes.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>2. Click <strong>-&gt; Edit code.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>3. Click <strong>Add a new template.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>4. Select <strong>Page, Liquid,</strong> and name the template <strong>"wnreview_wall".</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>5. Click <strong>Done.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>6. Delete the existing code and paste the following code into the file.</Text>
            <Text variant='headingMd' fontWeight='regular'>7. When you're done, click <strong>Save.</strong></Text>
          </BlockStack>
          <span>Then</span>
          <BlockStack gap={300}>
            <Text variant='headingMd' fontWeight='regular'>1. From your Shopify admin, go to <strong>Online Store &gt; Pages.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>2. Click <strong>Add page.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>3. Give the page a title (e.g. "Happy Customers").</Text>
            <Text variant='headingMd' fontWeight='regular'>4. In the <strong>Online store section</strong> on the right, select <strong>wnreview_wall.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>5. When you're done, click <strong>Save.</strong></Text>
          </BlockStack>
        </InlineStack>,
      copyCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_wall_widget' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_wall_widget' %}`
    },
    {
      typ: 'verified_badge',
      enableUrl: `https://${shpName}/admin/themes/current/editor?addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/verifiedBadge&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=templates/product.liquid`}><strong>templates/product.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      copyCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_verified_widget' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_verified_widget' %}`
    },
    {
      typ: 'carousel_product',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/carouselProduct&target=mainSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=sections/product-template.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      copyCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_carousel_product' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_carousel_product' %}`
    },
    {
      typ: 'ugc',
      enableUrl: `https://${shpName}/admin/themes/current/editor?addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/ugc&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=templates/product.liquid`}><strong>templates/product.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      copyCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_ugc_widget' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_ugc_widget' %}`
    },
    {
      typ: 'sidebar_cta',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/sidebarCTA&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=layout/theme.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code  immediately after the opening	&lt;body&gt; tag.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      copyCode: `{% render 'wisernotify_widgets', widget_type: 'wnreview_sidebar_cta' %}`,
      wixCode: `{% render 'wisernotify_widgets', widget_type: 'wnreview_sidebar_cta' %}`
    },
    {
      typ: 'popup_widget',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/floatingPopup&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=layout/theme.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code immediately after the opening	&lt;body&gt; tag.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      copyCode: `{% render 'wisernotify_widgets', widget_type: 'wnreview_popup_widget' %}`,
      wixCode: `{% render 'wisernotify_widgets', widget_type: 'wnreview_popup_widget' %}`
    }
  ];

  let tabsName = [{   //default show 'display' tab
    typ: 'displayReview',
    label: 'Display review',
    slctIcon: StarFilledIcon,
    disSlct: StarIcon
  }];

  //if 'wrtfrmlst' list is not empty, then display 'writeReview' tab
  if (state.writeArr?.length > 0) {
    const label = state.widgetTyp === 'que_ans' ? 'Write question' : 'Write review';
    tabsName.push({ typ: 'writeReview', label, slctIcon: PaperCheckIcon });
  }

  const isDsplyInstlStp = InstallStepsArr.some(step => step.typ === state.widgetTyp);

  useEffect(() => { //on 1st time or refresh set to 1st default active 
    movingClass(0);
  }, []);

  useEffect(() => { //user complete the 1st step, hide that step in install widget modal.
    let obj = {};
    if (state.profileState && state.profileState.isvntgthm && state.profileState.isvntgthm === true) {
      obj['isShwStepOne'] = false;
      obj['tabEvent'] = 'secondStepOpen';
    }
    changeNameValue(obj);

    if (state.profileState && state.profileState.pvd && state.profileState.pvd === 'shopify') {
      shpName = state.profileState.objshp.d
    }

  }, [state.profileState, state.installOpen]);


  return (
    <Box paddingBlockEnd={600}>

      {state.isShwEditPanel !== true ?
        <Page
          title={'Widgets'}
          subtitle={'Multiple widgets that allow you to show reviews more effective way on your store.'}>

          {props.isShwBanner === true &&
            <Box paddingBlockStart={400} paddingBlockEnd={800}>
              <Card padding={0}>
                <Box background='bg-surface-critical' padding={200}>
                  <Banner tone='critical' onDismiss={() => props.setIsShwBanner(false)}>
                    <Box paddingBlockEnd={300}>
                      <InlineStack gap={200} direction={'column'}>
                        <Text variant='bodyLg' fontWeight='medium' as='h5'>App embeds missing from live theme</Text>
                        <Text variant='bodyMd' fontWeight='regular' as='span'>This is required for all widgets to show correctly. Click the button below and then Save.</Text>
                      </InlineStack>
                    </Box>
                    <Button variant='plain' tone='critical' target='_blank' url={`https://${localStorage.getItem('shp')}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/pixel&target=newAppsSection`}>
                      <InlineStack gap={100}>
                        <Text fontWeight='medium' variant='bodyMd'>Enable app embed</Text>
                        <Icon source={ArrowDiagonalIcon} />
                      </InlineStack>
                    </Button>
                  </Banner>
                </Box>
              </Card>
            </Box>
          }

          {state.lstLayout && state.lstLayout.length > 0 ?
            <InlineGrid gap={400} columns={{ xl: 3, lg: 3, md: 2 }}>
              {widgets}
            </InlineGrid>
            :
            <Box paddingBlockStart={600}>
              <InlineStack align='center'>
                <Spinner accessibilityLabel="Spinner example" size="large" />
              </InlineStack>
            </Box>
          }
        </Page>
        :
        <div className='container-custom'>
          <Page
            title={state.dn}
            subtitle={state.rm}
            backAction={{ onAction: () => handleBackToMain() }}>

            <Box paddingInlineStart={200}>
              <div className='widgets-preview-wrap-part'>
                <InlineGrid columns={2}>
                  <div className='widgets-preview-left'>

                    {/* Install steps section */}
                    {isDsplyInstlStp &&
                      <Card padding={'300'}>
                        <InlineStack align='space-between'>
                          <InlineStack gap={200} align='center' blockAlign='center'>
                            <Badge tone='info-strong'>
                              <Box paddingBlock={100}><Icon source={DnsSettingsIcon} /></Box>
                            </Badge>
                            <Text fontWeight='medium' tone='subdued'>Learn how to add the {state.dn} to your theme.</Text>
                          </InlineStack>
                          <Button onClick={() => {
                            openCloseModal('installOpen', state.installOpen, 'open');
                            changeNameValue({ firstStepOpen: true });
                          }}>Install</Button>
                        </InlineStack>
                      </Card>
                    }

                    <Card padding={'100'}>
                      <div className={isDsplyInstlStp ? 'wdgt-prvw-inpart' : 'wdgt-hght wdgt-prvw-inpart'}>
                        <InlineGrid columns={2}>
                          <div className="left_section">
                            <Box paddingBlock={300} paddingInlineStart={300}>

                              {/* display review tab && write review tab */}
                              <Box paddingInlineEnd={1000}>
                                <Card padding={100} roundedAbove="lg">
                                  <ButtonGroup fullWidth>
                                    {tabsName && tabsName.length > 0 &&
                                      tabsName.map((data, i) => {
                                        return (
                                          <Button key={i} id={state.newActiveTab === data.typ ? 'violet_btn' : ''}
                                            onClick={() => handleTabClick('newActiveTab', data.typ)} variant=''
                                            icon={<Icon source={state.newActiveTab === data.typ ? data.slctIcon : data.disSlct ? data.disSlct : data.slctIcon}
                                              tone={state.newActiveTab === data.typ ? '' : 'subdued'} />}>
                                            <Text fontWeight='medium' tone={state.newActiveTab === data.typ ? '' : 'subdued'}>
                                              {data.label}</Text>
                                          </Button>
                                        );
                                      })}
                                  </ButtonGroup>
                                </Card>
                              </Box>

                              {/* all fields sidebar (vertical tabs) */}
                              <div className='nf-sidebar'>
                                <div className='nf-sidebar-block'>
                                  {state.verticalTabsArr && state.verticalTabsArr.length > 0 &&
                                    state.verticalTabsArr.map((data, i) => {
                                      return (
                                        <div key={i} id={`moveLink_${i}`} onClick={() => movingClass(i)}
                                          className={state.sidebarActiveIndex === i ? 'side-active move_div' : 'move_div'}>
                                          <div className='move_Inner'>
                                            <InlineStack gap={200} blockAlign='center'>
                                              <Icon source={data.img} tone={state.sidebarActiveIndex === i ? 'base' : 'subdued'} />
                                              <Text tone={state.sidebarActiveIndex === i ? '' : 'subdued'} fontWeight='medium'>
                                                {data.title}</Text>
                                            </InlineStack>
                                          </div>
                                        </div>
                                      );
                                    })
                                  }
                                </div>
                                {/* moving class */}
                                <div className="sidebar-ani-block is-active" id='Wdgt_sidebar_Blk'></div>
                              </div>

                              {state.isEdit === true && //discard && save btn
                                <Box paddingInlineStart={500} paddingInlineEnd={800}>
                                  <ButtonGroup fullWidth>
                                    <Button variant='secondary' onClick={() => handlePreviewDiscrd()}>Discard</Button>
                                    <Button id='primary_btn' onClick={() => handleSaveChange()}>Save</Button>
                                  </ButtonGroup>
                                </Box>
                              }

                            </Box>
                          </div>

                          {/* tabs content */}
                          <AllReviewTab
                            props={props}
                            state={state}
                            changeNameValue={changeNameValue}
                            handleTextChange={handleTextChange} />

                        </InlineGrid>
                      </div>
                    </Card>
                  </div>

                  {/* preview card */}
                  <PreviewCard
                    state={state}
                    changeNameValue={changeNameValue}
                    openCloseModal={openCloseModal}
                    callResetApi={callResetApi} />

                </InlineGrid>
              </div>
            </Box>

          </Page>
        </div >
      }











      {/* Install step modal */}
      {InstallStepsArr && InstallStepsArr.map((val, i) => {
        if (val.typ === localStorage.getItem('WS_PRVW_TYP')) {
          return (
            <Modal
              key={i}
              size=''
              open={state.installOpen}
              onClose={(e) => openCloseModal('installOpen', state.installOpen, e)}
              title={<Text variant='headingMd' fontWeight='bold' as='h3'>
                Follow the below mention steps to enable this widget</Text>}>
              <Modal.Section>

                {localStorage.getItem('pvd') === 'wix' ?
                  <Box id='custom_infoBG' padding={400}>
                    <div id='cursorPoint' onClick={() => setTabEvent('firstStepOpen')}>
                      <InlineStack align='space-between'>
                        <InlineStack align='center' blockAlign='center' gap={200}>
                          <Box><Icon source={CodeIcon} /></Box>
                          <Text variant='bodyLg' fontWeight='medium' as='h1'>Manual Setup via Code</Text>
                          <div className='warning_banner'>
                            <InlineStack gap={200} wrap={false}>
                              <img src={warning_icon} alt='warning' />
                              <Text variant='bodySm' fontWeight='medium' as='p'>Recommended for Vintage Themes Users</Text>
                            </InlineStack>
                          </div>
                        </InlineStack>
                        <Box>
                          <Icon source={tabEvent === 'firstStepOpen' === true ? ChevronUpIcon : ChevronDownIcon}></Icon>
                        </Box>
                      </InlineStack>
                    </div>

                    <Collapsible
                      open={tabEvent === 'firstStepOpen'}
                      id="db_collaspe"
                      transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                      <Box paddingInlineStart={100} paddingBlockStart={400}>
                        {/* copy code box */}
                        <div className="copycode-box">
                          <InlineStack align='start' blockAlign='start'>
                            <textarea id='copy_code' className="textarea-copycode" readOnly={true}
                              onClick={(e) => handleCopyCode(e, val.wixCode)} value={val.wixCode}>
                            </textarea>
                            <Button variant='monochromePlain'
                              onClick={() => handleCopyCode('', val.wixCode)}>
                              <img src={copy_icon} alt='copy'></img>
                            </Button>
                          </InlineStack>
                        </div>
                      </Box>
                    </Collapsible>

                  </Box>
                  :
                  <Box maxWidth='100%' paddingBlockEnd={300}>
                    {state.isShwStepOne === true &&
                      <Box paddingBlockEnd={400}>
                        <Box id='custom_infoBG' padding={400}>
                          <div id='cursorPoint'
                            onClick={() => setTabEvent('firstStepOpen')} >
                            <InlineStack align='space-between'>
                              <InlineStack align='center' blockAlign='center' gap={200}>
                                <Box><Icon source={AppExtensionIcon} /></Box>
                                <Text variant='bodyLg' fontWeight='medium' as='h1'>Embed app via drag & Drop</Text>
                                <div className='warning_banner'>
                                  <InlineStack gap={200} wrap={false}>
                                    <img src={warning_icon} alt='warning' />
                                    <Text variant='bodySm' fontWeight='medium' as='p'>For shopify 2 themes users only</Text>
                                  </InlineStack>
                                </div>
                              </InlineStack>
                              <Box>
                                <Icon source={tabEvent === 'firstStepOpen' ? ChevronUpIcon : ChevronDownIcon}></Icon>
                              </Box>
                            </InlineStack>
                          </div>

                          <Collapsible
                            open={tabEvent === 'firstStepOpen'}
                            id="db_collaspe"
                            transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                            <Box paddingInlineStart={100} paddingBlockStart={400}>
                              <BlockStack gap={300}>
                                <Text variant='headingSm' fontWeight='regular' tone='subdued'>
                                  1. Click on Enable the app & click on save button (top-right)</Text>
                                <Text variant='headingSm' fontWeight='regular' tone='subdued'>
                                  <InlineStack gap={100}>
                                    <Box maxWidth='10%'>2.</Box>
                                    <Box maxWidth='90%'>Then click on {state.dn} & place you want to show the review section & save the change</Box>
                                  </InlineStack>
                                </Text>
                              </BlockStack>
                              <Box paddingBlockStart={400}>
                                <InlineStack gap={400} direction={'column'} align='start' blockAlign='start'>
                                  <Button id='primary_btn' target='_blank' url={`https://${shpName}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/pixel&target=newAppsSection`} icon={ExternalIcon}>Enable app</Button>
                                  <Button id='primary_btn' target='_blank' url={val.enableUrl} icon={ExternalIcon}>Embed {state.dn}</Button>
                                </InlineStack>
                              </Box>
                            </Box>
                          </Collapsible>

                        </Box>
                      </Box>
                    }
                    <Box id='custom_infoBG' padding={400}>
                      <div id='cursorPoint' onClick={() => setTabEvent('secondStepOpen')}>
                        <InlineStack align='space-between'>
                          <InlineStack align='center' blockAlign='center' gap={200}>
                            <Box><Icon source={CodeIcon} /></Box>
                            <Text fontWeight='medium' variant='bodyLg' as='h1'>Manual setup via code </Text>
                            <div className='warning_banner'>
                              <InlineStack gap={200} wrap={false}>
                                <img src={warning_icon} alt='warning' />
                                <Text fontWeight='medium' as='p'>Recommended for vintage themes users</Text>
                              </InlineStack>
                            </div>
                          </InlineStack>
                          <Box>
                            <Icon source={tabEvent === 'secondStepOpen' === true ? ChevronUpIcon : ChevronDownIcon}></Icon>
                          </Box>
                        </InlineStack>
                      </div>

                      <Collapsible
                        open={tabEvent === 'secondStepOpen'}
                        id="db_collaspe"
                        transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                        <Box paddingInlineStart={100} paddingBlockStart={400}>
                          <Text as='p' variant='bodySm' tone='subdued'>{val.listItem}</Text>
                          <Box paddingBlockStart={300}>
                            {/* copy code box */}
                            <div className="copycode-box">
                              <InlineStack align='start' blockAlign='start'>
                                <textarea id='copy_code' className="textarea-copycode" readOnly={true}
                                  onClick={(e) => handleCopyCode(e, val.copyCode)} value={val.copyCode}>
                                </textarea>
                                <Button variant='monochromePlain'
                                  onClick={() => handleCopyCode('', val.copyCode)}>
                                  <img src={copy_icon} alt='copy'></img>
                                </Button>
                              </InlineStack>
                            </div>
                          </Box>
                        </Box>
                      </Collapsible>

                    </Box>
                  </Box>
                }
              </Modal.Section>
            </Modal>
          );
        }
      })}

      <ConfirmationAlert
        state={state}
        openCloseModal={props.openCloseModal}
        saveChanges={props.saveChanges}
        discardChanges={props.discardChanges}
      />
    </Box >
  );
}

export default memo(List);