import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const email = createSlice({
  name: 'email',
  initialState,
  reducers: {
    getSeqData: (state, action) => { },
    getSeqDataSuccess: (state, action) => {
      return { ...state, SeqList: action.payload };
    },
    updateSequence: (state, action) => { },
    testEmail: (state, action) => { },
    updConfig: (state, action) => { },
    getConfigByType: (state, action) => { },
    getConfigByTypeSuccess: (state, action) => {
      return { ...state, configData: action.payload };
    },
    removeCondition: (state, action) => { },
    getAnalyticsCount: (state, action) => { },
    getAnalyticsCountSuccess: (state, action) => {
      return { ...state, AnalyticsCount: action.payload };
    },
    createCampaign: (state, action) => { },
    getCampaignHistory: (state, action) => { },
    getCampaignHistorySuccess: (state, action) => {
      return { ...state, HistoryList: action.payload };
    },
    updTmpTypeStatus: (state, action) => { },
    getCampaignCnt: (state, action) => { },
    getCampaignCntSuccess: (state, action) => {
      return { ...state, CampgnCount: action.payload };
    }
  }
});

export const {
  getSeqData,
  getSeqDataSuccess,
  updateSequence,
  testEmail,
  updConfig,
  getConfigByType,
  getConfigByTypeSuccess,
  removeCondition,
  getAnalyticsCount,
  getAnalyticsCountSuccess,
  createCampaign,
  getCampaignHistory,
  getCampaignHistorySuccess,
  updTmpTypeStatus,
  getCampaignCnt,
  getCampaignCntSuccess
} = email.actions;

export default email.reducer;