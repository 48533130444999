import React, { memo } from 'react';
import { IndexTable, IndexFilters, Text, Card, Page, useSetIndexFiltersMode } from '@shopify/polaris';

const sortOptions = [
  { label: 'Name', value: 'cnm asc', directionLabel: 'A-Z' },
  { label: 'Name', value: 'cnm desc', directionLabel: 'Z-A' },
  { label: 'Email Id', value: 'eml asc', directionLabel: 'A-Z' },
  { label: 'Email Id', value: 'eml desc', directionLabel: 'Z-A' },
  { label: 'Publish Review', value: 'prtng asc', directionLabel: 'Ascending' },
  { label: 'Publish Review', value: 'prtng desc', directionLabel: 'Descending' },
  { label: 'Unpublish review', value: 'uprtng asc', directionLabel: 'Ascending' },
  { label: 'Unpublish review', value: 'uprtng desc', directionLabel: 'Descending' }
];

function List({
  queryValue,
  setQueryValue,
  sortSelected,
  setSortSelected,
  customerData,
  onPaggiCall,
  pageNumber,
  paggiActive
}) {
  const { mode, setMode } = useSetIndexFiltersMode();

  return (
    <Page
      title={"Customer"}>

      <Card padding="0">
        <IndexFilters
          sortOptions={sortOptions}
          sortSelected={sortSelected}
          onSort={(e) => setSortSelected(e)}
          queryValue={queryValue}
          queryPlaceholder="Search Customer Name & Email ID"
          onQueryChange={(e) => setQueryValue(e)}
          onQueryClear={() => setQueryValue('')}
          cancelAction={{ onAction: () => setQueryValue('') }}
          tabs={[]}
          filters={[]}
          mode={mode}
          setMode={setMode} />

        <IndexTable
          itemCount={customerData ? customerData.length : 0}
          selectable={false}
          headings={[
            { title: 'Customer Name' },
            { title: 'Email ID' },
            { title: 'Publish review' },
            { title: 'Unpublish review' }
          ]}
          pagination={{
            // label: paggiLabel,
            hasPrevious: paggiActive > 1 ? true : false,
            hasNext: (paggiActive < pageNumber) ? true : false,
            onPrevious: () => onPaggiCall('-1'),
            onNext: () => onPaggiCall('+1')
          }}>

          {customerData && customerData.length > 0 && customerData.map((data, index) =>
            <IndexTable.Row id={data._id} key={index} position={index}>
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="regular" as="span">{data.cnm || '-'}</Text>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="regular" as="span">{data.eml || '-'}</Text>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="regular" as="span">{data.prtng || '0'}</Text>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="regular" as="span">{data.uprtng || '0'}</Text>
              </IndexTable.Cell>
            </IndexTable.Row>
          )}
        </IndexTable>

      </Card>
    </Page>
  );
}

export default memo(List);