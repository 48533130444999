import { Box, Text, Card, InlineStack } from '@shopify/polaris';
import Import from '../../containers/Import/Import';

const ImportTab = (props) => {
  return (
    <Card padding={'600'}>
      <InlineStack gap={100} direction={'column'}>
        <Text variant='bodyLg' fontWeight='medium' as='h4'>{props.state.selSeqName}</Text>
        <Text variant='bodySm' fontWeight='regular' as='p' tone='subdued'>{props.state.subTtl}</Text>
      </InlineStack>

      <Box paddingBlockStart={600}>
        <Import />
      </Box>
    </Card >
  );
}

export default ImportTab;