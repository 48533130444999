import { Modal, Box, Button, Text, InlineStack, ButtonGroup, Divider } from '@shopify/polaris';

function ConfirmationAlert({ state, openCloseModal, discardChanges }) {
  return (
    <Modal
      open={state.confirmOpen}
      title={<Text variant='headingMd' as='h3' fontWeight='bold'>Discard changes?</Text>}
      onClose={(e) => openCloseModal('confirmOpen', state.confirmOpen, e)}>
      <Modal.Section>
        <Text fontWeight='medium' id='clrsecondary' variant='headingMd' as='h5'>
          Changes that you make will not be saved.</Text>
        <Box paddingBlock={400}><Divider /></Box>
        <InlineStack align='end'>
          <ButtonGroup>
            <Button onClick={() => openCloseModal('confirmOpen', state.confirmOpen, 'close')}>Cancel</Button>
            <Button variant='primary' tone='critical' onClick={() => discardChanges()}>Discard changes</Button>
          </ButtonGroup>
        </InlineStack>
      </Modal.Section>
    </Modal>
  );
}

export default ConfirmationAlert;