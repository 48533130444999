import { createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";

export const changePathForShopifyAppEmbed = async (path) => {
  const strObjConfig = await localStorage.getItem('store_config');

  if (strObjConfig) {
    // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
    const appShopify = createApp(JSON.parse(strObjConfig));
    const redirect = Redirect.create(appShopify);
    redirect.dispatch(Redirect.Action.APP, path);
  }
};