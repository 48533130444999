import { Badge, Page, Button, Box, Tooltip, InlineStack, Modal, PageActions, IndexTable, TextField, Text, Checkbox, Card, Divider, Icon, Collapsible, List, BlockStack, } from '@shopify/polaris';
import { DeleteIcon, ClipboardIcon, ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { useDispatch } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import { useCallback, useState } from 'react';

let _ia = false;

const WebhookDataExport = ({ props }) => {
  const [cState, setCState] = useState({
    rmk: '',
    webUrl: '',
    deleteOpen: false,
  });
  const { state, validator, handleSave, changeNameValue, handleTextChange, handleCopyCode, handleBackToMain } = props;
  const dispatch = useDispatch();

  if (state.currUserInt && state.currUserInt.ia !== undefined) { //for isActive
    _ia = state.currUserInt.ia;
  }
  if (state.newDObj && Object.keys(state.newDObj).length <= 0) //set active on create
    _ia = true;

  const changeNameCValue = useCallback((obj) => {
    setCState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  const openCloseModal = useCallback((name, value, typ) => {
    if (typ !== undefined) changeNameCValue({ [name]: !value });
  }, [changeNameCValue]);

  const handleDelIntegration = () => {
    let obj = {
      shp: localStorage.getItem('shp'),
      id: state.currIntId
    }
    dispatch(integrationDucks.delIntegration(obj));

    let intObj = {
      isDelWbHook: true,
      WbhkField: {},
      ampArray: [],
      currUserInt: {},
      dObj: {},
      newDObj: {},
      currIntId: '',
      wfkey: '',
      cfkey: '',
      isLisDtSuc: false
    }
    changeNameValue(intObj);

    let intCObj = {
      rmk: '',
      webUrl: '',
    }
    changeNameCValue(intCObj);
    setTimeout(() => openCloseModal('deleteOpen', cState.deleteOpen, 'close'), 600);
  }

  let queArr = [
    {
      field: 'firstTabCollapse',
      question: 'What is a webhook for review export?',
      answer: "It's a tool that automatically sends your review data to another application or system in real time."
    },
    {
      field: 'secondTabCollapse',
      question: 'How do I set up a webhook for exporting reviews?',
      answer: "Enter a name for the connection and the endpoint URL where you want to send the review data, then click 'Create'."
    },
    {
      field: 'thirdTabCollapse',
      question: 'What format is the review data sent in?',
      answer: 'Review data is usually sent in a commonly accepted data format like JSON'
    }
  ];

  return (
    <Page
      title={(state.currMasterObj && state.currMasterObj.dn) && state.currMasterObj.dn}

      titleMetadata={_ia === true ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">Active</Text></Badge>
        : <Badge><Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text></Badge>}
      subtitle={
        <Text tone='base' >
          {state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
        </Text>}
      backAction={{ onAction: () => handleBackToMain() }}>

      <Box paddingInline={800}>
        <BlockStack gap={400}>
          <Card>
            <Box paddingBlockEnd={200}>
              <Text variant='headingMd' as='p' fontWeight='semibold'>Webhook setup</Text>
            </Box>
            {/* <Text>Configure the webhook URL where you want to receive the reviews.</Text> */}
            <BlockStack gap={400}>
              <Text variant='headingSm' as='p' fontWeight='semibold'>Connect your app:</Text>
              <Box>
                <List type="bullet">
                  <List.Item>Create a connection & enter your webhook endpoint URL where you want to receive the reviews.</List.Item>
                  <List.Item>After creating the connection, you will start receiving the new reviews.</List.Item>
                </List>
              </Box>

              {Object.keys(state.newDObj).length <= 0 ?
                <Box>
                  <TextField
                    label="Name your connection"
                    placeholder='Name your connection'
                    value={cState.rmk}
                    onChange={(e) => { handleTextChange('rmk', e); changeNameCValue({ 'rmk': e }) }}
                    autoComplete="off"
                    error={state.errMessage.remark ? state.errMessage.remark : false} />
                  {validator.message('remark', cState.rmk, 'required')}
                  <Box paddingBlockStart={300}>
                    <TextField
                      label="Add your webhook API"
                      placeholder='Enter URL'
                      value={cState.webUrl}
                      onChange={(e) => { handleTextChange('webUrl', e); changeNameCValue({ 'webUrl': e }) }}
                      autoComplete="off"
                      error={state.errMessage.webUrl ? state.errMessage.webUrl : false} />
                    {validator.message('webUrl', cState.webUrl, 'required')}
                  </Box>
                  <Box paddingBlockStart={400}>
                    <InlineStack align='center'>
                      <Button id='primary_btn' onClick={() => handleSave(_ia)}>Create</Button>
                    </InlineStack>
                  </Box>
                </Box>
                :
                <Box>
                  <Box paddingBlockEnd={200}>
                    <Text variant='headingSm' as='h2' fontWeight='semibold'>Account Details</Text>
                  </Box>
                  <Card padding={0}>
                    <div id='sd_tbl'>
                      <IndexTable
                        itemCount={1}
                        selectable={false}
                        headings={[
                          { title: 'Name' },
                          { title: 'EndPoint' },
                          { title: 'Active' },
                          { title: 'Action' }
                        ]} >

                        {state.currUserInt &&
                          <IndexTable.Row
                            id={0}
                            key={0}
                            position={0}>
                            <IndexTable.Cell>{(state.currUserInt.d && state.currUserInt.d.rmk) ? state.currUserInt.d.rmk : '-'}</IndexTable.Cell>
                            <IndexTable.Cell><div id='txt_limit1'>{(state.currUserInt.d && state.currUserInt.d.webUrl) ? state.currUserInt.d.webUrl : '-'}</div></IndexTable.Cell>
                            <IndexTable.Cell>
                              {/* <label className="switch">
                        <input type="checkbox" checked={state.currUserInt.ia} onChange={(e) => handleSave(e.target.checked)} />
                        <span className="switch-label"></span>
                        <span className="switch-handle"></span>
                      </label> */}
                              <Checkbox
                                checked={state.currUserInt.ia}
                                onChange={() => handleSave(!state.currUserInt.ia)}
                              />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <InlineStack gap={300} align='center' wrap={false}>
                                <Tooltip width='wide' padding="400" dismissOnMouseOut content="Copy URL">
                                  <Button id='primary_btn' icon={ClipboardIcon} onClick={() => handleCopyCode(state.currUserInt.d.webUrl, 'Endpoint')} />
                                </Tooltip>
                                <Tooltip width='wide' padding="400" dismissOnMouseOut content="Delete">
                                  <Button variant="primary" tone="critical" icon={DeleteIcon} onClick={() => openCloseModal('deleteOpen', cState.deleteOpen, 'open')}></Button>
                                </Tooltip>
                              </InlineStack>
                            </IndexTable.Cell>
                          </IndexTable.Row>
                        }
                      </IndexTable>
                    </div>
                  </Card>
                </Box>
              }
            </BlockStack>
          </Card>

          <Card padding={0}>
            <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
              <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
            </Box>
            <Divider borderColor='border-tertiary' />
            <Box paddingInline={400} paddingBlockEnd={300}>

              {queArr && queArr.map((data, i) => {
                return (
                  <div key={i} id='cursorPoint' onClick={() => changeNameValue({ tabEvent: data.field })}>
                    <Box paddingBlock={300}>
                      <InlineStack wrap={false} blockAlign='center' align='space-between'>
                        <Text variant='headingSm' as='p' fontWeight='medium'>{data.question}</Text>
                        <Box><Icon source={data.field === state.tabEvent ? ChevronUpIcon : ChevronDownIcon}></Icon></Box>
                      </InlineStack>
                      <Collapsible open={data.field === state.tabEvent} id="db_collaspe"
                        transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                        <Box paddingBlockStart={100}>
                          <Text variant='headingSm' as='p' fontWeight='regular' tone="subdued">{data.answer}</Text>
                        </Box>
                      </Collapsible>
                    </Box>
                    {i !== queArr.length - 1 && <Divider />}
                  </div>
                )
              })}

            </Box>
          </Card>
        </BlockStack>
      </Box>

      <Modal
        size='small'
        open={cState.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', cState.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this?</Text>
            <PageActions
              primaryAction={{
                id: 'primary_btn',
                content: 'Yes',
                onAction: () => handleDelIntegration()
              }}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', cState.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </Page >
  );
}

export default WebhookDataExport;