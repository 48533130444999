import {
  IndexTable, IndexFilters, Text, Popover, Filters, ResourceList, ResourceItem, Card, PageActions, Page, Button, Modal, Box, TextField, InlineStack, useSetIndexFiltersMode,
  BlockStack
} from '@shopify/polaris';
import { PlusCircleIcon, EditIcon, DeleteIcon } from '@shopify/polaris-icons';
import { useEffect, memo } from 'react';

const opt = { day: 'numeric', month: 'long', year: 'numeric' };

const sortOptions = [
  { label: 'Name', value: 'nm asc', directionLabel: 'A-Z' },
  { label: 'Name', value: 'nm desc', directionLabel: 'Z-A' },
  { label: 'Date', value: 'cdt asc', directionLabel: 'Ascending' },
  { label: 'Date', value: 'cdt desc', directionLabel: 'Descending' }
];

function List({ props }) {
  const { state, changeNameValue } = props;
  const { mode, setMode } = useSetIndexFiltersMode();

  const filterControl = (
    <Filters
      focused
      queryValue={state.groupNewSearch}
      queryPlaceholder='Search product'
      onQueryChange={(e) => changeNameValue({ groupNewSearch: e })}
      filters={[]}
      appliedFilters={[]}
      onQueryClear={() => props.changeNameValue({ groupNewSearch: '' })}
    />
  );

  const SelectionActions = [{ content: 'Save', onAction: () => changeNameValue({ groupViewProOpen: false }) }]

  useEffect(() => {
    changeNameValue({ SelectionActions });
  }, []);

  useEffect(() => {
    let rmvAllCta = {
      content: 'Remove all',
      onAction: () => changeNameValue({ createProSelectedItem: [] })
    };
    changeNameValue({ SelectionActions: state.hasNextPage === true ? [rmvAllCta] : [] });
  }, [state.hasNextPage]);

  // timeSince function
  function timeAgo(udt) {
    const updateDate = new Date(udt);
    const currentDate = new Date();

    const timeDifference = currentDate - updateDate; // in milliseconds

    const minutes = Math.floor(timeDifference / 1000 / 60); // convert ms to minutes
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) {
      return "Updated just now";
    } else if (minutes < 60) {
      return `Updated ${minutes} min ago`;
    } else if (hours < 24) {
      return `Updated ${hours} hr ago`;
    } else {
      return `Updated ${days} days ago`;
    }
  }

  return (
    <div className='group_Page'>
      <Page
        title={"Grouping"}
        subtitle={'Share reviews across similar products as a group'}
        primaryAction={
          <Button id='primary_btn' icon={PlusCircleIcon}
            onClick={() => props.openCloseModal('groupCreateOpen', state.groupCreateOpen, 'open')}>Create New</Button>}>

        <Box paddingBlockEnd={400}>
          <Card padding={0}>
            <IndexFilters
              sortOptions={sortOptions}
              sortSelected={state.groupSortSelected}
              onSort={(e) => changeNameValue({ groupSortSelected: e })}
              queryValue={state.groupQueryValue}
              queryPlaceholder="Search name..."
              onQueryChange={(e) => changeNameValue({ groupQueryValue: e })}
              onQueryClear={() => changeNameValue({ groupQueryValue: '' })}
              cancelAction={{
                onAction: () => changeNameValue({ groupQueryValue: '' })
              }}
              tabs={[]}
              filters={[]}
              hideFilters
              mode={mode}
              setMode={setMode}
            />
            <div id='group_tbl'>
              <IndexTable
                itemCount={state.groupData ? state.groupData.length : 0}
                selectable={false}
                headings={[
                  { title: ' Group name' },
                  { title: 'Date' },
                  { title: 'Product count' },
                  { title: 'Action', alignment: 'center' }
                ]}
                pagination={{
                  // label: props.groupPaggiLabel,
                  hasPrevious: state.groupPaggiActive > 1 ? true : false,
                  hasNext: (state.groupPaggiActive < state.groupPageNumber) ? true : false,
                  onPrevious: () => props.onGroupPaggiCall('-1'),
                  onNext: () => props.onGroupPaggiCall('+1')
                }}>

                {state.groupData && state.groupData.length > 0 && state.groupData.map((data, index) => (
                  <IndexTable.Row
                    id={data._id}
                    key={index}
                    position={index}>
                    <IndexTable.Cell>
                      <Text variant="bodySm" id='txt_limit1' fontWeight="medium" as="span">
                        {data.nm ? data.nm : '-'}
                      </Text>
                    </IndexTable.Cell>

                    {/* Date */}
                    <IndexTable.Cell>
                      <BlockStack>
                        <Text variant="bodySm" fontWeight="medium" as="span">{(new Date(data.cdt)).toLocaleDateString("en-US", opt)}</Text>
                        <Text variant="bodySm" as="span">{timeAgo(data.udt)}</Text>
                      </BlockStack>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <Text variant="bodySm" fontWeight="medium" as="span">{data.arr ? (data.arr).length : 0}</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <InlineStack align='center' gap={100} wrap={false}>
                        <Button variant='monochromePlain' icon={EditIcon}
                          onClick={() => {
                            props.handleUpdateAction(data.nm, data.arr, data._id);
                            props.getProductNameList();
                          }} />
                        <Button variant='monochromePlain' icon={DeleteIcon}
                          onClick={() => {
                            props.handleDeleteAction(data._id);
                            changeNameValue({ gName: data.nm })
                          }} />
                      </InlineStack>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                ))}
              </IndexTable>
            </div>

          </Card>
        </Box>

        <Modal //create group modal
          open={state.groupCreateOpen}
          onClose={(e) => props.openCloseModal('groupCreateOpen', state.groupCreateOpen, e)}
          title={<Text variant='headingMd' as='h3' fontWeight='bold'>Create group name</Text>}>

          <Modal.Section>
            <TextField
              label={<Text>Group name</Text>}
              value={state.pName}
              onChange={(e) => changeNameValue({ pName: e })}
              autoComplete="off"
              error={state.pnameErr === true ? "Name is required" : false}
              placeholder='Type group name' />

            <Box paddingBlock={400}>
              <Popover
                fullWidth
                preferredPosition='mostSpace'
                active={state.groupPopActive}
                onClose={() => changeNameValue({ groupPopActive: !state.groupPopActive })}
                activator={
                  <TextField
                    label={<Text>Select multiple products</Text>}
                    autoComplete='off'
                    placeholder='Type product name'
                    verticalContent={state.verticalTags}
                    onChange={(e) => {
                      props.openGroupProModal('groupNewSearch', e);
                      props.handleSearchGroupProduct(e);
                    }}
                    error={state.proCreateErr === true && 'Please select a product'}
                    connectedRight={<Button onClick={() => {
                      changeNameValue({ groupNewSearch: '' });
                      props.openCloseModal('groupViewProOpen', state.groupViewProOpen, 'open')
                    }}>Search</Button>} />
                }>
              </Popover>
            </Box>

            <InlineStack align='end'>
              <Button id='primary_btn' onClick={() => props.handleSaveGroup()}>Save</Button>
            </InlineStack>
          </Modal.Section>
        </Modal>

        <Modal //update group modal
          open={state.groupEditOpen}
          title={<Text variant='headingMd' as='h3' fontWeight='bold'>Update product review group</Text>}
          onClose={(e) => props.openCloseModal('groupEditOpen', state.groupEditOpen, e)}>
          <Modal.Section>
            <TextField
              label={<Text>Group name</Text>}
              value={state.Upname}
              onChange={(e) => changeNameValue({ Upname: e })}
              autoComplete="off"
              error={state.UpnameErr === true ? "Name is required" : false}
              placeholder='Type group name' />

            <Box paddingBlock={400}>
              <Popover
                fullWidth
                preferredPosition='below'
                active={state.groupPopActive}
                onClose={() => changeNameValue({ groupPopActive: !state.groupPopActive })}
                activator={
                  <TextField
                    label={<Text>Select multiple products</Text>}
                    autoComplete='off'
                    placeholder='Type product name'
                    verticalContent={state.verticalTags}
                    error={state.proUpdErr === true && 'Please select a product'}
                    onChange={(e) => {
                      props.openGroupProModal('groupNewSearch', e)
                      props.handleSearchGroupProduct(e);
                    }}
                    connectedRight={<Button onClick={() => {
                      changeNameValue({ groupNewSearch: '' });
                      props.openCloseModal('groupViewProOpen', state.groupViewProOpen, 'open')
                    }}>Search</Button>} />
                }>
              </Popover>
            </Box>

            <InlineStack align='end'>
              <Button id='primary_btn' onClick={() => props.handleUpdateCall(state.groupProIdUpd)}>Update</Button>
            </InlineStack>
          </Modal.Section>
        </Modal>

        <Modal //all product
          open={state.groupViewProOpen}
          limitHeight={true}
          noScroll={true}
          onClose={(e) => props.openCloseModal('groupViewProOpen', state.groupViewProOpen, e)}
          title={"Product list"}>

          <Modal.Section>

            <div className='groupProList'>
              <ResourceList
                selectable
                // showHeader={false} 
                headerContent={`Showing ${state.groupProdOptions.length} Products`}
                totalItemsCount={state.createProSelectedItem.length}
                selectedItems={state.createProSelectedItem}
                onSelectionChange={(e) => props.selectProducts('createProSelectedItem', e)}
                items={state.groupProdOptions}
                filterControl={filterControl}
                flushFilters
                pagination={{
                  hasPrevious: (state.hasPreviousPage === true) ? true : false,
                  hasNext: (state.hasNextPage === true) ? true : false,
                  onNext: () => props.groupPagiCall('+1'),
                  onPrevious: () => props.groupPagiCall('-1')
                }}
                promotedBulkActions={state.SelectionActions}
                renderItem={(item) => {
                  return (
                    <ResourceItem
                      id={item.id}
                      media={item.media}
                      verticalAlignment='center'
                      onClick={() => {
                        props.selectProducts(
                          'createProSelectedItem',
                          [...state.createProSelectedItem, item.id]
                        );
                      }}>
                      <Text variant="bodySm" fontWeight="bold" as="h3">{item.label}</Text>
                    </ResourceItem>
                  );
                }} />
            </div>

          </Modal.Section>
        </Modal>

        <Modal
          open={state.groupDeleteOpen}
          title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
          onClose={(e) => props.openCloseModal('groupDeleteOpen', state.groupDeleteOpen, e)}>
          <Modal.Section>
            <Text fontWeight='semibold' variant='headingMd' as='h3'>Delete "{state.gName}" group?</Text>
            <div className='pageAction_paDDing'>
              <PageActions
                primaryAction={{
                  icon: DeleteIcon,
                  content: 'Delete',
                  tone: 'critical',
                  onAction: () => props.handleDeleteCall(state.groupProIdUpd)
                }}
                secondaryActions={[{
                  content: 'No',
                  onAction: () => props.openCloseModal('groupDeleteOpen', state.groupDeleteOpen, 'close')
                }]} />
            </div>
          </Modal.Section>
        </Modal>

      </Page>
    </div >
  );
}

export default memo(List);