import { fromJS } from 'immutable';

export const importDataFromCSV = (data) => ({ type: "IMPORT_DATA_FROM_CSV", data });
export const importDataFromCSVSuccess = (data) => ({ type: "IMPORT_DATA_FROM_CSV_SUCCESS", data });

export const getIntegrationMST = (data) => ({ type: "GET_INTEGRATION_MST", data });
export const getIntegrationMSTSuccess = (data) => ({ type: "GET_INTEGRATION_MST_SUCCESS", data });

export const getUserIntegration = (data) => ({ type: "GET_USER_INTEGRATION", data });
export const getUserIntegrationSuccess = (data) => ({ type: "GET_USER_INTEGRATION_SUCCESS", data });

export const addUpdIntegration = (data) => ({ type: "ADD_UPD_INTEGRATION", data });

export const delIntegration = (data) => ({ type: "DEL_INTEGRATION", data });

export const getWebhookField = (data) => ({ type: "GET_WEBHOOK_FIELD", data });
export const getWebhookFieldSuccess = (data) => ({ type: "GET_WEBHOOK_FIELD_SUCCESS", data });

export const createCstmWbhookField = (data) => ({ type: "CREATE_WEBHOOK_FIELD", data });
export const createCstmWbhookFieldSuccess = (data) => ({ type: "CREATE_WEBHOOK_FIELD_SUCCESS", data });

export const delWebhookField = (data) => ({ type: "DEL_WEBHOOK_FIELD", data });

export const startListening = (data) => ({ type: "START_LISTENING", data });

export const syncToken = (data) => ({ type: "SYNC_TOKEN", data });
export const syncTokenSuccess = (data) => ({ type: "SYNC_TOKEN_SUCCESS", data });

export const delSyncToken = (data) => ({ type: "DEL_SYNC_TOKEN", data });
export const delSyncTokenSuccess = (data) => ({ type: "DEL_SYNC_TOKEN_SUCCESS", data });

export const createInterakt = (data) => ({ type: "CREATE_INTERAKT", data });
export const createInteraktSuccess = (data) => ({ type: "CREATE_INTERAKT_SUCCESS", data });

export const updateInterakt = (data) => ({ type: "UPDATE_INTERAKT", data });
export const updateInteraktSuccess = (data) => ({ type: "UPDATE_INTERAKT_SUCCESS", data });
export const updateInteraktErr = (data) => ({ type: "UPDATE_INTERAKT_ERR", data });

export const addTmptIdInterakt = (data) => ({ type: "ADD_TMPT_ID_INTERAKT", data });
export const addTmptIdInteraktSuccess = (data) => ({ type: "ADD_TMPT_ID_INTERAKT_SUCCESS", data });

export const delTmptIdInterakt = (data) => ({ type: "DEL_TMPT_ID_INTERAKT", data });
export const delTmptIdInteraktSuccess = (data) => ({ type: "DEL_TMPT_ID_INTERAKT_SUCCESS", data });

export const updateTmplIdInteraktStatus = (data) => ({ type: "UPDATE_TMPT_ID_INTERAKT_STATUS", data });
export const updateTmplIdInteraktStatusSuccess = (data) => ({ type: "UPDATE_TMPT_ID_INTERAKT_STATUS_SUCCESS", data });

export const updateTmplMappingInterakt = (data) => ({ type: "UPDATE_TMPL_MAPPING_INTERAKT", data });
export const updateTmplMappingInteraktSuccess = (data) => ({ type: "UPDATE_TMPL_MAPPING_INTERAKT_SUCCESS", data });

export const sendWTAMessageInterakt = (data) => ({ type: "SEND_WTA_MESSAGE_INTERAKT", data });
export const sendWTAMessageInteraktSuccess = (data) => ({ type: "SEND_WTA_MESSAGE_INTERAKT_SUCCESS", data });

export const addUpdateSyndication = (data) => ({ type: "ADD_UPD_SYNDICATION", data });

export const createKlaviyo = (data) => ({ type: "CREATE_KLAVIYO", data });

export const delKlaviyo = (data) => ({ type: "DEL_KLAVIYO", data });

export const createWati = (data) => ({ type: "CREATE_WATI", data });

export const delWati = (data) => ({ type: "DEL_WATI", data });

export const createWatiTmp = (data) => ({ type: "CREATE_WATI_TMP", data });

export const delWatiTmp = (data) => ({ type: "DEL_WATI_TMP", data });

export const watiTampList = (data) => ({ type: "WATI_TAMP_LIST", data });
export const watiTampListSuccess = (data) => ({ type: "WATI_TAMP_LIST_SUCCESS", data });

export const getWatiField = (data) => ({ type: "GET_WATI_LIST", data });
export const getWatiFieldSuccess = (data) => ({ type: "GET_WATI_LIST_SUCCESS", data });

export const delWatiField = (data) => ({ type: "DEL_WATI_FIELD", data });

export const updateWatiTemplateData = (data) => ({ type: "UPDATE_WATI_TMP", data });
export const updateWatiTemplateDataSuccess = (data) => ({ type: "UPDATE_WATI_TMP_SUCCESS", data });

export const sendWatiMessage = (data) => ({ type: "SEND_WATI_MSG", data });

export const sendTestDataWebhook = (data) => ({ type: "SEND_TEST_DATA_WEBHOOK", data });

export const updateIntegrationStatus = (data) => ({ type: "UPDATE_INTEGRATION_STATUS", data })

const initialState = fromJS({

});

function integration(state = initialState, action) {
  switch (action.type) {

    case "IMPORT_DATA_FROM_CSV_SUCCESS":
      return { state: action.data };

    case "WATI_TAMP_LIST_SUCCESS":
      return { ...state, watiTempList: action.data };

    case "GET_WATI_LIST_SUCCESS":
      return { ...state, getWatiList: action.data }

    case "UPDATE_WATI_TMP_SUCCESS":
      return { ...state, updateWatiList: action.data }

    case "GET_INTEGRATION_MST_SUCCESS":
      return { ...state, IntegrationList: action.data };


    case "GET_USER_INTEGRATION_SUCCESS":
      return { ...state, UserIntegration: action.data };

    case "GET_WEBHOOK_FIELD_SUCCESS":
      return { ...state, webhookField: action.data };

    case "UPDATE_INTERAKT_ERR":
      return { ...state, interaktUpdData: action.data };

    default:
      return { ...state };
  }
}

export default integration;