import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/reviewWidget';
import { toastify } from '../ducks/toast';

function* getWidgetList() {
  try {
    yield put(load());
    let res = yield call(api.GET, '/getWidgetList');

    if (res.status === 'success') {
      yield put(actions.getWidgetListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }))
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWidget(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createNewWidget', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      // yield put(actions.createWidgetSuccess(true));
      yield put(actions.getWidgetList());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (e) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateWidget(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updWidget', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getWidgetListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (e) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetWidgetList() {
  yield takeLatest(actions.getWidgetList.type, getWidgetList);
}
export function* watchCreateWidget() {
  yield takeLatest(actions.createWidget.type, createWidget);
}
export function* watchUpdWidget() {
  yield takeLatest(actions.updateWidget.type, updateWidget);
}

export default function* rootSaga() {
  yield fork(watchGetWidgetList);
  yield fork(watchCreateWidget);
  yield fork(watchUpdWidget);
} 