import { Text, Page, Icon, Button, Popover, InlineGrid, Grid, Card, DatePicker, Scrollable, OptionList, Box, TextField, Select, InlineStack, ProgressBar } from '@shopify/polaris';
import { CalendarIcon, ArrowRightIcon } from '@shopify/polaris-icons';
import { useState, useEffect, useRef, memo } from 'react';
import { useBreakpoints } from '@shopify/polaris';

function Cards(props) {

  const { mdDown, lgUp } = useBreakpoints();
  const shouldShowMultiMonth = lgUp;
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const yesterday = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0, 0));

  const ranges = [
    {
      title: "Today",
      alias: "today",
      period: {
        since: today,
        until: today,
      },
    },
    {
      title: "Yesterday",
      alias: "yesterday",
      period: {
        since: yesterday,
        until: yesterday,
      },
    },
    {
      title: "Last 7 days",
      alias: "last7days",
      period: {
        since: new Date(
          new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0)
        ),
        until: yesterday,
      },
    },
    {
      title: "Last 30 days",
      alias: "last30days",
      period: {
        since: new Date(
          new Date(new Date().setDate(today.getDate() - 30)).setHours(0, 0, 0, 0)
        ),
        until: yesterday,
      },
    },
    {
      title: "Last 90 days",
      alias: "last90days",
      period: {
        since: new Date(
          new Date(new Date().setDate(today.getDate() - 90)).setHours(0, 0, 0, 0)
        ),
        until: yesterday,
      },
    }
  ];

  const [inputValues, setInputValues] = useState({});
  const [activeDateRange, setActiveDateRange] = useState(ranges[0]);
  const [{ month, year }, setDate] = useState({
    month: activeDateRange.period.since.getMonth(),
    year: activeDateRange.period.since.getFullYear()
  });


  const datePickerRef = useRef(null);

  function isValidYearMonthDayDateString(date) {
    return /^\d{4}-\d{1,2}-\d{1,2}/.test(date) && !isNaN(new Date(date).getDate());
  }
  function parseYearMonthDayDateString(input) {
    const [year, month, day] = input.split("-");
    return new Date(Number(year), Number(month) - 1, Number(day));
  }
  function frmtDtToYrMnthDtStr(date) {
    const year = String(date.getFullYear());
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    if (month.length < 2) {
      month = String(month).padStart(2, "0");
    }
    if (day.length < 2) {
      day = String(day).padStart(2, "0");
    }
    return [year, month, day].join("-");
  }
  function nodeContainsDescendant(rootNode, descendant) {
    if (rootNode === descendant) {
      return true;
    }
    let parent = descendant.parentNode;
    while (parent !== null) {
      if (parent === rootNode) {
        return true;
      }
      parent = parent.parentNode;
    }
    return false;
  }
  function isNodeWithinPopover(node) {
    return datePickerRef?.current
      ? nodeContainsDescendant(datePickerRef.current, node)
      : false;
  }
  function startInputValueChange(value) {
    setInputValues((prevState) => {
      return { ...prevState, since: value };
    });
    if (value.length === 10 && isValidYearMonthDayDateString(value)) {
      const newSince = parseYearMonthDayDateString(value);
      setActiveDateRange((prevState) => {
        const newPeriod =
          prevState.period && newSince <= prevState.period.until
            ? { since: newSince, until: prevState.period.until }
            : { since: newSince, until: newSince };
        return {
          ...prevState,
          period: newPeriod,
        };
      });
    }
  }
  function endInputValueChange(value) {
    setInputValues((prevState) => ({ ...prevState, until: value }));
    if (value.length === 10 && isValidYearMonthDayDateString(value)) {
      const newUntil = parseYearMonthDayDateString(value);
      setActiveDateRange((prevState) => {
        const newPeriod =
          prevState.period && newUntil >= prevState.period.since
            ? { since: prevState.period.since, until: newUntil }
            : { since: newUntil, until: newUntil };
        return {
          ...prevState, period: newPeriod
        };
      });
    }
  }
  function handleInputBlur({ relatedTarget }) {
    const isRelatedTargetWithinPopover = relatedTarget !== null && isNodeWithinPopover(relatedTarget);

    // If focus moves from the TextField to the Popover // we don't want to close the popover
    if (isRelatedTargetWithinPopover) return;

    props.changeNameValue('popoverActive', false);
  }
  function handleMonthChange(month, year) {
    setDate({ month, year });
  }
  function handleCalendarChange({ start, end }) {
    const newDateRange = ranges.find((range) => {
      return (
        range.period.since.valueOf() === start.valueOf() &&
        range.period.until.valueOf() === end.valueOf()
      );
    }) || {
      alias: "custom",
      title: "Custom",
      period: {
        since: start,
        until: end,
      },
    };
    setActiveDateRange(newDateRange);
  }

  const handleApply = () => {
    props.changeNameValue('popoverActive', false);
  }
  const handleCancel = () => {
    props.changeNameValue('popoverActive', false);
  }

  useEffect(() => {
    if (activeDateRange) {
      setInputValues({
        since: frmtDtToYrMnthDtStr(activeDateRange.period.since),
        until: frmtDtToYrMnthDtStr(activeDateRange.period.until),
      });
      function monthDiff(referenceDate, newDate) {
        return (newDate.month - referenceDate.month + 12 * (referenceDate.year - newDate.year));
      }
      const monthDifference = monthDiff(
        { year, month },
        {
          year: activeDateRange.period.until.getFullYear(),
          month: activeDateRange.period.until.getMonth(),
        }
      );
      if (monthDifference > 1 || monthDifference < 0) {
        setDate({
          month: activeDateRange.period.until.getMonth(),
          year: activeDateRange.period.until.getFullYear()
        });
      }
    }
  }, [activeDateRange]);

  const buttonValue = activeDateRange.title === "Custom" ?
    activeDateRange.period.since.toDateString() + " - " + activeDateRange.period.until.toDateString()
    : activeDateRange.title;

  return (
    <Page
      fullWidth
      title={"Analytic"}>
      <Box paddingBlockEnd={400}>

        <Box paddingBlockEnd={400}>
          <Popover
            fluidContent
            fullHeight
            active={props.state.popoverActive}
            autofocusTarget="none"
            preferredAlignment="left"
            preferredPosition="below"
            sectioned={false}
            activator={<Button icon={CalendarIcon}
              onClick={() => props.changeNameValue('popoverActive', !props.state.popoverActive)}>{buttonValue}</Button>}
            onClose={() => props.changeNameValue('popoverActive', false)}>

            <Popover.Pane fixed>
              <InlineGrid
                columns={{
                  xs: "1fr",
                  mdDown: "1fr",
                  md: "max-content max-content",
                }}
                gap={0}
                useRef={datePickerRef}>

                <Box
                  maxWidth={mdDown ? "516px" : "160px"}
                  width={mdDown ? "100%" : "160px"}  >

                  {mdDown ?
                    <Select
                      labelHidden
                      label="dateRangeLabel"
                      options={ranges.map(({ alias, title }) => title || alias)}
                      onChange={(value) => {
                        const result = ranges.find(({ title, alias }) => title === value || alias === value);
                        setActiveDateRange(result);
                      }}
                      value={activeDateRange?.title || activeDateRange?.alias || ""} />
                    :
                    <Scrollable style={{ height: "334px" }}>
                      <OptionList
                        options={ranges.map((x) => ({
                          value: x.alias,
                          label: x.title
                        }))}
                        selected={activeDateRange.alias}
                        // onChange={(value) => {
                        //   setActiveDateRange(
                        //     ranges.find((range) => range.alias === value[0])
                        //   );
                        // }}
                        onChange={(value) => {
                          const result = ranges.find((range) => range.alias === value[0]);
                          setActiveDateRange(result);
                        }}
                      />
                    </Scrollable>
                  }
                </Box>
                <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>
                  <InlineStack gap="400">
                    <InlineStack gap="200">
                      <div style={{ flexGrow: 1 }}>
                        <TextField
                          labelHidden
                          role="combobox"
                          label={"Since"}
                          prefix={<Icon source={CalendarIcon} />}
                          onChange={startInputValueChange}
                          onBlur={handleInputBlur}
                          value={inputValues.since}
                          autoComplete="off" />
                      </div>
                      <Icon source={ArrowRightIcon} />
                      <div style={{ flexGrow: 1 }}>
                        <TextField
                          labelHidden
                          role="combobox"
                          label={"Until"}
                          prefix={<Icon source={CalendarIcon} />}
                          onChange={endInputValueChange}
                          onBlur={handleInputBlur}
                          value={inputValues.until}
                          autoComplete="off" />
                      </div>
                    </InlineStack>
                    <div>
                      <DatePicker
                        month={month}
                        year={year}
                        // weekStartsOn={3}
                        selected={{
                          start: activeDateRange.period.since,
                          end: activeDateRange.period.until,
                        }}
                        onMonthChange={handleMonthChange}
                        onChange={handleCalendarChange}
                        multiMonth={shouldShowMultiMonth}
                        allowRange />
                    </div>
                  </InlineStack>
                </Box>
              </InlineGrid>
            </Popover.Pane>
            <Popover.Pane fixed>
              <Popover.Section>
                <Box padding={200}>
                  <InlineStack align="end">
                    <Button onClick={() => handleCancel()}>Cancel</Button>
                    <Box paddingInlineStart={200}><Button id='primary_btn' onClick={() => handleApply()}>Apply</Button></Box>
                  </InlineStack>
                </Box>
              </Popover.Section>
            </Popover.Pane>
          </Popover>
        </Box>

        <Grid gap={{ xl: '30px', md: '30px', md: '20px' }} >
          <Grid.Cell columnSpan={{ xs: 6, sm: 12 }}>
            <Card>

              <Box padding={400}>
                <Text variant='headingLg' as='h4' fontWeight='semibold'>Review request send(email analytic)</Text>
              </Box>

              <Box>
                <InlineStack align="space-between">
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>Delivered</Text>
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>12</Text>
                </InlineStack>
                <div style={{ margin: '7px 0 0' }}>
                  <Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text>
                </div>
              </Box>
              <Box>
                <InlineStack align="space-between">
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>Delivered</Text>
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>12</Text>
                </InlineStack>
                <div style={{ margin: '7px 0 0' }}>
                  <Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text>
                </div>
              </Box>
              <Box>
                <InlineStack align="space-between">
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>Delivered</Text>
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>12</Text>
                </InlineStack>
                <div style={{ margin: '7px 0 0' }}>
                  <Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text>
                </div>
              </Box>

              <Box paddingBlockStart={600} paddingBlockEnd={600}></Box>
              <Box paddingBlockStart={400} ></Box>

            </Card>
          </Grid.Cell>

          <Grid.Cell columnSpan={{ xs: 6, sm: 12 }}>
            <Card>

              <Box paddingBlockEnd={400}>
                <Text variant='headingLg' as='h4' fontWeight='semibold'>Review request send(email analytic)</Text>
              </Box>

              <InlineStack align="space-between">
                <Text as='h5' variant='headingMd' fontWeight='medium'>30</Text>
                <Text variant='headingMd' as='h5' fontWeight='semibold'>
                  <svg fill="none" width="16" height="16" viewBox="0 0 16 16"><path fill="#FFC524" d="M4.445 9.423L1.868 7.29a.711.711 0 01.344-1.26l3.655-.163 1.561-3.913a.64.64 0 011.145 0l1.561 3.913 3.655.163c.618.088.83.87.345 1.26l-2.578 2.133.704 4.165a.711.711 0 01-1.022.737L8 12.268l-3.238 2.056a.71.71 0 01-1.022-.736l.704-4.165z" /></svg>
                </Text>
                <ProgressBar size="small" progress={70} animated tone="primary" />
              </InlineStack>
              <InlineStack align="space-between">
                <Text as='h5' variant='headingMd' fontWeight='medium'>30</Text>
                <Text variant='headingMd' as='h5' fontWeight='semibold'>
                  <svg fill="none" width="16" height="16" viewBox="0 0 16 16"><path fill="#FFC524" d="M4.445 9.423L1.868 7.29a.711.711 0 01.344-1.26l3.655-.163 1.561-3.913a.64.64 0 011.145 0l1.561 3.913 3.655.163c.618.088.83.87.345 1.26l-2.578 2.133.704 4.165a.711.711 0 01-1.022.737L8 12.268l-3.238 2.056a.71.71 0 01-1.022-.736l.704-4.165z" /></svg>
                </Text>
                <ProgressBar size="small" progress={70} animated tone="primary" />
              </InlineStack>
              <InlineStack align="space-between">
                <Text as='h5' variant='headingMd' fontWeight='medium'>30</Text>
                <Text variant='headingMd' as='h5' fontWeight='semibold'>
                  <svg fill="none" width="16" height="16" viewBox="0 0 16 16"><path fill="#FFC524" d="M4.445 9.423L1.868 7.29a.711.711 0 01.344-1.26l3.655-.163 1.561-3.913a.64.64 0 011.145 0l1.561 3.913 3.655.163c.618.088.83.87.345 1.26l-2.578 2.133.704 4.165a.711.711 0 01-1.022.737L8 12.268l-3.238 2.056a.71.71 0 01-1.022-.736l.704-4.165z" /></svg>
                </Text>
                <ProgressBar size="small" progress={70} animated tone="primary" />
              </InlineStack>
              <InlineStack align="space-between">
                <Text as='h5' variant='headingMd' fontWeight='medium'>30</Text>
                <Text variant='headingMd' as='h5' fontWeight='semibold'>
                  <svg fill="none" width="16" height="16" viewBox="0 0 16 16"><path fill="#FFC524" d="M4.445 9.423L1.868 7.29a.711.711 0 01.344-1.26l3.655-.163 1.561-3.913a.64.64 0 011.145 0l1.561 3.913 3.655.163c.618.088.83.87.345 1.26l-2.578 2.133.704 4.165a.711.711 0 01-1.022.737L8 12.268l-3.238 2.056a.71.71 0 01-1.022-.736l.704-4.165z" /></svg>
                </Text>
                <ProgressBar size="small" progress={70} animated tone="primary" />
              </InlineStack>
              <InlineStack align="space-between">
                <Text as='h5' variant='headingMd' fontWeight='medium'>30</Text>
                <Text variant='headingMd' as='h5' fontWeight='semibold'>
                  <svg fill="none" width="16" height="16" viewBox="0 0 16 16"><path fill="#FFC524" d="M4.445 9.423L1.868 7.29a.711.711 0 01.344-1.26l3.655-.163 1.561-3.913a.64.64 0 011.145 0l1.561 3.913 3.655.163c.618.088.83.87.345 1.26l-2.578 2.133.704 4.165a.711.711 0 01-1.022.737L8 12.268l-3.238 2.056a.71.71 0 01-1.022-.736l.704-4.165z" /></svg>
                </Text>
                <ProgressBar size="small" progress={70} animated tone="primary" />
              </InlineStack>
            </Card>
          </Grid.Cell>

          <Grid.Cell columnSpan={{ xs: 6, sm: 12 }} >
            <Card>

              <Box padding={400} >
                <Text variant='headingLg' as='h4' fontWeight='semibold'>Review request send(email analytic)</Text>
              </Box>

              <Box>
                <InlineStack align="space-between">
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>Delivered</Text>
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>12</Text>
                </InlineStack>
                <div style={{ margin: '7px 0 0' }}>
                  <Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text>
                </div>
              </Box>
              <Box>
                <InlineStack align="space-between">
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>Delivered</Text>
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>12</Text>
                </InlineStack>
                <div style={{ margin: '7px 0 0' }}>
                  <Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text>
                </div>
              </Box>

              <Box paddingBlockStart={600} paddingBlockEnd={600}></Box>
              <Box paddingBlockStart={600} ></Box>
            </Card>
          </Grid.Cell>

          <Grid.Cell columnSpan={{ xs: 6, sm: 12 }}>
            <Card>

              <Box padding={400}>
                <Text variant='headingLg' as='h4' fontWeight='semibold'>Review request send(email analytic)</Text>
              </Box>

              <Box>
                <InlineStack align="space-between">
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>Delivered</Text>
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>12</Text>
                </InlineStack>
                <div style={{ margin: '7px 0 0' }}>
                  <Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text>
                </div>
              </Box>
              <Box>
                <InlineStack align="space-between">
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>Delivered</Text>
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>12</Text>
                </InlineStack>
                <div style={{ margin: '7px 0 0' }}>
                  <Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text>
                </div>
              </Box>
              <Box>
                <InlineStack align="space-between">
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>Delivered</Text>
                  <Text variant='headingMd' as='h5' fontWeight='semibold'>12</Text>
                </InlineStack>
                <div style={{ margin: '7px 0 0' }}>
                  <Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text>
                </div>
              </Box>
            </Card>
          </Grid.Cell>

          <Grid.Cell columnSpan={{ xs: 6, sm: 12 }}>
            <Card>

              <Box paddingBlockEnd={400}>
                <Text variant='headingMd' as='h5' fontWeight='semibold'>Top Keywords in Reviews</Text>
                <Box paddingBlockStart={100}><Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text></Box>
              </Box>

              <Box paddingBlockEnd={300}>
                <InlineStack gap="400" align="space-between">
                  <Text variant='bodyMd' as='h5' fontWeight='medium'>There are many variations of passages</Text>
                  <Text variant='headingMd' as='h5' fontWeight='medium'>12</Text>
                </InlineStack>
              </Box>

              <Box paddingBlockEnd={300}>
                <InlineStack align="space-between">
                  <Text variant='bodyMd' as='h5' fontWeight='medium'>Various versions have evolved</Text>
                  <Text variant='headingMd' as='h5' fontWeight='medium'>12</Text>
                </InlineStack>
              </Box>

              <Box paddingBlockEnd={300}>
                <InlineStack align="space-between">
                  <Text variant='bodyMd' as='h5' fontWeight='medium'>Contrary to popular belief,</Text>
                  <Text variant='headingMd' as='h5' fontWeight='medium'>12</Text>
                </InlineStack>
              </Box>

            </Card>
          </Grid.Cell>

          <Grid.Cell columnSpan={{ xs: 6, sm: 12 }}>
            <Card>

              <Box paddingBlockEnd={400}>
                <Text variant='headingMd' as='h5' fontWeight='semibold'>Top Keywords in Reviews</Text>
                <Box paddingBlockStart={100}><Text variant='headingSm' as='h6' fontWeight='regular'>Contrary to popular belief, Lorem Ipsum is not simply random text.</Text></Box>
              </Box>

              <Box paddingBlockEnd={300}>
                <InlineStack gap="400" align="space-between">
                  <Text variant='bodyMd' as='h5' fontWeight='medium'>There are many variations of passages</Text>
                  <Text variant='headingMd' as='h5' fontWeight='medium'>12</Text>
                </InlineStack>
              </Box>

              <Box paddingBlockEnd={300}>
                <InlineStack align="space-between">
                  <Text variant='bodyMd' as='h5' fontWeight='medium'>Various versions have evolved</Text>
                  <Text variant='headingMd' as='h5' fontWeight='medium'>12</Text>
                </InlineStack>
              </Box>

              <Box paddingBlockEnd={300}>
                <InlineStack align="space-between">
                  <Text variant='bodyMd' as='h5' fontWeight='medium'>Contrary to popular belief,</Text>
                  <Text variant='headingMd' as='h5' fontWeight='medium'>12</Text>
                </InlineStack>
              </Box>

            </Card>
          </Grid.Cell>
        </Grid>

      </Box>
    </Page >
  );
}

export default memo(Cards);