import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/groupProduct';
import { toastify } from '../ducks/toast';

function* getProductName(action) {
  let payload = { ...action.payload, pvd: localStorage.getItem('pvd') }
  try {
    const res = yield call(api.POST, '/getProductList', payload);

    if (res.status === 'success') {
      yield put(actions.getProductNameSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getGroupList(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getProductGroupList', action.payload);
    if (res.status === 'success') {
      yield put(actions.getGroupListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createGroup(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createProductGroup', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getGroupList(global.groupObj));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
      yield put(actions.getGroupList(global.groupObj));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateGroup(action) {
  try {
    const res = yield call(api.POST, '/updateProductGroup', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getGroupList(global.groupObj));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
      yield put(actions.getGroupList(global.groupObj));
    }
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteGroup(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/deleteProductGroup?_id=' + action.payload._id + '&shp=' + action.payload.shp);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getGroupList(global.groupObj));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
      yield put(actions.getGroupList(global.groupObj));
    }
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetProductName() {
  yield takeLatest(actions.getProductName.type, getProductName);
}
export function* watchGetGroupList() {
  yield takeLatest(actions.getGroupList.type, getGroupList);
}
export function* watchCreateGroup() {
  yield takeLatest(actions.createGroup.type, createGroup);
}
export function* watchUpdateGroup() {
  yield takeLatest(actions.updateGroup.type, updateGroup);
}
export function* watchDeleteGroup() {
  yield takeLatest(actions.deleteGroup.type, deleteGroup);
}

export default function* rootSaga() {
  yield fork(watchGetProductName);
  yield fork(watchGetGroupList);
  yield fork(watchCreateGroup);
  yield fork(watchUpdateGroup);
  yield fork(watchDeleteGroup);
}