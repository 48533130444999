// import { fromJS } from 'immutable';

// export const getUpsell = (data) => ({ type: "GET_UPSELL", data });
// export const getUpsellSuccess = (data) => ({ type: "GET_UPSELL_SUCCESS", data });

// export const createUpsell = (data) => ({ type: "CREATE_UPSELL", data });

// export const updateUpsell = (data) => ({ type: "UPDATE_UPSELL", data });

// export const deleteUpsell = (data) => ({ type: "DELETE_UPSELL", data });

// const initialState = fromJS({
// });

// function upsell(state = initialState, action) {
//   switch (action.type) {

//     case "GET_UPSELL_SUCCESS":
//       return { ...state, UpsellList: action.data };

//     default:
//       return { ...state };
//   }
// }

// export default upsell;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const upsell = createSlice({
  name: 'upsell',
  initialState,
  reducers: {
    getUpsell: (state, action) => { },
    getUpsellSuccess: (state, action) => {
      return { ...state, UpsellList: action.payload };
    },
    createUpsell: (state, action) => { },
    updateUpsell: (state, action) => { },
    deleteUpsell: (state, action) => { },
  }
});

export const {
  getUpsell,
  getUpsellSuccess,
  createUpsell,
  updateUpsell,
  deleteUpsell,
} = upsell.actions;

export default upsell.reducer;