import { Text } from '@shopify/polaris';
import './Comingsoon.css';

function CommingSoon(props) {
  return (
    <div className='onBord_container comng_snClss'>
      <div className='cmng_div'>
        <Text variant="heading4xl" as="h1" fontWeight='regular'>COMING SOON...</Text>
      </div>
    </div>
  );
}

export default CommingSoon;