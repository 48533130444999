import { Text, Button, ButtonGroup, InlineStack, InlineGrid, Box, Link, BlockStack, Collapsible, Icon, Divider } from '@shopify/polaris';
import { ArrowLeftIcon, ExternalIcon, AppExtensionIcon, CodeIcon, ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { onboard_step_3, copy_icon, error, warning_icon } from '../../img';

const BoardingStep3 = ({ props }) => {

  let { state, changeNameValue } = props;
  let starRateCode = `{% render 'wnreview_widgets', widget_type: 'wnreview_star_rating', product: product %}`;
  return (
    <Box>
      <Box paddingBlockEnd={800}>
        <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
          <div className='border-right'>
            <div className='onboard-image'>
              <img src={onboard_step_3} width='100%' height='100%' alt="onboard" />
            </div>
          </div>
          <div>
            <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
              <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 3 / 6</Text>
              <Text variant="headingLg" as="h5" fontWeight='medium'>Showcase Product Star Ratings</Text>
            </BlockStack>

            <Box paddingBlockStart={600}>
              {state.isShwStepOne === true &&
                <div className="collapse-active coll-cta">
                  <div onClick={() => changeNameValue({ tabEvent: 'firstTabCollapse' })}>
                    <InlineStack blockAlign='center' align='space-between'>
                      <Box paddingBlock={200}>
                        <InlineStack gap={200}>
                          <Box paddingInlineStart={400}>
                            <Icon source={AppExtensionIcon} tone="base" />
                          </Box>
                          <Text as="h5" variant="headingSm" fontWeight='medium'>Embed App via Drag & Drop</Text>
                          <div className='warning_banner'>
                            <InlineStack gap={200} wrap={false}>
                              <img src={warning_icon} alt='warning' />
                              <Text variant='headingSm' fontWeight='medium' as='p'>Recommended for Shopify 2 Themes Users</Text>
                            </InlineStack>
                          </div>
                        </InlineStack>
                      </Box>
                      <Box paddingInlineEnd={400}>
                        {state.tabEvent === 'firstTabCollapse' ?
                          <Icon source={ChevronUpIcon} tone="base"></Icon> :
                          <Icon source={ChevronDownIcon} tone="base"></Icon>}
                      </Box>
                    </InlineStack>
                  </div>
                  <Collapsible
                    open={state.tabEvent === 'firstTabCollapse'}
                    id="firstTabCollapse"
                    transition={{ duration: '500ms', timingFunction: 'ease-in' }}
                    expandOnPrint>
                    <Box paddingInlineStart={400} paddingBlockEnd={200} paddingBlockStart={200}>
                      <BlockStack gap={200}>
                        <Text variant='headingSm' fontWeight='regular'>1. Click 'Embed App' to open the setup.</Text>
                        <Text variant='headingSm' fontWeight='regular'>2. Position the star rating badge, then click 'Save' & return back to here</Text>
                      </BlockStack>
                      <Box paddingBlockStart={300}>
                        <Button id='primary_btn' variant="primary" icon={ExternalIcon} url={`https://${localStorage.getItem('shp')}/admin/themes/current/editor?template=product&addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/starRating&target=mainSection`} target='_blank'>Embed App</Button>
                      </Box>
                    </Box>
                  </Collapsible>
                </div>
              }
            </Box>

            <div className="collapse-active coll-cta">
              <div onClick={() => changeNameValue({ tabEvent: 'secondTabCollapse' })} >
                <InlineStack blockAlign='center' align='space-between'>
                  <Box paddingBlock={200}>
                    <InlineStack gap={200}>
                      <Box paddingInlineStart={400}>
                        <Icon source={CodeIcon} tone="base" />
                      </Box>
                      <Text as="h5" variant="headingSm" fontWeight='medium'>Manual Setup via Code</Text>
                      <div className='warning_banner'>
                        <InlineStack gap={200} wrap={false}>
                          <img src={warning_icon} alt='warning' />
                          <Text variant='headingSm' fontWeight='medium' as='p'>Recommended for Vintage Themes Users</Text>
                        </InlineStack>
                      </div>
                    </InlineStack>
                  </Box>
                  <Box paddingInlineEnd={400}>
                    {state.tabEvent === 'secondTabCollapse' ?
                      <Icon source={ChevronUpIcon} tone="base"></Icon> :
                      <Icon source={ChevronDownIcon} tone="base"></Icon>}
                  </Box>
                </InlineStack>
              </div>
              <Collapsible
                open={state.tabEvent === 'secondTabCollapse'}
                id="secondTabCollapse"
                transition={{ duration: '500ms', timingFunction: 'ease-in' }}
                expandOnPrint>
                <Box paddingInline={400} paddingBlock={200}>
                  <Box>
                    <Text variant="headingSm" as="h6" fontWeight='regular'>
                      1. Open <Link target='_blank' url={`https://${localStorage.getItem('shp')}/admin/themes/current?key=sections/product-template.liquid`}> sections/product-template.liquid</Link> editor <br />

                      <Box paddingBlockStart={300} paddingBlockEnd={400}>
                        2. Add the following code under the &#123;&#123; product.title &#125;&#125; element. <br />
                      </Box>

                      <div className="copycode-box">
                        <InlineStack wrap={false} blockAlign='start'>
                          <textarea id='copy_code' className="textarea-copycode" readOnly={true} onClick={(e) => props.handleCopyCode(e, starRateCode)} value={starRateCode} >
                          </textarea>
                          <Button variant='monochromePlain' onClick={() => props.handleCopyCode('', starRateCode)}>
                            <img src={copy_icon} alt='copy'></img>
                          </Button>
                        </InlineStack>
                      </div>
                      <Box paddingBlockStart={200}>
                        3. When you're done, click Save.
                      </Box>
                    </Text>
                  </Box>
                </Box>
              </Collapsible>
            </div>
            <Box paddingBlockStart={200}>
              <div className='info_banner'>
                <InlineStack gap={200} wrap={false}>
                  <img src={error} alt='error' />
                  <Text variant='bodyMd' fontWeight='medium' as='p'>If you need any assistance, our support team is here to <Button variant='plain' onClick={() => props.handleDeskuShow()}>help!</Button></Text>
                </InlineStack>
              </div>
            </Box>
          </div>
        </InlineGrid>
      </Box>
      <Divider />
      <Box paddingBlockStart={500}>
        <InlineStack align="space-between">
          <Button id='plain_clr' variant={'plain'} icon={ArrowLeftIcon} onClick={() => props.handleNextStep('step2', 'Back_Step3')}>Back</Button>
          <ButtonGroup>
            <Button variant={'plain'} onClick={() => props.handleNextStep('step4', 'Skip_Step3')}>Skip</Button>
            <Button id='primry_clr' variant='primary' onClick={() => props.handleNextStep('step4', 'Step3')}>Continue</Button>
          </ButtonGroup>
        </InlineStack>
      </Box>
    </Box>
  );
}

export default BoardingStep3;