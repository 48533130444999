import React from 'react';
import { Page, Grid, Text, ButtonGroup, Button, Card, Collapsible, InlineGrid, Box, ProgressBar, Tooltip, InlineStack, Modal, Divider, Icon } from '@shopify/polaris';
import Parser from 'html-react-parser';
import { import_icon, campaign_icon, automate_icon, incentive_icon, nudges, wall, carousel, syndication01, review01, content_feed, marketing, start_frame, dashboard_step_1, dashboard_step_2, dashboard_step_3, dashboard_step_4, dashboard_step_5, dashboard_step_6 } from '../../img';
import { ExternalIcon, TeamIcon, LightbulbIcon, SmileyHappyIcon, ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { Link } from 'react-router-dom';

function Dashboard(props) {
  const choiceList = [
    {
      key: 1,
      fieldNm: 'ienblpxlapp',
      isChecked: props.state.ienblpxlapp,
      label: 'Enable this app to your shopify theme',
      description: `1. Click 'Enable app' to integrate with your shopify theme.<br />
                    2. Save changes in the new window.<br />
                    3. Return here to continue.`,
      btnDesc: '',
      ctaName: 'Enable app',
      image: dashboard_step_1,
      link: `https://${localStorage.getItem('shp')}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/pixel&target=newAppsSection`
    },
    {
      key: 2,
      fieldNm: 'iinstlwdgt',
      isChecked: props.state.iinstlwdgt,
      label: 'Add the product reviews section to your theme',
      description: 'Tailor the product review section to match your theme and activate it to showcase customer reviews on product pages',
      ctaName: 'Visit the product reviews widget',
      image: dashboard_step_2,
      link: '/reviewWidget/main?p=dbd',
      wdgTyp: 'main',
    },
    {
      key: 3,
      fieldNm: 'iinstlstrrtng',
      isChecked: props.state.iinstlstrrtng,
      label: 'Highlight with star ratings',
      description: 'Draw attention to your products with a prominent star rating badge.',
      ctaName: 'Visit star rating setting',
      image: dashboard_step_3,
      link: '/reviewWidget/star_rating?p=dbd',
      wdgTyp: 'star_rating',
    },
    {
      key: 4,
      fieldNm: 'ibrnd',
      isChecked: props.state.ibrnd,
      label: 'Brand your reviews',
      description: 'Add a logo and select colors that match your business identity for consistency across reviews, forms, and emails',
      ctaName: 'Visit branding setting',
      image: dashboard_step_4,
      link: '/branding?p=brnd'
    },
    {
      key: 5,
      fieldNm: 'iaumtrvwclt',
      isChecked: props.state.iaumtrvwclt,
      label: 'Begin automated review collection',
      description: 'Set up your system to ask for reviews automatically after each purchase',
      ctaName: 'Visit automate setting',
      image: dashboard_step_5,
      link: '/sequence?typ=automation_ds',
      camTyp: 'on_order_fulfill',
    },
    {
      key: 6,
      fieldNm: 'iplnslct',
      isChecked: props.state.iplnslct,
      label: 'Find your perfect plan',
      description: 'Select the plan that best supports your review strategy',
      btnDesc: '',
      ctaName: 'Visit plan details',
      image: dashboard_step_6,
      link: '/plan?p=pln'
    }
  ];

  const supportList = [
    {
      key: 1,
      label: 'Want to talk with the support team?',
      description: <Text tone='subdued'>you will always get response in between 9 a.m. to 11 p.m. IST time zone.</Text>,
      image: TeamIcon,
      ctaName: 'Talk with our live chat support team'
    },
    {
      key: 2,
      label: 'Knowledge-based',
      description: <Text tone='subdued'>We’ve written support documents to answer your features & implementation related queries</Text>,
      image: LightbulbIcon,
      ctaName: 'Visit support center'
    },
    {
      key: 3,
      label: 'Our expert will help you to implement it',
      description: <Text tone='subdued'>Give your theme access to <Button variant='plain' url='milato:info@wisernotify.com'>info@wisernotify.com</Button> & share your requirement related to implementation</Text>,
      image: SmileyHappyIcon,
      ctaName: 'Visit support center'
    },
  ]

  return (
    <div className='dashboard_page containerSM-custom-mediascr'>
      <Page fontWeight="regular"
        title={"Dashboard"} secondaryActions={<Button variant='plain'
          onClick={() => props.openCloseModal('supportOpen', props.state.supportOpen, 'open')}>Support?</Button>}>

        <Box padding={{ xs: 400, sm: 400, md: 0 }}>

          <Box paddingBlockEnd={200}>
            <div className={props.state.guideOpen ? 'collapse-active-db coll-db' : 'coll-db'}>
              {/* <Button
            fullWidth
            textAlign="left"
            disclosure={props.state.guideOpen ? 'up' : 'down'}
            ariaExpanded={props.state.guideOpen}
            ariaControls="guideOpen"
            onClick={() => props.changeNameValue('guideOpen', !props.state.guideOpen)}>

            <Box padding={200}>
              <Text as="h3" variant="headingMd">Onboarding Guide</Text>
              <Box paddingBlockStart={200} paddingBlockEnd={300}>
                <Text as="p" variant="headingSm" tone='subdued' fontWeight='regular'>Follow This Tailored Guide To Seamlessly Integrate wiser Reviews Into Your E-Commerce Store.</Text>
              </Box>
              <InlineStack wrap={false} blockAlign='center' gap={400}>
                <Text variant="bodyMd" as="p">Step {props.state.stepCount} / 6</Text>
                <div style={{ width: '103px' }}>
                  <ProgressBar size='small' animated={true} progress={props.state.progress} tone="primary" />
                </div>
              </InlineStack>
            </Box>
          </Button> */}

              <div onClick={() => props.changeNameValue({ 'guideOpen': !props.state.guideOpen })}>
                <Box padding={200}>
                  <InlineStack align='space-between'>
                    <Text as="h3" variant="headingMd" fontWeight='semibold'>Onboarding guide</Text>
                    <Box>
                      {props.state.guideOpen ?
                        <Icon source={ChevronUpIcon} tone="base"></Icon> :
                        <Icon source={ChevronDownIcon} tone="base"></Icon>}
                    </Box>
                  </InlineStack>
                  <Box paddingBlockStart={300} paddingBlockEnd={400}>
                    <Text as="h4" variant="headingMd" tone='subdued' fontWeight='regular'>Complete the steps to start collecting & display reviews effectively</Text>
                  </Box>
                  <InlineStack wrap={false} blockAlign='center' gap={400}>
                    <Text as="h4" variant="headingMd" fontWeight='regular'>Step {props.state.stepCount} / 6</Text>
                    <div style={{ width: '103px' }}>
                      <ProgressBar size='small' animated={true} progress={props.state.progress} tone="primary" />
                    </div>
                  </InlineStack>
                </Box>
              </div>

              <Collapsible
                open={props.state.guideOpen}
                id="db_collaspe"
                transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                <Box paddingBlock={400} paddingInline={200} id='db_choices'>

                  {choiceList && choiceList.map((list, i) => { //checkboxes
                    return (
                      <div key={i} className={props.state.selectedName === list.fieldNm ? 'db_Main db_bg' : 'db_Main '}
                        onClick={() => props.changeNameValue({ 'selectedName': list.fieldNm })}>
                        <InlineStack align='space-between' wrap={false} blockAlign='start'>
                          <div className="d-flex">
                            <Text fontWeight="bold" as="span">
                              <Tooltip width='wide' padding="400" dismissOnMouseOut content={list.isChecked ? "Mark as not done" : "Mark as done"}>
                                <input type="checkbox" checked={list.isChecked}
                                  onChange={(e) => props.handleSelectChoice(list.fieldNm, e.target.checked)} />
                              </Tooltip>
                            </Text>
                            <div>
                              <Box paddingInlineStart={200}>
                                <label className='w-100'>
                                  <Text id='lbl_text' variant="headingMd" as="h4" fontWeight={props.state.selectedName === list.fieldNm ? 'medium' : 'regular'}>{list.label}</Text>
                                </label>
                              </Box>

                              {props.state.selectedName === list.fieldNm &&
                                <div className='db_helpText' style={{ opacity: '0' }}>
                                  <Box paddingBlockEnd={300} paddingInlineEnd={{ xs: 400, sm: 400, md: 800 }} >
                                    <Text tone='subdued'>{Parser(list.description)}</Text>
                                  </Box>
                                  {/* link with cta  */}
                                  {list.link.startsWith('/') ?
                                    <Button id='primary_btn' url={list.link} icon={ExternalIcon} onClick={() => props.handleAction(list.wdgTyp)}>{list.ctaName}</Button>
                                    :
                                    <Button id='primary_btn' url={list.link} icon={ExternalIcon} target='_blank' onClick={() => props.handleAction(list.wdgTyp)}>{list.ctaName}</Button>
                                  }
                                  {/* <Button id='primary_btn' url={list.link} icon={ExternalIcon} onClick={() => props.handleAction(list.wdgTyp)}>{list.ctaName}</Button> */}
                                </div>
                              }

                            </div>
                          </div>

                          <div className='dashbored-step-right-img' style={{ opacity: 0 }}>
                            <Box paddingInlineEnd={200}>
                              {props.state.selectedName === list.fieldNm &&
                                <img src={list.image} alt='Image' />
                              }
                            </Box>
                          </div>
                        </InlineStack>
                      </div>
                    );
                  })}

                </Box>
              </Collapsible>
            </div>
          </Box >

          <Box paddingBlockStart={600} paddingBlockEnd={600}>
            <Box paddingBlockEnd={300}>
              <InlineStack align='space-between'>
                <Text as="h3" variant="headingMd" fontWeight='semibold'>Performance report</Text>
                <ButtonGroup variant="segmented">
                  <Button onClick={() => props.changeNameValue({ 'activeCta': 'today' })}
                    variant={props.state.activeCta === 'today' ? 'primary' : 'secondary'}>Today</Button>

                  <Button onClick={() => props.changeNameValue({ 'activeCta': 'week' })}
                    variant={props.state.activeCta === 'week' ? 'primary' : 'secondary'}>7 days</Button>

                  <Button onClick={() => props.changeNameValue({ 'activeCta': 'month' })}
                    variant={props.state.activeCta === 'month' ? 'primary' : 'secondary'}>30 days</Button>
                </ButtonGroup>
              </InlineStack>
            </Box>
            <div className='dashbord-sec-performancereport'>
              <Grid gap={{ xs: 0, sm: 0, md: '8px', lg: '8px', xl: '16px' }}>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }} gap={{ xs: 0, sm: 0, md: '(var(--p-space-400))', lg: '(var(--p-space-400))', xl: '(var(--p-space-400))' }}  >
                  <Card>
                    <Box paddingBlockEnd={200}>
                      <Text as="h4" variant="headingXl" fontWeight='semibold'>{props.state.publishReview}</Text>
                    </Box>
                    <Text as="h6" variant="headingMd" fontWeight='medium'>Publish reviews</Text>
                  </Card>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }} gap={{ xs: 0, sm: 0, md: '(var(--p-space-400))', lg: '(var(--p-space-400))', xl: '(var(--p-space-400))' }} >
                  <Card>
                    <Box paddingBlockEnd={200}>
                      <Text as="h4" variant="headingXl" fontWeight='semibold'>{props.state.reviewCount}</Text>
                    </Box>
                    <Text as="h6" variant="headingMd" fontWeight='medium'>Collected reviews</Text>
                  </Card>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }} gap={{ xs: 0, sm: 0, md: '(var(--p-space-400))', lg: '(var(--p-space-400))', xl: '(var(--p-space-400))' }} >
                  <Card>
                    <Box paddingBlockEnd={200}>
                      <Text as="h4" variant="headingXl" fontWeight='semibold'>{props.state.unpublishReview}</Text>
                    </Box>
                    <Text as="h6" variant="headingMd" fontWeight='medium'>Unpublish reviews</Text>
                  </Card>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }} gap={{ xs: 0, sm: 0, md: '(var(--p-space-400))', lg: '(var(--p-space-400))', xl: '(var(--p-space-400))' }} >
                  <Card>
                    <Box paddingBlockEnd={200}>
                      <Text as="h4" variant="headingXl" fontWeight='semibold'>{props.state.emailSendCount}</Text>
                    </Box>
                    <Text as="h6" variant="headingMd" fontWeight='medium'>Email sent</Text>
                  </Card>
                </Grid.Cell>
              </Grid>
            </div>

            {/* <InlineGrid columns={4} gap={300}>
            <Card>
              <Box paddingBlockEnd={200}>
                <Text as="h4" variant="headingXl" fontWeight='semibold'>{props.state.publishReview}</Text>
              </Box>
              <Text as="h6" variant="headingMd" fontWeight='medium'>Publish reviews</Text>
            </Card>
            <Card>
              <Box paddingBlockEnd={200}>
                <Text as="h4" variant="headingXl" fontWeight='semibold'>{props.state.reviewCount}</Text>
              </Box>
              <Text as="h6" variant="headingMd" fontWeight='medium'>Collected reviews</Text>
            </Card>
            <Card>
              <Box paddingBlockEnd={200}>
                <Text as="h4" variant="headingXl" fontWeight='semibold'>{props.state.unpublishReview}</Text>
              </Box>
              <Text as="h6" variant="headingMd" fontWeight='medium'>Unpublish reviews</Text>
            </Card>
            <Card>
              <Box paddingBlockEnd={200}>
                <Text as="h4" variant="headingXl" fontWeight='semibold'>{props.state.emailSendCount}</Text>
              </Box>
              <Text as="h6" variant="headingMd" fontWeight='medium'>Email sent</Text>
            </Card>
          </InlineGrid> */}
          </Box>

          <Box paddingBlockEnd={1000} paddingBlockStart={200}>
            <Box paddingBlockEnd={400}>
              <Text as="h3" variant="headingMd" fontWeight='semibold'>Collect more reviews to strengthen customer trust</Text>
            </Box>
            <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={300}>

              <Card padding="0">
                <img className='dash_imgs' src={import_icon} alt="import_icon" />
                <Box paddingInline={400} paddingBlockEnd={400}>
                  <InlineStack gap={200}>
                    <Text as="h6" variant="headingMd" fontWeight='semibold'>Import reviews</Text>
                    <Text as="p" id='txtFixHeight' variant="headingSm" tone='subdued' fontWeight='regular'>Got reviews on other providers? Download from that platform & import them via CSVs file here</Text>
                    <Link to="/sequence?typ=import_ds"><Button>Visit import reviews section</Button></Link>
                  </InlineStack>
                </Box>
              </Card>

              <Card padding="0">
                <img src={campaign_icon} className='dash_imgs' alt="campaign_icon" />
                <Box paddingInline={400} paddingBlockEnd={400}>
                  <InlineStack gap={200}>
                    <Text as="h6" variant="headingMd" fontWeight='semibold'>Start campaign</Text>
                    <Text as="p" id='txtFixHeight' variant="headingSm" tone='subdued' fontWeight='regular'>Collect reviews from existing customers. Set up your first campaign now</Text>
                    <Link to="/sequence?typ=manual_ds"><Button>Visit manual campaign setting</Button></Link>
                  </InlineStack>
                </Box>
              </Card>

              <Card padding='0'>
                <img src={automate_icon} className='dash_imgs' alt="automate_icon" />
                <Box paddingInline={400} paddingBlockEnd={400}>
                  <InlineStack gap={200}>
                    <Text as="h6" variant="headingMd" fontWeight='semibold'>Automate review collection</Text>
                    <Text as="p" id='txtFixHeight' variant="headingSm" tone='subdued' fontWeight='regular'>Effortlessly gather fresh reviews with photos and videos from new customers</Text>
                    <Link to="/sequence?typ=automation_ds"><Button>View automate setting</Button></Link>
                  </InlineStack>
                </Box>
              </Card>

            </InlineGrid>
          </Box>

          <div className='sec-motivate-reviews'>
            <InlineStack align='space-between' wrap={false}>
              <Box padding={600} maxWidth='95%'>
                <InlineStack gap={600}>
                  <Text variant="headingLg" as="h5" fontWeight='semibold'>Motivate reviews with rewards</Text>
                  <Text as="p" variant="headingSm" tone='subdued' fontWeight='regular'>
                    Thank your customers for their valuable insights by offering special discounts. Encourage rich testimonials complete with images or videos of your products</Text>
                  <Link to="/incentives?p=inc"><Button variant='primary'>View incentives section</Button></Link>
                </InlineStack>
              </Box>
              <div className='motivate-reviews-right'>
                <img src={incentive_icon} alt='incentive_icon' height="160px" width="220px" />
              </div>
            </InlineStack>
          </div>

          <Box paddingBlockStart={800}>
            <Box paddingBlockEnd={500}>
              <Text as="h3" variant="headingMd" fontWeight='semibold'>Maximize conversion with strategic review placement</Text>
            </Box>
            <InlineGrid columns={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }} gap={400}>

              <Card padding='0'>
                <img src={nudges} className='dash_imgs' alt="nudges" />
                <Box paddingInline={400} paddingBlockStart={300} paddingBlockEnd={400}>
                  <InlineStack gap={200} direction={'column'}>
                    <Text as="h6" variant="headingMd" fontWeight='semibold'>Nudges</Text>
                    <Text id='txtFixHeight' as="p" variant="headingSm" tone='subdued' fontWeight='regular'>Highlight your best reviews right where shoppers decide, creating immediate trust.</Text>
                    <Link to="/reviewWidget/carousel_product?p=dbd"><Button onClick={() => props.handleAction('carousel_product')}>Visit nudges section</Button></Link>
                  </InlineStack>
                </Box>
              </Card>

              <Card padding="0">
                <img src={wall} alt="wall" className='dash_imgs' />
                <Box paddingInline={400} paddingBlockStart={300} paddingBlockEnd={400}>
                  <InlineStack gap={200} direction={'column'}>
                    <Text variant="headingMd" as="h6" fontWeight='semibold'>Wall of love</Text>
                    <Text id='txtFixHeight' as="p" variant="headingSm" tone='subdued' fontWeight='regular'>Collect all customer reviews and display them on a dedicated page.</Text>
                    <Link to="/reviewWidget/wall?p=dbd"><Button onClick={() => props.handleAction('wall')}>Visit wall of love section</Button></Link>
                  </InlineStack>
                </Box>
              </Card>

              <Card padding='0'>
                <img src={carousel} alt="carousel" className='dash_imgs' />
                <Box paddingInline={400} paddingBlockStart={300} paddingBlockEnd={400}>
                  <InlineStack gap={200} direction={'column'}>
                    <Text variant="headingMd" as="h6" fontWeight='semibold'>Carousel</Text>
                    <Text id='txtFixHeight' as="p" variant="headingSm" tone='subdued' fontWeight='regular'>Curate and display reviews in a dynamic carousel at key locations on your site</Text>
                    <Link to="/reviewWidget/carousel?p=dbd"><Button onClick={() => props.handleAction('carousel')}>Visit carousel section</Button></Link>
                  </InlineStack>
                </Box>
              </Card>

            </InlineGrid>
          </Box>

          <Box paddingBlock={800}><Divider borderColor="border" /></Box>

          <div className='sec-twopart-spacebeetw'>
            <Box paddingBlockEnd={600}>
              <Card>
                <InlineStack align='space-between' wrap={false} gap={500}>
                  <Box>
                    <Text variant="headingMd" as="h6" fontWeight='semibold'>Boost visibility with review syndication</Text>
                    <Box paddingBlockStart={200} paddingBlockEnd={400} maxWidth='80%'>
                      <Text as="p" variant="headingSm" tone='subdued' fontWeight='regular'>Enhance your Google Shopping presence and Google Ads with star ratings from product reviews</Text>
                    </Box>
                    <Link to='/integration?typ=google_shopping'><Button>Visit syndication setting</Button></Link>
                  </Box>
                  <div className='sec-twopart-boostvisibility'>
                    <img src={syndication01} alt='syndication' />
                  </div>
                </InlineStack>
              </Card>
            </Box>
          </div>

          <div className='sec-twopart-spacebeetw'>
            <Box paddingBlockEnd={1000}>
              <Card>
                <InlineStack align='space-between' wrap={false} gap={300}>
                  <Box>
                    <Text variant="headingMd" as="h6" fontWeight='semibold'>Simplify review submission</Text>
                    <Box paddingBlockStart={200} paddingBlockEnd={400} maxWidth='85%'>
                      <Text as="p" variant="headingSm" tone='subdued' fontWeight='regular'>Provide your customers with direct links for easy access to submit and update their reviews anytime, anywhere</Text>
                    </Box>
                    <Link to='/forms?p=frm'><Button>Access review forms</Button></Link>
                  </Box>
                  <div className='sec-twopart-simplifyreview'>
                    <img src={review01} alt='review' />
                  </div>
                </InlineStack>
              </Card>
            </Box>
          </div>

          <Box paddingBlockEnd={1000}>
            <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} gap={400}>

              <Card padding='0'>
                <img src={content_feed} alt="content_feed" className='dash_imgs' />
                <Box paddingInline={400} paddingBlockStart={300} paddingBlockEnd={400}>
                  <InlineStack gap={200}>
                    <Text variant="headingMd" as="h6" fontWeight='semibold'>Show user generated content feed</Text>
                    <Box paddingBlockEnd={200}><Text as="span" variant="bodyMd" tone='subdued' fontWeight='regular'>Display a live feed of social media posts by customers about your products directly on your store</Text></Box>
                    <Link to='/ugc?p=ugc'><Button>Visit UGC section</Button></Link>
                  </InlineStack>
                </Box>
              </Card>

              <Card padding="0">
                <img src={marketing} alt="marketing" className='dash_imgs' />
                <Box paddingInline={400} paddingBlockStart={300} paddingBlockEnd={400}>
                  <InlineStack gap={200}>
                    <Text variant="headingMd" as="h6" fontWeight='semibold'>Seamless marketing integration</Text>
                    <Box paddingBlockEnd={200}><Text as="span" variant="bodyMd" tone='subdued' fontWeight='regular'>Connect to your favorite marketing apps to streamline review requests and management</Text></Box>
                    <Link to='/integration?p=intg'><Button>Visit integration section</Button></Link>
                  </InlineStack>
                </Box>
              </Card>

            </InlineGrid>
          </Box>

          <div className='sec-twopart-spacebeetw'>
            <Box paddingBlockEnd={600}>
              <Box paddingBlockEnd={400}>
                <Text as="h3" variant="headingMd" fontWeight='semibold'>Tell us your thoughts</Text>
              </Box>
              <Card padding='0'>
                <Box paddingBlock={500} paddingInline={400}>
                  <InlineStack align='space-between' wrap={false} blockAlign='center'>
                    <Text as="p" variant="headingMd" fontWeight='medium'>
                      We'd love to hear about your experience with our shopify app</Text>
                    <Button variant='monochromePlain' url='https://apps.shopify.com/wisernotify-product-reviews' target='_blank'><img src={start_frame} alt='start_frame' /></Button>
                  </InlineStack>
                </Box>
              </Card>
            </Box>
          </div>

        </Box>

        <Modal
          open={props.state.supportOpen}
          onClose={(e) => props.openCloseModal('supportOpen', props.state.supportOpen, e)}
          title={<Text fontWeight='bold'>Need any support & having any issue?</Text>}
        >
          <Box paddingBlock={800} paddingInline={400}>
            {supportList && supportList.map((list, i) => {
              return (
                <div className='db_support' key={i}>
                  <InlineStack wrap={false} gap={200} blockAlign='start'>
                    <div className='db_box_bg'>
                      <Icon source={list.image} tone='base'></Icon>
                    </div>
                    <InlineStack gap={300}>
                      <InlineStack gap={100} blockAlign='start'>
                        <Text as='p' fontWeight='bold' variant='headingMd'>{list.label}</Text>
                        {list.description}
                      </InlineStack>
                      <Button id='primary_btn' onClick={() => props.handleDeskuShow()}>{list.ctaName}</Button>
                    </InlineStack>
                  </InlineStack>
                  {i !== supportList.length - 1 &&
                    <Box paddingBlock={600}><Divider /></Box>}
                </div>
              )
            })}
          </Box>
        </Modal>

      </Page >
    </div >
  );
}

export default Dashboard;