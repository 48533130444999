import { fromJS } from 'immutable';

export const createTemplate = (data) => ({ type: "CREATE_TEMPLATE", data });
export const createTemplateSuccess = (data) => ({ type: "CREATE_TEMPLATE_SUCCESS", data });

const initialState = fromJS({
});

function setting(state = initialState, action) {
  switch (action.type) {

    default:
      return { ...state };
  }
}

export default setting;