import { Text, Popover, OptionList, Tooltip, Checkbox, Card, InlineGrid, Button, Box, InlineStack, Thumbnail, TextField, Select } from '@shopify/polaris';
import { ThemeTemplateIcon, StarIcon, ButtonIcon, EyeCheckMarkIcon, ImageWithTextOverlayIcon, CodeIcon, LogoGoogleIcon, LanguageIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const dateOpt = [
  { label: 'mm-dd-yyyy', value: 'mm-dd-yyyy' },
  { label: 'dd-mm-yyyy', value: 'dd-mm-yyyy' }
];

const nameOpt = [
  { label: 'Jenny Karl', value: 0 },
  { label: 'J. karl', value: 1 },
  { label: 'Jenny K.', value: 2 },
  { label: 'J. K.', value: 3 }
];

const btnPosOpt = [
  { label: 'Right side', value: 'center_right' },
  { label: 'Left side', value: 'center_left' }
];

const TextSidebarCta = ({ props }) => {
  let { state, handleTextChange, changeNameValue } = props;
  let cnf = state.cnf;

  let verticalTabsArr = [
    { title: 'Sidebar button configuration', img: ButtonIcon },
    { title: 'Theme layouts', img: ThemeTemplateIcon },
    { title: 'Reviewer cards', img: ImageWithTextOverlayIcon },
    { title: 'Start rating summary', img: StarIcon },
    { title: 'Color combination', img: EyeCheckMarkIcon },
    { title: 'Add custom css', img: CodeIcon },
    { title: 'Google schema', img: LogoGoogleIcon },
    { title: 'Language', img: LanguageIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  const getLabel = (val) => {
    let _lbl = <Text fontWeight='medium' tone='subdued'>Select</Text>;
    if (val) btnPosOpt.map((x) => { if (x.value === val) _lbl = x.label; });
    return _lbl;
  }

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <div>

          <Text variant='bodyMd' fontWeight='bold'>Sidebar button</Text>

          <Box paddingBlockStart={200} paddingInlineStart={300}>
            {cnf && cnf.sdctabtntxt !== undefined &&
              <TextField
                label={<Text fontWeight='medium' as='span'>Button text</Text>}
                value={cnf.sdctabtntxt}
                onChange={(e) => handleTextChange({ sdctabtntxt: e })}
                autoComplete="off" />
            }

            {cnf && cnf.sdctabtnpstn !== undefined &&
              <Box paddingBlock={500}>
                <Text fontWeight='medium' as='span'>Button position</Text>
                <Box paddingBlockStart={100}>
                  <Popover
                    fullWidth
                    active={state.isBtnPos}
                    onClose={() => changeNameValue({ isBtnPos: !state.isBtnPos })}
                    activator={<Button textAlign="left" fullWidth disclosure="select"
                      onClick={() => changeNameValue({ isBtnPos: !state.isBtnPos })}>
                      <Text variant='bodyMd' tone='subdued'>
                        {getLabel(cnf.sdctabtnpstn)}
                      </Text>
                    </Button>}>
                    <OptionList
                      options={btnPosOpt}
                      selected={cnf.sdctabtnpstn}
                      onChange={() => changeNameValue({ isBtnPos: !state.isBtnPos })}
                      onFocusOption={(e) => handleTextChange({ sdctabtnpstn: e })} />
                  </Popover>
                </Box>
              </Box>
            }

            {cnf && cnf.sdctabtnistricn !== undefined &&
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Show star icon</Text>}
                checked={cnf.sdctabtnistricn}
                onChange={(e) => handleTextChange({ sdctabtnistricn: e })}
              />
            }

            {cnf && cnf.sdctabtnhdmbl !== undefined &&
              <Box paddingBlock={500}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Hide in mobile</Text>}
                  checked={cnf.sdctabtnhdmbl}
                  onChange={(e) => handleTextChange({ sdctabtnhdmbl: e })}
                />
              </Box>
            }
          </Box>

        </div>
        :
        state.sidebarActiveIndex === 1 ?
          <div>
            <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1 }} gap={300}>
              {state?.mainLayoutArr?.length > 0 && state.mainLayoutArr.map((val, i) => {
                return (
                  <div key={i} className={val._id === state.currLayoutId ? 'select_block active' : 'select_block'}
                    onClick={() => changeNameValue({ currLayoutId: val._id }, 'isEditable')}>
                    <InlineStack align='center' blockAlign='center'>
                      <Checkbox tone={val._id === state.currLayoutId ? 'magic' : ''}
                        checked={val._id === state.currLayoutId} />
                      <Text id='text_capitalize' fontWeight='medium' alignment='center'>{val.dn}</Text>
                    </InlineStack>
                    <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                  </div>
                );
              })}
            </InlineGrid>

            {cnf && cnf.isrtby !== undefined &&
              <Box paddingBlockStart={400}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Sorting dropdown</Text>}
                  checked={cnf.isrtby}
                  onChange={(e) => handleTextChange({ isrtby: e })}
                />
              </Box>
            }

            {cnf && cnf.isrchbx !== undefined &&
              <Box paddingBlockStart={500}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Review search box</Text>}
                  checked={cnf.isrchbx}
                  onChange={(e) => handleTextChange({ isrchbx: e })}
                />
              </Box>
            }

            {cnf && cnf.ibrnd !== undefined &&
              <Box paddingBlockStart={500}>
                <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                  <Checkbox
                    disabled={state.isFreePln === true}
                    label={<Text fontWeight='medium' as='span'>Branding</Text>}
                    checked={cnf.ibrnd}
                    // checked={state.isFreePln === true ? true : cnf.ibrnd}
                    onChange={(e) => handleTextChange({ ibrnd: e })} />
                </Tooltip>
              </Box>
            }

            {cnf && cnf.ildmr !== undefined &&
              <Box paddingBlock={500}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show load more button</Text>}
                  checked={cnf.ildmr}
                  onChange={(e) => handleTextChange({ ildmr: e })}
                />
                <Box paddingInlineStart={600} paddingBlockStart={100}>
                  <TextField
                    disabled={!cnf.ildmr}
                    value={cnf.ldmrtxt}
                    onChange={(e) => handleTextChange({ ldmrtxt: e })}
                    autoComplete="off" />
                </Box>
              </Box>
            }

          </div>
          :
          state.sidebarActiveIndex === 2 ?
            <Box>
              <InlineGrid columns={2} gap={300}>
                {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
                  return (
                    <div key={i} className={val._id === state.currCardId ? 'select_block active' : 'select_block'}
                      onClick={() => changeNameValue({ currCardId: val._id }, 'isEditable')}>
                      <InlineStack align='center' blockAlign='center'>
                        <Checkbox tone={val._id === state.currCardId ? 'magic' : ''}
                          checked={val._id === state.currCardId} />
                        <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                          {val.dn}</Text>
                      </InlineStack>
                      <Thumbnail source={val.iu} size="large" alt="Layout" />
                    </div>
                  );
                })}
              </InlineGrid>

              {cnf && cnf.crdirvwnm !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show reviewer name</Text>}
                    checked={cnf.crdirvwnm}
                    onChange={(e) => handleTextChange({ crdirvwnm: e })}
                  />
                  <Box paddingBlockStart={200} paddingInlineStart={600}>
                    <Text as='span'>Select name format</Text>
                    <Box paddingBlockStart={100}>
                      {cnf.crdirvwnm !== true ?
                        <TextField value={nameOpt[cnf.crdrvwnmfrmt].label} disabled={!cnf.crdirvwnm} />
                        :
                        <Popover
                          fullWidth
                          active={state.isNmFrmt}
                          onClose={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                          activator={<Button textAlign="left" fullWidth disclosure
                            onClick={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {nameOpt.find(({ value }) => value === cnf.crdrvwnmfrmt)?.label || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={nameOpt}
                            selected={[cnf.crdrvwnmfrmt]}
                            onChange={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                            onFocusOption={(e) => handleTextChange({ crdrvwnmfrmt: Number(e) })} />
                        </Popover>
                      }
                    </Box>
                  </Box>
                </Box>
              }

              {cnf && cnf.crdittl !== undefined &&
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show main text title</Text>}
                  checked={cnf.crdittl}
                  onChange={(e) => handleTextChange({ crdittl: e })}
                />
              }

              {cnf && cnf.crdidt !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show review date</Text>}
                    checked={cnf.crdidt}
                    onChange={(e) => handleTextChange({ crdidt: e })}
                  />
                  <Box paddingBlockStart={200} paddingInlineStart={600}>
                    <Text as='span'>Select date format</Text>
                    <Box paddingBlockStart={100}>
                      {cnf.crdidt !== true ?
                        <TextField value={cnf?.crddtfrmt || 'Select'} disabled={true} />
                        :
                        <Popover
                          fullWidth
                          active={state.isDtFrmt}
                          onClose={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                          activator={<Button textAlign="left" fullWidth disclosure
                            onClick={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {cnf?.crddtfrmt || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={dateOpt}
                            selected={cnf.crddtfrmt}
                            onChange={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                            onFocusOption={(e) => handleTextChange({ crddtfrmt: e })} />
                        </Popover>
                      }
                    </Box>

                  </Box>
                </Box>
              }

              {cnf && cnf.crdirdmr !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show trim content</Text>}
                    checked={cnf.crdirdmr}
                    onChange={(e) => handleTextChange({ crdirdmr: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.crdirdmr}
                      onChange={(e) => handleTextChange({ crdrdmrtxt: e })}
                      value={cnf.crdrdmrtxt} />
                  </Box>
                </Box>
              }

              {cnf && cnf.ivrfd !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show verified badge</Text>}
                    checked={cnf.ivrfd}
                    onChange={(e) => handleTextChange({ ivrfd: e })}
                  />
                  <Box paddingBlockStart={400}>
                    <Checkbox
                      disabled={!cnf.ivrfd}
                      label={<Text fontWeight='medium' as='span'>Show verified text on hover</Text>}
                      checked={cnf.ivrfdtxthvr}
                      onChange={(e) => handleTextChange({ ivrfdtxthvr: e })}
                    />
                    <Box paddingBlockStart={100} paddingInlineStart={600}>
                      <TextField
                        disabled={!cnf.ivrfd}
                        onChange={(e) => handleTextChange({ vrfdtxt: e })}
                        value={cnf.vrfdtxt} />
                    </Box>
                  </Box>
                </Box>
              }

              {cnf && cnf.irvwvtng !== undefined &&
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show review upvotes/downvotes</Text>}
                  checked={cnf.irvwvtng}
                  onChange={(e) => handleTextChange({ irvwvtng: e })}
                />
              }

              {cnf && cnf.crdilctn !== undefined &&
                <Box paddingBlockStart={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show location</Text>}
                    checked={cnf.crdilctn}
                    onChange={(e) => handleTextChange({ crdilctn: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.crdilctn}
                      onChange={(e) => handleTextChange({ crdlctnstr: e })}
                      value={cnf.crdlctnstr}
                    />
                  </Box>
                </Box>
              }

            </Box>
            :
            state.sidebarActiveIndex === 3 ?
              <Box>

                {cnf && cnf.hdrittl !== undefined &&
                  <Box>
                    <Checkbox
                      label={<Text fontWeight='medium' as='span'>Show title text</Text>}
                      checked={cnf.hdrittl}
                      onChange={(e) => handleTextChange({ hdrittl: e })}
                    />
                    <Box paddingBlockStart={100} paddingInlineStart={600}>
                      <TextField
                        disabled={!cnf.hdrittl}
                        value={cnf.hdrttltxt}
                        onChange={(e) => handleTextChange({ hdrttltxt: e })}
                        autoComplete="off" />
                    </Box>
                  </Box>
                }
              </Box>
              :
              state.sidebarActiveIndex === 4 ?
                <Box>
                  <Text variant="bodyMd" fontWeight='bold'>Sidebar button appearance</Text>
                  {cnf &&
                    <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                      <InlineStack direction={'column'} gap={500}>
                        {cnf.sdctabtnbgclr !== undefined &&
                          <ColorPick
                            labelName='Button background color'
                            colorKey="sdctabtnbgclr"
                            colorValue={cnf.sdctabtnbgclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.sdctabtnclr !== undefined &&
                          <ColorPick
                            labelName='Button text color'
                            colorKey="sdctabtnclr"
                            colorValue={cnf.sdctabtnclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.sdctabtnstrclr !== undefined &&
                          <ColorPick
                            labelName='Star icon color'
                            colorKey="sdctabtnstrclr"
                            colorValue={cnf.sdctabtnstrclr}
                            changeNameValue={handleTextChange} />
                        }
                      </InlineStack>
                    </Box>
                  }

                  <Text variant="bodyMd" fontWeight='bold'>Reviewer card</Text>
                  {cnf &&
                    <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                      <InlineStack direction={'column'} gap={500}>
                        {cnf.crdbgclr !== undefined &&
                          <ColorPick
                            labelName='Background color'
                            colorKey="crdbgclr"
                            colorValue={cnf.crdbgclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdstrclr !== undefined &&
                          <ColorPick
                            labelName='Star color'
                            colorKey="crdstrclr"
                            colorValue={cnf.crdstrclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crddtclr !== undefined &&
                          <ColorPick
                            labelName='Date color'
                            colorKey="crddtclr"
                            colorValue={cnf.crddtclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdrvwnmclr !== undefined &&
                          <ColorPick
                            labelName='Name color'
                            colorKey="crdrvwnmclr"
                            colorValue={cnf.crdrvwnmclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.vrfdtxtclr !== undefined &&
                          <ColorPick
                            labelName='Verified text color'
                            colorKey="vrfdtxtclr"
                            colorValue={cnf.vrfdtxtclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.vrfdbgclr !== undefined &&
                          <ColorPick
                            labelName='Verified bg color'
                            colorKey="vrfdbgclr"
                            colorValue={cnf.vrfdbgclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdlctnstrclr !== undefined &&
                          <ColorPick
                            labelName='Location label text color'
                            colorKey="crdlctnstrclr"
                            colorValue={cnf.crdlctnstrclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdttlclr !== undefined &&
                          <ColorPick
                            labelName='Review title color'
                            colorKey="crdttlclr"
                            colorValue={cnf.crdttlclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdtxtclr !== undefined &&
                          <ColorPick
                            labelName='Review text color'
                            colorKey="crdtxtclr"
                            colorValue={cnf.crdtxtclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.vtngclrfll !== undefined &&
                          <ColorPick
                            labelName='Vote fill color'
                            colorKey="vtngclrfll"
                            colorValue={cnf.vtngclrfll}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.vtngclrntfll !== undefined &&
                          <ColorPick
                            labelName='Vote not-fill color'
                            colorKey="vtngclrntfll"
                            colorValue={cnf.vtngclrntfll}
                            changeNameValue={handleTextChange} />
                        }
                      </InlineStack>
                    </Box>
                  }

                  <Text variant="bodyMd" fontWeight='bold'>Load more button</Text>
                  {cnf &&
                    <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={2400}>

                      <InlineStack direction={'column'} gap={500}>
                        {cnf.ldmrbgclr &&
                          <ColorPick
                            labelName='Background color'
                            colorKey="ldmrbgclr"
                            colorValue={cnf.ldmrbgclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.ldmrtxtclr &&
                          <ColorPick
                            labelName='Text color'
                            colorKey="ldmrtxtclr"
                            colorValue={cnf.ldmrtxtclr}
                            changeNameValue={handleTextChange} />
                        }
                      </InlineStack>
                    </Box>
                  }
                </Box>
                :
                state.sidebarActiveIndex === 5 ?
                  <Box>

                    {cnf && cnf.icstcss !== undefined &&
                      <Box>
                        <Checkbox
                          label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                          checked={cnf.icstcss}
                          onChange={(e) => handleTextChange({ icstcss: e })}
                        />
                        <Box paddingBlockStart={200} paddingInlineStart={600}>
                          <TextField
                            label={<Text tone='subdued' as='span'>Custom css block</Text>}
                            disabled={!cnf.icstcss}
                            multiline={4}
                            value={cnf.cstcss}
                            onChange={(e) => handleTextChange({ cstcss: e })}
                            autoComplete="off" />
                        </Box>
                      </Box>
                    }

                  </Box>
                  :
                  state.sidebarActiveIndex === 6 ?
                    <Box>
                      {cnf && cnf.iprdctrcsnpt !== undefined &&
                        <Card>
                          <Checkbox
                            label={<Text fontWeight='medium' as='span'>Is enabled product rich snippets</Text>}
                            checked={cnf.iprdctrcsnpt}
                            onChange={(e) => handleTextChange({ iprdctrcsnpt: e })}
                          />
                        </Card>
                      }
                    </Box>
                    :
                    state.sidebarActiveIndex === 7 &&
                    <Box>
                      {cnf && cnf.lng !== undefined &&
                        <Card>
                          <Select
                            label="Select language"
                            options={state.lngOptions ? state.lngOptions : []}
                            onChange={props.handleLanguageChange}
                            value={cnf.lng}
                          />
                        </Card>
                      }
                    </Box>
      }
    </Box >
  );
}

export default TextSidebarCta;