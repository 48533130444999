import { Badge, Page, Button, Box, Tooltip, InlineStack, Modal, PageActions, TextField, Text, IndexTable, Select, Popover, InlineGrid, OptionList, Divider, Icon, Collapsible, Card, Checkbox, BlockStack } from '@shopify/polaris';
import { DeleteIcon, ConnectIcon, SendIcon, ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';

let _ia = false;

const Wati = ({ props }) => {
	const dispatch = useDispatch();

	const { state, handleTextChange, changeNameValue, validator } = props;

	const [statewati, setStatewati] = useState({
		watiMapOpen: false,
		watiTmpLists: [],
		watiField: [],
		watiIdField: "",
		watiMapObj: {},
		watiFieldValue: "",
		watiFieldName: "",
		watiFieldArray: [],
		clickDel: "",
		sendWatiMessageOpen: false,
	});

	const changeNameValuewati = useCallback((obj) => {
		setStatewati((prevState) => ({ ...prevState, ...obj }));
	}, []);

	const watiTmp = useSelector(state => state.integration.watiTempList);
	const getwatifield = useSelector(state => state.integration.getWatiList);
	const UserIntegration = useSelector(state => state.integration.UserIntegration);

	useEffect(() => {
		dispatch(integrationDucks.watiTampList());
	}, [])

	useEffect(() => {
		changeNameValuewati({ 'watiTmpLists': watiTmp });
		changeNameValuewati({ 'watiField': getwatifield });
		if (UserIntegration && UserIntegration.length > 0) {
			for (let i = 0; i < UserIntegration.length; i++) {
				if (UserIntegration[i].inm === "wati") {
					let ut = UserIntegration[i].d.arr;
					if (ut && ut.length > 0) {
						for (let t = 0; t < ut.length; t++) {
							if (ut[t]._id === statewati.watiIdField) {
								changeNameValuewati({ 'watiFieldArray': ut[t].mapObj })
							}
						}
					}
				}
			}
		}
	}, [watiTmp, getwatifield, UserIntegration, statewati.watiIdField, changeNameValuewati])

	const openWatiFieldMappModal = (val) => {
		changeNameValuewati({ 'watiMapOpen': true })
		dispatch(integrationDucks.getWatiField({ "tmplid": val.tmplid }));
		changeNameValuewati({ "watiIdField": val._id })
		changeNameValuewati({ 'watiField': getwatifield });
	};

	const handleSendMsg = (val) => {
		changeNameValuewati({ 'sendWatiMessageOpen': !val })
	}

	const handleSendWatiMessage = () => {
		let obj = {
			id: state.currIntId,
			tid: state.tempId,
			number: state.dObj.number
		};
		dispatch(integrationDucks.sendWatiMessage(obj));
		changeNameValue({ 'dObj': {} });
		handleSendMsg(statewati.sendWatiMessageOpen)
	}

	let watiFieldOrG = [];
	if (statewati.watiField) {
		for (let i = 0; i < statewati.watiField.length; i++) {
			watiFieldOrG.push({ label: statewati.watiField[i], value: statewati.watiField[i] });
		}
	}

	const clearState = () => {
		let intObj = {
			currIntId: '',
			rmk: '',
			webUrl: '',
			wfkey: '',
			cfkey: '',
			isLisDtSuc: false,
		}
		changeNameValue(intObj);

		let intCObj = {
			watiFieldValue: '',
			watiFieldName: '',
		}
		changeNameValuewati(intCObj);
	};

	const handleAddWatiTmptId = () => {
		if (!validator.allValid()) {
			validator.showMessages();
			validator.helpers.forceUpdateIfNeeded();
			changeNameValue({ 'errMessage': validator.errorMessages });
		}
		else {
			changeNameValue({ 'errMessage': {} });
			let obj = {
				id: state.currIntId,
				rmk: state.dObj.rmk,
				tmplid: state.dObj.tmplid,
			}
			dispatch(integrationDucks.createWatiTmp(obj));
			changeNameValue({ 'dObj': {} });
		}
	}

	const handleCreateWati = (_ia) => {
		if (!validator.allValid()) {
			validator.showMessages();
			validator.helpers.forceUpdateIfNeeded();
			changeNameValue({ 'errMessage': validator.errorMessages });
		}
		else {
			changeNameValue({ 'errMessage': {} });
			let obj = {
				key: state.dObj.key,
				shp: localStorage.getItem('shp'),
				iid: state.currMasterObj._id ? state.currMasterObj._id : '',
				inm: state.currMasterObj.in ? state.currMasterObj.in : '',
				d: state.dObj,
				ia: _ia !== undefined ? _ia : state.currMasterObj.ia,
				icltrvw: state.currMasterObj.icltrvw
			}
			dispatch(integrationDucks.createWati(obj));
			let intObj = {
				isEditable: false,
				dObj: {},
			}
			changeNameValue(intObj);
		}
	}

	const handleDelWati = () => {
		let obj = {
			id: state.currIntId,
			ti: state.currIntTi
		}
		dispatch(integrationDucks.delWati(obj));
		let intObj = {
			dObj: {},
			newDObj: {},
		}
		changeNameValue(intObj);
		clearState();
		props.openCloseModal('deleteOpen', state.deleteOpen, 'close');
	}

	const handleDelWatiField = (num) => {
		Array.prototype.splice.call(statewati.watiFieldArray, num, 1);
		let obj = {
			id: state.currIntId,
			ti: state.currIntTi,
			tid: state.tempId,
			mapObj: statewati.watiFieldArray
		}
		dispatch(integrationDucks.delWatiField(obj));
	}

	const handleDelWatiTmp = () => {
		let obj = {
			id: state.currIntId,
			ti: state.currIntTi,
			tid: state.tempId
		}
		dispatch(integrationDucks.delWatiTmp(obj));
		let intObj = {
			dObj: {},
			newDObj: {},
		}
		changeNameValue(intObj);
		clearState();
		props.openCloseModal('deleteOpen', state.deleteOpen, 'close');
	}

	const handleupdateTmplIdStatus = (_ia, tid) => {
		let obj = {
			id: state.currIntId,
			tid: tid,
			ia: _ia
		}
		dispatch(integrationDucks.updateTmplIdInteraktStatus(obj));
	}

	const handleDel = () => {
		if (statewati.clickDel === "handleDelWati") {
			handleDelWati();
		}
		if (statewati.clickDel === "handleDelWatiTmp") {
			handleDelWatiTmp();
		}
	}

	const handleDelApiKey = () => {
		props.openCloseModal('deleteOpen', state.deleteOpen, 'open');
		changeNameValuewati({ 'clickDel': "handleDelWati" });
	}

	const handleSendMsgBtn = (val) => {
		handleSendMsg(statewati.sendWatiMessageOpen);
		changeNameValue({ 'tempId': val._id });
	}

	const watiFieldMapModel = (val) => {
		openWatiFieldMappModal(val);
		changeNameValue({ 'tempId': val._id });
	}

	const handleDelTmpList = (val) => {
		props.openCloseModal('deleteOpen', state.deleteOpen, 'open');
		changeNameValue({ 'tempId': val._id });
		changeNameValuewati({ 'clickDel': "handleDelWatiTmp" })
	}

	if (state.currUserInt && state.currUserInt.ia !== undefined) { //for isActive
		_ia = state.currUserInt.ia;
	}
	if (state.newDObj && Object.keys(state.newDObj).length <= 0) { //set active on create
		_ia = true;
	}

	const opt = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
	const option = [
		{ label: 'PRODUCT_NAME', value: 'PRODUCT_NAME' },
		{ label: 'PRODUCT_IMAGE', value: 'PRODUCT_IMAGE' },
		{ label: 'CUSTOMER_NAME', value: 'CUSTOMER_NAME' },
		{ label: 'STORE_NAME', value: 'STORE_NAME' },
		{ label: 'WRITE_REVIEW_URL', value: 'WRITE_REVIEW_URL' },
		{ label: 'DISCOUNT_CODE', value: 'DISCOUNT_CODE' },
		{ label: 'DISCOUNT_VALUE', value: 'DISCOUNT_VALUE' },
	];

	const addMappingField = () => {
		let obj = {
			id: state.currIntId,
			tid: statewati.watiIdField,
			mapObj: {
				value: statewati.watiFieldValue,
				name: statewati.watiFieldName,
				cdt: state.currUserInt.cdt
			}
		}
		dispatch(integrationDucks.updateWatiTemplateData(obj));
		changeNameValuewati({ 'watiFieldArray': obj.mapObj })
		clearState();
	}

	let queArr = [
		{
			field: 'firstTabCollapse',
			question: 'Why API key need?',
			answer: 'An API key is required to establish a connection between WiserReview and Wati.'
		},
		{
			field: 'secondTabCollapse',
			question: 'What is template and where you can find it?',
			answer: 'Wati have whatsapp message template we need template name for send message. you can select and map data from the dropdwon.'
		},
		{
			field: 'thirdTabCollapse',
			question: 'When we send message?',
			answer: 'Messages are sent when we receive events from shopify or through automation as per your settings, and when an incentive code is generated.'
		}
	];

	return (
		<Page
			title={<div><span>{(state.currMasterObj && state.currMasterObj.dn) && state.currMasterObj.dn} </span>
				{_ia === true ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">Active</Text></Badge>
					: <Badge><Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text></Badge>}</div>}
			subtitle={
				<Text tone='base' >
					{state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
				</Text>}
			backAction={{ onAction: () => props.handleBackToMain() }}>

			<Box paddingInline={800}>
				<BlockStack gap={400}>
					<Card>
						<BlockStack gap={200}>
							<Text variant='headingMd' as='p' fontWeight='semibold'>Wati setup</Text>
							<Text>Integrate your wati account to send email and collect reviews.</Text>
							{Object.keys(state.newDObj).length <= 0 ?
								<Box>
									<TextField
										label="Map your wati account"
										placeholder='Enter API key'
										value={state.dObj.key}
										onChange={(e) => handleTextChange('key', e)}
										autoComplete="off"
										error={state.errMessage.apiKey ? state.errMessage.apiKey : false} />
									{props.validator.message('apiKey', state.dObj.key, 'required')}

									<Box paddingBlockStart={400}>
										<InlineStack align='center'>
											<Button id='primary_btn' onClick={() => handleCreateWati(_ia)}>Create</Button>
										</InlineStack>
									</Box>
								</Box>
								:
								<Box>
									<Text variant="headingSm" as="h3">API key :</Text>
									<Box paddingBlockStart={200}>
										<TextField
											readOnly
											value={state.dObj.key}
											onFocus={(e) => e.target.select()}
											connectedRight={
												<Tooltip width='wide' padding="400" dismissOnMouseOut content='Delete'>
													<Button icon={DeleteIcon} variant='plain' onClick={() => handleDelApiKey()} />
												</Tooltip>
											} />
									</Box>
								</Box>
							}
						</BlockStack>
					</Card>

					{Object.keys(state.newDObj).length !== 0 &&
						<Card>
							<BlockStack gap={200}>
								<Text variant='headingMd' as='p' fontWeight='semibold'>Add template</Text>
								<TextField
									label="Name"
									placeholder='Enter Name'
									value={state.dObj.rmk}
									onChange={(e) => handleTextChange('rmk', e)}
									autoComplete="off"
									error={state.errMessage.Name ? state.errMessage.Name : false} />
								{props.validator.message('Name', state.dObj.rmk, 'required')}

								<Box paddingBlockStart={300}>
									<Select
										label="Template"
										placeholder="Select"
										options={statewati.watiTmpLists}
										onChange={(e) => handleTextChange('tmplid', e)}
										value={state.dObj.tmplid}
										error={state.errMessage.TemplateName ? state.errMessage.TemplateName : false}
									/>
									{props.validator.message('TemplateName', state.dObj.tmplid, 'required')}
								</Box>

								<Box paddingBlockStart={400}>
									<InlineStack align='center'>
										<Button id='primary_btn' onClick={() => handleAddWatiTmptId()}>Create</Button>
									</InlineStack>
								</Box>

							</BlockStack>
							{state.tempArry?.length > 0 &&
								<Box>
									<Box paddingBlockEnd={200}>
										<Text variant='headingSm' as='p' fontWeight='semibold'>Template list</Text>
									</Box>
									<Card padding={0}>
										<div id='sd_tbl'>
											<IndexTable
												itemCount={1}
												selectable={false}
												headings={[
													{ title: 'Name' },
													{ title: 'Template name' },
													{ title: 'Active' },
													{ title: 'Action' }
												]} >

												{state.tempArry && state.tempArry.map((val, index) =>
													<IndexTable.Row
														id={index}
														position={index}
														key={index}>
														<IndexTable.Cell>{val.rmk ? val.rmk : '-'}</IndexTable.Cell>
														<IndexTable.Cell>{val.tmplid ? val.tmplid : '-'}</IndexTable.Cell>
														<IndexTable.Cell>
															{/* <Box paddingBlockEnd={300}>
                                                        <label className="switch">
                                                            <input type="checkbox" checked={val.ia} onChange={(e) => handleupdateTmplIdStatus(e.target.checked, val._id)} />
                                                            <span className="switch-label"></span>
                                                            <span className="switch-handle"></span>
                                                        </label>
                                                    </Box> */}
															<Checkbox
																checked={val.ia}
																onChange={() => handleupdateTmplIdStatus(!val.ia, val._id)}
															/>
														</IndexTable.Cell>
														<IndexTable.Cell>
															<InlineStack gap={300} align='center' wrap={false}>
																<Tooltip width='wide' padding="400" dismissOnMouseOut content="Send">
																	<Button id='primary_btn' icon={SendIcon} onClick={() => handleSendMsgBtn(val)} />
																</Tooltip>
																<Tooltip width='wide' padding="400" dismissOnMouseOut content="Field Mapping">
																	<Button variant='secondary' icon={ConnectIcon} onClick={() => watiFieldMapModel(val)}></Button></Tooltip>
																<Tooltip width='wide' padding="400" dismissOnMouseOut content="Delete">
																	<Button variant="primary" tone="critical" icon={DeleteIcon} onClick={() => handleDelTmpList(val)}></Button>
																</Tooltip>
															</InlineStack>
														</IndexTable.Cell>
													</IndexTable.Row>
												)}
											</IndexTable>
										</div>
									</Card>
								</Box>
							}
						</Card>
					}
					<Card padding={0}>
						<Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
							<Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
						</Box>
						<Divider borderColor='border-tertiary' />
						<Box paddingInline={400} paddingBlockEnd={300}>

							{queArr && queArr.map((data, i) => {
								return (
									<div key={i} id='cursorPoint' onClick={() => changeNameValue({ tabEvent: data.field })}>
										<Box paddingBlock={300}>
											<InlineStack wrap={false} blockAlign='center' align='space-between'>
												<Text variant='headingSm' as='p' fontWeight='medium'>{data.question}</Text>
												<Box><Icon source={data.field === state.tabEvent ? ChevronUpIcon : ChevronDownIcon}></Icon></Box>
											</InlineStack>
											<Collapsible open={data.field === state.tabEvent} id="db_collaspe"
												transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
												<Box paddingBlockStart={100}>
													<Text variant='headingSm' as='p' fontWeight='regular' tone="subdued">{data.answer}</Text>
												</Box>
											</Collapsible>
										</Box>
										{i !== queArr.length - 1 && <Divider />}
									</div>
								)
							})}

						</Box>
					</Card>
				</BlockStack>
			</Box>

			<Modal
				size='large'
				open={statewati.watiMapOpen}
				title={<Text variant='headingMd' fontWeight='bold'>Field Mapping</Text>}
				noScroll
				onClose={() => changeNameValuewati({ 'watiMapOpen': false })}>
				<Modal.Section>
					<div className='inTe_wrapper'>
						<Text fontWeight='medium' variant='bodyLg' as='h3'>Do mapping between Wisernotify Review App & Webhook data:</Text>

						<Box paddingBlockStart={200} paddingBlockEnd={600}>
							<InlineGrid columns={3} gap={300} alignItems='start'>
								<Popover
									fullWidth
									preferredPosition='above'
									active={state.isWfKey}
									onClose={() => changeNameValue({ 'isWfKey': !state.isWfKey })}
									activator={
										<Button onClick={() => changeNameValue({ 'isWfKey': !state.isWfKey })} textAlign="left" fullWidth disclosure>{statewati.watiFieldValue ? statewati.watiFieldValue : '--Select Keyword--'}</Button>}>
									<OptionList
										options={option}
										selected={statewati.watiFieldValue}
										onChange={() => changeNameValue({ 'isWfKey': !state.isWfKey })}
										onFocusOption={(e) => changeNameValuewati({ 'watiFieldValue': e })} />
								</Popover>
								<Box>
									<Popover
										fullWidth
										preferredPosition='above'
										active={state.isCfkey}
										onClose={() => changeNameValue({ 'isCfkey': !state.isCfkey })}
										activator={
											<Button onClick={() => changeNameValue({ 'isCfkey': !state.isCfkey })} textAlign="left" fullWidth disclosure>{statewati.watiFieldName ? statewati.watiFieldName : '--Select Key/Value--'}</Button>}>
										<OptionList
											options={watiFieldOrG}
											selected={statewati.watiFieldName}
											onChange={() => changeNameValue({ 'isCfkey': !state.isCfkey })}
											onFocusOption={(e) => changeNameValuewati({ 'watiFieldName': e })} />
									</Popover>
									{state.keyValOpt && state.keyValOpt.length > 0 && <p id="sucess-data-rec-p">Data received</p>}
								</Box>
								<Button id='primary_btn' size='large' disabled={!statewati.watiFieldName || !statewati.watiFieldValue}
									onClick={() => addMappingField()}>Add</Button>
							</InlineGrid>
						</Box>

						<IndexTable
							itemCount={1}
							selectable={false}
							headings={[
								{ title: 'No.' },
								{ title: 'Key' },
								{ title: 'Value' },
								{ title: 'Create date' },
								{ title: 'Action' }
							]}>
							{statewati.watiFieldArray && statewati.watiFieldArray.length > 0 ?
								statewati.watiFieldArray.map((data, index) => (
									<IndexTable.Row
										id={data._id}
										key={index}
										position={index}
									>
										<IndexTable.Cell>{index + 1}</IndexTable.Cell>
										<IndexTable.Cell>{data[Object.keys(data)[0]]}</IndexTable.Cell>
										<IndexTable.Cell>{data[Object.keys(data)[1]]}</IndexTable.Cell>
										<IndexTable.Cell>
											{statewati.watiFieldArray[index].cdt ? (new Date(statewati.watiFieldArray[index].cdt)).toLocaleDateString("en-US", opt) : '-'}
										</IndexTable.Cell>
										<IndexTable.Cell>
											<Tooltip width='wide' padding="400" dismissOnMouseOut content="Delete mapping field">
												<Button variant="primary" tone="critical" icon={DeleteIcon}
													onClick={() => { handleDelWatiField(index); }} />
												{/* // props.delCustomField(Object.keys(data)[0]) */}
											</Tooltip>
										</IndexTable.Cell>
									</IndexTable.Row>
								))
								:
								<IndexTable.Row>
									<IndexTable.Cell colSpan='4' className="text-center">
										<Text fontWeight='medium' as='h6'>No record found</Text>
									</IndexTable.Cell>
								</IndexTable.Row>
							}
						</IndexTable>
					</div>
				</Modal.Section>
			</Modal>
			<Modal
				size='small'
				open={statewati.sendWatiMessageOpen}
				title={<Text variant='headingMd' fontWeight='bold'>Send Message</Text>}
				onClose={() => handleSendMsg(statewati.sendWatiMessageOpen)}>
				<Modal.Section>
					<TextField
						label="Number (with dial code)"
						type='number'
						value={state.dObj.number}
						onChange={(e) => handleTextChange('number', e)}
					/>
					<PageActions
						primaryAction={{
							id: 'primary_btn',
							content: 'Send',
							onAction: () => handleSendWatiMessage()
						}}
					/>
				</Modal.Section>
			</Modal>
			<Modal
				size='small'
				open={state.deleteOpen}
				title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
				onClose={(e) => props.openCloseModal('deleteOpen', state.deleteOpen, e)}>
				<Modal.Section>
					<div className="pageAction_paDDing">
						<Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this?</Text>
						<PageActions
							primaryAction={{
								id: 'primary_btn',
								content: 'Yes',
								onAction: () => handleDel()
							}}
							secondaryActions={[{
								content: 'No',
								onAction: () => props.openCloseModal('deleteOpen', state.deleteOpen, 'close')
							}]} />
					</div>
				</Modal.Section>
			</Modal>
		</Page >
	);
}

export default Wati;