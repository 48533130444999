import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const customer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomer: (state, action) => { },
    getCustomerSuccess: (state, action) => {
      return { ...state, customerList: action.payload };
    }
  }
});

export const {
  getCustomer,
  getCustomerSuccess
} = customer.actions;

export default customer.reducer;