import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/product';
import { toastify } from '../ducks/toast';

function* getProduct(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getProductData', action.payload);
    if (res.status === 'success') {
      yield put(actions.getProductSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getReviewByProduct(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getReviewByProduct', action.payload);
    if (res.status === 'success') {
      yield put(actions.getReviewByProductSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updProductDetail(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updProductDetail', action.payload);
    if (res.status === 'success') {
      yield put(actions.getProduct());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetProduct() {
  yield takeLatest(actions.getProduct.type, getProduct);
}
export function* watchGetReviewByProduct() {
  yield takeLatest(actions.getReviewByProduct.type, getReviewByProduct);
}
export function* watchUpdProductDetail() {
  yield takeLatest(actions.updProductDetail.type, updProductDetail);
}

export default function* rootSaga() {
  yield fork(watchGetProduct);
  yield fork(watchGetReviewByProduct);
  yield fork(watchUpdProductDetail);
}