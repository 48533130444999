import { Page, Box, Text, Layout, BlockStack, Divider, InlineStack, Button, Card, ButtonGroup, IndexFilters, useSetIndexFiltersMode, IndexFiltersMode, IndexTable, useIndexResourceState, Modal, ResourceList, ResourceItem, Filters, Badge, List, Spinner } from '@shopify/polaris';
import { reset_icon } from '../../img';

function UgcGrid(props) {
  const { state, fbInstaIntegration, InstaData, changeNameValue } = props;

  const instaUgcGrid = fbInstaIntegration && fbInstaIntegration.d && fbInstaIntegration.d.insta_page ? fbInstaIntegration.d.insta_page : [];

  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);

  const { selectedResources, handleSelectionChange } = useIndexResourceState(InstaData);

  const sortOptions = [
    { label: 'Date', value: 'pubdt asc', directionLabel: 'Ascending' },
    { label: 'Date', value: 'pubdt desc', directionLabel: 'Descending' },
  ];

  const opt = { day: 'numeric', month: 'short', year: 'numeric' };

  const handleClearButtonClick = () => {
    changeNameValue({ 'queryValue': '' });
  }

  const filterControladd = (
    <div className="filter-container">
      <div className="filter-wrapper">
        <Filters
          focused
          queryValue={state.ugcSearch}
          queryPlaceholder='Search Product'
          onQueryChange={(e) => changeNameValue({ 'ugcSearch': e })}
          filters={[]}
          appliedFilters={[]}
          onQueryClear={() => props.changeNameValue({ 'ugcSearch': '' })}
        />
      </div>
      <Button onClick={() => props.handleProductSave()}>save</Button>
    </div>
  );

  const filterControlUpdt = (
    <div className="filter-container">
      <div className="filter-wrapper">
        <Filters
          focused
          queryValue={state.ugcUpdtSearch}
          queryPlaceholder='Search Product'
          onQueryChange={(e) => props.changeNameValue({ 'ugcUpdtSearch': e })}
          filters={[]}
          appliedFilters={[]}
          onQueryClear={() => props.changeNameValue({ 'ugcUpdtSearch': '' })}
        />
      </div>
      <Button onClick={() => props.handleProductUpdate()}>Update</Button>
    </div>
  );

  return (
    <div className='brand_pge'>
      <Page
        title={"UGC Feed"} backAction={{ onAction: () => props.handleBackToMain() }}
        secondaryActions={
          <ButtonGroup>
            <Button variant='plain' onClick={() => props.accountConnect()}>Account Connection</Button>
            <Button id='primary_btn' onClick={() => props.custWidget()}>Customize Widget</Button>
          </ButtonGroup>
        }
      >

        <Box paddingBlockStart={400} paddingInlineStart={100}>
          <BlockStack gap={600}>
            <div id='ugc_layout'>
              <Layout>
                <Layout.Section variant="oneThird">
                  <BlockStack gap={200}>
                    <Text variant="headingMd" as="h2" fontWeight='semibold'>Pull Instagram Feed</Text>
                    <Text variant='headingSm' as="p" fontWeight='regular'>
                      Select the Instagram content you wish to feature on your store and tag relevant products to make your feed shoppable.
                    </Text>
                  </BlockStack>
                </Layout.Section>
                <Layout.Section>
                  <Card padding={0}>
                    <Box padding={400}>
                      <Box>
                        <Text variant='headingMd' as='h6'>Connection Status</Text>
                      </Box>
                      <Box paddingInlineStart={200} paddingBlockStart={400}>
                        <List>
                          <List.Item>
                            {instaUgcGrid.length > 0 && instaUgcGrid.some(account => account.name) ? (
                              <List.Item>
                                Connected as{' '}
                                {instaUgcGrid.map((account, index) => (
                                  <span key={account.name}>
                                    <a href={`https://www.instagram.com/${account.name}`} target="_blank" rel="noreferrer">{`@${account.name}`}</a>
                                    {index < instaUgcGrid.length - 1 && ', '}
                                  </span>
                                ))}. Ready to bring social buzz right into your store.
                              </List.Item>
                            ) : (
                              <List.Item>
                                Connected as <a href='https://www.instagram.com' target="_blank" rel="noreferrer">@WiserNotify</a>. Ready to bring social buzz right into your store.
                              </List.Item>
                            )}
                          </List.Item>

                          <List.Item>Adjust the look and feel of your widget from here.</List.Item>
                          <List.Item>For account settings or to switch users, visit Account connection.</List.Item>
                        </List>
                      </Box>

                      <Box paddingBlockStart={400} paddingBlockEnd={400}><Divider /></Box>

                      <Box>
                        <Text variant='headingMd' as='h6'>Feed Management</Text>
                      </Box>
                      <Box paddingInlineStart={200} paddingBlockStart={400}>
                        <List>
                          <List.Item>Use the search to find specific posts or sort by Most Recent to keep your content up-to-date.</List.Item>
                          <List.Item>Click 'Publish' to add Instagram posts on your store.</List.Item>
                          <List.Item>Tag your products in posts to turn your feed into an interactive shopping experience.</List.Item>

                        </List>
                      </Box>

                      <Box paddingBlockStart={400} paddingBlockEnd={400}><Divider /></Box>


                      <div id='ugc_filter'>
                        <InlineStack align="space-between">
                          <IndexFilters
                            sortOptions={sortOptions}
                            sortSelected={state.sortSelected}
                            queryValue={state.queryValue}
                            queryPlaceholder="Search"
                            onQueryChange={(value) => changeNameValue({ 'queryValue': value })}
                            onQueryClear={handleClearButtonClick}
                            onSort={(e) => changeNameValue({ 'sortSelected': e })}
                            tabs={[]}
                            filters={[]}
                            mode={mode}
                            setMode={setMode}
                          />
                          <Box>
                            <Button onClick={() => props.refreshPost()}><img src={reset_icon} alt='reset' /></Button>
                          </Box>
                        </InlineStack>
                      </div>
                    </Box>

                    <div id='ugctbl'>
                      <IndexTable
                        itemCount={InstaData ? InstaData.length : 0}
                        selectedItemsCount={selectedResources.length}
                        onSelectionChange={handleSelectionChange}
                        selectable={false}
                        headings={[
                          { title: '' },
                        ]}

                        pagination={{
                          hasPrevious: state.paggiActive > 1 ? true : false,
                          hasNext: (state.paggiActive < props.pageNumber) ? true : false,
                          onPrevious: () => props.onPaggiCall('-1'),
                          onNext: () => props.onPaggiCall('+1')
                        }} >

                        {InstaData && InstaData.length > 0 && InstaData.map((data, index) => {
                          return (
                            <IndexTable.Row
                              id={data._id}
                              key={index}
                              position={index}
                              onClick={() => changeNameValue('', '')}
                            >
                              <IndexTable.Cell>
                                <Box padding={400} key={index}>
                                  <InlineStack gap={200}>
                                      <a href={`https://www.instagram.com/${data.pgnm}`} target="_blank" rel="noreferrer">
                                        <Button variant='plain'>{`@${data.pgnm}`}</Button>
                                      </a> |
                                    <Box>
                                      <Text variant="bodyXs" tone='subdued' fontWeight="medium" as="span">
                                        {(new Date(data.pubdt)).toLocaleDateString("en-US", opt)}
                                      </Text>
                                    </Box>

                                  </InlineStack>
                                  <div className='UGC-post-text'>
                                    <Text>{data.psttxt ? data.psttxt : 'No Caption Available'}</Text>
                                  </div>
                                  <Box paddingBlockStart={200}>
                                    <InlineStack blockAlign='center' align='space-between'>
                                      <InlineStack>
                                        <Box>
                                          <img src={data.imgurl} alt='' className='instapost_img' />
                                        </Box>
                                        <Box paddingInlineStart={500}>
                                          <ButtonGroup>
                                            {data.sts === 1 ? (
                                              <>
                                                <Box>
                                                  <InlineStack gap={100}>
                                                    <Badge tone="success">Published</Badge>
                                                    <Button variant='plain' onClick={() => props.publishPost(0, data._id)}>Unpublish</Button>
                                                  </InlineStack>
                                                </Box>
                                              </>
                                            ) : (
                                              <>
                                                <Button id='primary_btn' onClick={() => props.publishPost(1, data._id)}>Publish</Button>
                                              </>
                                            )}
                                          </ButtonGroup>
                                        </Box>
                                        {data.sts === 1 && (
                                          <Box paddingInlineStart={500}>
                                            <Button variant='plain' onClick={() => props.productupdtModal(data.pstid, data.arrpid, data._id, 'ugcEditOpen', state.ugcEditOpen, 'open')}> {data.arrpid.length > 0 && `${data.arrpid.length} Product tagged`}</Button>
                                          </Box>
                                        )}
                                      </InlineStack>
                                      <Box>
                                        {data.sts === 1 ? (
                                          <Button variant='plain' onClick={() => { props.addProduct(data.pstid, data.arrpid, 'ugcViewProOpen', state.ugcViewProOpen, 'open'); }}>Add Product</Button>
                                        ) : null}
                                      </Box>
                                    </InlineStack>
                                  </Box>
                                </Box>
                              </IndexTable.Cell>
                            </IndexTable.Row>
                          );
                        })}
                      </IndexTable>
                    </div>
                  </Card>
                </Layout.Section>
              </Layout>
            </div>
          </BlockStack>
        </Box>

        <Modal // Add Product
          open={state.ugcViewProOpen}
          limitHeight={true}
          noScroll={true}
          onClose={(e) => props.openCloseModal('ugcViewProOpen', state.ugcViewProOpen, 'close', e)}
          title={"Product List"}>

          <Modal.Section>
            <div className='groupProList'>
              {!state.isLoading ? (
                <ResourceList
                  selectable
                  // showHeader={false}
                  headerContent={`Showing ${state.ugcProdOptions.length} Products`}
                  totalItemsCount={state.createProSelectedItem.length}
                  selectedItems={state.createProSelectedItem}
                  onSelectionChange={(e) => { props.selectProdcuts('createProSelectedItem', e) }}
                  items={state.ugcProdOptions}
                  filterControl={filterControladd}
                  flushFilters
                  pagination={{
                    hasPrevious: (state.hasPreviousPage === true) ? true : false,
                    hasNext: (state.hasNextPage === true) ? true : false,
                    onNext: () => props.groupPagiCall('+1'),
                    onPrevious: () => props.groupPagiCall('-1')
                  }}
                  renderItem={(item) => {
                    return (
                      <ResourceItem
                        id={item.id}
                        media={item.img}
                        verticalAlignment='center'
                        onClick={() => {
                          props.selectProdcuts(
                            'createProSelectedItem',
                            [...state.createProSelectedItem, item.id]
                          )
                        }}>
                        <Text variant="bodyMd" fontWeight="bold" as="h3">{item.name}</Text>
                      </ResourceItem>
                    );
                  }} />
              ) :
                (
                  <InlineStack align='center'>
                    <Spinner accessibilityLabel="Spinner example" size="large" />
                  </InlineStack>
                )}
            </div>

          </Modal.Section>
        </Modal>

        <Modal // Update a Ugc Modal
          open={state.ugcEditOpen}
          limitHeight={true}
          noScroll={true}
          onClose={(e) => props.openCloseModal('ugcEditOpen', state.ugcEditOpen, 'close', e)}
          title={"Products tagged"}>

          <Modal.Section>
            <div className='groupProList'>
              {!state.isLoading ? (
                <ResourceList
                  selectable
                  headerContent={`${state.ugcUpdateOptions.length} Products tagged`}
                  totalItemsCount={state.updtProSelectedItem.length}
                  onSelectionChange={(e) => { props.selectUpdtProdcuts('updtProSelectedItem', e) }}
                  selectedItems={state.updtProSelectedItem}
                  items={state.ugcUpdateOptions}
                  flushFilters
                  filterControl={filterControlUpdt}
                  pagination={{
                    hasPrevious: (state.hasPreviousPage === true) ? true : false,
                    hasNext: (state.hasNextPage === true) ? true : false,
                    onNext: () => props.groupPagiCall('+1'),
                    onPrevious: () => props.groupPagiCall('-1')
                  }}
                  renderItem={(data) => {
                    return (
                      <ResourceItem
                        id={data.id}
                        media={data.img}
                        verticalAlignment='center'
                        onClick={() => {
                          props.selectUpdtProdcuts(
                            'updtProSelectedItem',
                            [...state.updtProSelectedItem, data.id]
                          )
                        }}>
                        <Text variant="bodyMd" fontWeight="bold" as="h3">{data.name}</Text>
                      </ResourceItem>
                    );
                  }} />
              ) :
                (
                  <InlineStack align='center'>
                    <Spinner accessibilityLabel="Spinner example" size="large" />
                  </InlineStack>
                )}

            </div>

          </Modal.Section>
        </Modal>
      </Page>
    </div >
  );
}

export default UgcGrid;