import { Button, Card, SkeletonBodyText, ButtonGroup, Text, Modal, Badge, Tooltip, InlineStack, Box } from '@shopify/polaris';
import TriggerCard from './TriggerCard';
import FilterCard from './FilterCard';
import TemplateCard from './TemplateCard';
import HistoryCard from './HistoryCard';
import { useEffect } from 'react';

const getDate = (dt) => {
  return dt.substr(dt.indexOf(' ') + 1);
}

const AllCards = (props) => {
  let { state } = props;

  useEffect(() => {
    let isCnfmLnchCmp = true;

    if (state.ProfileObj && state.ProfileObj.ulq >= state.CampgnCount)
      isCnfmLnchCmp = false; //enable cta if count user.ulq is greater than count
    else
      isCnfmLnchCmp = true;

    props.changeNameValue({ isCnfmLnchCmp });
  }, [state.ProfileObj, state.CampgnCount]);

  return (
    <Box paddingBlockEnd={600}>
      <Card padding={600}>
        <Box paddingBlockEnd={600}>

          {/* header section (automation on/off active) */}
          {props.state.emailTyp !== 'one_time_campaign' ?
            <div>
              {(state.selSeqName !== '' || state.subTtl !== '') ?
                <InlineStack align='space-between' blockAlign='start' gap={400}>
                  <InlineStack gap={100} direction={'column'}>
                    <InlineStack gap={200}>
                      <Text variant='bodyLg' fontWeight='medium' as='h4'>{state.selSeqName}</Text>
                      {props.state.ia === true ?
                        <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">On</Text></Badge>
                        : <Badge tone='enabled'><Text variant="bodySm" fontWeight="medium" as="span">Off</Text></Badge>}
                    </InlineStack>
                    <Text variant='bodySm' fontWeight='regular' as='p' tone='subdued'>{state.subTtl}</Text>
                  </InlineStack>
                  <Button id={props.state.ia === true ? '' : 'primary_btn'}
                    variant={props.state.ia === true ? 'primary' : ''}
                    tone={props.state.ia === true ? 'critical' : ''}
                    onClick={() => props.updConfigData({ ia: !props.state.ia }, 'main')}>
                    {props.state.ia === true ? 'Turn off automation' : 'Turn on automation'}</Button>
                </InlineStack>
                :
                <InlineStack align='space-between' blockAlign='start' gap={200}>
                  <SkeletonBodyText lines={2} /> <SkeletonBodyText lines={1} />
                </InlineStack>
              }
            </div>
            :  //onetimecampagin's 'launchCampaign' section
            <InlineStack align='space-between' blockAlign='start' gap={300}>
              <InlineStack gap={100} direction={'column'}>
                <Text variant='bodyLg' fontWeight='medium' as='h4'>{state.selSeqName}</Text>
                <Text variant='bodySm' fontWeight='regular' as='p' tone='subdued'>{state.subTtl}</Text>
              </InlineStack>

              {localStorage.getItem('csub') === 'free' ?
                <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                  <Button disabled id='primary_btn'>Launch campaign</Button>
                </Tooltip>
                :
                <Button id='primary_btn'
                  onClick={() => props.handleLaunchCampClk()}>Launch campaign</Button>
              }
            </InlineStack>
          }
        </Box>

        <TriggerCard //firstBlock (request days)
          props={props} />

        <FilterCard //secondBlock (add condition)
          props={props} />

        <TemplateCard //showing emailTemplates & integration
          props={props} />

        {props.state.emailTyp === 'one_time_campaign' &&
          <HistoryCard //history only for one_time_campaign
            props={props} />
        }

      </Card >

      <Modal //send test email model
        open={state.launchOpen}
        onClose={(e) => props.openCloseModal('launchOpen', state.launchOpen, e)}
        title={<Text variant='headingMd' fontWeight='bold'>Are you sure?</Text>}>
        <Modal.Section>
          <Box paddingBlockEnd={600}>

            {/* <Text>This will target all customers who've made a purchase between <strong>{getDate(state.frmdt)}</strong> and <strong>{getDate(state.tdt)}</strong> and ask them to leave a review.</Text> */}

            {state.isCmpgnLoad === false ?
              state.ProfileObj &&
                (state.CampgnCount > state.ProfileObj.ulq) ?
                <Text>You received {state.CampgnCount} orders within the selected duration, and you only have {state.ProfileObj && state.ProfileObj.ulq}quotas left. To launch the campaign, kindly upgrade your plan or adjust the duration.<br /><br />   Selected date : {getDate(state.frmdt)} to {getDate(state.tdt)}.</Text>
                // <Text> {state.CampgnCount} emails cannot be sent because your remaining quota is {state.ProfileObj && state.ProfileObj.ulq}, which is not enough.<br /><br />   Selected date : {getDate(state.frmdt)} to {getDate(state.tdt)}.</Text>
                :
                state.CampgnCount > 0 ?
                  <Text> {state.CampgnCount} quota will be deducted from your remaining {state.ProfileObj && state.ProfileObj.ulq} quota. <br /><br />   Selected date : {getDate(state.frmdt)} to {getDate(state.tdt)}.</Text>
                  :
                  <Text>No orders is available for the selected date: {getDate(state.frmdt)} to {getDate(state.tdt)}.</Text>
              :
              <SkeletonBodyText lines={2} />
            }

          </Box>
          <InlineStack align='end'>
            <ButtonGroup>
              <Button onClick={() => props.openCloseModal('launchOpen', state.launchOpen, 'close')}>Cancel</Button>
              <Button id='primary_btn' disabled={state.isCnfmLnchCmp} onClick={() => props.launchCampaign()}>
                Yes, launch campaign</Button>
            </ButtonGroup>
          </InlineStack>
        </Modal.Section>
      </Modal>

    </Box >
  );
}

export default AllCards;