import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const master = createSlice({
  name: 'master',
  initialState,
  reducers: {
    getMasterData: (state, action) => { },
    getMasterDataSuccess: (state, action) => {
      return { ...state, MasterData: action.payload }
    }
  }
});

export const {
  getMasterData,
  getMasterDataSuccess
} = master.actions;

export default master.reducer;