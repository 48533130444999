import { Text, Button, InlineStack, InlineGrid, Box, BlockStack } from '@shopify/polaris';
import { wiser, toggle_bg, star_bg, shape_icon } from '../../img';

const BoardingStep = ({ props }) => {
  return (
    <InlineGrid gap="800">
      <div className='border-right'>
        <div className='onboard-started'>
          <InlineStack gap={800}>
            <Text variant="headingXl" as="h3" fontWeight='medium'>Let’s Get started</Text>
            <Box maxWidth='90%'>
              <Text as="h3" variant='headingMd' fontWeight='regular'>We're thrilled to have you on board. It'll only take about 5 minutes to empower your store with the voice of your customers.</Text>
            </Box>
          </InlineStack>
          <div className='Steps1_img'>
            <img src={wiser} alt='wiser' width="250px" />
          </div>
        </div>
      </div>

      <InlineStack align='end' blockAlign='end'>
        <InlineStack align='start' blockAlign='center' gap={1000}>
          <Text variant="headingLg" as="h5" fontWeight='medium'>Here's what we'll do:</Text>
          <Box maxWidth='85%'>
            <BlockStack gap="1000">
              <InlineStack wrap={false} blockAlign='center' gap={500}>
                <img src={toggle_bg} alt='toggle' />
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='medium'>Enable WiserReview in Your Store</Text>
                  <Text variant="bodyLg" as="p" fontWeight='regular' tone="subdued">A quick setup to integrate our app seamlessly with your store</Text>
                </BlockStack>
              </InlineStack>
              <InlineStack wrap={false} blockAlign='center' gap={500}>
                <img src={star_bg} alt='star' />
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='medium'>Showcase Product Reviews and Star Ratings</Text>
                  <Text variant="bodyLg" as="p" fontWeight='regular' tone="subdued">Display a dedicated section for reviews and an eye-catching star rating on your product pages to build trust.</Text>
                </BlockStack>
              </InlineStack>
              <InlineStack wrap={false} blockAlign='center' gap={500}>
                <img src={shape_icon} alt='branding' />
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='medium'>Set your branding & start collecting reviews</Text>
                  <Text variant="bodyLg" as="p" fontWeight='regular' tone="subdued">Align the look of your reviews with your brand identity and start automatically collecting customer reviews.</Text>
                </BlockStack>
              </InlineStack>
            </BlockStack>
          </Box>
        </InlineStack>
        <Button variant='primary' onClick={() => props.handleNextStep('step1', 'Start')}>Continue</Button>
      </InlineStack>

    </InlineGrid>
  );
}

export default BoardingStep;