import { Text, Popover, OptionList, Tooltip, Checkbox, Card, InlineGrid, Button, Box, InlineStack, Thumbnail, TextField, Select } from '@shopify/polaris';
import { ThemeTemplateIcon, StarIcon, OrderUnfulfilledIcon, ButtonIcon, EyeCheckMarkIcon, ImageWithTextOverlayIcon, CodeIcon, LogoGoogleIcon, LanguageIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const dateOpt = [
  { label: 'mm-dd-yyyy', value: 'mm-dd-yyyy' },
  { label: 'dd-mm-yyyy', value: 'dd-mm-yyyy' }
];

const nameOpt = [
  { label: 'Jenny Karl', value: 0 },
  { label: 'J. karl', value: 1 },
  { label: 'Jenny K.', value: 2 },
  { label: 'J. K.', value: 3 }
];

const btnPosOpt = [
  { label: 'Right side', value: 'center_right' },
  { label: 'Left side', value: 'center_left' }
];

const TextPopupWdgt = ({ props }) => {
  let { state, handleTextChange, changeNameValue } = props;
  let cnf = state.cnf;

  let verticalTabsArr = [
    { title: 'Popup configuration', img: ButtonIcon },
    { title: 'Theme layouts', img: ThemeTemplateIcon },
    { title: 'Reviewer cards', img: ImageWithTextOverlayIcon },
    { title: 'Color combination', img: EyeCheckMarkIcon },
    { title: 'Add custom css', img: CodeIcon },
    { title: 'Language', img: LanguageIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  const getLabel = (val) => {
    let _lbl = <Text fontWeight='medium' tone='subdued'>Select</Text>;
    if (val) btnPosOpt.map((x) => { if (x.value === val) _lbl = x.label; });
    return _lbl;
  }

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <div>

          {cnf && cnf.sdctabtnpstn !== undefined &&
            <Box>
              <Text fontWeight='medium' as='span'>Popup position</Text>
              <Box paddingBlockStart={100}>
                <Popover
                  fullWidth
                  active={state.isBtnPos}
                  onClose={() => changeNameValue({ isBtnPos: !state.isBtnPos })}
                  activator={<Button textAlign="left" fullWidth disclosure="select"
                    onClick={() => changeNameValue({ isBtnPos: !state.isBtnPos })}>
                    <Text variant='bodyMd' tone='subdued'>
                      {getLabel(cnf.sdctabtnpstn)}
                    </Text>
                  </Button>}>
                  <OptionList
                    options={btnPosOpt}
                    selected={cnf.sdctabtnpstn}
                    onChange={() => changeNameValue({ isBtnPos: !state.isBtnPos })}
                    onFocusOption={(e) => handleTextChange({ sdctabtnpstn: e })} />
                </Popover>
              </Box>
            </Box>
          }

          {cnf && cnf.brdrstl !== undefined &&
            <Box paddingBlock={500}>
              <TextField
                type='Number'
                suffix='px'
                label={<Text fontWeight='medium' as='span'>Border radius</Text>}
                value={cnf.brdrstl}
                onChange={(e) => handleTextChange({ brdrstl: e })}
                autoComplete="off" />
            </Box>
          }

          {cnf && cnf.intdly !== undefined &&
            <TextField
              type='Number'
              suffix='seconds'
              label={<Text fontWeight='medium' as='span'>Initial delay</Text>}
              value={cnf.intdly}
              onChange={(e) => handleTextChange({ intdly: e })}
              autoComplete="off" />
          }

          {cnf && cnf.dsptm !== undefined &&
            <Box paddingBlock={500}>
              <TextField
                type='Number'
                suffix='seconds'
                label={<Text fontWeight='medium' as='span'>Pop-up duration</Text>}
                value={cnf.dsptm}
                onChange={(e) => handleTextChange({ dsptm: e })}
                autoComplete="off" />
            </Box>
          }

          {cnf && cnf.dlybtwn !== undefined &&
            <TextField
              type='Number'
              suffix='seconds'
              label={<Text fontWeight='medium' as='span'>Delay between pop-ups</Text>}
              value={cnf.dlybtwn}
              onChange={(e) => handleTextChange({ dlybtwn: e })}
              autoComplete="off" />
          }

          {cnf && cnf.sdctabtnhdmbl !== undefined &&
            <Box paddingBlock={500}>
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Hide in mobile</Text>}
                checked={cnf.sdctabtnhdmbl}
                onChange={(e) => handleTextChange({ sdctabtnhdmbl: e })}
              />
            </Box>
          }

        </div>
        :
        state.sidebarActiveIndex === 1 ?
          <div>
            <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1 }} gap={300}>
              {state.mainLayoutArr && state.mainLayoutArr.length > 0 && state.mainLayoutArr.map((val, i) => {
                return (
                  <div key={i} className={val._id === state.currLayoutId ? 'select_block active' : 'select_block'}
                    onClick={() => changeNameValue({ currLayoutId: val._id }, 'isEditable')}>
                    <InlineStack align='center' blockAlign='center'>
                      <Checkbox tone={val._id === state.currLayoutId ? 'magic' : ''}
                        checked={val._id === state.currLayoutId} />
                      <Text id='text_capitalize' fontWeight='medium' alignment='center'>{val.dn}</Text>
                    </InlineStack>
                    <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                  </div>
                );
              })}
            </InlineGrid>

          </div>
          :
          state.sidebarActiveIndex === 2 ?
            <Box>
              <InlineGrid columns={2} gap={300}>
                {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
                  return (
                    <div key={i} className={val._id === state.currCardId ? 'select_block active' : 'select_block'}
                      onClick={() => changeNameValue({ currCardId: val._id }, 'isEditable')}>
                      <InlineStack align='center' blockAlign='center'>
                        <Checkbox tone={val._id === state.currCardId ? 'magic' : ''}
                          checked={val._id === state.currCardId} />
                        <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                          {val.dn}</Text>
                      </InlineStack>
                      <Thumbnail source={val.iu} size="large" alt="Layout" />
                    </div>
                  );
                })}
              </InlineGrid>

              {cnf && cnf.crdirvwnm !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show reviewer name</Text>}
                    checked={cnf.crdirvwnm}
                    onChange={(e) => handleTextChange({ crdirvwnm: e })}
                  />
                  <Box paddingBlockStart={200} paddingInlineStart={600}>
                    <Text as='span'>Select name format</Text>
                    <Box paddingBlockStart={100}>
                      {cnf.crdirvwnm !== true ?
                        <TextField value={nameOpt[cnf.crdrvwnmfrmt].label} disabled={!cnf.crdirvwnm} />
                        :
                        <Popover
                          fullWidth
                          active={state.isNmFrmt}
                          onClose={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                          activator={<Button textAlign="left" fullWidth disclosure
                            onClick={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {nameOpt.find(({ value }) => value === cnf.crdrvwnmfrmt)?.label || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={nameOpt}
                            selected={[cnf.crdrvwnmfrmt]}
                            onChange={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                            onFocusOption={(e) => handleTextChange({ crdrvwnmfrmt: Number(e) })} />
                        </Popover>
                      }
                    </Box>
                  </Box>
                </Box>
              }

              {cnf && cnf.crdittl !== undefined &&
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show main text title</Text>}
                  checked={cnf.crdittl}
                  onChange={(e) => handleTextChange({ crdittl: e })}
                />
              }

              {cnf && cnf.crdidt !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show review date</Text>}
                    checked={cnf.crdidt}
                    onChange={(e) => handleTextChange({ crdidt: e })}
                  />
                  <Box paddingBlockStart={200} paddingInlineStart={600}>
                    <Text as='span'>Select date format</Text>
                    <Box paddingBlockStart={100}>
                      {cnf.crdidt !== true ?
                        <TextField value={cnf?.crddtfrmt || 'Select'} disabled={true} />
                        :
                        <Popover
                          fullWidth
                          active={state.isDtFrmt}
                          onClose={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                          activator={<Button textAlign="left" fullWidth disclosure
                            onClick={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {cnf?.crddtfrmt || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={dateOpt}
                            selected={cnf.crddtfrmt}
                            onChange={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                            onFocusOption={(e) => handleTextChange({ crddtfrmt: e })} />
                        </Popover>
                      }
                    </Box>

                  </Box>
                </Box>
              }

              {cnf && cnf.crdirdmr !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show trim content</Text>}
                    checked={cnf.crdirdmr}
                    onChange={(e) => handleTextChange({ crdirdmr: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.crdirdmr}
                      onChange={(e) => handleTextChange({ crdrdmrtxt: e })}
                      value={cnf.crdrdmrtxt} />
                  </Box>
                </Box>
              }

              {cnf && cnf.ivrfd !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show verified badge</Text>}
                    checked={cnf.ivrfd}
                    onChange={(e) => handleTextChange({ ivrfd: e })}
                  />
                  <Box paddingBlockStart={400}>
                    <Checkbox
                      disabled={!cnf.ivrfd}
                      label={<Text fontWeight='medium' as='span'>Show verified text on hover</Text>}
                      checked={cnf.ivrfdtxthvr}
                      onChange={(e) => handleTextChange({ ivrfdtxthvr: e })}
                    />
                    <Box paddingBlockStart={100} paddingInlineStart={600}>
                      <TextField
                        disabled={!cnf.ivrfd}
                        onChange={(e) => handleTextChange({ vrfdtxt: e })}
                        value={cnf.vrfdtxt} />
                    </Box>
                  </Box>
                </Box>
              }

              {cnf && cnf.crdilctn !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show location</Text>}
                    checked={cnf.crdilctn}
                    onChange={(e) => handleTextChange({ crdilctn: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.crdilctn}
                      onChange={(e) => handleTextChange({ crdlctnstr: e })}
                      value={cnf.crdlctnstr}
                    />
                  </Box>
                </Box>
              }

            </Box>
            :
            state.sidebarActiveIndex === 3 ?
              <Box>

                <Text variant="bodyMd" fontWeight='bold'>Reviewer card</Text>
                {cnf &&
                  <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                    <InlineStack direction={'column'} gap={500}>
                      {cnf.crdbgclr !== undefined &&
                        <ColorPick
                          labelName='Background color'
                          colorKey="crdbgclr"
                          colorValue={cnf.crdbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdstrclr !== undefined &&
                        <ColorPick
                          labelName='Star color'
                          colorKey="crdstrclr"
                          colorValue={cnf.crdstrclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crddtclr !== undefined &&
                        <ColorPick
                          labelName='Date color'
                          colorKey="crddtclr"
                          colorValue={cnf.crddtclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdrvwnmclr !== undefined &&
                        <ColorPick
                          labelName='Name color'
                          colorKey="crdrvwnmclr"
                          colorValue={cnf.crdrvwnmclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vrfdtxtclr !== undefined &&
                        <ColorPick
                          labelName='Verified text color'
                          colorKey="vrfdtxtclr"
                          colorValue={cnf.vrfdtxtclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vrfdbgclr !== undefined &&
                        <ColorPick
                          labelName='Verified bg color'
                          colorKey="vrfdbgclr"
                          colorValue={cnf.vrfdbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdlctnstrclr !== undefined &&
                        <ColorPick
                          labelName='Location label text color'
                          colorKey="crdlctnstrclr"
                          colorValue={cnf.crdlctnstrclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdttlclr !== undefined &&
                        <ColorPick
                          labelName='Review title color'
                          colorKey="crdttlclr"
                          colorValue={cnf.crdttlclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdtxtclr !== undefined &&
                        <ColorPick
                          labelName='Review text color'
                          colorKey="crdtxtclr"
                          colorValue={cnf.crdtxtclr}
                          changeNameValue={handleTextChange} />
                      }
                    </InlineStack>
                  </Box>
                }

              </Box>
              :
              state.sidebarActiveIndex === 4 ?
                <Box>

                  {cnf && cnf.icstcss !== undefined &&
                    <Box>
                      <Checkbox
                        label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                        checked={cnf.icstcss}
                        onChange={(e) => handleTextChange({ icstcss: e })}
                      />
                      <Box paddingBlockStart={200} paddingInlineStart={600}>
                        <TextField
                          label={<Text tone='subdued' as='span'>Custom css block</Text>}
                          disabled={!cnf.icstcss}
                          multiline={4}
                          value={cnf.cstcss}
                          onChange={(e) => handleTextChange({ cstcss: e })}
                          autoComplete="off" />
                      </Box>
                    </Box>
                  }

                </Box>
                :
                state.sidebarActiveIndex === 5 &&
                <Box>
                  {cnf && cnf.lng !== undefined &&
                    <Card>
                      <Select
                        label="Select language"
                        options={state.lngOptions ? state.lngOptions : []}
                        onChange={props.handleLanguageChange}
                        value={cnf.lng}
                      />
                    </Card>
                  }
                </Box>
      }
    </Box >
  );
}

export default TextPopupWdgt;