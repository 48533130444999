import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/analytics';
import { toastify } from '../ducks/toast';

function* testingApi(action) {
  try {
    yield put(load());
    let res = yield call(api.GET, '/testingApi', action.data);

    if (res.status === 'success') {
      yield put(actions.testingApiSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchtestingApi() {
  yield takeLatest(actions.testingApi.type, testingApi);
}

export default function* rootSaga() {
  yield fork(watchtestingApi);
} 