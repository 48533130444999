import { Box, Checkbox, TextField, Text, InlineStack, InlineGrid } from '@shopify/polaris';
import { ImageWithTextOverlayIcon, CodeIcon, EyeCheckMarkIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const TextVerifBadge = ({ props }) => {
  let { state, handleTextChange, changeNameValue } = props;
  let cnf = state.cnf;

  let verticalTabsArr = [
    { title: 'Reviewer cards', img: ImageWithTextOverlayIcon },
    { title: 'Color combination', img: EyeCheckMarkIcon },
    { title: 'Add custom css', img: CodeIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);
  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <Box>
          <InlineGrid columns={2} gap={300}>
            {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
              return (
                <div key={i} className={val._id === state.currCardId ? 'select_block active' : 'select_block'}
                  onClick={() => { changeNameValue({ currCardId: val._id }, 'isEditable') }}>
                  <InlineStack align='center' blockAlign='center'>
                    <Checkbox tone={val._id === state.currCardId ? 'magic' : ''}
                      checked={val._id === state.currCardId} />
                    <Text id='text_capitalize' fontWeight='medium' alignment='center'>{val.dn}</Text>
                  </InlineStack>
                  {/* <Thumbnail source={val.iu} size="large" alt="Layout" /> */}
                  <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                </div>
              );
            })}
          </InlineGrid>
        </Box>
        :
        state.sidebarActiveIndex === 1 ?
          <Box>
            {cnf.vrfdbgclr !== undefined &&
              <ColorPick
                labelName='Verified bg color'
                colorKey="vrfdbgclr"
                colorValue={cnf.vrfdbgclr}
                changeNameValue={handleTextChange} />
            }
            {cnf.vrfdtxtclr !== undefined &&
              <Box paddingBlock={500}>
                <ColorPick
                  labelName='Verified text color'
                  colorKey="vrfdtxtclr"
                  colorValue={cnf.vrfdtxtclr}
                  changeNameValue={handleTextChange} />
              </Box>
            }
            {cnf && cnf.strclr !== undefined &&
              <ColorPick
                labelName='Star color'
                colorKey="strclr"
                colorValue={cnf.strclr}
                changeNameValue={handleTextChange} />
            }
          </Box>
          :
          state.sidebarActiveIndex === 2 &&
          <Box>
            {cnf && cnf.icstcss !== undefined &&
              <Box>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                  checked={cnf.icstcss}
                  onChange={(e) => handleTextChange({ icstcss: e })}
                />
                <Box paddingBlockStart={100} paddingInlineStart={600}>
                  <TextField
                    label={<Text tone='subdued' as='span'>Custom css block</Text>}
                    disabled={!cnf.icstcss}
                    multiline={4}
                    value={cnf.cstcss}
                    onChange={(e) => handleTextChange({ cstcss: e })}
                    autoComplete="off" />
                </Box>
              </Box>
            }
          </Box>
      }

    </Box>
  );
}

export default TextVerifBadge;