import { Box, Checkbox, Text, InlineStack, Label, Button, Popover, InlineGrid, Thumbnail, OptionList, TextField } from '@shopify/polaris';
import { ImageWithTextOverlayIcon, ThemeTemplateIcon, EyeCheckMarkIcon, CodeIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const dateOpt = [
  { label: 'mm-dd-yyyy', value: 'mm-dd-yyyy' },
  { label: 'dd-mm-yyyy', value: 'dd-mm-yyyy' }
];

const nameOpt = [
  { label: 'Jenny Karl', value: 0 },
  { label: 'J. karl', value: 1 },
  { label: 'Jenny K.', value: 2 },
  { label: 'J. K.', value: 3 }
];

const TextChkOutRev = ({ props }) => {
  let { state, handleTextChange, changeNameValue } = props;
  let cnf = state.cnf;

  let verticalTabsArr = [
    { title: 'Theme layouts', img: ThemeTemplateIcon },
    { title: 'Review card', img: ImageWithTextOverlayIcon },
    { title: 'Color combination', img: EyeCheckMarkIcon },
    { title: 'Add custom css', img: CodeIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <div>
          <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1 }} gap={300}>
            {state.mainLayoutArr && state.mainLayoutArr.length > 0 && state.mainLayoutArr.map((val, i) => {
              return (
                <div key={i} className={val._id === state.currLayoutId ? 'select_block active' : 'select_block'}
                  onClick={() => changeNameValue({ currLayoutId: val._id }, 'isEditable')}>
                  <InlineStack align='center' blockAlign='center'>
                    <Checkbox tone={val._id === state.currLayoutId ? 'magic' : ''}
                      checked={val._id === state.currLayoutId} />
                    <Text id='text_capitalize' fontWeight='medium' alignment='center'>{val.dn}</Text>
                  </InlineStack>
                  <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                </div>
              );
            })}
          </InlineGrid>

          {cnf && cnf.ickrv !== undefined &&
            <Box paddingBlockStart={400} paddingBlockEnd={500}>
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Show Checkout review</Text>}
                checked={cnf.ickrv}
                onChange={(e) => handleTextChange({ ickrv: e })}
              />
            </Box>
          }

          {cnf && cnf.mxrcrd !== undefined &&
            <Box paddingBlockStart={100}>
              <TextField
                type='number'
                label={<Text fontWeight='medium'>Display maximum record</Text>}
                // disabled={!cnf.ildmr}
                value={cnf.mxrcrd}
                onChange={(e) => handleTextChange({ mxrcrd: e })}
                autoComplete="off" />
            </Box>
          }

        </div>
        :
        state.sidebarActiveIndex === 1 ?
          <Box>
            <InlineGrid columns={2} gap={300}>
              {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
                return (
                  <div key={i} className={val._id === state.currCardId ? 'select_block active' : 'select_block'}
                    onClick={() => changeNameValue({ currCardId: val._id }, 'isEditable')}>
                    <InlineStack align='center' blockAlign='center'>
                      <Checkbox tone={val._id === state.currCardId ? 'magic' : ''}
                        checked={val._id === state.currCardId} />
                      <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                        {val.dn}</Text>
                    </InlineStack>
                    <Thumbnail source={val.iu} size="large" alt="Layout" />
                  </div>
                );
              })}
            </InlineGrid>

            {cnf && cnf.crdirvwnm !== undefined &&
              <Box paddingBlock={400}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show reviewer name</Text>}
                  checked={cnf.crdirvwnm}
                  onChange={(e) => handleTextChange({ crdirvwnm: e })}
                />
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <Text as='span'>Select name format</Text>
                  <Box paddingBlockStart={100}>
                    {cnf.crdirvwnm !== true ?
                      <TextField value={nameOpt[cnf.crdrvwnmfrmt].label} disabled={!cnf.crdirvwnm} />
                      :
                      <Popover
                        fullWidth
                        active={state.isNmFrmt}
                        onClose={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                        activator={<Button textAlign="left" fullWidth disclosure
                          onClick={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}>
                          <Text variant='bodyMd' tone='subdued'>
                            {nameOpt.find(({ value }) => value === cnf.crdrvwnmfrmt)?.label || 'Select'}
                          </Text>
                        </Button>}>
                        <OptionList
                          options={nameOpt}
                          selected={[cnf.crdrvwnmfrmt]}
                          onChange={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                          onFocusOption={(e) => handleTextChange({ crdrvwnmfrmt: Number(e) })} />
                      </Popover>
                    }
                  </Box>
                </Box>
              </Box>
            }

            {cnf && cnf.crdidt !== undefined &&
              <Box paddingBlock={400}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show review date</Text>}
                  checked={cnf.crdidt}
                  onChange={(e) => handleTextChange({ crdidt: e })}
                />
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <Text as='span'>Select date format</Text>
                  <Box paddingBlockStart={100}>
                    {cnf.crdidt !== true ?
                      <TextField value={cnf.crddtfrmt || 'Select'} disabled={true} />
                      :
                      <Popover
                        fullWidth
                        active={state.isDtFrmt}
                        onClose={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                        activator={<Button textAlign="left" fullWidth disclosure
                          onClick={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}>
                          <Text variant='bodyMd' tone='subdued'>
                            {cnf.crddtfrmt || 'Select'}
                          </Text>
                        </Button>}>
                        <OptionList
                          options={dateOpt}
                          selected={cnf.crddtfrmt}
                          onChange={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                          onFocusOption={(e) => handleTextChange({ crddtfrmt: e })} />
                      </Popover>
                    }
                  </Box>

                </Box>
              </Box>
            }

          </Box>
          :
          state.sidebarActiveIndex === 2 ?
            <Box>

              <Text variant="bodyMd" fontWeight='bold'>Reviewer card</Text>
              {cnf &&
                <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                  <InlineStack direction={'column'} gap={500}>
                    {cnf.crdbgclr !== undefined &&
                      <ColorPick
                        labelName='Background color'
                        colorKey="crdbgclr"
                        colorValue={cnf.crdbgclr}
                        changeNameValue={handleTextChange} />
                    }
                    {cnf.crddtclr !== undefined &&
                      <ColorPick
                        labelName='Date color'
                        colorKey="crddtclr"
                        colorValue={cnf.crddtclr}
                        changeNameValue={handleTextChange} />
                    }
                    {cnf.crdrvwnmclr !== undefined &&
                      <ColorPick
                        labelName='Name color'
                        colorKey="crdrvwnmclr"
                        colorValue={cnf.crdrvwnmclr}
                        changeNameValue={handleTextChange} />
                    }
                  </InlineStack>
                </Box>
              }

            </Box>
            :
            state.sidebarActiveIndex === 3 &&
            <Box>
              {cnf && cnf.icstcss !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                    checked={cnf.icstcss}
                    onChange={(e) => handleTextChange({ icstcss: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      label={<Text tone='subdued' as='span'>Custom css block</Text>}
                      disabled={!cnf.icstcss}
                      multiline={4}
                      value={cnf.cstcss}
                      onChange={(e) => handleTextChange({ cstcss: e })}
                      autoComplete="off" />
                  </Box>
                </Box>
              }
            </Box>
      }

    </Box >
  );
}

export default TextChkOutRev;