import { Text, Popover, OptionList, Tooltip, Checkbox, Card, InlineGrid, Button, Box, InlineStack, Thumbnail, TextField, Select } from '@shopify/polaris';
import { ThemeTemplateIcon, LayoutHeaderIcon, OrderUnfulfilledIcon, EyeCheckMarkIcon, ImageWithTextOverlayIcon, CodeIcon, LanguageIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const dateOpt = [
  { label: 'mm-dd-yyyy', value: 'mm-dd-yyyy' },
  { label: 'dd-mm-yyyy', value: 'dd-mm-yyyy' }
];

const nameOpt = [
  { label: 'Jenny Karl', value: 0 },
  { label: 'J. karl', value: 1 },
  { label: 'Jenny K.', value: 2 },
  { label: 'J. K.', value: 3 }
];

const TextQueAns = ({ props }) => {
  let { state, changeNameValue, handleTextChange } = props;
  let cnf = state.cnf;

  let verticalTabsArr = [
    { title: 'Theme layouts', img: ThemeTemplateIcon },
    { title: 'Questions card', img: ImageWithTextOverlayIcon },
    { title: 'Header section', img: LayoutHeaderIcon },
    { title: 'Color combination', img: EyeCheckMarkIcon },
    { title: 'No question available', img: OrderUnfulfilledIcon },
    { title: 'Add custom css', img: CodeIcon },
    { title: 'Language', img: LanguageIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <div>
          <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1 }} gap={300}>
            {state.mainLayoutArr && state.mainLayoutArr.length > 0 && state.mainLayoutArr.map((val, i) => {
              return (
                <div key={i} className={val._id === state.currQueLayoutId ? 'select_block active' : 'select_block'}
                  onClick={() => changeNameValue({ currQueLayoutId: val._id }, 'isEditable')}>
                  <InlineStack align='center' blockAlign='center'>
                    <Checkbox tone={val._id === state.currQueLayoutId ? 'magic' : ''}
                      checked={val._id === state.currQueLayoutId} />
                    <Text id='text_capitalize' fontWeight='medium' alignment='center'>{val.dn}</Text>
                  </InlineStack>
                  <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                </div>
              );
            })}
          </InlineGrid>

          {cnf && cnf.iqueanstb !== undefined &&
            <Box paddingBlockStart={400} paddingBlockEnd={500}>
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Show Q&A tab</Text>}
                checked={cnf.iqueanstb}
                onChange={(e) => handleTextChange({ iqueanstb: e })}
              />
            </Box>
          }

          {cnf && cnf.isrchbx !== undefined &&
            <Checkbox
              label={<Text fontWeight='medium' as='span'>Search box</Text>}
              checked={cnf.isrchbx}
              onChange={(e) => handleTextChange({ isrchbx: e })}
            />
          }

          {cnf && cnf.ildmr !== undefined &&
            <Box paddingBlockStart={500}>
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Show load more button</Text>}
                checked={cnf.ildmr}
                onChange={(e) => handleTextChange({ ildmr: e })}
              />
              <Box paddingInlineStart={600} paddingBlockStart={100}>
                <TextField
                  disabled={!cnf.ildmr}
                  value={cnf.ldmrtxt}
                  onChange={(e) => handleTextChange({ ldmrtxt: e })}
                  autoComplete="off" />
              </Box>
            </Box>
          }

        </div >
        :
        state.sidebarActiveIndex === 1 ?
          <Box>
            <InlineGrid columns={2} gap={300}>
              {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
                return (
                  <div key={i} className={val._id === state.currQueCardId ? 'select_block active' : 'select_block'}
                    onClick={() => changeNameValue({ currQueCardId: val._id }, 'isEditable')}>
                    <InlineStack align='center' blockAlign='center'>
                      <Checkbox tone={val._id === state.currQueCardId ? 'magic' : ''}
                        checked={val._id === state.currQueCardId} />
                      <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                        {val.dn}</Text>
                    </InlineStack>
                    <Thumbnail source={val.iu} size="large" alt="Layout" />
                  </div>
                );
              })}
            </InlineGrid>

            {cnf && cnf.crdirvwnm !== undefined &&
              <Box paddingBlock={400}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show questioner name</Text>}
                  checked={cnf.crdirvwnm}
                  onChange={(e) => handleTextChange({ crdirvwnm: e })}
                />
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <Text as='span'>Select name format</Text>
                  <Box paddingBlockStart={100}>
                    {cnf.crdirvwnm !== true ?
                      <TextField value={nameOpt[cnf.crdrvwnmfrmt].label} disabled={!cnf.crdirvwnm} />
                      :
                      <Popover
                        fullWidth
                        active={state.isNmFrmt}
                        onClose={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                        activator={<Button textAlign="left" fullWidth disclosure
                          onClick={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}>
                          <Text variant='bodyMd' tone='subdued'>
                            {nameOpt.find(({ value }) => value === cnf.crdrvwnmfrmt)?.label || 'Select'}
                          </Text>
                        </Button>}>
                        <OptionList
                          options={nameOpt}
                          selected={[cnf.crdrvwnmfrmt]}
                          onChange={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                          onFocusOption={(e) => handleTextChange({ crdrvwnmfrmt: Number(e) })} />
                      </Popover>
                    }
                  </Box>
                </Box>
              </Box>
            }

            {cnf && cnf.crdidt !== undefined &&
              <Box>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show question date</Text>}
                  checked={cnf.crdidt}
                  onChange={(e) => handleTextChange({ crdidt: e })}
                />
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <Text as='span'>Select date format</Text>
                  <Box paddingBlockStart={100}>
                    {cnf.crdidt !== true ?
                      <TextField value={cnf?.crddtfrmt || 'Select'} disabled={true} />
                      :
                      <Popover
                        fullWidth
                        active={state.isDtFrmt}
                        onClose={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                        activator={<Button textAlign="left" fullWidth disclosure
                          onClick={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}>
                          <Text variant='bodyMd' tone='subdued'>
                            {cnf?.crddtfrmt || 'Select'}
                          </Text>
                        </Button>}>
                        <OptionList
                          options={dateOpt}
                          selected={cnf.crddtfrmt}
                          onChange={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                          onFocusOption={(e) => handleTextChange({ crddtfrmt: e })} />
                      </Popover>
                    }
                  </Box>

                </Box>
              </Box>
            }

            {cnf && cnf.crdirdmr !== undefined &&
              <Box paddingBlock={400}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show trim content</Text>}
                  checked={cnf.crdirdmr}
                  onChange={(e) => handleTextChange({ crdirdmr: e })}
                />
                <Box paddingBlockStart={100} paddingInlineStart={600}>
                  <TextField
                    disabled={!cnf.crdirdmr}
                    onChange={(e) => handleTextChange({ crdrdmrtxt: e })}
                    value={cnf.crdrdmrtxt} />
                </Box>
              </Box>
            }

          </Box>
          :
          state.sidebarActiveIndex === 2 ?
            <Box>

              {cnf && cnf.hdriwrtbtn !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show the “Ask a question” button</Text>}
                    checked={cnf.hdriwrtbtn}
                    onChange={(e) => handleTextChange({ hdriwrtbtn: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.hdriwrtbtn}
                      value={cnf.hdrwrttxt}
                      onChange={(e) => handleTextChange({ hdrwrttxt: e })}
                      autoComplete="off" />
                  </Box>
                </Box>
              }

            </Box>
            :
            state.sidebarActiveIndex === 3 ?
              <Box>

                <Box paddingBlockEnd={300}>
                  <Tooltip width='wide' padding="400" dismissOnMouseOut content='You can import all colors from your main widget colors to this widget'><Button variant='plain' onClick={() => props.applyMainWdgt()}>Import from main widget</Button></Tooltip>
                </Box>

                <Text variant="bodyMd" fontWeight='bold'>Header section</Text>
                {cnf &&
                  <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                    <InlineStack direction={'column'} gap={500}>
                      {cnf.hdrwrtbtnbgclr !== undefined &&
                        <ColorPick
                          labelName='Ask question button '
                          colorKey="hdrwrtbtnbgclr"
                          colorValue={cnf.hdrwrtbtnbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.hdrwrtbtntxtclr !== undefined &&
                        <ColorPick
                          labelName='Ask question text'
                          colorKey="hdrwrtbtntxtclr"
                          colorValue={cnf.hdrwrtbtntxtclr}
                          changeNameValue={handleTextChange} />
                      }
                    </InlineStack>

                  </Box>
                }

                <Text variant="bodyMd" fontWeight='bold'>Reviewer card</Text>
                {cnf &&
                  <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                    <InlineStack direction={'column'} gap={500}>
                      {cnf.crdbgclr !== undefined &&
                        <ColorPick
                          labelName='Background color'
                          colorKey="crdbgclr"
                          colorValue={cnf.crdbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crddtclr !== undefined &&
                        <ColorPick
                          labelName='Date color'
                          colorKey="crddtclr"
                          colorValue={cnf.crddtclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdrvwnmclr !== undefined &&
                        <ColorPick
                          labelName='Name color'
                          colorKey="crdrvwnmclr"
                          colorValue={cnf.crdrvwnmclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdtxtclr !== undefined &&
                        <ColorPick
                          labelName='Review text color'
                          colorKey="crdtxtclr"
                          colorValue={cnf.crdtxtclr}
                          changeNameValue={handleTextChange} />
                      }
                    </InlineStack>
                  </Box>
                }

                <Text variant="bodyMd" fontWeight='bold'>Load more button</Text>
                {cnf &&
                  <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={2400}>

                    <InlineStack direction={'column'} gap={500}>
                      {cnf.ldmrbgclr &&
                        <ColorPick
                          labelName='Background color'
                          colorKey="ldmrbgclr"
                          colorValue={cnf.ldmrbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.ldmrtxtclr &&
                        <ColorPick
                          labelName='Text color'
                          colorKey="ldmrtxtclr"
                          colorValue={cnf.ldmrtxtclr}
                          changeNameValue={handleTextChange} />
                      }
                    </InlineStack>
                  </Box>
                }
              </Box>
              :
              state.sidebarActiveIndex === 4 ?
                <Box>
                  {cnf && cnf.emtytxt !== undefined &&
                    <TextField
                      value={cnf.emtytxt}
                      label={<Text fontWeight='medium' as='span'>Message</Text>}
                      onChange={(e) => handleTextChange({ emtytxt: e })} />
                  }
                  {cnf && cnf.emtytxtclr !== undefined &&
                    <Box paddingBlockStart={400}>
                      <ColorPick
                        labelName='Message color'
                        colorKey="emtytxtclr"
                        colorValue={cnf.emtytxtclr}
                        changeNameValue={handleTextChange} />
                    </Box>
                  }
                </Box>
                :
                state.sidebarActiveIndex === 5 ?
                  <Box>

                    {cnf && cnf.icstcss !== undefined &&
                      <Box>
                        <Checkbox
                          label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                          checked={cnf.icstcss}
                          onChange={(e) => handleTextChange({ icstcss: e })}
                        />
                        <Box paddingBlockStart={200} paddingInlineStart={600}>
                          <TextField
                            label={<Text tone='subdued' as='span'>Custom css block</Text>}
                            disabled={!cnf.icstcss}
                            multiline={4}
                            value={cnf.cstcss}
                            onChange={(e) => handleTextChange({ cstcss: e })}
                            autoComplete="off" />
                        </Box>
                      </Box>
                    }

                  </Box>
                  :
                  state.sidebarActiveIndex === 6 &&
                  <Box>
                    {cnf && cnf.lng !== undefined &&
                      <Card>
                        <Select
                          label="Select language"
                          options={state.lngOptions}
                          onChange={props.handleLanguageChange}
                          value={cnf.lng}
                        />
                      </Card>
                    }
                  </Box>

      }
    </Box >
  );
}

export default TextQueAns;