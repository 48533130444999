import { Frame, TopBar, ProgressBar, Text, InlineStack } from '@shopify/polaris';
import { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as masterDucks from '../../ducks/master';
import * as authDucks from '../../ducks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { ExitIcon } from '@shopify/polaris-icons';
// import { createApp } from '@shopify/app-bridge';
// import { Redirect } from '@shopify/app-bridge/actions';
import Sidebar from './Sidebar';

function Header(props) {
  // const navigate = useNavigate();

  // //subscribe shopify admin menu navigation click event
  // const strObjConfig = localStorage.getItem('store_config');
  // if (strObjConfig) {
  //   const appShopify = createApp(JSON.parse(strObjConfig));
  //   appShopify.subscribe(Redirect.Action.APP, (payload) => {
  //     // console.log('=', payload.path, window._cio);
  //     window._cio.page(payload.path);
  //     navigate(payload.path);
  //   });
  // }
  // //end subscribe 

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(masterDucks.getMasterData());
    dispatch(authDucks.getProfile());
    localStorage.removeItem('WS_OPEN_THNK');
    localStorage.removeItem('WS_OPEN_FORM');
  }, []);

  const profileData = useSelector(state => state.auth.profile);
  let fName = localStorage.getItem('fn') || '';

  const [state, setState] = useState({
    userMenuActive: false,
    mobiNvigtnActive: false,
    stepCount: 0,
    progress: 0
  });

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    let proSteps = 0, obj = {};

    if (profileData) {
      if (profileData.ienblpxlapp === true) {
        proSteps = proSteps + 17;
        obj['progress'] = proSteps;
      }
      if (profileData.iinstlwdgt === true) {
        proSteps = proSteps + 17;
        obj['progress'] = proSteps;
      }
      if (profileData.iinstlstrrtng === true) {
        proSteps = proSteps + 17;
        obj['progress'] = proSteps;
      }
      if (profileData.ibrnd === true) {
        proSteps = proSteps + 17;
        obj['progress'] = proSteps;
      }
      if (profileData.iaumtrvwclt === true) {
        proSteps = proSteps + 16;
        obj['progress'] = proSteps;
      }
      if (profileData.iplnslct === true) {
        proSteps = proSteps + 16;
        obj['progress'] = proSteps;
      }
      obj['progress'] = proSteps;
      changeNameValue(obj);
    }

    setTimeout(() => {
      let stepCount = Math.floor(proSteps / 16);
      changeNameValue({ stepCount });
    }, 100);

  }, [profileData]);

  const handleLogout = () => {
    let shp = localStorage.getItem('shp');

    localStorage.removeItem('WS_RENDER');
    localStorage.removeItem('e');
    localStorage.removeItem('ienblpxlapp');
    localStorage.removeItem('iinstlwdgt');
    localStorage.removeItem('iinstlstrrtng');
    localStorage.removeItem('WS_PRVW_WIDGET');
    localStorage.removeItem('WS_PRVW_TYP');
    localStorage.removeItem('fn');
    localStorage.removeItem('ti');
    localStorage.removeItem('WS_PRVW_DATA');
    localStorage.removeItem('WS_PRVW_DATA_COUNT');
    localStorage.removeItem('shp');
    localStorage.removeItem('authToken');
    localStorage.removeItem('WS_OPEN_THNK');
    localStorage.removeItem('WS_OPEN_FORM');

    setTimeout(() => {
      if (shp) {
        localStorage.removeItem('icmpltonbrdng');
        let _url = 'https://' + shp + '/admin';
        window.location.href = _url;
      }
    }, 300);
  }

  const userMenuActions = [{
    items: [{ content: 'Log out', icon: ExitIcon, onAction: () => handleLogout() }]
  }];

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={fName ? fName : ''}
      initials={fName ? fName.charAt(0).toUpperCase() : ''}
      open={state.userMenuActive}
      onToggle={() => changeNameValue({ userMenuActive: !state.userMenuActive })} />
  );

  // onBoarding completed steps
  const onBoardSteps = (
    state.stepCount !== 6 &&
    <div className="steps_wrapper">
      <Link to="/">
        <InlineStack direction={'column'} gap={100}>
          <Text variant="headingSm" as="h6"> {state.stepCount} out of 6 step Completed</Text>
          <ProgressBar size='small' animated={true} progress={state.progress} tone="success" />
        </InlineStack>
      </Link>
    </div>
  );

  const topBarMarkup = (
    <TopBar
      searchField={localStorage.getItem('icmpltonbrdng') === 'true' ? onBoardSteps : ''}
      showNavigationToggle={localStorage.getItem('icmpltonbrdng') === 'true' ? true : false}
      userMenu={localStorage.getItem('icmpltonbrdng') === 'true' ? userMenuMarkup : ''}
      onNavigationToggle={() => changeNameValue({ mobiNvigtnActive: !state.mobiNvigtnActive })} />
  );

  const logo = {
    progress: 182,
    width: '100%',
    topBarSource: '../images/wiser_logo.svg',
    topBarSource: '../images/wiser_logo.svg?qdfgdfg=',
    // contextualSaveBarSource: 'https://cdn.shopify.com/s/files/1/0446/6937/files/jaded-pixel-logo-gray.svg?6215648040070010999',
    url: '/',
    accessibilityLabel: 'Wiser Review'
  };

  return (
    <div className='header_clss'>

      {localStorage.getItem('is_shopify_embed') ?
        <Frame>{props.component}</Frame>
        :
        <Frame
          logo={logo}
          topBar={topBarMarkup}
          navigation={localStorage.getItem('icmpltonbrdng') === 'true' ? <Sidebar /> : false}
          showMobileNavigation={state.mobiNvigtnActive}
          onNavigationDismiss={() => changeNameValue({ mobiNvigtnActive: !state.mobiNvigtnActive })}>
          {props.component}
        </Frame>
      }
    </div>
  );
}

export default Header;