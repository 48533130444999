import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/customer';
import { toastify } from '../ducks/toast';

function* getCustomer(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getCustomerData', action.payload);
    if (res.status === 'success') {
      yield put(actions.getCustomerSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetCustomer() {
  yield takeLatest(actions.getCustomer.type, getCustomer);
}

export default function* rootSaga() {
  yield fork(watchGetCustomer);
}


