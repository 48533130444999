import React from 'react';
import { BlockStack, Box, Button, ButtonGroup, Layout, InlineStack, Card, Page, Text, TextField } from '@shopify/polaris';
import { ExternalIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { toastify } from '../../ducks/toast';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { changePathForShopifyAppEmbed } from "../../utils/utils";
let shopifyStore = '';

function StoreInformation(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    reviewLink: '',
    storeKey: '',
    searchParam: '',
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  state.searchParam = queryParams.get("p");

  const profileData = useSelector(state => state.auth.profile);

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    if (profileData && profileData.ak) {
      changeNameValue({ 'storeKey': profileData.ak });

      if (profileData.pvd === 'shopify' && profileData.objshp) {
        shopifyStore = state.profileState.objshp.d;
      }
    }

  }, [profileData, changeNameValue]);

  const handleCopyCode = (value) => {
    let selected = document.getElementById('CopyKey');
    if (selected) selected.select();
    copy(value, { debug: true });
    dispatch(toastify({ type: 'success', msg: `Your key is copied.` }));
  }

  const handleBackToMain = () => {
    if (localStorage.getItem('is_shopify_embed')) {
      changePathForShopifyAppEmbed('/setting');
    }
    else navigate('/setting');
  }

  let _ti = localStorage.getItem('ti') ? window.btoa(localStorage.getItem('ti')) : ''
  let _shp = window.btoa(localStorage.getItem('shp'));
  let _hrefLink = `https://wisernotify.com/form/writereview?ti=${_ti}&shp=${_shp}&typ=ord`;

  useEffect(() => {
    changeNameValue({ 'reviewLink': _hrefLink });
  }, [_hrefLink, changeNameValue]);

  // const options = [
  //   { label: 'India', value: 'India' },
  // ];

  return (
    <Page title={'Store information'} backAction={props.isShwBack === true ? { onAction: () => handleBackToMain() } : false}>
      <Box paddingInlineStart={800} paddingBlockEnd={500}>
        <BlockStack gap={600}>
          <Layout>
            <Layout.Section variant="oneThird">
              <BlockStack gap={200}>
                <Text variant="headingMd" as="h2" fontWeight='semibold'>Reviews theme setup</Text>
                <Text variant='headingSm' as="p" fontWeight='regular'>
                  View the steps on how to add wisernotify to your online store 2.0 shopify theme
                </Text>
              </BlockStack >
            </Layout.Section>

            <Layout.Section>
              <Card>
                <InlineStack gap={400}>
                  <div className='box_bg'>
                    <BlockStack gap={200}>
                      <Text variant='headingMd' as='h5' fontWeight='medium'>1. Enable the wisernotify app embed</Text>
                      <Box maxWidth={'91%'}>
                        <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>Visit your Shopify theme editor and enable the wisernotify app embed under Theme settings and hit Save.</Text>
                      </Box>
                      <Box paddingBlockStart={100}>
                        <Button id='primary_btn' variant="primary" icon={ExternalIcon} url={`https://${shopifyStore}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/pixel&target=newAppsSection`} target='_blank'>Enable app embed</Button>
                      </Box>
                    </BlockStack>
                  </div>
                  <div className='box_bg'>
                    <BlockStack gap={200}>
                      <Text variant='headingMd' as='h5' fontWeight='medium'>2. Add the wisernotify review stars and reviews widget to your theme</Text>
                      <Box maxWidth={'80%'}>
                        <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>Visit your shopify theme editor and add the wisernotify product summary stars and the product reviews blocks to your theme.</Text>
                      </Box>
                      <Box paddingBlockStart={100}>
                        <ButtonGroup>
                          <Button id='primary_btn' variant="primary" icon={ExternalIcon} url={`https://${shopifyStore}/admin/themes/current/editor?template=product&addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/starRating&target=mainSection`} target='_blank'>Add product summary stars</Button>
                          <Button id='primary_btn' variant="primary" icon={ExternalIcon} url={`https://${shopifyStore}/admin/themes/current/editor?template=product&addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/productReview&target=newAppsSection`} target='_blank'>Add product reviews</Button>
                        </ButtonGroup>
                      </Box>
                    </BlockStack>
                  </div>
                  <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>We recommend adding the summary stars just under your product title and the reviews under your product information. If your theme supports editing the collection view we also recommend adding the summary stars under your product titles in your grids</Text>
                </InlineStack>
              </Card>
            </Layout.Section>
          </Layout>

          {/* <Layout>
            <Layout.Section variant="oneThird">
              <Box paddingBlockStart={100}>
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>
                    Reviews snippets
                  </Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>
                    Add or remove the wisernotify snippets from your site
                  </Text>
                </BlockStack >
              </Box>
            </Layout.Section>
            <Layout.Section>
              <Box paddingBlockEnd={400}>
                <Banner>
                  <Text variant='bodyMd' fontWeight='medium' as='p'>
                    It looks like you are running a Shopify theme that supports sections everywhere. Use the steps above to add wisernotify to your site. If you don't wish to use sections, you can try the automatic snippet installation below
                  </Text>
                </Banner>
              </Box>
              <Card>
                <Box>
                  <div className='box_bg'>
                    <BlockStack gap={200}>
                      <Text variant='headingMd' as='h6' fontWeight='medium'>Add the <Text variant='headingMd' as='span' fontWeight='semibold'>Reviews</Text> snippets to your site</Text>
                      <Box>
                        <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>Click below to add the wisernotify snippets to your current theme. If you have a complex store theme or simply wish customize your installation check out our <Button variant='plain' url='#'>docs</Button> on manually adding the wisernotify snippets.</Text>
                      </Box>
                      <Box paddingBlockStart={100}>
                        <Button id='primary_btn' variant="primary" disclosure>Add snippets</Button>
                      </Box>
                    </BlockStack>
                  </div>
                </Box>
                <Box>
                  <Text>Already added the wisernotify snippets manually? Click <Button variant='plain' url='#'>here</Button> to mark your installation as completed</Text>
                </Box>
                <Box>
                  <div className='box_bg'>
                    <BlockStack gap={200}>
                      <Text variant='headingMd' as='h6' fontWeight='medium'>Remove the <Text variant='headingMd' as='span' fontWeight='semibold'>Reviews</Text> snippets from your site</Text>
                      <Box maxWidth={'98%'}>
                        <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>If you wish to remove our wisernotify snippets from your current theme, use the button below. If you manually added the wisernotify snippets or changed your theme since installation we can't guarantee this will work.</Text>
                      </Box>
                      <Box paddingBlockStart={100}>
                        <Button variant="primary" tone="critical" disclosure>Remove snippets</Button>
                      </Box>
                    </BlockStack>
                  </div>
                </Box>
              </Card>
            </Layout.Section>
          </Layout> */}

          <Layout>
            <Layout.Section variant="oneThird">
              <Box paddingBlockStart={100}>
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>Store key</Text>
                  {/* <Text variant='headingSm' as="p" fontWeight='regular'>
                    View your store connection status
                  </Text> */}
                </BlockStack >
              </Box>
            </Layout.Section>
            <Layout.Section>
              <Box>
                <Card>
                  <Box>
                    {/* <InlineStack gap={400} align='space-between' blockAlign='center'>
                    <InlineStack gap={400}>
                      <img src={shopify_icon} alt='shopify' />
                      <BlockStack>
                        <Text variant='headingXs' as="p" fontWeight='medium'>Devkeval</Text>
                        <Box>
                          <InlineStack gap={200} blockAlign='center'>
                            <Text variant='headingXs' as="p" fontWeight='semibold'>Provider: <Text variant='headingXs' as="span" fontWeight='medium'>Shopify</Text></Text>
                            <div className='store_Info_border'></div>
                            <Text variant='headingXs' as="p" fontWeight='semibold'>URL: <Text variant='headingXs' as="span" fontWeight='medium'><Button variant='plain' url={'#'}>devkeval.myshopify.com</Button></Text></Text>
                          </InlineStack>
                        </Box>
                      </BlockStack>
                    </InlineStack>
                    <Box>
                      <Badge tone='success'>Connected</Badge>
                    </Box>
                  </InlineStack>
                  <Box paddingBlockStart={500} paddingBlockEnd={400}>
                    <Divider />
                  </Box> */}
                    <Text variant='headingMd' as='h6' fontWeight='semibold'>Store key:</Text>
                    <Box paddingBlockStart={100}>
                      <TextField
                        readOnly
                        id='CopyKey'
                        value={state.storeKey}
                        onFocus={(e) => e.target.select()}
                        connectedRight={<Button id='primary_btn' variant='primary' onClick={() => handleCopyCode(state.storeKey)}>Copy</Button>} />
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Layout.Section>
          </Layout>

          {/* <Layout>
            <Layout.Section variant="oneThird">
              <Box paddingBlockStart={100}>
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>
                    Store URL
                  </Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>
                    If your store URL is different than what is displayed above, you can change it here.
                  </Text>
                </BlockStack >
              </Box>
            </Layout.Section>
            <Layout.Section>
              <Box>
                <Card>
                  <Text variant='headingMd' as='h6' fontWeight='semibold'>Edit your store URL</Text>
                  <Box paddingBlockStart={100}>
                    <InlineGrid >
                      <TextField
                        readOnly
                        value={state.reviewLink}
                        onFocus={(e) => e.target.select()}
                        connectedRight={<Button id='primary_btn' variant='primary' onClick={() => handleCopyCode(state.reviewLink, 'link')}>Copy link</Button>} />
                    </InlineGrid>
                  </Box>
                  <Box paddingBlockStart={500} paddingBlockEnd={400}>
                      <Divider />
                    </Box>
                    <InlineStack align='end'>
                      <Button id='primary_btn' variant='primary'>Save</Button>
                    </InlineStack>
                </Card>
              </Box>
            </Layout.Section>
          </Layout> */}

          {/* <Layout>
          <Layout.Section variant="oneThird">
            <Box paddingBlockStart={100}>
              <BlockStack gap={200}>
                <Text variant="headingMd" as="h2" fontWeight='semibold'>
                  Physical Address
                </Text>
                <Text variant='headingSm' as="p" fontWeight='regular'>
                  This address is displayed in the footer of your emails.
                </Text>
              </BlockStack >
            </Box>
          </Layout.Section>
          <Layout.Section>
            <Box>
              <Card>
                <Box>
                  <BlockStack gap={300}>
                    <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} gap={400}>
                      <TextField
                        type="text"
                        label="Address"
                        onChange={() => { }}
                        autoComplete="off"
                      />
                      <TextField
                        type="text"
                        label="Apartment, suite. etc"
                        onChange={() => { }}
                        autoComplete="off"
                      />
                    </InlineGrid>
                    <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} gap={400}>
                      <TextField
                        type="text"
                        label="City"
                        onChange={() => { }}
                        autoComplete="off"
                      />
                      <Select
                        label="Country"
                        options={options}
                      // onChange={handleSelectChange}
                      // value={selected}
                      />
                    </InlineGrid>
                    <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} gap={400}>
                      <TextField
                        type="text"
                        label="Province/State"
                        onChange={() => { }}
                        autoComplete="off"
                      />
                      <TextField
                        type="text"
                        label="Postal Code/Zip"
                        onChange={() => { }}
                        autoComplete="off"
                      />
                    </InlineGrid>
                  </BlockStack>
                  <Box paddingBlockStart={500} paddingBlockEnd={400}>
                    <Divider />
                  </Box>
                  <InlineStack align='end'>
                    <Button id='primary_btn' variant='primary'>Save</Button>
                  </InlineStack>
                </Box>
              </Card>
            </Box>
          </Layout.Section>
        </Layout> */}
        </BlockStack>
      </Box>
    </Page>
  );
}

export default StoreInformation;