import { Badge, BlockStack, Box, Button, ButtonGroup, Card, Collapsible, Divider, Icon, IndexTable, InlineStack, Layout, Modal, Page, PageActions, Text, TextField, Tooltip } from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon } from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { copy_icon } from '../../img';
import { useDispatch } from 'react-redux';
import { toastify } from '../../ducks/toast';
import * as integrationDucks from '../../ducks/integration';

function MultiStoreSyndication({ props }) {
  const [cState, setCState] = useState({
    shpVal: '',
  });
  const { state, validator, handleTextChange, handleSave, changeNameValue, handleCopyCode, handleBackToMain, openCloseModal } = props;
  const dispatch = useDispatch();

  let _ia = false;
  if (state.currUserInt && state.currUserInt.ia !== undefined) {
    _ia = state.currUserInt.ia;
  }

  const changeNameCValue = useCallback((obj) => {
    setCState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  const tkn = `${localStorage.getItem('shp')}_${localStorage.getItem('ti')}`
  useEffect(() => {  // if 'd' obj is empty, add custom 'd' obj  
    if (state.dObj && Object.keys(state.dObj).length === 0) {
      let obj = { tkn: tkn };
      changeNameValue({ 'dObj': obj });
    }
  }, [state.dObj, tkn, changeNameValue]);

  const handleUpdateSyncToken = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    }
    else if (state.dObj.tkn === state.dObj.shptkn) {
      dispatch(toastify({ type: 'error', msg: 'Please enter valid token, token is same' }));
    }
    else if (state.syncArray?.map((val) => val.shptkn === state.dObj.shptkn).includes(true)) {
      dispatch(toastify({
        type: 'error', msg: 'Duplicate store not allowed'
      }));
    }
    else {
      changeNameValue({ 'errMessage': {} });
      let obj = {
        id: state.currIntId,
        tkn: state.dObj.shptkn,
      }
      dispatch(integrationDucks.syncToken(obj));
      changeNameValue({ 'shptkn': '' })
    }
  }

  const handleDelSyncToken = () => {
    let obj = {
      id: state.currIntId,
      shp: cState.shpVal
    }
    dispatch(integrationDucks.delSyncToken(obj));
    changeNameCValue({ 'shpVal': '' })
    openCloseModal('deleteOpen', state.deleteOpen, 'close');
  }

  const handleStatusUpdate = (_ia) => {
    let obj = {
      _id: state.currIntId,
      ia: _ia !== undefined ? _ia : state.currMasterObj.ia
    }
    dispatch(integrationDucks.addUpdIntegration(obj));
  }

  let queArr = [
    {
      field: 'firstTabCollapse',
      question: 'What is multi-store syndication?',
      answer: 'Multi-store syndication allows you to share and display reviews from one Shopify store across your other Shopify stores, enhancing social proof across multiple points of sale.'
    },
    {
      field: 'secondTabCollapse',
      question: 'How does multi-store syndication work?',
      answer: 'Reviews are syndicated between stores when the reviewed products have matching SKU IDs, facilitating a streamlined sharing process.'
    }
  ];

  return (
    <Page
      title={(state.currMasterObj && state.currMasterObj.dn) && state.currMasterObj.dn}
      titleMetadata={_ia === true ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">Active</Text></Badge> :
        <Badge><Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text></Badge>}
      subtitle={
        <Text tone='base' >
          {state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
        </Text>}
      secondaryActions={
        <div className='btn-group btn-head-flex'>
          <ButtonGroup gap='extraTight'>
            <Button variant='plain'>View article</Button>
            {_ia === true ?
              <Button variant='primary' tone='critical' onClick={() => handleStatusUpdate(false)}>Turn off</Button>
              :
              <Button variant='primary' tone='success' onClick={() => { state.currIntId ? handleStatusUpdate(true) : handleSave(true) }}>Turn on</Button>
            }
          </ButtonGroup>
        </div>
      }
      backAction={{ onAction: () => handleBackToMain() }}
    >

      {state.UserIntegration && state.UserIntegration?.filter(item => item.inm === 'multi_store_syndication')?.length > 0 ? (
        <>
          <Box padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockEnd={500} paddingInlineStart={800}>
            <BlockStack gap={600}>
              <Layout>
                <Layout.Section variant="oneThird">
                  <BlockStack gap={200}>
                    <Text variant="headingMd" as="h2" fontWeight='semibold'>Setting up</Text>
                    <Text variant='headingSm' as="p" fontWeight='regular'>
                      Enable review sharing across your group's stores using store tokens, allowing sharing of existing and future reviews between them.
                    </Text>
                  </BlockStack>
                </Layout.Section>

                <Layout.Section>
                  <BlockStack gap={400}>
                    <Card>
                      <Box paddingBlockStart={200}>
                        <Text variant="headingSm" as="p" fontWeight='semibold'>Store token:</Text>
                        <div className="copycode-box copcode_bg">
                          <InlineStack align='space-between' blockAlign='start' gap={200} wrap={false}>
                            <textarea rows={1} className="textarea-copycode" readOnly={true} value={state.dObj.tkn} onFocus={(e) => e.target.select()}
                              onClick={() => handleCopyCode(state.dObj.tkn, 'Token')}>
                            </textarea>
                            <Button variant='monochromePlain' onClick={() => handleCopyCode(state.dObj.tkn, 'Token')}>
                              <img src={copy_icon} alt='copy'></img></Button>
                          </InlineStack>
                        </div>
                      </Box>
                      {/* <TextField
                          readOnly
                          value={state.dObj.tkn}
                          onFocus={(e) => e.target.select()}
                          connectedRight={<Tooltip content='copy to clipboard'>
                            <Button icon={ClipboardIcon} variant='plain' onClick={() => handleCopyCode(state.dObj.tkn, 'Token')} />
                          </Tooltip>}
                        /> */}
                    </Card>

                    <Card>
                      <BlockStack gap={300}>
                        <Text variant='headingSm' as='h2' fontWeight='semibold'>Join an existing group</Text>
                        <Text variant='headingSm' as='p' fontWeight='regular'>For seamless review sharing across multiple stores selling the same products, ensure all items have matching SKU codes.</Text>
                        <TextField
                          label="Enter store group token"
                          placeholder='Enter store group token'
                          value={state.dObj.shptkn}
                          onChange={(e) => handleTextChange('shptkn', e)}
                          autoComplete="off"
                          error={state.errMessage.groupToken ? state.errMessage.groupToken : false} />
                        {validator.message('groupToken', state.dObj.shptkn, 'required')}

                        <Box paddingBlockStart={100}>
                          <InlineStack align='start'>
                            <Button id='primary_btn' onClick={() => handleUpdateSyncToken()}>Create</Button>
                          </InlineStack>
                        </Box>

                        {state.syncArray?.length > 0 &&
                          <Box>
                            <Box paddingBlockEnd={200} paddingBlockStart={200}>
                              <Text variant='headingSm' as='h2' fontWeight='semibold'>Syndicating stores</Text>
                            </Box>
                            <Card padding={0}>
                              <IndexTable
                                itemCount={1}
                                selectable={false}
                                headings={[
                                  { title: 'Type' },
                                  { title: 'Action' }
                                ]} >
                                {state.syncArray.map((val, index) => {
                                  return (
                                    <IndexTable.Row
                                      id={0}
                                      key={index}
                                      position={0}>
                                      <IndexTable.Cell><div>{val.shp}</div></IndexTable.Cell>
                                      <IndexTable.Cell>
                                        <ButtonGroup gap={'loose'}>
                                          <Tooltip width='wide' padding="400" dismissOnMouseOut content="Delete">
                                            <Button variant='monochromePlain' icon={DeleteIcon} onClick={() => { openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameCValue({ 'shpVal': val.shp }) }}></Button>
                                          </Tooltip>
                                        </ButtonGroup>
                                      </IndexTable.Cell>
                                    </IndexTable.Row>
                                  )
                                })}
                              </IndexTable>
                            </Card>
                          </Box>
                        }
                      </BlockStack>
                    </Card>

                    <Card padding={0}>
                      <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                        <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
                      </Box>
                      <Divider borderColor='border-tertiary' />
                      <Box paddingInline={400} paddingBlockEnd={300}>

                        {queArr && queArr.map((data, i) => {
                          return (
                            <div key={i} id='cursorPoint' onClick={() => changeNameValue({ tabEvent: data.field })}>
                              <Box paddingBlock={300}>
                                <InlineStack wrap={false} blockAlign='center' align='space-between'>
                                  <Text variant='headingSm' as='p' fontWeight='medium'>{data.question}</Text>
                                  <Box><Icon source={data.field === state.tabEvent ? ChevronUpIcon : ChevronDownIcon}></Icon></Box>
                                </InlineStack>
                                <Collapsible open={data.field === state.tabEvent} id="db_collaspe"
                                  transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                                  <Box paddingBlockStart={100}>
                                    <Text variant='headingSm' tone='subdued' as='p' fontWeight='regular'>{data.answer}</Text>
                                  </Box>
                                </Collapsible>
                              </Box>
                              {i !== queArr.length - 1 && <Divider />}
                            </div>
                          )
                        })}

                      </Box>
                    </Card>
                  </BlockStack>

                </Layout.Section>
              </Layout>
            </BlockStack>
          </Box>
        </>
      ) : null}

      <Modal
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this?</Text>
            <PageActions
              primaryAction={{
                id: 'primary_btn',
                content: 'Yes',
                onAction: () => handleDelSyncToken()
              }}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </Page >
  );
}

export default MultiStoreSyndication;