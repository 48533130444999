import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/auth';
import { toastify } from '../ducks/toast';

//function for date to second 
function dateToSeconds(dateString) {
  // Parse the date string into milliseconds
  let milliseconds = Date.parse(dateString);

  // Convert milliseconds to seconds
  let seconds = milliseconds / 1000;

  return seconds;
}

function* getProfile(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/auth/getProfile', action.data);

    if (res.status === 'success') {
      yield put(actions.getProfileSuccess(res.data));

      if (res.data.e) localStorage.setItem('e', res.data.e);
      if (res.data.fn) localStorage.setItem('fn', res.data.fn);
      if (res.data.ti) localStorage.setItem('ti', res.data.ti);
      if (res.data.csub) localStorage.setItem('csub', res.data.csub);
      if (res.data.pvd) localStorage.setItem('pvd', res.data.pvd);
      if (res.data.objshp && res.data.objshp.d) localStorage.setItem('shp', res.data.objshp.d);

      window._cio.identify({
        // Required attributes
        id: res.data.ti,    // Use either cio_id, id, or email.

        // attributes
        name: res.data.fn,
        email: res.data.e,
        registered: res.data.cdt,
        created_at: dateToSeconds(res.data.cdt),
        quota_reset_date: res.data.nxtb,
        store_URL: res.data.objshp.d,
        product_reviews: res.data.iinstlwdgt === true ? 'Yes' : 'No',
        wall_love: res.data.iinstlwall === true ? 'Yes' : 'No',
        nudges: res.data.iinstlstrrtng === true ? 'Yes' : 'No',
        carousel: res.data.iinstlcrsl === true ? 'Yes' : 'No',
        PlanName: res.data.csub
      });

      //is complete onboard flow
      let _isCmpbord = localStorage.getItem('icmpltonbrdng');
      if (_isCmpbord === undefined || _isCmpbord === null || _isCmpbord === 'false') {
        // setTimeout(() => window.open('/', "_self"), 10);
        if (res.data.iskponbrd) {
          localStorage.setItem('icmpltonbrdng', true);
        }
        else if (!res.data.iinstlstrrtng || !res.data.iinstlwdgt || !res.data.ienblpxlapp) {
          localStorage.setItem('icmpltonbrdng', false);
        }
        else {
          localStorage.setItem('icmpltonbrdng', true);
        }
      }

      // onboarding steps flow
      if (!res.data.ienblpxlapp) { localStorage.setItem('ienblpxlapp', false); }
      else { localStorage.setItem('ienblpxlapp', res.data.ienblpxlapp); }

      if (!res.data.iinstlwdgt) { localStorage.setItem('iinstlwdgt', false); }
      else { localStorage.setItem('iinstlwdgt', res.data.iinstlwdgt); }

      if (!res.data.iinstlstrrtng) { localStorage.setItem('iinstlstrrtng', false); }
      else { localStorage.setItem('iinstlstrrtng', res.data.iinstlstrrtng); }
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
    yield put(loaded());
  } catch (error) {
    console.log(error, '====error');
  }
}

function* updSetupFlow(action) {
  try {
    const res = yield call(api.POST, '/auth/updSetupFlow', action.data);

    if (res.status === 'success') {
      // yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please refresh page.' }));
    }
  } catch (error) {
    console.log(error, '====error');
  }
}

function* getDataCount(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getDataCount', action.payload);

    if (res.status === 'success')
      yield put(actions.getDataCountSuccess(res.data));
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    console.log(error, '====error');
  }
}

function* loginWithWix(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/loginWithWix', action.payload);
    if (res.status === 'success') {
      localStorage.setItem('authToken', res.token);
      window.location.href = window.location.origin;
    }
    else if (res.status === 'not_found') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_active') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  }
  catch (error) {
    console.log(error, '====error');
  }
}

function* skipOnBoard() {
  try {
    yield put(load());
    const res = yield call(api.POST, '/skipOnBoard');

    if (res.status === 'success') {
      window.location.reload();
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    console.log(error, '====error');
  }
}

export function* watchGetProfile() {
  yield takeLatest(actions.getProfile.type, getProfile);
  yield takeLatest(actions.loginWithWix.type, loginWithWix);
}

export function* watchUpdSetupFlow() {
  yield takeLatest(actions.updSetupFlow.type, updSetupFlow);
}

export function* watchGetDataCount() {
  yield takeLatest(actions.getDataCount.type, getDataCount);
}

export function* watchSkipOnBoard() {
  yield takeLatest(actions.skipOnBoard.type, skipOnBoard);
}

export default function* rootSaga() {
  yield fork(watchGetProfile);
  yield fork(watchUpdSetupFlow);
  yield fork(watchGetDataCount);
  yield fork(watchSkipOnBoard);
}