import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/integration';
import { toastify } from '../ducks/toast';

function* importDataFromCSV(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/importDataFromCSV', action.data);
    if (res.status === 'success') {
      yield put(actions.importDataFromCSVSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getIntegrationMST(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getIntegrationMST', action.data);

    if (res.status === 'success') {
      yield put(actions.getIntegrationMSTSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getUserIntegration(action) {
  try {
    yield put(load());
    let res;
    if (action.data.typ)
      res = yield call(api.GET, '/getUserIntegration?shp=' + action.data.shp + '&typ=' + action.data.typ);
    else
      res = yield call(api.GET, '/getUserIntegration?shp=' + action.data.shp);

    if (res.status === 'success') {
      yield put(actions.getUserIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addUpdIntegration(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addUpdateIntegration', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delIntegration(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteIntegration', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getWebhookField(action) {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/webhook/getField?fuid=' + action.data.fuid);

    if (res.status === 'success') {
      yield put(actions.getWebhookFieldSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* startListening(action) {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/webhook/startNewListening?fuid=' + action.data.fuid);

    if (res.status === 'success') {
      yield put(actions.getWebhookFieldSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCstmWbhookField(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/webhook/createField', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      //get updated user integration data
      yield put(actions.getUserIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delWebhookField(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/webhook/delField', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      //get updated user integration data
      yield put(actions.getUserIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* syncToken(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/syncToken', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.syncTokenSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delSyncToken(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/removeSyncToken', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
      yield put(actions.delSyncTokenSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createInterakt', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.createInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateInteraktKey', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.updateInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
      yield put(actions.updateInteraktErr(res.data));
    }
    yield put(loaded());
  } catch (error) {
    yield put(actions.updateInteraktErr(true));
  }
}

function* addTmptIdInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addTmptId', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.addTmptIdInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delTmptIdInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/removeTmplById', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.delTmptIdInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateTmplIdInteraktStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateTmplIdStatus', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.updateTmplIdInteraktStatusSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateTmplMappingInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateTmplMapping', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.updateTmplMappingInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* sendWTAMessageInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/sendWTAMessage', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.sendWTAMessageInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addUpdateSyndication(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addUpdateSyndication', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createKlaviyo(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createKlaviyo', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delKlaviyo(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteKlaviyo', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWati(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createWati', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
      yield put(actions.watiTampList());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}
function* createWatiTmp(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createWatiTemplate', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
      yield put(actions.watiTampList());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delWati(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteWati', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delWatiTmp(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/removeWatiTmpl', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delWatiField(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/delWatiField', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* watiTampList(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getWatiTemplate', action.data);
    if (res.status === 'success') {
      yield put(actions.watiTampListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}
function* getWatiList(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getMappingField ', action.data);
    if (res.status === 'success') {
      yield put(actions.getWatiFieldSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateWatiTmp(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateTemplateData', action.data);
    if (res.status === 'success') {
      yield put(actions.updateWatiTemplateDataSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* sendWatiMsg(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/sendWatiMessage', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* sendTestDataWebhook(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/webhook/sendTestData', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateIntegrationStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateIntegrationStatus', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchImportDataFromCSV() {
  yield takeLatest("IMPORT_DATA_FROM_CSV", importDataFromCSV);
}
export function* watchGetIntegrationMST() {
  yield takeLatest("GET_INTEGRATION_MST", getIntegrationMST);
}
export function* watchGetUserIntegration() {
  yield takeLatest("GET_USER_INTEGRATION", getUserIntegration);
}
export function* watchAddUpdIntegration() {
  yield takeLatest("ADD_UPD_INTEGRATION", addUpdIntegration);
}
export function* watchDelIntegration() {
  yield takeLatest("DEL_INTEGRATION", delIntegration);
}
export function* watchGetWebhookField() {
  yield takeLatest("GET_WEBHOOK_FIELD", getWebhookField);
}
export function* watchCreateCstmWbhookField() {
  yield takeLatest("CREATE_WEBHOOK_FIELD", createCstmWbhookField);
}
export function* watchDelWebhookField() {
  yield takeLatest("DEL_WEBHOOK_FIELD", delWebhookField);
}
export function* watchStartListening() {
  yield takeLatest("START_LISTENING", startListening);
}
export function* watchSyncToken() {
  yield takeLatest("SYNC_TOKEN", syncToken);
}
export function* watchDelSyncToken() {
  yield takeLatest("DEL_SYNC_TOKEN", delSyncToken);
}
export function* watchCreateInterakt() {
  yield takeLatest("CREATE_INTERAKT", createInterakt);
}
export function* watchUpdateInterakt() {
  yield takeLatest("UPDATE_INTERAKT", updateInterakt);
}
export function* watchAddTmptIdInterakt() {
  yield takeLatest("ADD_TMPT_ID_INTERAKT", addTmptIdInterakt);
}
export function* watchDelTmptIdInterakt() {
  yield takeLatest("DEL_TMPT_ID_INTERAKT", delTmptIdInterakt);
}
export function* watchUpdateTmplIdStatus() {
  yield takeLatest("UPDATE_TMPT_ID_INTERAKT_STATUS", updateTmplIdInteraktStatus);
}
export function* watchUpdateTmplMappingInterakt() {
  yield takeLatest("UPDATE_TMPL_MAPPING_INTERAKT", updateTmplMappingInterakt);
}
export function* watchSendWTAMessageInterakt() {
  yield takeLatest("SEND_WTA_MESSAGE_INTERAKT", sendWTAMessageInterakt);
}
export function* watchAddUpdateSyndication() {
  yield takeLatest("ADD_UPD_SYNDICATION", addUpdateSyndication);
}
export function* watchCreateKlaviyo() {
  yield takeLatest("CREATE_KLAVIYO", createKlaviyo);
}
export function* watchDelKlaviyo() {
  yield takeLatest("DEL_KLAVIYO", delKlaviyo);
}
export function* watchCreateWati() {
  yield takeLatest("CREATE_WATI", createWati);
}
export function* watchDelWati() {
  yield takeLatest("DEL_WATI", delWati);
}
export function* watchWatiTampList() {
  yield takeLatest("WATI_TAMP_LIST", watiTampList);
}
export function* watchGetWatiList() {
  yield takeLatest("GET_WATI_LIST", getWatiList);
}
export function* watchUpdateWatiTmp() {
  yield takeLatest("UPDATE_WATI_TMP", updateWatiTmp)
}
export function* watchcreateWatiTmp() {
  yield takeLatest("CREATE_WATI_TMP", createWatiTmp)
}
export function* watchDelWatiTmp() {
  yield takeLatest("DEL_WATI_TMP", delWatiTmp)
}
export function* watchDelWatiField() {
  yield takeLatest("DEL_WATI_FIELD", delWatiField)
}
export function* watchSendWatiMsg() {
  yield takeLatest("SEND_WATI_MSG", sendWatiMsg)
}
export function* watchSendTestDataWebhook() {
  yield takeLatest("SEND_TEST_DATA_WEBHOOK", sendTestDataWebhook)
}
export function* watchupdateIntegrationStatus() {
  yield takeLatest("UPDATE_INTEGRATION_STATUS", updateIntegrationStatus)
}



export default function* rootSaga() {
  yield fork(watchImportDataFromCSV);
  yield fork(watchGetIntegrationMST);
  yield fork(watchGetUserIntegration);
  yield fork(watchAddUpdIntegration);
  yield fork(watchDelIntegration);
  yield fork(watchGetWebhookField);
  yield fork(watchDelWebhookField);
  yield fork(watchCreateCstmWbhookField);
  yield fork(watchStartListening);
  yield fork(watchSyncToken);
  yield fork(watchDelSyncToken);
  yield fork(watchCreateInterakt);
  yield fork(watchUpdateInterakt);
  yield fork(watchAddTmptIdInterakt);
  yield fork(watchDelTmptIdInterakt);
  yield fork(watchUpdateTmplIdStatus);
  yield fork(watchUpdateTmplMappingInterakt);
  yield fork(watchSendWTAMessageInterakt);
  yield fork(watchAddUpdateSyndication);
  yield fork(watchCreateKlaviyo);
  yield fork(watchDelKlaviyo);
  yield fork(watchCreateWati);
  yield fork(watchDelWati);
  yield fork(watchWatiTampList);
  yield fork(watchGetWatiList);
  yield fork(watchUpdateWatiTmp);
  yield fork(watchcreateWatiTmp);
  yield fork(watchDelWatiTmp);
  yield fork(watchDelWatiField);
  yield fork(watchSendWatiMsg);
  yield fork(watchSendTestDataWebhook);
  yield fork(watchupdateIntegrationStatus);
}
