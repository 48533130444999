import { fromJS } from 'immutable';

export const getBranding = (data) => ({ type: "GET_BRANDING", data });
export const getBrandingSuccess = (data) => ({ type: "GET_BRANDING_SUCCESS", data });

export const updBranding = (data) => ({ type: "UPD_BRANDING", data });

export const removeBrndImg = (data) => ({ type: "REMOVE_BRND_IMG", data });

export const verifyEmail = (data) => ({ type: "VERIFY_EMAIL", data });
export const verifyEmailSuccess = (data) => ({ type: "VERIFY_EMAIL_SUCCESS", data });

const initialState = fromJS({

});

function branding(state = initialState, action) {
  switch (action.type) {

    case "GET_BRANDING_SUCCESS":
      return { ...state, brandList: action.data };

    case "VERIFY_EMAIL_SUCCESS":
      return { ...state, verifyEmlList: action.data };

    default:
      return { ...state };
  }
}

export default branding;