import { createSlice } from '@reduxjs/toolkit';

const initialState = {}

const importPage = createSlice({
  name: 'importPage',
  initialState,
  reducers: {
    getImportData: (state, action) => { },
    getImportDataSuccess: (state, action) => {
      return { ...state, ImportList: action.payload }
    }
  }
});

export const {
  getImportData,
  getImportDataSuccess
} = importPage.actions;

export default importPage.reducer;