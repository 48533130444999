import React from 'react';
import { BlockStack, Box, Button, ButtonGroup, Card, Checkbox, Collapsible, Divider, Filters, Icon, InlineGrid, InlineStack, Modal, Page, PageActions, RadioButton, ResourceItem, ResourceList, Select, Text, TextField, Thumbnail } from '@shopify/polaris';
import { CashDollarIcon, ChevronDownIcon, ChevronUpIcon, DeleteIcon, EditIcon, ImageIcon, PlusIcon } from '@shopify/polaris-icons';

function Upsell(props) {
  const { state, changeNameValue, openCloseModal } = props;

  const filterControl = (
    <Filters
      focused
      queryValue={state.groupNewSearch}
      queryPlaceholder='Search Product'
      onQueryChange={(e) => changeNameValue({ groupNewSearch: e })}
      filters={[]}
      appliedFilters={[]}
      onQueryClear={() => changeNameValue({ groupNewSearch: '' })}
    />
  );

  const options = [
    { label: 'No discount', value: 0 },
    { label: 'Percentage', value: 1 },
    { label: 'Fixed amount', value: 2 },
    // { label: 'Free shipping', value: 3 },
  ];

  const SelectionActions = [{ content: 'Save', onAction: () => props.handleProdSave() }]
  const SelectionOneProAction = [{ content: 'Save', onAction: () => props.handleOneProdSave() }]

  return (
    <Page title={'Upsell offer'}
      primaryAction={!state.upsellEditView && !state.upsellView && state.upsellList.length > 0 ? <Button id='primary_btn' variant='primary' icon={PlusIcon} onClick={() => changeNameValue({ upsellView: true })}>New upsell offer</Button> : false}
      backAction={state.upsellView ? { onAction: () => props.handleBackToMain() } : false}>
      <Box paddingBlockEnd={400}>
        {state.upsellList && state.upsellList.length > 0 && !state.upsellView &&
          <Card>
            <BlockStack gap={400}>
              {state.upsellList.map((data, i) => (
                <Card key={i}>
                  <InlineStack gap={400} wrap={false} blockAlign='center' align='space-between'>
                    <InlineStack gap={400} wrap={false} blockAlign='center'>
                      <div className='upsell_img'>
                        <Text variant='headingMd' as='h4' fontWeight='semibold'>{i + 1}</Text>
                      </div>
                      <Box>
                        <BlockStack gap={100}>
                          <InlineStack>
                            <Checkbox
                              label='Upsell offer'
                              checked={data.ia}
                              onChange={() => props.handleUpdateUpslStatus(data._id, !data.ia)}
                            />
                          </InlineStack>
                          <Text variant='bodyMd' as='p' fontWeight='regular'>Impressions: 0
                            |
                            Upsells: 0
                            |
                            Revenue: ₹0</Text>
                        </BlockStack>
                      </Box>
                    </InlineStack>
                    <ButtonGroup>
                      <Button icon={EditIcon} onClick={() => props.handleUpdateUpsellShow(data)}></Button>
                      <Button icon={DeleteIcon} onClick={() => { openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameValue({ upsProId: data._id }) }}></Button>
                    </ButtonGroup>
                  </InlineStack>
                </Card>
              ))
              }
            </BlockStack>
          </Card>
        }

        {state.upsellView &&
          <BlockStack gap={600}>
            <Card>
              <div onClick={() => changeNameValue({ tabEvent: 'firstTabCollapse' })} id='cursorPoint'>
                <InlineStack gap={400} wrap={false} blockAlign='center'>
                  <div className='upsell_img'>
                    <Icon source={CashDollarIcon} />
                  </div>
                  <Box>
                    <BlockStack gap={100}>
                      <Text variant='bodyMd' as='h4' fontWeight='semibold'>Select trigger</Text>
                      <Text variant='bodyMd' as='p' fontWeight='regular'>This funnel will trigger upsell offers when any of the following criteria are met</Text>
                    </BlockStack>
                  </Box>
                </InlineStack>
              </div>
              <Collapsible
                open={state.tabEvent === 'firstTabCollapse'}
                id="db_collaspe"
                transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                <Box paddingBlock={500}>
                  <BlockStack gap={400}>
                    <Card>
                      <RadioButton
                        label="Trigger this funnel for all deals"
                        checked={state.tgrtyp === 0}
                        onChange={() => changeNameValue({ 'tgrtyp': 0 })}
                      />
                    </Card>
                    <Card>
                      <InlineStack align='space-between' blockAlign='center'>
                        <RadioButton
                          label="Trigger this funnel only for this product"
                          checked={state.tgrtyp === 1}
                          onChange={() => changeNameValue({ 'tgrtyp': 1 })}
                        />
                        <Button onClick={() => props.handleSelectProducts()} disabled={state.tgrtyp === 0 ? true : false}>{state.disProSelectedItem.length > 0 ? `${state.disProSelectedItem.length} product selected` : 'Select products'}</Button>
                      </InlineStack>
                    </Card>
                  </BlockStack>
                </Box>
                <ButtonGroup>
                  <Button id='primary_btn' variant='primary'
                    onClick={() => changeNameValue({ tabEvent: 'secondTabCollapse' })}
                    disabled={state.tgrtyp === 1 && !state.disProSelectedItem.length > 0 ? true : false}>
                    {state.upsellEditView ? 'Save & Continue' : 'Continue'}</Button>
                </ButtonGroup>
              </Collapsible>
            </Card>

            <Card>
              <div onClick={() => changeNameValue({ tabEvent: 'secondTabCollapse' })} id='cursorPoint'>
                <InlineStack gap={400} wrap={false} blockAlign='center'>
                  <div className='upsell_img'>
                    <Icon source={CashDollarIcon} />
                  </div>
                  <Box>
                    <BlockStack gap={100}>
                      <Text variant='bodyMd' as='h4' fontWeight='semibold'>Post purchase upsell</Text>
                      <Text variant='bodyMd' as='p' fontWeight='regular'>Choose a products and configure your upsell offer settings</Text>
                    </BlockStack>
                  </Box>
                </InlineStack>
              </div>
              <Collapsible
                open={state.tabEvent === 'secondTabCollapse'}
                id="db_collaspe"
                transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                <Box paddingBlock={500}>
                  {state.oneProSelected && state.oneProSelected.length > 0 ?
                    state.oneProSelected.map((data) =>
                      <div className='upsell-bg' key={data.pid}>
                        <BlockStack gap={400}>
                          <InlineStack gap={400} wrap={false} blockAlign='center' align='space-between'>
                            <InlineStack gap={400}>
                              <Thumbnail
                                source={data.pimg ? data.pimg : ImageIcon}
                                alt="img"
                              />
                              <Box>
                                <BlockStack gap={100}>
                                  <Text variant='bodyMd' as='h4' fontWeight='semibold'>{data.pnm}</Text>
                                </BlockStack>
                              </Box>
                            </InlineStack>
                            <ButtonGroup>
                              <Button>Preview</Button>
                              <Button id='primary_btn' variant='primary' onClick={() => { changeNameValue({ groupNewSearch: '' }); openCloseModal('oneViewProOpen', state.oneViewProOpen, 'open') }}>Replace product</Button>
                              <Button icon={DeleteIcon} onClick={() => props.handleRemoveProduct()}></Button>
                            </ButtonGroup>
                          </InlineStack>
                          <Divider borderColor="border" />
                          <Box>
                            <BlockStack gap={200}>
                              <InlineStack gap={400}>
                                <Box width='20%'>
                                  <Select
                                    label="Offer a discount type"
                                    options={options}
                                    value={state.dscnttyp}
                                    onChange={(e) => changeNameValue({ 'dscnttyp': Number(e) })}
                                  />
                                </Box>
                                <Box width='20%'>
                                  {state.dscnttyp === 1 &&
                                    <TextField
                                      label="Discount amount"
                                      type="number"
                                      value={state.dscntamnt}
                                      onChange={(e) => changeNameValue({ 'dscntamnt': Number(e) })}
                                      prefix="%"
                                      autoComplete="off"
                                    />
                                  }
                                  {state.dscnttyp === 2 &&
                                    <TextField
                                      label="Discount amount"
                                      type="number"
                                      value={state.dscntamnt}
                                      onChange={(e) => changeNameValue({ 'dscntamnt': Number(e) })}
                                      prefix="Rs."
                                      autoComplete="off"
                                    />
                                  }
                                </Box>
                              </InlineStack>
                            </BlockStack>
                          </Box>
                          <Divider borderColor="border" />
                          {/* <Box>
                            <BlockStack gap={200}>
                              <Text variant='bodyMd' as='p' fontWeight='semibold'>Product reviews</Text>
                              <BlockStack>
                                <RadioButton
                                  label="Use recent high rating reviews"
                                  checked={state.rvwtyp === 0}
                                  onChange={() => changeNameValue({ 'rvwtyp': 0 })}
                                />
                                <RadioButton
                                  label="Select photos reviews that will be displayed in the upsell offer"
                                  checked={state.rvwtyp === 1}
                                  onChange={() => changeNameValue({ 'rvwtyp': 1 })}
                                />
                              </BlockStack>
                            </BlockStack>
                          </Box>
                          <Divider borderColor="border" /> */}
                          <Box>
                            <BlockStack gap={200}>
                              <Text variant='bodyMd' as='p' fontWeight='semibold'>Quantity selector</Text>
                              <Checkbox
                                label="Allow multiple units of the selected upsell product"
                                checked={state.iqnty}
                                onChange={() => changeNameValue({ iqnty: !state.iqnty })}
                              />
                            </BlockStack>
                          </Box>
                          <Divider borderColor="border" />
                          <Box>
                            <BlockStack gap={200}>
                              <Text variant='bodyMd' as='p' fontWeight='semibold'>Timer</Text>
                              <Checkbox
                                label="Display the time for which the offer is available"
                                checked={state.icnttmr}
                                onChange={() => changeNameValue({ icnttmr: !state.icnttmr })}
                              />
                              {state.icnttmr &&
                                <Box width='50%' paddingInlineStart={600}>
                                  <TextField
                                    label="Timer duration (minutes)"
                                    type='number'
                                    value={state.tmrdr}
                                    onChange={(value) => {
                                      const numValue = Number(value);
                                      changeNameValue({ tmrdr: numValue <= 0 ? '' : numValue });
                                    }}
                                    autoComplete="off"
                                  />
                                </Box>
                              }
                            </BlockStack>
                          </Box>
                          <Divider borderColor="border" />
                          <Box>
                            <BlockStack gap={200}>
                              <Checkbox
                                label="Skip offer if the product is in the original order"
                                checked={state.iskporgn}
                                onChange={() => changeNameValue({ 'iskporgn': !state.iskporgn })}
                              />
                            </BlockStack>
                          </Box>
                          <Divider borderColor="border" />
                          <Box>
                            <div onClick={() => changeNameValue({ 'contentTabCollapse': !state.contentTabCollapse })} id='cursorPoint'>
                              <InlineStack gap={400} wrap={false} blockAlign='center' align='space-between'>
                                <Box paddingBlockEnd={200}>
                                  <Text variant='bodyMd' as='h4' fontWeight='semibold'>Configure texts that will be displayed in the upsell offer</Text>
                                </Box>
                                <Box>
                                  {state.contentTabCollapse ?
                                    <Icon source={ChevronUpIcon} tone="base"></Icon> :
                                    <Icon source={ChevronDownIcon} tone="base"></Icon>}
                                </Box>
                              </InlineStack>
                            </div>
                            <Collapsible
                              open={state.contentTabCollapse}
                              id="db_collaspe"
                              transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                              <Box paddingBlock={100}>
                                <BlockStack gap={400}>
                                  <InlineGrid columns={2} alignItems='center'>
                                    <BlockStack gap={100}>
                                      <Text variant='bodyMd' as='h4' fontWeight='semibold'>Offer title</Text>
                                      <Text variant='bodyMd' as='p' fontWeight='regular'>{`Use {{time}} for the timer countdown`}</Text>
                                    </BlockStack>
                                    <Box width='85%'>
                                      <TextField
                                        placeholder='Limited time offer {{time}}'
                                        value={state.ofrttl}
                                        onChange={(e) => changeNameValue({ 'ofrttl': e })}
                                        autoComplete="off"
                                      />
                                    </Box>
                                  </InlineGrid>
                                  <InlineGrid columns={2} alignItems='center'>
                                    <BlockStack gap={100}>
                                      <Text variant='bodyMd' as='h4' fontWeight='semibold'>Offer subtitle text</Text>
                                      <Text variant='bodyMd' as='p' fontWeight='regular'>{`Use {{discount}} for the discount amount`}</Text>
                                    </BlockStack>
                                    <Box width='85%'>
                                      <TextField
                                        placeholder='Get this item only now for {{discount}} off'
                                        value={state.ofrsbttl}
                                        onChange={(e) => changeNameValue({ 'ofrsbttl': e })}
                                        autoComplete="off"
                                      />
                                    </Box>
                                  </InlineGrid>
                                  <InlineGrid columns={2} alignItems='center'>
                                    <BlockStack gap={100}>
                                      <Text variant='bodyMd' as='h4' fontWeight='semibold'>Discount label</Text>
                                      <Text variant='bodyMd' as='p' fontWeight='regular'>{`Use {{discount}} for the discount amount`}</Text>
                                    </BlockStack>
                                    <Box width='85%'>
                                      <TextField
                                        placeholder='{{discount}} off'
                                        value={state.ofrdscntlbl}
                                        onChange={(e) => changeNameValue({ 'ofrdscntlbl': e })}
                                        autoComplete="off"
                                      />
                                    </Box>
                                  </InlineGrid>
                                </BlockStack>
                              </Box>
                            </Collapsible>
                          </Box>
                        </BlockStack>
                      </div>
                    )
                    :
                    <Card>
                      <InlineStack align='space-between' blockAlign='center'>
                        <Text>Choose a products to upsell</Text>
                        <Button onClick={() => props.handleOneSelectProduct()}>Select products</Button>
                      </InlineStack>
                    </Card>
                  }

                </Box>
                <InlineStack align={state.oneProSelected && state.oneProSelected.length > 0 ? 'center' : 'end'}>
                  <ButtonGroup>
                    <Button>Preview</Button>
                    <Button id='primary_btn' variant='primary' onClick={() => {
                      if (state.oneProSelected && state.oneProSelected.length > 0 && !state.upsellEditView) {
                        props.handleCreateUpsell();
                      } else if (state.upsellEditView) {
                        props.handleUpdateUpsell();
                      } else {
                        openCloseModal('noSelProOpen', state.noSelProOpen, 'open');
                      }
                    }}>Save & Live</Button>
                  </ButtonGroup>
                </InlineStack>
              </Collapsible>
            </Card>
          </BlockStack>
        }
        {state.upsellList && state.upsellList.length === 0 && !state.upsellView &&
          <Card>
            <Box paddingBlock={1600}>
              <BlockStack gap={400} inlineAlign='center'>
                <img src={"https://storage.googleapis.com/shopify_review_app/dash/incentive_create.svg"} alt='incentive_create' height="165px" />
                <Text variant='headingMd' as='h3' fontWeight='semibold'>Post-Purchase Upsell</Text>
                <Text variant='headingXs' as='p' fontWeight='regular'>Motivate customers to leave reviews with perks like discounts or free delivery</Text>
                <Box>
                  <Button id='primary_btn' variant='primary' onClick={() => changeNameValue({ upsellView: true })}>New upsell offer</Button>
                </Box>
              </BlockStack>
            </Box>
          </Card>
        }
      </Box>

      <Modal //All product
        open={state.groupViewProOpen}
        limitHeight={true}
        noScroll={true}
        onClose={(e) => openCloseModal('groupViewProOpen', state.groupViewProOpen, e)}
        title={"Add products"}>

        <Modal.Section>
          <div className='groupProList'>
            <ResourceList
              selectable
              headerContent={`Showing ${state.groupProdOptions.length} Products`}
              totalItemsCount={state.createProSelectedItem.length}
              selectedItems={state.createProSelectedItem}
              onSelectionChange={(e) => props.selectProducts('createProSelectedItem', e)}
              items={state.groupProdOptions}
              filterControl={filterControl}
              flushFilters
              pagination={{
                hasPrevious: (state.hasPreviousPage === true) ? true : false,
                hasNext: (state.hasNextPage === true) ? true : false,
                onNext: () => props.groupPagiCall('+1'),
                onPrevious: () => props.groupPagiCall('-1')
              }}
              promotedBulkActions={SelectionActions}
              renderItem={(item) => {
                return (
                  <ResourceItem
                    id={item.id}
                    media={item.media}
                    verticalAlignment='center'
                    onClick={() => {
                      props.selectOneProduct(
                        'createProSelectedItem',
                        [...state.createProSelectedItem, item.id]
                      );
                    }}>
                    <Text variant="bodyMd" fontWeight="bold" as="h3">{item.label}</Text>
                  </ResourceItem>
                );
              }} />
          </div>
        </Modal.Section>
      </Modal>

      <Modal //One product
        open={state.oneViewProOpen}
        limitHeight={true}
        noScroll={true}
        onClose={(e) => openCloseModal('oneViewProOpen', state.oneViewProOpen, e)}
        title={"Add products"}>

        <Modal.Section>
          <div className='groupProList'>
            <ResourceList
              selectable={state.oneProSelectedItem.length === 0}
              headerContent={`Showing ${state.groupProdOptions.length} Products`}
              totalItemsCount={state.groupProdOptions.length}
              selectedItems={state.oneProSelectedItem}
              onSelectionChange={(selectedItems) => {
                if (selectedItems.length <= 1) {
                  props.selectOneProduct('oneProSelectedItem', selectedItems);
                }
              }}
              items={state.groupProdOptions}
              filterControl={filterControl}
              flushFilters
              pagination={{
                hasPrevious: state.hasPreviousPage,
                hasNext: state.hasNextPage,
                onNext: () => props.groupPagiCall('+1'),
                onPrevious: () => props.groupPagiCall('-1'),
              }}
              promotedBulkActions={SelectionOneProAction}
              renderItem={(item) => {
                const isSelected = state.oneProSelectedItem.includes(item.id);
                return (
                  <ResourceItem
                    id={item.id}
                    media={item.media}
                    verticalAlignment='center'
                    disabled={state.oneProSelectedItem.length > 0 && !isSelected}
                    onClick={() => {
                      props.selectOneProduct(
                        'oneProSelectedItem',
                        [...state.oneProSelectedItem, item.id]
                      );
                    }}
                  >
                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                      {item.label}
                    </Text>
                  </ResourceItem>
                );
              }}
            />
          </div>
        </Modal.Section>
      </Modal>

      <Modal //No product selected
        size='small'
        open={state.noSelProOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('noSelProOpen', state.noSelProOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='semibold' variant='headingMd' as='h6'>No upsell product was selected</Text>
            <Text fontWeight='regular' variant='headingMd' as='p'>Please select a product to activate the Post Purchase upsell.</Text>
            <PageActions
              primaryAction={<Button id='primary_btn' onClick={() => props.handleOneSelectProduct()}>Select product</Button>}
              secondaryActions={[{
                content: 'Cancel',
                onAction: () => openCloseModal('noSelProOpen', state.noSelProOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

      <Modal //Remove upsell
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this?</Text>
            <PageActions
              primaryAction={{
                id: 'primary_btn',
                content: 'Yes',
                onAction: () => props.handleRemoveUpsell()
              }}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
}

export default Upsell;