import { Text, Checkbox, InlineStack, Box, InlineGrid, Tooltip, TextField } from '@shopify/polaris';
import { ThemeTemplateIcon, ImageWithTextOverlayIcon, EyeCheckMarkIcon, HeartIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect } from 'react';

const customHandler = {
  'color': function (value) {
    if (value === 'custom-color') value = window.prompt('Enter Hex Color Code');
    this.quill.format('color', value, 'user');
  },
  'background': function (value) {
    if (value === 'custom-background') value = window.prompt('Enter Hex Color Code');
    this.quill.format('background', value, 'user');
  }
};

const toolbarWithoutDynamic = {
  container: [
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline'],
    [
      { 'list': 'ordered' }, { 'list': 'bullet' },
      { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
      { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'clean'],
    // [{ 'align': [] }], 
  ],
  handlers: customHandler
};

const moduleQuillWithoutDynamic = {
  toolbar: toolbarWithoutDynamic
};

const WriteReviewTab = ({ props }) => {
  let { state, changeNameValue, handleTextChange } = props;
  let cnf = state.cnf;

  var style = document.createElement('style');
  document.head.appendChild(style);

  const editorFocus = (typ) => {
    if (document.getElementsByClassName('showEditor').length) {
      const arrShow = document.getElementsByClassName('showEditor');
      for (let i = 0; i < arrShow.length; i++) {
        const oShow = arrShow[i];
        oShow.classList.remove('showEditor');
      }
    }
    if (document.getElementsByClassName(typ).length > 0)
      document.getElementsByClassName(typ)[0].classList.add('showEditor');
  }

  let verticalTabsArr = [
    { title: 'Form layouts', img: ThemeTemplateIcon },
    { title: 'Form fields', img: ImageWithTextOverlayIcon },
    { title: 'Form color combination', img: EyeCheckMarkIcon },
    { title: 'Thank you message', img: HeartIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <InlineGrid columns={2} gap={300}>
          {state.writeArr && state.writeArr.length > 0 && state.writeArr.map((val, i) => {
            return (
              <div key={i} className={val._id === state.currWriteId ? 'select_block active' : 'select_block'}
                onClick={() => changeNameValue({ currWriteId: val._id }, 'isEditable')}>
                <InlineStack align='center' blockAlign='center'>
                  <Checkbox tone={val._id === state.currWriteId ? 'magic' : ''}
                    checked={val._id === state.currWriteId} />
                  <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                    {val.dn ? val.dn : 'form'}</Text>
                </InlineStack>
                <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
              </div>
            );
          })}
        </InlineGrid>
        :
        state.sidebarActiveIndex === 1 ?
          <Box>

            {cnf && cnf.wrtfrmistrrtng !== undefined &&
              <Box paddingBlockEnd={500}>
                <Checkbox
                  label={<Text fontWeight='medium'>Star rating field</Text>}
                  checked={cnf.wrtfrmistrrtng}
                  onChange={(e) => handleTextChange({ wrtfrmistrrtng: e })}
                />
                {cnf.wrtfrmstrlbltxt !== undefined &&
                  <Box paddingBlockStart={100} paddingInlineStart={800}>
                    <TextField
                      label={<Text>Label</Text>}
                      disabled={!cnf.wrtfrmistrrtng}
                      value={cnf.wrtfrmstrlbltxt}
                      onChange={(e) => handleTextChange({ wrtfrmstrlbltxt: e })}
                      autoComplete="off" />
                  </Box>
                }
              </Box>
            }

            {cnf && cnf.wrtfrminptittl !== undefined &&
              <Box paddingBlockEnd={500}>
                <Checkbox
                  label={<Text fontWeight='medium'>Review title field</Text>}
                  checked={cnf.wrtfrminptittl}
                  onChange={(e) => handleTextChange({ wrtfrminptittl: e })}
                />
                <Box paddingBlockStart={200} paddingInlineStart={800}>
                  {cnf.wrtfrminptttllbltxt !== undefined &&
                    <TextField
                      label={<Text>Label</Text>}
                      disabled={!cnf.wrtfrminptittl}
                      value={cnf.wrtfrminptttllbltxt}
                      onChange={(e) => handleTextChange({ wrtfrminptttllbltxt: e })}
                      autoComplete="off" />
                  }
                  {cnf.wrtfrminptplchldrtxt !== undefined &&
                    <Box paddingBlockStart={300}>
                      <TextField
                        label={<Text>Placeholder text</Text>}
                        disabled={!cnf.wrtfrminptittl}
                        value={cnf.wrtfrminptplchldrtxt}
                        onChange={(e) => handleTextChange({ wrtfrminptplchldrtxt: e })}
                        autoComplete="off" />
                    </Box>
                  }
                </Box>
              </Box>
            }

            {cnf && (cnf.wrtfrminptcntlbltxt !== undefined || cnf.wrtfrminptcntplchdlrtxt !== undefined) &&
              <Box paddingBlockEnd={500}>
                <Text fontWeight='bold' as='h6'>Review content field</Text>
                {cnf.wrtfrminptcntlbltxt !== undefined &&
                  <div>
                    <Box paddingBlockStart={200} paddingInlineStart={600}>
                      <TextField
                        label={<Text>Label</Text>}
                        value={cnf.wrtfrminptcntlbltxt}
                        onChange={(e) => handleTextChange({ wrtfrminptcntlbltxt: e })}
                        autoComplete="off" />
                    </Box>
                  </div>
                }
                {cnf.wrtfrminptcntplchdlrtxt !== undefined &&
                  <Box paddingBlockStart={300} paddingInlineStart={600}>
                    <TextField
                      label={<Text>Placeholder text</Text>}
                      value={cnf.wrtfrminptcntplchdlrtxt}
                      onChange={(e) => handleTextChange({ wrtfrminptcntplchdlrtxt: e })}
                      autoComplete="off" />
                  </Box>
                }
              </Box>
            }

            {cnf &&
              <Box paddingBlockEnd={500}>
                <Text fontWeight='bold' as='h6'>Media field</Text>
                <Box paddingBlockStart={200} paddingInlineStart={600}>

                  {cnf.wrtfrminptimedia !== undefined &&
                    <Checkbox
                      label={<Text>Allow reviewers to upload photo</Text>}
                      checked={cnf.wrtfrminptimedia}
                      onChange={(e) => handleTextChange({ wrtfrminptimedia: e })}
                    />
                  }
                  {cnf.wrtfrminptivdo !== undefined &&
                    <Box paddingBlock={100}>
                      {state.isFreePln === true ?
                        <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                          <Checkbox
                            disabled
                            label={<Text>Allow reviewers to upload video</Text>}
                            checked={false}
                          />
                        </Tooltip>
                        :
                        <Checkbox
                          label={<Text>Allow reviewers to upload video</Text>}
                          checked={cnf.wrtfrminptivdo}
                          onChange={(e) => handleTextChange({ wrtfrminptivdo: e })}
                        />
                      }
                    </Box>
                  }
                  {cnf.wrtfrminptiytburl !== undefined &&
                    <Box>
                      {state.isFreePln === true ?
                        <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                          <Checkbox
                            disabled
                            label={<Text>Allow reviewers to submit youtube URL</Text>}
                            checked={false}
                          />
                        </Tooltip>
                        :
                        <Checkbox
                          label={<Text>Allow reviewers to submit youtube URL</Text>}
                          checked={cnf.wrtfrminptiytburl}
                          onChange={(e) => handleTextChange({ wrtfrminptiytburl: e })}
                        />
                      }
                    </Box>
                  }

                  <Box paddingBlockStart={200}>
                    <TextField
                      disabled={!cnf.wrtfrminptimedia === true && !cnf.wrtfrminptivdo === true && !cnf.wrtfrminptiytburl === true}
                      label={<Text >Label</Text>}
                      value={cnf.wrtfrminptmdatxt}
                      onChange={(e) => handleTextChange({ wrtfrminptmdatxt: e })}
                      autoComplete="off" />

                    <Box paddingBlockStart={200}>
                      <TextField
                        disabled={!cnf.wrtfrminptimedia === true && !cnf.wrtfrminptivdo === true && !cnf.wrtfrminptiytburl === true}
                        value={cnf.wrtfrminptmdatxt2}
                        onChange={(e) => handleTextChange({ wrtfrminptmdatxt2: e })}
                        autoComplete="off" />
                    </Box>
                  </Box>

                </Box>
              </Box>
            }

            {cnf &&
              <Box paddingBlockEnd={500}>
                {cnf.wrtfrminptemllbl !== undefined &&
                  <Box>
                    <Text fontWeight='bold' as='h6'>Email field</Text>
                    <Box paddingInlineStart={600} paddingBlockStart={200}>
                      <TextField
                        label={<Text>Label</Text>}
                        value={cnf.wrtfrminptemllbl}
                        onChange={(e) => handleTextChange({ wrtfrminptemllbl: e })}
                        autoComplete="off" />
                    </Box>
                  </Box>
                }
                {cnf.wrtfrminptemlplchldr !== undefined &&
                  <Box paddingInlineStart={600} paddingBlockStart={200}>
                    <TextField
                      label={<Text >Placeholder text</Text>}
                      value={cnf.wrtfrminptemlplchldr}
                      onChange={(e) => handleTextChange({ wrtfrminptemlplchldr: e })}
                      autoComplete="off" />
                  </Box>
                }
              </Box>
            }

            {cnf &&
              <Box paddingBlockEnd={500}>
                {cnf.wrtfrminptunmlbl !== undefined &&
                  <Box>
                    <Text fontWeight='bold' as='h6'>Name field</Text>
                    <Box paddingBlockStart={200} paddingInlineStart={600}>
                      <TextField
                        label={<Text>Username label text</Text>}
                        value={cnf.wrtfrminptunmlbl}
                        onChange={(e) => handleTextChange({ wrtfrminptunmlbl: e })}
                        autoComplete="off" />
                    </Box>
                  </Box>
                }
                {cnf && cnf.wrtfrminptunmplchldr !== undefined &&
                  <Box paddingInlineStart={600} paddingBlockStart={200}>
                    <TextField
                      label={<Text>Username placeholder text</Text>}
                      value={cnf.wrtfrminptunmplchldr}
                      onChange={(e) => handleTextChange({ wrtfrminptunmplchldr: e })}
                      autoComplete="off" />
                  </Box>
                }
              </Box>
            }

            {cnf && cnf.wrtfrmsbmtbtntxt !== undefined &&
              <Box paddingBlockEnd={500}>
                <Text fontWeight='bold' as='h6'>Submit button</Text>
                <Box paddingBlockStart={200} paddingInlineStart={600}>
                  <TextField
                    label={<Text >Label</Text>}
                    value={cnf.wrtfrmsbmtbtntxt}
                    onChange={(e) => handleTextChange({ wrtfrmsbmtbtntxt: e })}
                    autoComplete="off" />
                </Box>
              </Box>
            }

            {cnf.wrtfrmircmndprdct !== undefined &&
              <Box paddingBlockEnd={400}>
                <Checkbox
                  label={<Text >Show recommend product option</Text>}
                  checked={cnf.wrtfrmircmndprdct}
                  onChange={(e) => handleTextChange({ wrtfrmircmndprdct: e })}
                />
                <Box paddingInlineStart={600} paddingBlockStart={100}>
                  <TextField
                    disabled={!cnf.wrtfrmircmndprdct}
                    value={cnf.wrtfrmrcmndlbl}
                    onChange={(e) => handleTextChange({ wrtfrmrcmndlbl: e })}
                    autoComplete="off" />
                </Box>
              </Box>
            }

            {cnf.wrtfrmitnc !== undefined &&
              <Box paddingBlockEnd={500}>
                <Checkbox
                  label={<Text >Show terms & condition label</Text>}
                  checked={cnf.wrtfrmitnc}
                  onChange={(e) => handleTextChange({ wrtfrmitnc: e })}
                />
                <Box paddingInlineStart={600}>
                  {cnf.wrtfrmitnc !== true ?
                    <TextField
                      disabled={true}
                      value={cnf.wrtfrmtnclbl}
                      onChange={(e) => handleTextChange({ wrtfrmtnclbl: e })}
                      autoComplete="off" />
                    :
                    <ReactQuill disabled value={cnf.wrtfrmtnclbl} className='hideEditor wrtfrmtnclbl'
                      modules={moduleQuillWithoutDynamic}
                      onChange={(newValue, delta, source) => {
                        if (source === 'user') { handleTextChange({ wrtfrmtnclbl: newValue }) }
                      }}
                      onFocus={() => editorFocus('wrtfrmtnclbl')} />
                  }
                </Box>
              </Box>
            }

          </Box>
          :
          state.sidebarActiveIndex === 2 ?
            <Box>
              <InlineStack direction={'column'} gap={500}>
                {cnf && cnf.wrtfrmistrrtng === true && cnf.wrtfrmttltxtclr !== undefined &&
                  <ColorPick
                    labelName='Title font color'
                    colorKey="wrtfrmttltxtclr"
                    colorValue={cnf.wrtfrmttltxtclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf && cnf.wrtfrmistrrtng === true && cnf.wrtfrmbgclr !== undefined &&
                  <ColorPick
                    labelName='Title background color'
                    colorKey="wrtfrmbgclr"
                    colorValue={cnf.wrtfrmbgclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf && cnf.wrtfrminptlblclr !== undefined &&
                  <ColorPick
                    labelName='label color'
                    colorKey="wrtfrminptlblclr"
                    colorValue={cnf.wrtfrminptlblclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf && cnf.wrtfrmstrclr !== undefined &&
                  <ColorPick
                    labelName='Star color'
                    colorKey="wrtfrmstrclr"
                    colorValue={cnf.wrtfrmstrclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf && cnf.wrtfrmsbmtbtnbgclr !== undefined &&
                  <ColorPick
                    labelName='Submit background color'
                    colorKey="wrtfrmsbmtbtnbgclr"
                    colorValue={cnf.wrtfrmsbmtbtnbgclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf && cnf.wrtfrmsbmtbtntxtclr !== undefined &&
                  <ColorPick
                    labelName='Submit text color'
                    colorKey="wrtfrmsbmtbtntxtclr"
                    colorValue={cnf.wrtfrmsbmtbtntxtclr}
                    changeNameValue={handleTextChange} />
                }
                {cnf && cnf.thnktxtclr !== undefined &&
                  <ColorPick
                    labelName='Thank you message Color'
                    colorKey="thnktxtclr"
                    colorValue={cnf.thnktxtclr}
                    changeNameValue={handleTextChange} />
                }
              </InlineStack>

            </Box>
            :
            state.sidebarActiveIndex === 3 &&
            <Box>

              {cnf && cnf.thnktxt !== undefined &&
                <TextField
                  label={<Text>Thank you message</Text>}
                  value={cnf.thnktxt}
                  onChange={(e) => handleTextChange({ thnktxt: e })}
                  autoComplete="off" />
              }

              {cnf && cnf.wrtfrmisclicn !== undefined &&
                <Box paddingBlock={600}>
                  <Checkbox
                    label={<Text>Share on social media if the star rating is 4/5</Text>}
                    checked={cnf.wrtfrmisclicn}
                    onChange={(e) => handleTextChange({ wrtfrmisclicn: e })}
                  />
                </Box>
              }

              {cnf && cnf.wrtfrmtncincntv !== undefined &&
                <Box paddingInlineStart={600}>
                  <label>Terms displayed when incentive appears</label>
                  <Box paddingBlock={200}>
                    <ReactQuill value={cnf.wrtfrmtncincntv} className='hideEditor wrtfrmtncincntv'
                      modules={moduleQuillWithoutDynamic}
                      onChange={(newValue, delta, source) => {
                        if (source === 'user') { handleTextChange({ wrtfrmtncincntv: newValue }) }
                      }}
                      onFocus={() => editorFocus('wrtfrmtncincntv')} />
                  </Box>
                </Box>
              }

            </Box>
      }

    </Box >
  );
}

export default WriteReviewTab;