import { useEffect } from 'react';
import Branding from './Branding';

const BoardingStep4 = ({ props }) => {

  useEffect(() => {
    if (props.state.shwDcrdSave === true)
      props.changeNameValue({ isDiabledCta: false });
  }, [props.state.shwDcrdSave]);

  return (
    <Branding
      state={props.state}
      changeNameValue={props.changeNameValue}
      handleDropZone={props.handleDropZone}
      handleNextStep={props.handleNextStep}
      handleSaveContinue={props.handleSaveContinue} />
  );
}

export default BoardingStep4;