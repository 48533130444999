import { Page, Box, InlineGrid, InlineStack, ContextualSaveBar, OptionList, Thumbnail, TextField, Popover, Text, DropZone, Button, Label, Layout, BlockStack, Divider, Badge, RadioButton, ButtonGroup, Tooltip, Checkbox, Card } from '@shopify/polaris';
import { EditIcon, NoteIcon, ReplayIcon, SendIcon } from '@shopify/polaris-icons';
import { dropimg } from '../../img';
import ColorPick from '../ColorPick/ColorPick';
import ConfirmationAlert from '../ConfirmationModal/ConfirmationAlert';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as brandingDucks from '../../ducks/branding';
import { changePathForShopifyAppEmbed } from "../../utils/utils";
import Savebar from '../Savebar/Savebar';
import '../../containers/Branding/Branding.css';

const btnStyle = [
  { label: 'Sharp', value: 'sharp' },
  { label: 'Shaved', value: 'shaved' },
  { label: 'Rounded', value: 'rounded' },
  { label: 'Circular', value: 'circular' }
];

const starStyle = [
  { label: 'With border sharp', value: 'with_border_sharp' },
  { label: 'With out border sharp', value: 'without_border_sharp' },
  { label: 'With border round', value: 'with_border_round' },
  { label: 'With out border round', value: 'without_border_round' },
];

const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'image/svg+xml'];

function List(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    prmclr: '#212121',
    scndclr: '#ffffff',
    btnclr: '#FF8000',
    btntxtclr: '#000000',
    btnshp: 'shaved',
    strclr: '#FF8000',
    strstl: 'without_border_sharp',
    ivrfd: false,
    ivrfdtxt: false,
    vrfdtxt: 'verified',
    vrfdbgclr: '#ffffff',
    vrfdtxtclr: '#000000',
    ivrfdtxthvr: false,
    isRemove: false,  //is remove brand-img
    lg: '',
    imgString: '',
    images: [],
    frmnm: '',
    frmeml: '',
    isEditEml: false,
    ifrmemlvrf: '',
    ifrmemlcstm: '',  // add custm eml
    isEdit: false,
    searchParam: '',
    isSavebar: false
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  state.searchParam = queryParams.get("p");

  useEffect(() => {
    dispatch(brandingDucks.getBranding());
  }, []);

  useEffect(() => { //on refresh
    if (state.isEdit === true) //on sidebar click alert
      localStorage.setItem('isShowLert', true)
    else
      localStorage.removeItem('isShowLert');

    const handleBeforeUnload = (e) => {
      if (localStorage.getItem('isShowLert')) {
        // Display a confirmation dialog
        const confirmationMessage = 'Are you sure you want to leave this page?';
        e.preventDefault();
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [state.isEdit]);

  const saveChanges = () => {
    changeNameValue({ isSavebar: false });
  }

  const discardChanges = () => {
    changeNameValue({ isEdit: false });
    openCloseModal('confirmOpen', state.confirmOpen, 'close');
    handleBackToMain('isEdit');
  }

  const shopiDiscardChanges = () => {
    changeNameValue({ isSavebar: false });
    openCloseModal('confirmOpen', state.confirmOpen, 'open');
  }

  const brandList = useSelector(state => state.branding.brandList);
  const verifyEmlList = useSelector(state => state.branding.verifyEmlList);

  useEffect(() => {
    const stateObj = {};
    if (brandList !== state.brandList) {
      stateObj['brandList'] = brandList;
    }
    if (verifyEmlList !== state.verifyEmlList) {
      stateObj['verifyEmlList'] = verifyEmlList;
    }
    if (Object.keys(stateObj).length > 0)
      changeNameValue(stateObj);

  }, [brandList, verifyEmlList]);

  useEffect(() => {
    if (state.brandList) {
      let obj = {
        prmclr: state.brandList.prmclr,
        scndclr: state.brandList.scndclr,
        btnclr: state.brandList.btnclr,
        btntxtclr: state.brandList.btntxtclr,
        btnshp: state.brandList.btnshp ? state.brandList.btnshp : 'shaved',
        strclr: state.brandList.strclr ? state.brandList.strclr : '#FF8000',
        strstl: state.brandList.strstl ? state.brandList.strstl : 'without_border_sharp',
        lg: state.brandList.lg,
        vrfdtxt: state.brandList.vrfdtxt ? state.brandList.vrfdtxt : '',
        ivrfd: state.brandList.ivrfd ? state.brandList.ivrfd : false,
        ivrfdtxt: state.brandList.ivrfdtxt ? state.brandList.ivrfdtxt : false,
        vrfdbgclr: state.brandList.vrfdbgclr ? state.brandList.vrfdbgclr : '#000000',
        vrfdtxtclr: state.brandList.vrfdtxtclr ? state.brandList.vrfdtxtclr : '#000000',
        ivrfdtxthvr: state.brandList.ivrfdtxthvr ? state.brandList.ivrfdtxthvr : false,
        frmnm: state.brandList.frmnm ? state.brandList.frmnm : localStorage.getItem('fn'),
        frmeml: state.brandList.frmeml ? state.brandList.frmeml : localStorage.getItem('e'),
        ifrmemlvrf: state.brandList.ifrmemlvrf ? state.brandList.ifrmemlvrf : false,
        ifrmemlcstm: state.brandList.ifrmemlcstm ? state.brandList.ifrmemlcstm : false,
        nm: state.brandList.nm ? state.brandList.nm : '',
      }
      changeNameValue(obj);
    }
    if (state.verifyEmlList) {
      let stateObj = {
        frmeml: state.verifyEmlList.frmeml,
        ifrmemlvrf: state.verifyEmlList.ifrmemlvrf,
      }
      changeNameValue(stateObj);
    }
  }, [state.brandList, state.verifyEmlList]);

  const openCloseModal = useCallback((name, value, type) => { //use of type is don't close modal on click of outside
    if (type !== undefined) changeNameValue({ [name]: !value });
  }, []);

  const handleSaveChange = (typ) => {
    changeNameValue({ 'shwDcrdSave': '' });

    let obj = { typ };

    if (typ === 'lg') {
      obj['base64'] = state.imgString;

      if (state.isRemove === true) {
        dispatch(brandingDucks.removeBrndImg());
      }
    }
    else if (typ === 'clr') {
      obj['prmclr'] = state.prmclr;
      obj['scndclr'] = state.scndclr;
    }
    else if (typ === 'btn') {
      obj['btnclr'] = state.btnclr;
      obj['btntxtclr'] = state.btntxtclr;
      obj['btnshp'] = state.btnshp;
    }
    else if (typ === 'str') {
      obj['strclr'] = state.strclr;
      obj['strstl'] = state.strstl;
    }
    else if (typ === 'bdg') {
      obj['ivrfd'] = state.ivrfd;
      obj['ivrfdtxt'] = state.ivrfdtxt;
      obj['vrfdtxt'] = state.vrfdtxt;
      obj['vrfdbgclr'] = state.vrfdbgclr;
      obj['vrfdtxtclr'] = state.vrfdtxtclr;
    }
    else if (typ === 'nm') {
      obj['nm'] = state.nm;
    }
    else { //sndr
      obj['frmnm'] = state.frmnm;
      obj['frmeml'] = state.frmeml;
      obj['ifrmemlcstm'] = state.ifrmemlcstm;
    }

    dispatch(brandingDucks.updBranding(obj));
  }

  const handleDiscardChange = (typ) => {
    changeNameValue({ shwDcrdSave: '' });

    let obj = { isEdit: false };

    if (typ === 'clr') {
      obj = {
        prmclr: state.brandList.prmclr,
        scndclr: state.brandList.scndclr,
      }
    }
    else if (typ === 'btn') {
      obj = {
        btnclr: state.brandList.btnclr,
        btntxtclr: state.brandList.btntxtclr,
        btnshp: state.brandList.btnshp,
      }
    }
    else if (typ === 'str') {
      obj = {
        strclr: state.brandList.strclr,
        strstl: state.brandList.strstl,
      }
    }
    else if (typ === 'bdg') {
      obj = {
        ivrfd: state.brandList.ivrfd,
        ivrfdtxt: state.brandList.ivrfdtxt,
        vrfdtxt: state.brandList.vrfdtxt,
        vrfdbgclr: state.brandList.vrfdbgclr,
        vrfdtxtclr: state.brandList.vrfdtxtclr,
      }
    }
    else if (typ === 'nm') {
      obj = {
        nm: state.brandList.nm,
      }
    }
    else if (typ === 'sndr') {
      obj = {
        frmnm: state.brandList.frmnm,
        frmeml: state.brandList.frmeml,
        ifrmemlcstm: state.brandList.ifrmemlcstm,
      }
    }
    changeNameValue(obj);
  }

  const changeNameValue = (obj, shwDcrdSave) => {
    if (shwDcrdSave) {
      changeNameValue({ shwDcrdSave, isEdit: true });
    }
    setState((prevState) => ({ ...prevState, ...obj }));
  };

  const handleDropZone = async (imgdrop) => {
    const base64String = await fileToBase64(imgdrop[0]);
    let obj = {
      images: imgdrop,
      imgString: base64String
    }
    changeNameValue(obj);
  }

  //function for create file to base64 
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleRemoveImg = () => {
    if (state.imgString || state.lg) {
      let obj = {
        isRemove: true, //if true call remove img api
        images: [],
        lg: '',
        imgString: '',
      }
      changeNameValue(obj);
    }
  }

  const handleSendVrfction = () => {
    let obj = {
      frmeml: state.frmeml
    }
    dispatch(brandingDucks.verifyEmail(obj));
    changeNameValue({ 'isEditEml': false });
  }

  const handleBackToMain = (isEdit) => {
    if (state.isEdit !== true || isEdit) {
      if (localStorage.getItem('is_shopify_embed')) {
        changePathForShopifyAppEmbed('/setting');
      }
      else navigate('/setting');
    }
    else {
      changeNameValue({ isSavebar: true });
      openCloseModal('confirmOpen', state.confirmOpen, 'open');
    }
  }

  let activeStyle = 'Select one';
  if (state.strstl && state.strstl !== '')
    starStyle.map((x) => { if (x.value === state.strstl) activeStyle = x.label; });

  const testChangeName = (obj, isTest) => {
    if (isTest) testChangeName({ isTest: true });
    setState((prevState) => ({ ...prevState, ...obj }));
  }

  const saveTestCall = () => {
    testChangeName({ isTest: false, isSavebar: false });
  }

  const testBackCall = () => {
    if (state.isTest !== true) {
      if (localStorage.getItem('is_shopify_embed')) {
        changePathForShopifyAppEmbed('/setting');
      }
      else navigate('/setting');
    }
    else {
      testChangeName({ isSavebar: true });
    }
  }

  return (
    <div className='brand_pge'>
      <Page
        title={"Branding"} backAction={(state.searchParam === 'brnd' || props.isShwBack === true) ?
          { onAction: () => handleBackToMain() } : false}>

        <ConfirmationAlert
          state={state}
          openCloseModal={openCloseModal}
          saveChanges={saveChanges}
          discardChanges={discardChanges}
        />

        {/* {state.isSavebar === true &&
          <Savebar
            state={state}
            changeNameValue={changeNameValue}
            ContextualSaveBarTest={ContextualSaveBar}
            saveChanges={saveChanges}
            shopiDiscardChanges={shopiDiscardChanges}
            openCloseModal={openCloseModal}
            testChangeName={testChangeName}
          />
        } */}

        <div style={{ display: 'none', width: '40%' }}>
          <Page
            title={"testBranding"} backAction={{ onAction: () => testBackCall() }}>

            <InlineStack align='start' blockAlign='end' gap={200}>
              <TextField
                label='Shop name'
                value={state.nmm}
                onChange={(e) => testChangeName({ 'nmm': e }, 'isTest')} />
              <Button variant='primary' onClick={() => saveTestCall()}>Save</Button>
            </InlineStack>
          </Page>
        </div>

        <Box padding={300}>
          <BlockStack gap={600}>
            <Layout>
              <Layout.Section variant="oneThird">
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>Set up your brand identity</Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>
                    Update your logo here to make sure it's displayed crisply in all your review request emails.</Text>
                </BlockStack>
              </Layout.Section>

              <Layout.Section>
                <Card>
                  <div className='bd-dropdragbox-wrap'>
                    <Box id='img_drops' paddingBlockEnd={400}>
                      <DropZone accept="image/*" type='image' onDrop={handleDropZone}>
                        <InlineStack align='center'>

                          {state.images && state.images.length > 0 ? state.images.map((img, i) => (
                            <InlineStack align="center" key={i}>
                              <Thumbnail size="large" alt={'Logo'}
                                source={validImageTypes.includes(img.type)
                                  ? img
                                    ? window.URL.createObjectURL(img)
                                    : `https://storage.googleapis.com/shopify_review_app/user_media/${state.lg}?q=${new Date().getTime()}`
                                  : NoteIcon} />
                            </InlineStack>
                          ))
                            :
                            state.lg ?
                              <InlineStack>
                                <Thumbnail size="large" alt='Logo'
                                  source={`https://storage.googleapis.com/shopify_review_app/user_media/${state.lg}?q=${new Date().getTime()}`} />
                              </InlineStack>
                              :
                              <BlockStack inlineAlign="center" gap={200}>
                                <img src={dropimg} alt='Upload-icon' />
                                <Text fontWeight='medium'>Drag and Drop or Browse</Text>
                                <div className='or-divider'>
                                  <div className='or-text'>or</div>
                                </div>
                                <Button id='primary_btn' variant="primary">Browse</Button>
                              </BlockStack>
                          }

                        </InlineStack>
                      </DropZone>
                    </Box>
                  </div>

                  <Text variant="headingSm" as="p" fontWeight='regular'>
                    For a sharp display across all devices, we present your logo at 150 pixels in width. To achieve the best quality, especially on high-resolution screens, please upload a 300-pixel-wide image.</Text>
                  <Box paddingBlock={400}><Divider /></Box>

                  <InlineStack align='end'>
                    <ButtonGroup>
                      <Tooltip width='wide' padding="400" dismissOnMouseOut content="Please click on the 'Upload' button after removing the image!">
                        <Button onClick={() => handleRemoveImg()}>Remove image</Button>
                      </Tooltip>
                      <Button onClick={() => handleSaveChange('lg')} id='primary_btn'>Save & proceed</Button>
                    </ButtonGroup>
                  </InlineStack>
                </Card>
              </Layout.Section>
            </Layout>

            <Divider borderColor="border" />
            <Layout>
              <Layout.Section variant="oneThird">
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>Define brand colors</Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>
                    Set primary and star colors to align with your brand identity.
                  </Text>
                </BlockStack>
              </Layout.Section>

              <Layout.Section>
                <Card>
                  <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} alignItems='center'>
                    <ColorPick
                      labelTag='h5'
                      labelVariant={'headingMd'}
                      labelName='Primary color'
                      colorKey="prmclr"
                      colorValue={state.prmclr}
                      changeNameValue={changeNameValue}
                      shwDcrdSave='clr' />

                    <ColorPick
                      labelTag='h5'
                      labelVariant={'headingMd'}
                      labelName='Secondary color'
                      colorKey="scndclr"
                      colorValue={state.scndclr}
                      changeNameValue={changeNameValue}
                      shwDcrdSave='clr' />
                  </InlineGrid>

                  {state.shwDcrdSave === 'clr' &&
                    <Box>
                      <Box paddingBlockStart={600} paddingBlockEnd={200}><Divider /></Box>
                      <Box paddingBlockStart={200}>
                        <InlineStack align='end'>
                          <ButtonGroup>
                            <Button onClick={() => handleDiscardChange('clr')}>Discard</Button>
                            <Button id='primary_btn' variant='primary' onClick={() => handleSaveChange('clr')}>Save</Button>
                          </ButtonGroup>
                        </InlineStack>
                      </Box>
                    </Box>
                  }
                </Card>
              </Layout.Section>
            </Layout>

            <Divider borderColor="border" />
            <Layout>
              <Layout.Section variant="oneThird">
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>Buttons</Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>
                    Select colors and shapes for your call-to-action buttons to match your brand style
                  </Text>
                </BlockStack>
              </Layout.Section>

              <Layout.Section>
                <Card>
                  <BlockStack gap={400}>
                    <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} alignItems='center'>
                      <ColorPick
                        labelTag='h5'
                        labelVariant={'headingMd'}
                        labelName='Button color'
                        colorKey="btnclr"
                        colorValue={state.btnclr}
                        changeNameValue={changeNameValue}
                        shwDcrdSave='btn' />

                      <ColorPick
                        labelTag='h5'
                        labelVariant={'headingMd'}
                        labelName='Button text color'
                        colorKey="btntxtclr"
                        colorValue={state.btntxtclr}
                        changeNameValue={changeNameValue}
                        shwDcrdSave='btn' />
                    </InlineGrid>
                    <Box>
                      <Box paddingBlockEnd={100}><Label>Button shape</Label></Box>
                      <Popover
                        fullWidth
                        active={state.isbtnshp}
                        onClose={() => changeNameValue({ 'isbtnshp': !state.isbtnshp })}
                        activator={<Button textAlign="left" fullWidth disclosure='down'
                          onClick={() => changeNameValue({ 'isbtnshp': !state.isbtnshp })}>
                          <Text tone='subdued'>
                            {state.btnshp}
                          </Text>
                        </Button>}>
                        <OptionList
                          options={btnStyle}
                          selected={state.btnshp}
                          onChange={() => changeNameValue({ 'isbtnshp': !state.isbtnshp })}
                          onFocusOption={(e) => changeNameValue({ 'btnshp': e }, 'btn')} />
                      </Popover>
                    </Box>
                    <Box>
                      <Box paddingBlockEnd={100}><Text variant='headingMd' as='p' fontWeight='medium'>Preview :</Text></Box>
                      <div className='box_bg'>
                        <button className='smpl_btn'
                          style={{
                            color: state.btntxtclr,
                            backgroundColor: state.btnclr,
                            borderRadius: state.btnshp === 'shaved' ? '8px'
                              : state.btnshp === 'rounded' ? '16px'
                                : state.btnshp === 'circular' ? '50px' : '0'
                          }}>
                          Sample button
                        </button>
                      </div>
                    </Box>
                  </BlockStack>
                  {state.shwDcrdSave === 'btn' &&
                    <Box>
                      <Box paddingBlockStart={600} paddingBlockEnd={200}>
                        <Divider />
                      </Box>
                      <Box paddingBlockStart={200}>
                        <InlineStack align='end'>
                          <ButtonGroup>
                            <Button onClick={() => handleDiscardChange('btn')}>Discard</Button>
                            <Button id='primary_btn' variant='primary' onClick={() => handleSaveChange('btn')}>Save</Button>
                          </ButtonGroup>
                        </InlineStack>
                      </Box>
                    </Box>
                  }
                </Card>
              </Layout.Section>
            </Layout>

            <Divider borderColor="border" />
            <Layout>
              <Layout.Section variant="oneThird">
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>Stars</Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>
                    Select a star color and style for your review ratings
                  </Text>
                </BlockStack>
              </Layout.Section>

              <Layout.Section>
                <Card>
                  <BlockStack gap={400}>
                    <ColorPick
                      labelTag='h5'
                      labelVariant={'headingMd'}
                      labelName='Star color'
                      colorKey="strclr"
                      colorValue={state.strclr}
                      changeNameValue={changeNameValue}
                      shwDcrdSave='str' />
                    <Box>
                      <Box paddingBlockEnd={100}><Label>Star style</Label></Box>
                      <Popover
                        fullWidth
                        active={state.isStrStl}
                        onClose={() => changeNameValue({ 'isStrStl': !state.isStrStl })}
                        activator={
                          <Button onClick={() => changeNameValue({ 'isStrStl': !state.isStrStl })} textAlign="left" fullWidth disclosure='down'>
                            <Text tone='subdued'>
                              {activeStyle}
                            </Text>
                          </Button>}>
                        <OptionList
                          options={starStyle}
                          selected={state.strstl}
                          onChange={() => changeNameValue({ 'isStrStl': !state.isStrStl })}
                          onFocusOption={(e) => changeNameValue({ 'strstl': e }, 'str')} />
                      </Popover>
                    </Box>
                    <Box>
                      <Box paddingBlockEnd={100}><Text variant='headingMd' as='p' fontWeight='medium'>Preview :</Text></Box>
                      <div className='box_bg'>
                        {state.strstl === 'with_border_sharp' ? //border-star
                          <div className='star_main border_star' style={{ '--star_clr': `${state.strclr}` }}>
                            &#9734;&#9734;&#9734;&#9734;&#9734;
                          </div>
                          : state.strstl === 'with_border_round' ? //round-border
                            <div className='round_border' style={{ '--star_clr': `${state.strclr}` }}></div>
                            : state.strstl === 'without_border_round' ? //no-round-border
                              <div className='noRound_border' style={{ '--star_clr': `${state.strclr}` }}></div>
                              :                                       //no-border
                              <div className='star_main' style={{ '--star_clr': `${state.strclr}` }}></div>
                        }
                      </div>
                    </Box>
                  </BlockStack>
                  {state.shwDcrdSave === 'str' &&
                    <Box>
                      <Box paddingBlockStart={600} paddingBlockEnd={200}>
                        <Divider />
                      </Box>
                      <Box paddingBlockStart={200}>
                        <InlineStack align='end'>
                          <ButtonGroup>
                            <Button onClick={() => handleDiscardChange('str')}>Discard</Button>
                            <Button id='primary_btn' variant='primary' onClick={() => handleSaveChange('str')}>Save</Button>
                          </ButtonGroup>
                        </InlineStack>
                      </Box>
                    </Box>
                  }
                </Card>
              </Layout.Section>
            </Layout>

            <Divider borderColor="border" />
            <Layout>
              <Layout.Section variant="oneThird">
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>Verified badge</Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>
                    Enable to display a badge or text on hover for verified reviews. Customize badge and text colors to match your brand
                  </Text>
                </BlockStack>
              </Layout.Section>

              <Layout.Section>
                <Card>
                  <InlineStack blockAlign='center'>
                    <Checkbox
                      label={<Text variant='bodyMd' as='p' fontWeight='semibold'>Show verified badge</Text>}
                      checked={state.ivrfd}
                      onChange={(e) => changeNameValue({ 'ivrfd': e }, 'bdg')}
                    />
                  </InlineStack>

                  <Box paddingBlockStart={400}>
                    <Checkbox
                      disabled={!state.ivrfd}
                      label={<Text variant='bodyMd' as='p' fontWeight='semibold'>Show verified text on hover</Text>}
                      checked={state.ivrfdtxt}
                      onChange={(e) => { changeNameValue({ 'ivrfdtxt': e }, 'bdg'); changeNameValue({ 'ivrfdtxthvr': !state.ivrfdtxthvr }) }}>
                    </Checkbox>
                    <Box paddingBlockStart={100} paddingInlineStart={600}>
                      <TextField
                        disabled={!state.ivrfd}
                        autoComplete='off'
                        onChange={(e) => changeNameValue({ 'vrfdtxt': e }, 'bdg')}
                        value={state.vrfdtxt} />
                    </Box>

                    <Box paddingInlineStart={600}>
                      <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} alignItems='center'>
                        <Box paddingBlock={600}>
                          <ColorPick
                            labelTag='h5'
                            labelVariant={'headingMd'}
                            labelName='Verified badge color'
                            colorKey="vrfdbgclr"
                            colorValue={state.vrfdbgclr}
                            changeNameValue={changeNameValue}
                            shwDcrdSave='bdg' />
                        </Box>

                        {!state.ivrfdtxt &&
                          <ColorPick
                            labelTag='h5'
                            labelVariant={'headingMd'}
                            labelName='Verified text color'
                            colorKey="vrfdtxtclr"
                            colorValue={state.vrfdtxtclr}
                            changeNameValue={changeNameValue}
                            shwDcrdSave='bdg' />
                        }
                      </InlineGrid>
                    </Box>

                    <Box>
                      <Box paddingBlockEnd={100}>
                        <Text variant='headingMd' as='p' fontWeight='medium'>Preview :</Text>
                      </Box>
                      <div className='box_bg'>
                        <InlineStack align='start'>

                          {!state.ivrfd ?
                            <div id='cursorPoint' style={{ opacity: 0.4, pointerEvents: 'none' }}>
                              <InlineStack gap={200}>
                                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.6239 0L17.2942 3.30087L21.1322 1.48547L22.1058 5.61802L26.3514 5.64765L25.4355 9.7934L29.2478 11.6622L26.6239 15L29.2478 18.3378L25.4355 20.2066L26.3514 24.3523L22.1058 24.382L21.1322 28.5145L17.2942 26.6991L14.6239 30L11.9536 26.6991L8.11566 28.5145L7.14204 24.382L2.89645 24.3523L3.81229 20.2066L0 18.3378L2.62392 15L0 11.6622L3.81229 9.7934L2.89645 5.64765L7.14204 5.61802L8.11566 1.48547L11.9536 3.30087L14.6239 0Z" fill={state.vrfdbgclr}></path><path fillRule="evenodd" clipRule="evenodd" d="M21.2364 9.33238C21.723 9.80429 21.7559 10.6042 21.3099 11.119L13.7977 19.7899C13.5774 20.0442 13.2685 20.1922 12.9426 20.1997C12.6167 20.2072 12.3021 20.0735 12.0716 19.8296L7.97406 15.4942C7.50734 15.0003 7.50734 14.1997 7.97406 13.7059C8.44079 13.212 9.1975 13.212 9.66422 13.7059L12.8791 17.1075L19.5479 9.41006C19.9939 8.89525 20.7499 8.86047 21.2364 9.33238Z" fill="white"></path></svg>
                                <span style={{ color: state.vrfdtxtclr }}>{state.vrfdtxt}</span>
                              </InlineStack>
                            </div>
                            :
                            <Tooltip width='wide' padding="400" dismissOnMouseOut active={state.ivrfdtxt} content={state.vrfdtxt}>
                              <div id='cursorPoint'>
                                <InlineStack gap={200}>
                                  <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.6239 0L17.2942 3.30087L21.1322 1.48547L22.1058 5.61802L26.3514 5.64765L25.4355 9.7934L29.2478 11.6622L26.6239 15L29.2478 18.3378L25.4355 20.2066L26.3514 24.3523L22.1058 24.382L21.1322 28.5145L17.2942 26.6991L14.6239 30L11.9536 26.6991L8.11566 28.5145L7.14204 24.382L2.89645 24.3523L3.81229 20.2066L0 18.3378L2.62392 15L0 11.6622L3.81229 9.7934L2.89645 5.64765L7.14204 5.61802L8.11566 1.48547L11.9536 3.30087L14.6239 0Z" fill={state.vrfdbgclr}></path><path fillRule="evenodd" clipRule="evenodd" d="M21.2364 9.33238C21.723 9.80429 21.7559 10.6042 21.3099 11.119L13.7977 19.7899C13.5774 20.0442 13.2685 20.1922 12.9426 20.1997C12.6167 20.2072 12.3021 20.0735 12.0716 19.8296L7.97406 15.4942C7.50734 15.0003 7.50734 14.1997 7.97406 13.7059C8.44079 13.212 9.1975 13.212 9.66422 13.7059L12.8791 17.1075L19.5479 9.41006C19.9939 8.89525 20.7499 8.86047 21.2364 9.33238Z" fill="white"></path></svg>
                                  {!state.ivrfdtxt &&
                                    // <Text fontWeight='medium' variant='bodyLg' as='span'>{state.vrfdtxt}</Text>
                                    <span style={{ color: state.vrfdtxtclr }}>{state.vrfdtxt}</span>
                                  }
                                </InlineStack>
                              </div>
                            </Tooltip>
                          }

                        </InlineStack>
                      </div>
                    </Box>
                  </Box>

                  {state.shwDcrdSave === 'bdg' &&
                    <Box>
                      <Box paddingBlockStart={600} paddingBlockEnd={200}>
                        <Divider />
                      </Box>
                      <Box paddingBlockStart={200}>
                        <InlineStack align='end'>
                          <ButtonGroup>
                            <Button onClick={() => handleDiscardChange('bdg')}>Discard</Button>
                            <Button id='primary_btn' variant='primary' onClick={() => handleSaveChange('bdg')}>Save</Button>
                          </ButtonGroup>
                        </InlineStack>
                      </Box>
                    </Box>
                  }
                </Card>
              </Layout.Section>
            </Layout>

            <Divider borderColor="border" />
            <Layout>
              <Layout.Section variant="oneThird">
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>Shop name</Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>
                    Update the shop name to correctly display them in the review collection email
                  </Text>
                </BlockStack>
              </Layout.Section>

              <Layout.Section>
                <Card>
                  <TextField
                    label='Shop name'
                    value={state.nm}
                    onChange={(e) => changeNameValue({ 'nm': e }, 'nm')} />
                  {state.shwDcrdSave === 'nm' &&
                    <Box>
                      <Box paddingBlockStart={600} paddingBlockEnd={200}>
                        <Divider />
                      </Box>
                      <Box paddingBlockStart={200}>
                        <InlineStack align='end'>
                          <ButtonGroup>
                            <Button onClick={() => handleDiscardChange('nm')}>Discard</Button>
                            <Button id='primary_btn' variant='primary' onClick={() => handleSaveChange('nm')}>Save</Button>
                          </ButtonGroup>
                        </InlineStack>
                      </Box>
                    </Box>
                  }
                </Card>
              </Layout.Section>
            </Layout>

            <Divider borderColor="border" />
            <Layout>
              <Layout.Section variant="oneThird">
                <BlockStack gap={200}>
                  <Text variant="headingMd" as="h2" fontWeight='semibold'>Email sender</Text>
                  <Text variant='headingSm' as="p" fontWeight='regular'>
                    Choose the email address for sending out review invitations to customers
                  </Text>
                </BlockStack>
              </Layout.Section>

              <Layout.Section>
                <Card>
                  <BlockStack gap={400}>
                    <Box>
                      <TextField
                        label='Email sender name'
                        value={state.frmnm}
                        onChange={(e) => changeNameValue({ 'frmnm': e }, 'sndr')} />
                    </Box>
                    <BlockStack>
                      <Box paddingBlockEnd={100}>
                        <Text variant='headingSm' as='p' fontWeight='semibold'>Select email for sending review invitations & notifications</Text>
                      </Box>
                      <RadioButton
                        label={`Use our default email: info@wisernotify.com `}
                        checked={!state.ifrmemlcstm}
                        id="disabled"
                        name="accounts"
                        onChange={() => changeNameValue({ 'ifrmemlcstm': false }, 'sndr')}
                      />
                      <RadioButton
                        label="Configure and verify your own store email "
                        id="optional"
                        name="accounts"
                        checked={state.ifrmemlcstm}
                        onChange={() => changeNameValue({ 'ifrmemlcstm': true }, 'sndr')}
                      />
                      {state.ifrmemlcstm &&
                        <Box paddingBlockStart={100}>
                          <div className='box_bg'>
                            <BlockStack gap={400}>
                              <TextField
                                readOnly={!state.isEditEml ? true : false}
                                value={state.frmeml}
                                onChange={(e) => changeNameValue({ 'frmeml': e }, 'sndr')}
                                suffix={state.ifrmemlvrf === true ?
                                  <Badge tone='success'><Text variant='headingXs' fontWeight='medium' as='span'>Verified</Text></Badge>
                                  : <Badge tone='attention'><Text variant='headingXs' fontWeight='medium' as='span'>Pending</Text></Badge>} />
                              {state.isEditEml === false &&
                                <ButtonGroup>
                                  <Button icon={EditIcon} onClick={() => changeNameValue({ 'isEditEml': true })}>Edit</Button>
                                  {state.ifrmemlvrf === false && <Button variant='plain' icon={ReplayIcon} onClick={() => handleSendVrfction()}>Verify email</Button>}
                                </ButtonGroup>
                              }
                              {state.isEditEml === true &&
                                <Box>
                                  <Button id='primary_btn' icon={SendIcon} onClick={() => handleSendVrfction()}>Send verification link</Button>
                                </Box>
                              }
                            </BlockStack>
                          </div>
                        </Box>
                      }
                    </BlockStack>
                  </BlockStack>

                  {state.shwDcrdSave === 'sndr' &&
                    <Box>
                      <Box paddingBlockStart={600} paddingBlockEnd={200}>
                        <Divider />
                      </Box>
                      <Box paddingBlockStart={200}>
                        <InlineStack align='end'>
                          <ButtonGroup>
                            <Button onClick={() => handleDiscardChange('sndr')}>Discard</Button>
                            <Button id='primary_btn' variant='primary' onClick={() => handleSaveChange('sndr')}>Save</Button>
                          </ButtonGroup>
                        </InlineStack>
                      </Box>
                    </Box>
                  }

                </Card>
              </Layout.Section>
            </Layout>
          </BlockStack>
        </Box>
      </Page >

    </div >
  );
}

export default List;