import { fromJS } from 'immutable';

export const getShopifyPlan = (data) => ({ type: "GET_SHOPIFY_PLAN", data });
export const getShopifyPlanSuccess = (data) => ({ type: "GET_SHOPIFY_PLAN_SUCCESS", data });

export const shopifyPayment = (data) => ({ type: "SHOPIFY_PAYMENT", data });
export const shopifyPaymentSuccess = (data) => ({ type: "SHOPIFY_PAYMENT_SUCCESS", data });

export const cancelShopifyPlan = (data) => ({ type: "CANCEL_SHOPIFY_PLAN", data });

const initialState = fromJS({

});

function plan(state = initialState, action) {
  switch (action.type) {

    case "GET_SHOPIFY_PLAN_SUCCESS":
      return { ...state, shopifyPlan: action.data };

    default:
      return { ...state };
  }
}

export default plan;