import { Grid, Button, Popover, Card, Divider, RadioButton, InlineGrid, Collapsible, Icon, InlineStack, OptionList, Box, IndexTable, Label, Text, TextField, Select } from '@shopify/polaris';
import { DeleteIcon, ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';

const FilterCard = ({ props }) => {
  let { state, changeNameValue } = props;

  const popOpt1 = [
    { label: 'Product name', value: 'product' },
    { label: 'Customer', value: 'customer' }
  ];

  const popOpt2 = [
    { label: 'Contains', value: 'contains' },
    { label: 'Equals', value: 'equals' },
    { label: 'Does not contain', value: 'notcontain' },
    { label: 'Does not equal', value: 'notequal' }
  ];

  const getLabel = (val) => {
    let _lbl = <Text fontWeight='medium' tone='subdued'>Select</Text>;
    if (val) {
      popOpt1.map((x) => { if (x.value === val) _lbl = x.label; });
      popOpt2.map((x) => { if (x.value === val) _lbl = x.label; });
    }
    return _lbl;
  }

  let starRtOpt = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' }
  ];

  return (
    <Box paddingBlock={600}>

      <Card padding={'0'}>
        <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
          <Text variant='bodyLg' fontWeight='medium' as='h1'>
            {state.emailTyp === 'media_request' ?
              'Set condition to request media upload form'
              : 'Select customers and products to request reviews from'}
          </Text>
        </Box>
        <Divider borderColor='border-tertiary'></Divider>

        {props.state.emailTyp !== 'media_request' ?
          <Box padding={400}>
            <InlineStack direction={'column'} gap={100}>
              <RadioButton
                label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Collect review for all customers and products</Text>}
                checked={props.state.iarrcnd === false}
                name="collectReview"
                onChange={() => props.handleConditionAdd({ iarrcnd: false }, 'radio')}
              />
              <RadioButton
                label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Collect review for all certain customers or specific products</Text>}
                name="collectReview"
                checked={props.state.iarrcnd === true}
                onChange={() => props.handleConditionAdd({ iarrcnd: true }, 'radio')}
              />
            </InlineStack>

            {props.state.iarrcnd === true &&
              <Box paddingBlockStart={300}>
                <Box id='custom_infoBG' padding={400} paddingBlock={300}>
                  <div id='cursorPoint' onClick={() => changeNameValue({ filterCollapseOpen: !props.state.filterCollapseOpen })}>
                    <InlineStack align='space-between'>
                      <Text variant='bodyLg' fontWeight='regular' as='h1'>Add conditions</Text>
                      <Box>
                        <Icon source={props.state.filterCollapseOpen ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                      </Box>
                    </InlineStack>
                  </div>

                  <Collapsible
                    open={props.state.filterCollapseOpen}
                    id="db_collaspe"
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}
                  >
                    <Box paddingBlockStart={400}>
                      <Grid gap={300} alignItems='center'>
                        <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 3, lg: 3, xl: 3 }}>
                          <Label><Text fontWeight='regular'>Select type</Text>
                            <div className='mt-2'>
                              <Popover
                                fullWidth
                                active={props.state.isSelectTypeOn}
                                onClose={() => changeNameValue({ isSelectTypeOn: !props.state.isSelectTypeOn })}
                                activator={<Button textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isSelectTypeOn: !props.state.isSelectTypeOn })}>
                                  <Text tone='subdued'>
                                    {getLabel(props.state.ctyp)}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={popOpt1}
                                  selected={`[${props.state.ctyp}]`}
                                  onChange={() => changeNameValue({ isSelectTypeOn: !props.state.isSelectTypeOn })}
                                  onFocusOption={(e) => changeNameValue({ ctyp: e }, 'isShowMessage')} />
                              </Popover>
                            </div>
                          </Label>
                        </Grid.Cell>
                        <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 3, lg: 4, xl: 4 }}>
                          <Label><Text fontWeight='regular'>Select condition</Text>
                            <div className='mt-2'>
                              <Popover
                                fullWidth
                                active={props.state.isSelectCondtOn}
                                onClose={() => changeNameValue({ isSelectCondtOn: !props.state.isSelectCondtOn })}
                                activator={<Button textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isSelectCondtOn: !props.state.isSelectCondtOn })}>
                                  <Text tone='subdued'>
                                    {getLabel(props.state.cnd)}
                                  </Text>
                                </Button>}>
                                <OptionList
                                  options={popOpt2}
                                  selected={`[${props.state.cnd}]`}
                                  onChange={() => changeNameValue({ isSelectCondtOn: !props.state.isSelectCondtOn })}
                                  onFocusOption={(e) => changeNameValue({ cnd: e }, 'isShowMessage')} />
                              </Popover>
                            </div>
                          </Label>
                        </Grid.Cell>
                        <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 3, lg: 4, xl: 4 }}>
                          <TextField
                            autoComplete='off'
                            label={<Text fontWeight='regular'>Enter data</Text>}
                            placeholder='Enter data'
                            value={props.state.dataTxt}
                            onChange={(e) => changeNameValue({ dataTxt: e }, 'isShowMessage')} />
                        </Grid.Cell>
                        <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 1, lg: 1, xl: 1 }}>
                          <Button id='primary_btn' size='large' onClick={() => props.handleConditionAdd()}>Add</Button>
                        </Grid.Cell>
                      </Grid>

                      <Box paddingBlockStart={400}>
                        <Card padding={0}>

                          <IndexTable
                            itemCount={state.arrcnd ? state.arrcnd.length : 0}
                            selectable={false}
                            headings={[
                              { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Type</Text> },
                              { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Condition</Text> },
                              { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Data</Text> },
                              { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Action</Text> }
                            ]}>
                            {props.state.arrcnd && props.state.arrcnd.map((val, i) => {
                              return (
                                <IndexTable.Row
                                  id={i}
                                  key={i}
                                  position={i}>
                                  <IndexTable.Cell>
                                    <Text variant="bodyMd" fontWeight="regular" as="span">
                                      {val.ctyp ? getLabel(val.ctyp) : '-'}</Text>
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    <Text variant="bodyMd" fontWeight="regular" as="span">
                                      {val.cnd ? getLabel(val.cnd) : '-'}</Text>
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    <Text variant="bodyMd" fontWeight="regular" as="span">{val.data ? val.data : '-'}</Text>
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    <Button icon={DeleteIcon} variant='monochromePlain' onClick={() => props.handleDelCondition(val)}></Button>
                                  </IndexTable.Cell>
                                </IndexTable.Row>
                              )
                            })}
                          </IndexTable>

                        </Card>
                      </Box>
                    </Box>
                  </Collapsible>

                </Box>
              </Box>
            }
          </Box>
          :  //for media typ
          <Box padding={400}>
            <Text fontWeight='medium' as='span'>Minimum star rating:</Text>
            <Box paddingBlockStart={150} paddingBlockEnd={400}>
              <InlineGrid columns={{ md: 2 }}>
                <Popover
                  fullWidth
                  active={state.isMinStar}
                  onClose={() => changeNameValue({ isMinStar: !state.isMinStar })}
                  activator={<Button textAlign="left" fullWidth disclosure
                    onClick={() => changeNameValue({ isMinStar: !state.isMinStar })}>
                    <Text tone='subdued'>
                      {starRtOpt.find(({ value }) => value == state.mnrtng)?.label || 'Select'}
                    </Text>
                  </Button>}>
                  <OptionList
                    options={starRtOpt}
                    selected={[state.mnrtng]}
                    onChange={() => changeNameValue({ isMinStar: !state.isMinStar })}
                    onFocusOption={(e) => props.changeNameValue({ mnrtng: e }, 'isShowMessage')} />
                </Popover>
              </InlineGrid>
            </Box>
            <Box>
            </Box>
            <InlineGrid columns={{ md: 8 }}>
              <Button id='primary_btn' onClick={() => props.saveMediaConfig()}>Save</Button>
            </InlineGrid>
          </Box>
        }

      </Card >
    </Box >
  );
}

export default FilterCard;