import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, BlockStack, Box, Button, ButtonGroup, Card, Divider, Icon, InlineStack, Page, Text } from '@shopify/polaris';
import { ColorIcon, PlusIcon, EditIcon, ReceiptDollarIcon, StoreIcon } from '@shopify/polaris-icons';
import MainEditPanel from '../Sequence/MainEditPanel';
import ConfirmationAlert from '../ConfirmationModal/ConfirmationAlert';
import { useLocation, useNavigate } from 'react-router-dom';
import Plan from '../Plan/Main'
import Branding from '../Branding/List';
import StoreInformation from '../StoreInformation/StoreInformation';
import { changePathForShopifyAppEmbed } from "../../utils/utils";

function Setting(props) {
  let { state } = props;

  const organizationList = [
    {
      key: 1,
      label: 'Billing',
      description: 'Access and manage your subscription and billing details',
      image: ReceiptDollarIcon,
      url: '/setting/plan',
      ctaName: 'Details',
    },
    {
      key: 2,
      label: 'Branding',
      description: `Customize your review collection look with your logo and colors.`,
      image: ColorIcon,
      url: '/setting/branding',
      ctaName: 'Update',
    },
    {
      key: 3,
      label: 'Store information',
      description: `Check app install status and get store key`,
      image: StoreIcon,
      url: '/setting/storeInformation',
      ctaName: 'Details',
    },
  ]

  let customSeqArr = [];

  if (state.emailSeqArr && state.emailSeqArr.length > 0 && state.emailMSTArr && state.emailMSTArr.length > 0) {
    customSeqArr = state.emailSeqArr.map(function (va1) {
      let Arr = state.emailMSTArr.find(function (va2) {
        return va1.typ === va2.typ;
      });

      if (Arr) {
        return {
          ...va1,
          dspttl: Arr.dspttl,
          dspdsc: Arr.dspdsc,
          iu: Arr.iu
        };
      }
    });
  }

  let createMstArr = state.emailMSTArr && state.emailMSTArr.length > 0 && state.emailMSTArr.filter(
    (data) => !state.emailSeqArr.some(val => val.typ === data.typ));

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let searchParam = queryParams.get("frm");

  let pathName = location.pathname.split('/');
  let searchPath = '';
  if (pathName) searchPath = pathName.length > 2 ? pathName[2] : '';

  const handleRedirectShp = (path) => {
    if (localStorage.getItem('is_shopify_embed')) {
      changePathForShopifyAppEmbed(path);
    }
    else navigate(path);
  }

  return (
    <div>

      {searchPath === 'plan' ?
        <Plan isShwBack={true} />
        :
        searchPath === 'branding' ?
          <Branding isShwBack={true} />
          :
          searchPath === 'storeInformation' ?
            <StoreInformation isShwBack={true} />
            :
            <div>
              {props.state.isNewEditPanel !== true && props.state.isConfiguration !== true ?
                <Page
                  title={'Settings'}
                  subtitle={'Manage settings that apply to your organization.'}
                  backAction={searchParam === 'mnr' ? { onAction: () => window.history.back() } : false}
                >
                  <Box paddingInlineStart={searchParam === 'mnr' ? 300 : 0}
                    padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockEnd={600}>
                    <BlockStack gap={400}>
                      <Card padding={0}>
                        <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                          <Text variant='headingMd' fontWeight='semibold' as='h1'>Organization settings</Text>
                        </Box>

                        <Divider borderColor='border-tertiary' />
                        <Box paddingInline={400} paddingBlockEnd={200}>
                          {organizationList && organizationList.map((data, i) => {
                            return (
                              <div id='cursorPoint' key={i} onClick={() => handleRedirectShp(data.url)} style={{ textDecoration: 'none' }}>
                                <InlineStack align='space-between' blockAlign='center'>
                                  <Box paddingBlockStart={400} paddingBlockEnd={i !== organizationList.length - 1 ? 400 : 200} padding={{ xs: 0, sm: 0, md: 0 }}>
                                    <InlineStack wrap={false} blockAlign='center' gap={300}>
                                      <Box>
                                        <Icon source={data.image} tone='base' />
                                      </Box>
                                      <Box>
                                        <Text variant='headingXs' as='h6' fontWeight='semibold'>{data.label ? data.label : '-'}</Text>
                                        <Box paddingBlockStart={100}>
                                          <Text variant='headingXs' as='p' fontWeight='regular' tone='subdued'>{data.description ? data.description : '-'}</Text>
                                        </Box>
                                      </Box>
                                    </InlineStack>
                                  </Box>
                                  <Box className="Polaris-Box Organization-Box-Rightitem">
                                    <Button>{data.ctaName}</Button>
                                  </Box>
                                </InlineStack>
                                {i !== organizationList.length - 1 && <Divider />}
                              </div>
                            )
                          })}
                        </Box>
                      </Card>

                      {((customSeqArr && customSeqArr.length > 0) || (createMstArr && createMstArr.length > 0)) &&
                        <Card padding={0}>
                          <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                            <Text variant='headingMd' fontWeight='semibold' as='h1'>Review alert preferences</Text>
                          </Box>

                          <Divider borderColor='border-tertiary' />

                          <Box paddingInline={400} paddingBlockEnd={200}>
                            {createMstArr && createMstArr.length > 0 && createMstArr.map((data, i) => {
                              return (
                                <Box key={i}>
                                  <Box paddingBlockStart={400} paddingBlockEnd={(i !== createMstArr.length - 1 || customSeqArr.length > 0) ? 400 : 200}>
                                    <InlineStack wrap={false} blockAlign='center' gap={300} align='space-between'>
                                      <InlineStack align='center'>
                                        <Box paddingInlineEnd={400}>
                                          <img src={data.iu} alt='icon' />
                                        </Box>
                                        <Box>
                                          <InlineStack direction={'column'} align='start' blockAlign='start' gap={100}>
                                            <InlineStack gap={200} align='start'>
                                              <Text as='span' fontWeight='semibold' variant='bodySm'>{data.dspttl ? data.dspttl : '-'}</Text>
                                            </InlineStack>
                                            <Text variant='bodySm' tone='subdued' as='span'>{data.dspdsc}</Text>
                                          </InlineStack>
                                        </Box>
                                      </InlineStack>
                                      <ButtonGroup>
                                        <Button icon={PlusIcon} variant=''
                                          onClick={() => props.createTemplate(data)}>Create template</Button>
                                      </ButtonGroup>
                                    </InlineStack>
                                  </Box>
                                  {(i !== createMstArr.length - 1 || customSeqArr.length > 0) && <Divider />}
                                </Box>
                              );
                            })
                            }
                          </Box>

                          <Box paddingInline={400} paddingBlockEnd={200}>
                            {customSeqArr && customSeqArr.length > 0 && customSeqArr.map((data, i) => {
                              return (
                                <Box key={i}>
                                  <Box paddingBlockStart={400} paddingBlockEnd={(i !== customSeqArr.length - 1 && state.emailSeqArr.length > 0) ? 400 : 200}>
                                    <InlineStack wrap={true} blockAlign='center' gap={300} align='space-between'>
                                      <InlineStack align='center' wrap={false} >
                                        <Box paddingInlineEnd={400}>
                                          <img src={data.iu} alt='icon' />
                                          {/* <Icon source={data.iu} tone='base' /> */}
                                        </Box>
                                        <Box>
                                          <InlineStack direction={'column'} align='start' blockAlign='start' gap={100}>
                                            <InlineStack gap={200} align='start'>
                                              <Text as='span' fontWeight='semibold' variant='bodySm'>{data.dspttl ? data.dspttl : '-'}</Text>
                                              {data.ia === true ?
                                                <Badge tone="success"><Text variant="bodySm" fontWeight="medium" as="span">On</Text></Badge> :
                                                <Badge><Text variant="bodySm" fontWeight="medium" as="span">Off</Text></Badge>}
                                            </InlineStack>
                                            <Text variant='bodySm' tone='subdued' as='span'>{data.dspdsc}</Text>
                                          </InlineStack>
                                        </Box>
                                      </InlineStack>
                                      <Box className="Polaris-Box Organization-Box-Rightitem">
                                        <ButtonGroup>
                                          <Button icon={EditIcon} onClick={() => props.editTemplateClk(data)}>Edit</Button>
                                          {data.ia === true ?
                                            <Button tone='critical' variant='primary'
                                              onClick={() => props.handleStatusUpdate(data._id, false)}>Turn OFF</Button>
                                            :
                                            <Button id='primary_btn' onClick={() => props.handleStatusUpdate(data._id, true)}>Turn ON</Button>
                                          }
                                        </ButtonGroup>
                                      </Box>
                                    </InlineStack>
                                  </Box>
                                  {(i !== customSeqArr.length - 1 && state.emailSeqArr.length > 0) && <Divider />}
                                </Box>
                              );
                            })
                            }
                          </Box>
                        </Card>
                      }
                    </BlockStack>
                  </Box>
                </Page>
                :
                <MainEditPanel  //notification edit page
                  state={props.state}
                  changeNameValue={props.changeNameValue}
                  handleTextChange={props.handleTextChange}
                  openCloseModal={props.openCloseModal}
                  backTempList={props.backTempList}
                />
              }
            </div>
      }

      <ConfirmationAlert
        state={props.state}
        openCloseModal={props.openCloseModal}
        saveChanges={props.saveChanges}
        discardChanges={props.discardChanges}
      />
    </div >
  );
}

export default Setting;