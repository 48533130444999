import { Badge, Page, Button, Box, Tooltip, InlineStack, Modal, PageActions, TextField, Text, Divider, Icon, Collapsible, Card, BlockStack, List, InlineGrid } from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon } from '@shopify/polaris-icons';
import * as integrationDucks from '../../ducks/integration';
import { useDispatch } from 'react-redux';

let _ia = false;

const Klaviyo = ({ props }) => {
  const { state, validator, handleTextChange, changeNameValue, handleBackToMain, openCloseModal } = props;
  const dispatch = useDispatch();

  if (state.currUserInt && state.currUserInt.ia !== undefined) //for isActive
    _ia = state.currUserInt.ia;
  if (state.newDObj && Object.keys(state.newDObj).length <= 0) //set active on create
    _ia = true;

  const handleCreateKlaviyo = (_ia) => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    }
    else {
      changeNameValue({ 'errMessage': {} });

      let obj = {
        key: state.dObj.key,
        shp: localStorage.getItem('shp'),
        iid: state.currMasterObj._id ? state.currMasterObj._id : '',
        inm: state.currMasterObj.in ? state.currMasterObj.in : '',
        d: state.dObj,
        ia: _ia !== undefined ? _ia : state.currMasterObj.ia,
        icltrvw: state.currMasterObj.icltrvw
      }

      dispatch(integrationDucks.createKlaviyo(obj));
      let intObj = {
        isEditable: false,
        dObj: {},
        isDelKlaviyo: false
      }
      changeNameValue(intObj);
    }
  }

  const handleDelKlaviyo = () => {
    let obj = {
      id: state.currIntId,
    }
    dispatch(integrationDucks.delKlaviyo(obj));

    let intObj = {
      isDelKlaviyo: true,
      currUserInt: {},
      dObj: {},
      newDObj: {},
      currIntId: '',
      rmk: '',
      webUrl: '',
      wfkey: '',
      cfkey: '',
      isLisDtSuc: false,
    }
    changeNameValue(intObj);
    openCloseModal('deleteOpen', state.deleteOpen, 'close');
  }

  const hideAPIKey = (apiKey) => {
    const hideLength = apiKey.length - 4;
    const hidePart = '*'.repeat(hideLength);
    const lastPart = apiKey.slice(-4);
    return hidePart + lastPart;
  }

  const supportEventList = [
    {
      title: 'Review request',
      desc: 'Triggered to initiate a review request to a customer.'
    },
    {
      title: 'Follow up',
      desc: `Triggered if a customer hasn't responded to a review request.`
    },
    {
      title: 'Manual campaign',
      desc: `Triggered for review requests sent through manual campaigns.`
    },
    {
      title: 'Send incentive code',
      desc: `Triggered to send a code following a review submission.`
    },
    {
      title: 'Review reject',
      desc: `Triggered when a customer's review is not accepted.`
    },
    {
      title: 'Review submitted',
      desc: `Triggered when a review is successfully submitted by a customer.`
    },
    {
      title: 'Review published',
      desc: `Triggered when a submitted review is published on the site.`
    },
    {
      title: 'Review replay events',
      desc: `Triggered when there is interaction with a published review.`
    }
  ]

  return (
    <Page
      title={(state.currMasterObj && state.currMasterObj.dn) && state.currMasterObj.dn}
      titleMetadata={_ia === true ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">Active</Text></Badge>
        : <Badge><Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text></Badge>}
      subtitle={state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
      backAction={{ onAction: () => handleBackToMain() }}>

      <Box paddingInline={800}>
        <BlockStack gap={400}>
          <Card>
            <Box paddingBlockEnd={200}>
              <Text variant='headingMd' as='p' fontWeight='semibold'>Klaviyo setup</Text>
            </Box>
            {/* <Text>Integrate your Klaviyo account to send Email and collect reviews.</Text> */}
            <BlockStack gap={400}>
              <Text variant='headingSm' as='p' fontWeight='semibold'>Connect Your App:</Text>
              <Box>
                <List type="bullet">
                  <List.Item>Enter your Klaviyo API key to build the connection.</List.Item>
                  <List.Item>Submitting the key will automatically push event data to Klaviyo.</List.Item>
                  <List.Item>Trigger a test event once the API key is submitted.</List.Item>
                </List>
              </Box>
              <Box>
                <Box paddingBlockEnd={200}>
                  <Text variant='headingSm' as='p' fontWeight='semibold'>Support events:</Text>
                </Box>
                <InlineGrid columns={['oneThird', 'twoThirds']}>
                  <Box>
                    <Text variant='headingXs' as='p' fontWeight='semibold'>Event name</Text>
                    <Box paddingBlockStart={300}>
                      <BlockStack gap={400}>
                        {supportEventList.map((data, i) => (
                          <Box key={i}>
                            <Text variant='bodyXs' as='p' fontWeight='semibold'>{data.title}</Text>
                          </Box>
                        ))}
                      </BlockStack>
                    </Box>
                  </Box>
                  <Box>
                    <Text variant='headingXs' as='p' fontWeight='semibold'>Description</Text>
                    <Box paddingBlockStart={300}>
                      <BlockStack gap={400}>
                        {supportEventList.map((data, i) => (
                          <Box key={i}>
                            <Text variant='bodyXs' as='p' fontWeight='regular'>{data.desc}</Text>
                          </Box>
                        ))}
                      </BlockStack>
                    </Box>
                  </Box>
                </InlineGrid>
              </Box>
              {Object.keys(state.newDObj).length <= 0 ?
                <Box>
                  <TextField
                    label="Enter Your Klaviyo API Key"
                    placeholder='Enter API key'
                    value={state.dObj.key}
                    onChange={(e) => handleTextChange('key', e)}
                    autoComplete="off"
                    error={state.errMessage.apiKey ? state.errMessage.apiKey : false} />
                  {validator.message('apiKey', state.dObj.key, 'required')}

                  <Box paddingBlockStart={400}>
                    <InlineStack align='center'>
                      <Button id='primary_btn' onClick={() => handleCreateKlaviyo(_ia)}>Create</Button>
                    </InlineStack>
                  </Box>
                </Box>
                :
                <Box>
                  <Text variant="headingSm" as="h3">API key :</Text>
                  <Box paddingBlockStart={200}>
                    <TextField
                      readOnly
                      value={hideAPIKey(state.dObj.key)}
                      onFocus={(e) => e.target.select()}
                      connectedRight={
                        <Tooltip width='wide' padding="400" dismissOnMouseOut content='Delete'>
                          <Button icon={DeleteIcon} variant='plain' onClick={() => openCloseModal('deleteOpen', state.deleteOpen, 'open')} />
                        </Tooltip>
                      } />
                  </Box>
                </Box>
              }
            </BlockStack>
          </Card>

          <Card padding={0}>
            <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
              <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
            </Box>
            <Divider borderColor='border-tertiary' />
            <Box paddingInline={400} paddingBlockEnd={300}>

              <div id='cursorPoint' onClick={() => changeNameValue({ tabEvent: 'firstTabCollapse' })}>
                <Box paddingBlock={300}>
                  <InlineStack wrap={false} blockAlign='center' align='space-between'>
                    <Text variant='headingMd' as='p' fontWeight='medium'>How do I send a review request using Klaviyo?</Text>
                    <Box><Icon source={state.tabEvent === 'firstTabCollapse' ? ChevronUpIcon : ChevronDownIcon}></Icon></Box>
                  </InlineStack>
                  <Collapsible open={state.tabEvent === 'firstTabCollapse'} id="db_collaspe"
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                    <Box paddingBlockStart={100}>
                      <Text variant='headingSm' as='p' fontWeight='regular'>Once you integrate with Klaviyo, we automatically push all the supported events, which you can leverage to send out review requests. To understand how to utilize these events for review requests, please refer to our detailed guide: <Button variant='plain' url='https://support.wisernotify.com/sending-review-requests-with-klaviyo' target='_blank'>Send Review Requests with Klaviyo</Button></Text>
                    </Box>
                  </Collapsible>
                </Box>
              </div>

            </Box>
          </Card>
        </BlockStack>
      </Box>

      <Modal
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this?</Text>
            <PageActions
              primaryAction={{
                id: 'primary_btn',
                content: 'Yes',
                onAction: () => handleDelKlaviyo()
              }}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </Page >
  );
}

export default Klaviyo;