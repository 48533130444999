import { Badge, Page, Button, ButtonGroup, Box, Tooltip, InlineStack, Modal, PageActions, IndexTable, TextField, Text, Select, Checkbox, Card, Divider, Icon, Collapsible, BlockStack, } from '@shopify/polaris';
import { DeleteIcon, EditIcon, ConnectIcon, SendIcon, ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import * as integrationDucks from '../../ducks/integration';
import { useDispatch, useSelector } from 'react-redux';

let _ia = false;

const WhatsappInterakt = ({ props }) => {
  const [cState, setCState] = useState({
    tempId: '',
    interaktUpdData: '',
    isEditable: false,
    mappInteraktOpen: false,
    sendWTAMessageOpen: false,
    deleteOpen: false,
  });
  const { state, validator, changeNameValue, handleTextChange, handleBackToMain } = props;
  const dispatch = useDispatch();

  const [headerData, setHeaderData] = useState([{ id: 1, value: 'PRODUCT_NAME' }]);
  const [bodyData, setBodyData] = useState([{ id: 1, value: 'PRODUCT_NAME' }]);
  const [footerData, setFooterData] = useState([{ id: 1, value: 'WRITE_REVIEW_URL' }]);

  if (state.currUserInt && state.currUserInt.ia !== undefined) //for isActive
    _ia = state.currUserInt.ia;
  if (state.newDObj && Object.keys(state.newDObj).length <= 0) //set active on create
    _ia = true;

  const changeNameCValue = useCallback((obj) => {
    setCState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  const openCloseModal = useCallback((name, value, typ, tempData) => {
    if (name === 'mappInteraktOpen' && tempData) {
      if (tempData.arrmphdr && tempData.arrmphdr.length > 0) {
        setHeaderData(tempData.arrmphdr);
      }
      if (tempData.arrmpbody && tempData.arrmpbody.length > 0) {
        setBodyData(tempData.arrmpbody);
      }
      if (tempData.arrmpbtn && tempData.arrmpbtn.length > 0) {
        setFooterData(tempData.arrmpbtn);
      }
    }
    if (typ !== undefined) changeNameCValue({ [name]: !value });
  }, [changeNameCValue]);

  //api data 
  const interaktUpdData = useSelector(state => state.integration.interaktUpdData);

  useEffect(() => {
    if (interaktUpdData) {
      changeNameCValue({ 'interaktUpdData': interaktUpdData });
    }
  }, [interaktUpdData, changeNameCValue]);

  const headerOptions = [
    { label: 'PRODUCT_NAME', value: 'PRODUCT_NAME' },
    { label: 'CUSTOMER_NAME', value: 'CUSTOMER_NAME' },
    { label: 'STORE_NAME', value: 'STORE_NAME' },
    { label: 'DISCOUNT_CODE', value: 'DISCOUNT_CODE' },
    { label: 'DISCOUNT_VALUE', value: 'DISCOUNT_VALUE' },
  ];

  const bodyOptions = [
    { label: 'PRODUCT_NAME', value: 'PRODUCT_NAME' },
    { label: 'CUSTOMER_NAME', value: 'CUSTOMER_NAME' },
    { label: 'STORE_NAME', value: 'STORE_NAME' },
    { label: 'WRITE_REVIEW_URL', value: 'WRITE_REVIEW_URL' },
    { label: 'DISCOUNT_CODE', value: 'DISCOUNT_CODE' },
    { label: 'DISCOUNT_VALUE', value: 'DISCOUNT_VALUE' },
  ];

  const footerOptions = [
    { label: 'WRITE_REVIEW_URL', value: 'WRITE_REVIEW_URL' },
  ];

  useEffect(() => { //set webPoint
    if (state.currUserInt) {
      let api_url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://reviewsbe.azurewebsites.net/api';
      let _webUrl = `${api_url}/webhook/import?ti=${state.currUserInt.ti}&fuid=${state.currUserInt._id}`;

      changeNameValue({ 'webUrl': _webUrl ? _webUrl : '' });
    }
  }, [state.currUserInt, changeNameValue]);


  useEffect(() => { //set 'select key/value' options
    let keyValOpt = [];
    if (state.WbhkField && state.WbhkField.resp && state.WbhkField.resp.length > 0) {
      keyValOpt = [];
      state.WbhkField.resp.map(((x) => {
        let obj = {};
        obj['value'] = x;
        obj['label'] = x;
        keyValOpt.push(obj);
      }))
    }
    changeNameValue({ 'keyValOpt': keyValOpt });
  }, [state.WbhkField, changeNameValue]);

  useEffect(() => { //remove used keyword from 'keywordsOpt' opt.
    state.ampArray && state.ampArray.length > 0 && state.ampArray.map((x) => {
      state.keywordsOpt && state.keywordsOpt.length > 0 && state.keywordsOpt.map((y, j) => {
        if (Object.keys(x)[0] === y.value) delete state.keywordsOpt[j];
      });
    });
  }, [state.keywordsOpt, state.ampArray]);

  useEffect(() => {  // if 'd' obj is empty, add custom 'd' obj  
    if (state.dObj && Object.keys(state.dObj).length === 0) {
      let obj = { key: '' };
      changeNameValue({ 'dObj': obj });
    }
  }, [state.dObj, changeNameValue]);

  useEffect(() => { //get & save 'd' object
    let obj = {};

    if (state.currUserInt) {
      if (state.currUserInt.d)
        obj = state.currUserInt.d;

      if (state.currUserInt._id)
        changeNameValue({ 'currIntId': state.currUserInt._id });
    }

    let intObj = {
      dObj: obj,
      newDObj: obj
    }
    changeNameValue(intObj);
  }, [state.currUserInt, changeNameValue]);

  const handleSelectHeaderData = (value, id) => {
    const updatedHeaderData = headerData.map(header => {
      if (header.id === id) {
        return { ...header, value };
      }
      return header;
    });
    setHeaderData(updatedHeaderData);
  };

  const handleAddBodyData = () => {
    const newBodyData = {
      id: bodyData.length + 1,
      value: ''
    };
    setBodyData([...bodyData, newBodyData]);
  };

  const handleRemoveBodyData = (id) => {
    const updatedBodyData = bodyData.filter(body => body.id !== id);
    const adjustedBodyData = updatedBodyData.map((body, index) => ({
      ...body,
      id: index + 1
    }));
    setBodyData(adjustedBodyData);
  };

  const handleSelectBodyData = (value, id) => {
    const updatedBodyData = bodyData.map(body => {
      if (body.id === id) {
        return { ...body, value };
      }
      return body;
    });
    setBodyData(updatedBodyData);
  };

  const handleAddFooterData = () => {
    const newFooterData = {
      id: footerData.length + 1,
      value: ''
    };
    setFooterData([...footerData, newFooterData]);
  };

  const handleRemoveFooterData = (id) => {
    const updatedFooterData = footerData.filter(footer => footer.id !== id);
    const adjustedFooterData = updatedFooterData.map((footer, index) => ({
      ...footer,
      id: index + 1
    }));
    setFooterData(adjustedFooterData);
  };

  const handleSelectFooterData = (value, id) => {
    const updatedFooterData = footerData.map(footer => {
      if (footer.id === id) {
        return { ...footer, value };
      }
      return footer;
    });
    setFooterData(updatedFooterData);
  };

  const handleTmplMappingInterakt = () => {
    let obj = {
      id: state.currIntId,
      tid: cState.tempId,
      arrmphdr: headerData,
      arrmpbody: bodyData,
      arrmpbtn: footerData
    }
    dispatch(integrationDucks.updateTmplMappingInterakt(obj));
  }

  const handleCreateInterakt = (_ia) => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    }
    else {
      changeNameValue({ 'errMessage': {} });

      let obj = {
        key: state.dObj.key,
        shp: localStorage.getItem('shp'),
        iid: state.currMasterObj._id ? state.currMasterObj._id : '',
        inm: state.currMasterObj.in ? state.currMasterObj.in : '',
        d: state.dObj,
        ia: _ia !== undefined ? _ia : state.currMasterObj.ia,
        icltrvw: state.currMasterObj.icltrvw
      }

      dispatch(integrationDucks.createInterakt(obj));
      changeNameCValue({ 'isEditable': false });
      changeNameValue({ 'dObj': {} });
    }
  }

  const handleUpdateInterakt = () => {
    let obj = {
      id: state.currIntId,
      key: state.dObj.key
    }

    dispatch(integrationDucks.updateInterakt(obj));
    if (state.interaktUpdData?.isKeyValid === false) {
      changeNameCValue({ 'isEditable': false });
    }
    changeNameValue({ 'dObj': {} });
  }

  const handleDiscrdInterakt = () => {
    changeNameCValue({ 'isEditable': false });
    handleTextChange('key', state.currUserInt.d.key)
  }

  const handleAddTmptId = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    }
    else {
      changeNameValue({ 'errMessage': {} });

      let obj = {
        id: state.currIntId,
        rmk: state.dObj.rmk,
        tmplid: state.dObj.tmplid,
      }

      dispatch(integrationDucks.addTmptIdInterakt(obj));
      changeNameValue({ 'dObj': {} });
    }
  }

  const handleDelTmptId = () => {
    let obj = {
      id: state.currIntId,
      tid: cState.tempId
    }
    dispatch(integrationDucks.delTmptIdInterakt(obj));
    openCloseModal('deleteOpen', cState.deleteOpen, 'close');
    changeNameCValue({ 'tempId': '' })
  }

  const handleupdateTmplIdStatus = (_ia, tid) => {
    let obj = {
      id: state.currIntId,
      tid: tid,
      ia: _ia
    }
    dispatch(integrationDucks.updateTmplIdInteraktStatus(obj));
  }

  const handleSendWTAMessageInterakt = () => {
    let obj = {
      id: state.currIntId,
      tid: cState.tempId,
      number: state.dObj.number
    }
    dispatch(integrationDucks.sendWTAMessageInterakt(obj));
    changeNameValue({ 'dObj': {} });
    openCloseModal('sendWTAMessageOpen', cState.sendWTAMessageOpen, 'close');
  }

  let queArr = [
    {
      field: 'firstTabCollapse',
      question: 'Why API key need?',
      answer: 'An API key is required to establish a connection between WiserReview and interact.'
    },
    {
      field: 'secondTabCollapse',
      question: 'What is template and where you can find it?',
      answer: 'Interact have whatsapp message template we need template name for send message. you can find templates at integract dashboard.'
    },
    {
      field: 'thirdTabCollapse',
      question: 'How mapping work?',
      answer: 'Interact offers WhatsApp message templates necessary for sending messages. You can locate these templates on the Interact dashboard.'
    },
    {
      field: 'fourthTabCollapse',
      question: 'When we send message?',
      answer: 'Messages are sent when we receive events from Shopify or through automation as per your settings, and when an incentive code is generated.'
    },
  ];

  return (
    <Page
      title={(state.currMasterObj && state.currMasterObj.dn) && state.currMasterObj.dn}
      titleMetadata={_ia === true ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">Active</Text></Badge>
        : <Badge><Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text></Badge>}

      subtitle={
        <Text tone='base' >
          {state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
        </Text>}
      backAction={{ onAction: () => handleBackToMain() }}>

      <Box paddingInline={800}>
        <BlockStack gap={400}>
          <Card>
            <BlockStack gap={200}>
              <Text variant='headingMd' as='p' fontWeight='semibold'>Interakt setup</Text>
              <Text>Integrate your interakt account to send messages and collect reviews.</Text>
              {!(state.currUserInt && state.currUserInt.d?.key) ?
                <Box>
                  <TextField
                    label="Map your interakt account"
                    placeholder='Enter API key'
                    value={state.dObj.key}
                    onChange={(e) => handleTextChange('key', e)}
                    autoComplete="off"
                    error={state.errMessage.apiKey ? state.errMessage.apiKey : false} />
                  {validator.message('apiKey', state.dObj.key, 'required')}

                  <Box paddingBlockStart={400}>
                    <InlineStack align='center'>
                      <Button id='primary_btn' onClick={() => handleCreateInterakt(_ia)}>Create</Button>
                    </InlineStack>
                  </Box>
                </Box>
                :
                <Box>
                  <Text variant="headingSm" as="h3">API key :</Text>
                  <Box paddingBlockStart={200}>
                    <TextField
                      readOnly={cState.isEditable ? false : true}
                      value={state.dObj.key}
                      onFocus={(e) => e.target.select()}
                      onChange={(e) => handleTextChange('key', e)}
                      connectedRight={
                        cState.isEditable === false ?
                          <Tooltip width='wide' padding="400" dismissOnMouseOut content='Edit'>
                            <Button onClick={() => changeNameCValue({ 'isEditable': true })} icon={EditIcon} variant='plain' />
                          </Tooltip>
                          :
                          <ButtonGroup>
                            <Button onClick={() => handleUpdateInterakt()} id='primary_btn'>
                              Save
                            </Button>
                            <Button onClick={() => handleDiscrdInterakt()} variant='secondary'>
                              Cancel
                            </Button>
                          </ButtonGroup>
                      } />
                  </Box>
                </Box>
              }
            </BlockStack>
          </Card>

          {state.currUserInt.d &&
            <Card>
              <BlockStack gap={200}>
                <Text variant='headingMd' as='p' fontWeight='semibold'>Add template</Text>
                <TextField
                  label="Remark"
                  placeholder='Enter name'
                  value={state.dObj.rmk}
                  onChange={(e) => handleTextChange('rmk', e)}
                  autoComplete="off"
                  error={state.errMessage.Name ? state.errMessage.Name : false} />
                {validator.message('Name', state.dObj.rmk, 'required')}

                <Box paddingBlockStart={300}>
                  <TextField
                    label="Template name"
                    placeholder='Enter template name'
                    value={state.dObj.tmplid}
                    onChange={(e) => handleTextChange('tmplid', e)}
                    autoComplete="off"
                    error={state.errMessage.TemplateName ? state.errMessage.TemplateName : false} />
                  {validator.message('TemplateName', state.dObj.tmplid, 'required')}
                </Box>

                <Box paddingBlockStart={400}>
                  <InlineStack align='center'>
                    <Button id='primary_btn' onClick={() => handleAddTmptId()}>Create</Button>
                  </InlineStack>
                </Box>
                {state.tempArry?.length > 0 &&
                  <Box>
                    <Box paddingBlockEnd={200}>
                      <Text variant='headingSm' as='p' fontWeight='semibold'>Template list</Text>
                    </Box>
                    <Card padding={0}>
                      <div id='sd_tbl'>
                        <IndexTable
                          itemCount={1}
                          selectable={false}
                          headings={[
                            { title: 'Remark' },
                            { title: 'Template name' },
                            { title: 'Active' },
                            { title: 'Action' }
                          ]} >

                          {state.tempArry && state.tempArry.map((val, index) =>
                            <IndexTable.Row
                              id={index}
                              position={index}
                              key={index}>
                              <IndexTable.Cell>{val.rmk ? val.rmk : '-'}</IndexTable.Cell>
                              <IndexTable.Cell>{val.tmplid ? val.tmplid : '-'}</IndexTable.Cell>
                              <IndexTable.Cell>
                                {/* <Box paddingBlockEnd={300}>
                        <label className="switch">
                          <input type="checkbox" checked={val.ia} onChange={(e) => handleupdateTmplIdStatus(e.target.checked, val._id)} />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </Box> */}
                                <Checkbox
                                  checked={val.ia}
                                  onChange={() => handleupdateTmplIdStatus(!val.ia, val._id)}
                                />
                              </IndexTable.Cell>
                              <IndexTable.Cell>
                                <InlineStack gap={300} align='center' wrap={false}>
                                  <Tooltip width='wide' padding="400" dismissOnMouseOut content="Send">
                                    <Button id='primary_btn' icon={SendIcon} onClick={() => { openCloseModal('sendWTAMessageOpen', cState.sendWTAMessageOpen, 'open'); changeNameCValue({ 'tempId': val._id }) }} />
                                  </Tooltip>
                                  <Tooltip width='wide' padding="400" dismissOnMouseOut content="Field Mapping">
                                    <Button variant='secondary' icon={ConnectIcon} onClick={() => { openCloseModal('mappInteraktOpen', cState.mappInteraktOpen, 'open', val); changeNameCValue({ 'tempId': val._id }) }}></Button></Tooltip>
                                  <Tooltip width='wide' padding="400" dismissOnMouseOut content="Delete">
                                    <Button variant="primary" tone="critical" icon={DeleteIcon} onClick={() => { openCloseModal('deleteOpen', cState.deleteOpen, 'open'); changeNameCValue({ 'tempId': val._id }) }}></Button>
                                  </Tooltip>
                                </InlineStack>
                              </IndexTable.Cell>
                            </IndexTable.Row>
                          )}
                        </IndexTable>
                      </div>
                    </Card>
                  </Box>
                }
              </BlockStack>
            </Card>
          }

          <Card padding={0}>
            <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
              <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
            </Box>
            <Divider borderColor='border-tertiary' />
            <Box paddingInline={400} paddingBlockEnd={300}>

              {queArr && queArr.map((data, i) => {
                return (
                  <div key={i} id='cursorPoint' onClick={() => changeNameValue({ tabEvent: data.field })}>
                    <Box paddingBlock={300}>
                      <InlineStack wrap={false} blockAlign='center' align='space-between'>
                        <Text variant='headingSm' as='p' fontWeight='medium'>{data.question}</Text>
                        <Box><Icon source={data.field === state.tabEvent ? ChevronUpIcon : ChevronDownIcon}></Icon></Box>
                      </InlineStack>
                      <Collapsible open={data.field === state.tabEvent} id="db_collaspe"
                        transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                        <Box paddingBlockStart={100}>
                          <Text variant='headingSm' as='p' fontWeight='regular' tone="subdued">{data.answer}</Text>
                        </Box>
                      </Collapsible>
                    </Box>
                    {i !== queArr.length - 1 && <Divider />}
                  </div>
                )
              })}

            </Box>
          </Card>
        </BlockStack>
      </Box>

      <Modal
        size='large'
        open={cState.mappInteraktOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Field Mapping</Text>}
        onClose={(e) => openCloseModal('mappInteraktOpen', cState.mappInteraktOpen, e)}>
        <div className='fieldMapp_modal'>
          <Modal.Section>
            <Text variant="headingSm" fontWeight="regular" as="h6">Select columns from your CSV file to map against review fields, or to ignore during import. Note: Custom Forms columns will not be displayed below, but will also be imported automatically</Text>

            <Box paddingBlockStart={400} paddingBlockEnd={400}>
              <Card padding={0}>
                <IndexTable
                  itemCount={1}
                  headings={[
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">Header field</Text>, id: 'revField' },
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">CSV column name</Text>, id: 'csvName' },
                  ]}
                  selectable={false}>

                  {headerData?.map(header => (
                    <IndexTable.Row key={header.id}>
                      <IndexTable.Cell>
                        <Text variant="bodyMd" fontWeight="medium" as="h6">&#123;&#123;{header.id}&#125;&#125;</Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Select
                          options={headerOptions}
                          value={header.value}
                          onChange={(value) => handleSelectHeaderData(value, header.id)}
                        />
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  ))}
                </IndexTable>
              </Card>
            </Box>

            <Box paddingBlockStart={400} paddingBlockEnd={400}>
              <Card padding={0}>
                <IndexTable
                  itemCount={1}
                  headings={[
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">Body field</Text>, id: 'revField' },
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">CSV column name</Text>, id: 'csvName' },
                  ]}
                  selectable={false}>

                  {bodyData?.map(body => (
                    <IndexTable.Row key={body.id}>
                      <IndexTable.Cell>
                        <Text variant="bodyMd" fontWeight="medium" as="h6">&#123;&#123;{body.id}&#125;&#125;</Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <InlineStack wrap={false} gap={300}>
                          <div style={{ width: "100%" }}>
                            <Select
                              options={bodyOptions}
                              value={body.value}
                              onChange={(value) => handleSelectBodyData(value, body.id)}
                            />
                          </div>
                          <InlineStack wrap={false} gap={200}>
                            {body.id === bodyData.length ? <Button onClick={() => handleAddBodyData()}>Add</Button> : null}
                            {bodyData.length > 1 ? <Button onClick={() => handleRemoveBodyData(body.id)}>Remove</Button> : null}
                          </InlineStack>
                        </InlineStack>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  ))}
                </IndexTable>
              </Card>
            </Box>

            <Box paddingBlockStart={400} paddingBlockEnd={400}>
              <Card padding={0}>
                <IndexTable
                  itemCount={1}
                  headings={[
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">Button field</Text>, id: 'revField' },
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">CSV column name</Text>, id: 'csvName' },
                  ]}
                  selectable={false}>

                  {footerData?.map(footer => (
                    <IndexTable.Row key={footer.id}>
                      <IndexTable.Cell>
                        <Text variant="bodyMd" fontWeight="medium" as="h6">&#123;&#123;{footer.id}&#125;&#125;</Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <InlineStack wrap={false} gap={300}>
                          <div style={{ width: "100%" }}>
                            <Select
                              options={footerOptions}
                              value={footer.value}
                              onChange={(value) => handleSelectFooterData(value, footer.id)}
                            />
                          </div>
                          <InlineStack wrap={false} gap={200}>
                            {footerData.length === 2 ? null : <Button onClick={() => handleAddFooterData()}>Add</Button>}
                            {footerData.length > 1 ? <Button onClick={() => handleRemoveFooterData(footer.id)}>Remove</Button> : null}
                          </InlineStack>
                        </InlineStack>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  ))}
                </IndexTable>
              </Card>
            </Box>

            <InlineStack align="end">
              <ButtonGroup>
                <Button onClick={(e) => openCloseModal('mappInteraktOpen', cState.mappInteraktOpen, e)}>Cancel</Button>
                <Button id='primary_btn' onClick={() => handleTmplMappingInterakt()}>Submit</Button>
              </ButtonGroup>
            </InlineStack>
          </Modal.Section>
        </div>
      </Modal>

      <Modal
        size='small'
        open={cState.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', cState.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this?</Text>
            <PageActions
              primaryAction={{
                id: 'primary_btn',
                content: 'Yes',
                onAction: () => handleDelTmptId()
              }}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', cState.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

      <Modal
        size='small'
        open={cState.sendWTAMessageOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Send Message</Text>}
        onClose={(e) => openCloseModal('sendWTAMessageOpen', cState.sendWTAMessageOpen, e)}>
        <Modal.Section>
          <TextField
            label="Number (with dial code)"
            type='number'
            value={state.dObj.number}
            onChange={(e) => handleTextChange('number', e)}
          />
          <PageActions
            primaryAction={{
              id: 'primary_btn',
              content: 'Send',
              onAction: () => handleSendWTAMessageInterakt()
            }}
          />
        </Modal.Section>
      </Modal>
    </Page >
  );
}

export default WhatsappInterakt;