import React from 'react';
import { QnaCard } from "../../components";
import { useIndexResourceState, Scrollable, Filters, InlineStack, InlineGrid, Popover, useSetIndexFiltersMode, DatePicker, Button, Box, OptionList, Thumbnail, Tag } from '@shopify/polaris';
import { CalendarIcon, ImageIcon } from '@shopify/polaris-icons';
import * as groupDucks from '../../ducks/groupProduct';
import * as queAnsDucks from '../../ducks/queAns';
import { toastify } from '../../ducks/toast';
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';
import { useBreakpoints } from '@shopify/polaris';

const moment = require('moment');
function momentToDate(dt) {
  return moment(new Date(dt)).toDate().toDateString();
}

const QueAns = () => {
  const validator = new SimpleReactValidator({ locale: 'en' });
  let num = 1, pageNumber = 0, TotalData = 0, QuestionData = [], appliedFilters = [], newProOpt = [], groupProduct_options = [], groupPageInfo = {};
  const dispatch = useDispatch();
  const { mode, setMode } = useSetIndexFiltersMode();

  const [state, setState] = useState({
    importOpen: false,
    statusTabs: [ // tabs
      { label: 'All', value: 'All' },
      { label: 'Publish', value: 'publish' },
      { label: 'Unpublished', value: 'unpublish' },
      { label: 'Answer', value: 'answer' },
    ],
    csvData: '',
    fileErr: true, // import file error
    QuestionData: [],
    queStatus: 'publish', //status -> publish,unpublish
    queryValue: '',  //filter search value
    sortSelected: ['quedt desc'],
    pnTyp: '', // filter product name
    proPid: '', //filter product id
    viewOpen: false,
    paggiActive: 1,
    selected: 1,   //current tab selected
    frmdt: momentToDate(moment(new Date()).subtract(24, 'hour').format('YYYY-MM-DD')),
    tdt: momentToDate(moment(new Date()).format('YYYY-MM-DD')),
    fromDate: '',
    toDate: '',
    mainTabIndex: 'quetionAns',
    prodOptions: newProOpt,
    src: 'csv',
    queAnsDeleteOpen: false,
    queAnsDeleteId: '',
    prodSelectErr: false,
    addQueAnsOpen: false,
    addQueAnsobj: {
      ti: localStorage.getItem('ti') ? localStorage.getItem('ti') : '',
      shp: localStorage.getItem('shp') ? localStorage.getItem('shp') : '',
      que: '',
      ans: '',
      ia: true,
      arr: [],
      pidarr: [],
      quedt: Date.now(),
      ansdt: Date.now(),
      src: 'admin'
    },
    curUserName: '',
    curUserEmail: '',
    curUserDate: '',
    groupProdOptions: newProOpt,
    errMessage: {},
    udtQueAnsOpen: false,
    udtQueAnsDataId: '',
    createProSelectedItem: [],
    groupPopActive: false,
    groupProRmvArr: [],
    groupViewProOpen: false,
  });

  useEffect(() => { //on tab click set paggination to 1st page
    if (state.mainTabIndex === 'quetionAns') {
      // getProductOpt();
      changeNameValue({ 'paggiActive': 1 });
    }
  }, [state.mainTabIndex]);

  useEffect(() => {
    if (state.mainTabIndex === 'quetionAns') {
      if (state.queryValue) {
        let queryTimer = setTimeout(() => { callQueAnsApi(); }, 1000);
        return () => clearTimeout(queryTimer);
      }
      else {
        callQueAnsApi();
      }
    }
  }, [state.mainTabIndex, state.pnTyp, state.queStatus, state.queryValue, state.paggiActive, state.sortSelected, state.fromDate, state.toDate]);

  const callQueAnsApi = () => {
    let srtValue = state.sortSelected.toString().replace('asc', '1').replace('desc', '-1');
    let obj = {
      num: state.paggiActive,
      stxt: state.queryValue,
      typ: state.queStatus,
      pn: state.pnTyp.toString(),
      srt: srtValue,
      from: state.fromDate ? moment(new Date(state.fromDate)).format('YYYY-MM-DD') + " 00:00:00" : '',
      to: state.toDate ? moment(new Date(state.toDate)).format('YYYY-MM-DD') + " 23:59:59" : '',
    };
    global.modeObj = obj;
    dispatch(queAnsDucks.getQueAns(obj));
  };

  // api data
  const custQueAns = useSelector(state => state.queAns);
  if (custQueAns.QuestionAnsList && custQueAns.QuestionAnsList.data) {
    QuestionData = custQueAns.QuestionAnsList.data;
    QuestionData = QuestionData.map(x => ({ ...x, id: x._id }));
  }
  if (custQueAns.QuestionAnsList && custQueAns.QuestionAnsList.total)
    TotalData = custQueAns.QuestionAnsList.total;

  const onPaggiCall = (i) => {
    if (i === '+1') {
      num = state.paggiActive + 1;
    } else if (i === '-1') {
      num = state.paggiActive - 1;
    } else
      num = i;
    changeNameValue({ 'paggiActive': num });
  }
  if (TotalData && TotalData > 0) {
    pageNumber = TotalData / 20;
    pageNumber = parseInt(Math.ceil(pageNumber));
  }
  let paggiLabel =
    Array.from(Array(pageNumber), (e, i) => {
      return (
        <span style={{ margin: '0 3px', display: 'inline-block' }} key={(i + 1)} onClick={() => onPaggiCall((i + 1))}>
          <Button variant={state.paggiActive === i + 1 ? 'primary' : 'secondary'}>
            {(i + 1)}
          </Button>
        </span>
      );
    })

  //date range
  const { selectedResources, handleSelectionChange } = useIndexResourceState(QuestionData);
  const { mdDown, lgUp } = useBreakpoints();
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const yesterday = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0, 0));

  const ranges = [
    {
      title: "Today",
      alias: "today",
      period: {
        since: yesterday,
        until: today,
      },
    },
    {
      title: "Last 7 days",
      alias: "last7days",
      period: {
        since: new Date(new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0)),
        until: yesterday,
      },
    },
    {
      title: "Last 30 days",
      alias: "last30days",
      period: {
        since: new Date(new Date(new Date().setDate(today.getDate() - 30)).setHours(0, 0, 0, 0)),
        until: yesterday,
      },
    }
  ];

  const [activeDateRange, setActiveDateRange] = useState(ranges[0]);
  const [{ month, year }, setDate] = useState({
    month: activeDateRange.period.since.getMonth(),
    year: activeDateRange.period.since.getFullYear()
  });

  const activeRangeChange = (state) => {
    setActiveDateRange(state);
    let obj = {
      frmdt: state.period.since.toDateString(),
      tdt: state.period.until.toDateString()
    }
    changeNameValue(obj);
  }

  function handleMonthChange(month, year) {
    setDate({ month, year });
  }

  function handleCalendarChange({ start, end }) {
    const newDateRange = ranges.find((range) => {
      return (
        range.period.since.valueOf() === start.valueOf() &&
        range.period.until.valueOf() === end.valueOf()
      );
    }) || {
      alias: "custom",
      title: "Custom",
      period: {
        since: start,
        until: end,
      },
    };

    let obj = {
      frmdt: newDateRange.period.since.toDateString(),
      tdt: newDateRange.period.until.toDateString()
    }
    changeNameValue(obj);
    activeRangeChange(newDateRange);
  }

  useEffect(() => {
    let start = new Date(state.frmdt);
    let end = new Date(state.tdt);

    const newDateRange = ranges.find((range) => {
      return (
        range.period.since.valueOf() === start.valueOf() &&
        range.period.until.valueOf() === end.valueOf()
      );
    }) || {
      alias: "custom",
      title: "Custom",
      period: {
        since: start,
        until: end,
      }
    };
    activeRangeChange(newDateRange);

    if (state.popoverActive) {
      if (state.fromDate === '' && state.toDate === '') activeRangeChange(ranges[0]);

      if (checkValidDate(state.frmdt) && checkValidDate(state.tdt)) {
        let obj = { fromDate: state.frmdt, toDate: state.tdt };
        changeNameValue(obj);
      }
      else changeNameValue({ fromDate: '', toDate: '' });
    }
  }, [state.frmdt, state.tdt, state.popoverActive]);

  const checkValidDate = (dateString) => {
    if (dateString && !dateString.includes("Invalid")) {
      return true;
    } else {
      return false;
    }
  }

  const buttonValue = (checkValidDate(state.frmdt) && checkValidDate(state.tdt)) ? state.frmdt + " - " + state.tdt : 'tdt';
  const dtFilterValue = (checkValidDate(state.fromDate) && checkValidDate(state.toDate)) ? state.fromDate + " - " + state.toDate : 'Date';

  //product list 
  const groupState = useSelector(state => state.groupProduct);
  if (groupState.ProductName && groupState.ProductName.data) //product List data
    groupProduct_options = groupState.ProductName.data;

  useEffect(() => { //get productList
    for (let i = 0; i < groupProduct_options.length; i++) {
      const ele = groupProduct_options[i];
      let optArr = {};
      optArr['id'] = ele.pid.toString();
      optArr['value'] = ele.pnm;
      optArr['label'] = ele.pnm;
      optArr['media'] = ele.pimg ?
        <Thumbnail source={ele.pimg} size='small' alt="Product Image" />
        : <Thumbnail source={ImageIcon} size='small' />

      newProOpt.push(optArr);
    }
    let obj = { prodOptions: newProOpt, prodNewOptions: newProOpt };
    changeNameValue(obj);
  }, [groupState.ProductName]);

  useEffect(() => { // call product search api on tab click 'Grouping'
    if (state.productValue !== undefined) {
      let productValue = setTimeout(() => handleSearchInnerProduct(), 700);
      return () => clearTimeout(productValue);
    }
  }, [state.productValue]);

  const handleSearchInnerProduct = (value) => {
    dispatch(groupDucks.getProductName({ title: value ? value : state.productValue }));
    changeNameValue({ prodOptions: state.prodOptions });
  };

  const filters = [
    {
      key: 'pnTyp',
      label: (state.pnTyp !== "" ? `Product: ${state.pnTyp}` : "Product"),
      shortcut: true,
      pinned: true,
      hideClearButton: true,
      filter: (
        <div>
          <Box paddingBlock={200}>
            <Filters
              queryValue={state.productValue}
              queryPlaceholder='Search product'
              onQueryChange={(e) => changeNameValue({ 'productValue': e })}
              filters={[]}
              appliedFilters={[]}
              onQueryClear={() => changeNameValue({ 'productValue': '' })}
            />
          </Box>
          <OptionList
            id='pro_name'
            onChange={(e) => changeNameValue({ 'pnTyp': e })}
            options={state.prodOptions}
            selected={state.pnTyp} />
        </div>
      )
    },
    {
      key: 'proCDT',
      label: 'Date',
      shortcut: true,
      pinned: true,
      filter: (
        <Popover
          fluidContent
          fullHeight
          active={state.popoverActive}
          preferredAlignment="left"
          preferredPosition="below"
          activator={<Button onClick={() => changeNameValue({ 'popoverActive': !state.popoverActive })}
            icon={CalendarIcon}>{buttonValue}</Button>}
          onClose={() => changeNameValue({ 'popoverActive': false })}>

          <Popover.Pane>
            <InlineGrid
              columns={{
                xs: "1fr",
                mdDown: "1fr",
                md: "max-content max-content",
              }}
              gap={0}>

              <Box  // today/week/month selection
                maxWidth={mdDown ? "516px" : "160px"}
                width={mdDown ? "100%" : "160px"} >
                <Scrollable>
                  <OptionList
                    options={ranges.map((x) => ({
                      value: x.alias,
                      label: x.title
                    }))}
                    selected={activeDateRange.alias}
                    onChange={(value) => {
                      const result = ranges.find((range) => range.alias === value[0]);
                      activeRangeChange(result);
                    }} />
                </Scrollable>
              </Box>

              <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>
                <InlineStack gap="400">
                  <div>
                    <DatePicker
                      month={month}
                      year={year}
                      selected={{
                        start: activeDateRange.period.since,
                        end: activeDateRange.period.until,
                      }}
                      onMonthChange={handleMonthChange}
                      onChange={handleCalendarChange}
                      multiMonth={lgUp}
                      allowRange />
                  </div>
                </InlineStack>
              </Box>
            </InlineGrid>
          </Popover.Pane>

          <Box padding={200}>
            <InlineStack align="end">
              <Button variant='tertiary' onClick={() => changeNameValue({ 'popoverActive': false })}>Close</Button>
            </InlineStack>
          </Box>

        </Popover>
      )
    }
  ];
  if (state.frmdt && state.tdt) {
    const key = "proCDT";
    appliedFilters.push({
      key,
      label: dtFilterValue,
      onRemove: () => {
        changeNameValue({ fromDate: '', toDate: '' });
      }
    });
  }

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  const handleFiltersClearAll = () => {
    let obj = {
      queryValue: '',
      pnTyp: '',
      fromDate: '',
      toDate: '',
    }
    changeNameValue(obj);
    changeNameValue({ productValue: "" })
  }

  const tabs = state.statusTabs.map((item, i) => ({
    key: i,
    id: `${item.label}-${i}`,
    content: item.label,
    onAction: () => queStatusClick(item.value)
  }));

  const queStatusClick = (typ) => {
    let stateObj = {
      queStatus: typ,
      paggiActive: 1
    }
    setState((prevState) => ({ ...prevState, ...stateObj }));
  }

  useEffect(() => { //on filterTab(all,publish...) click set paggination 1st page]
    changeNameValue({ paggiActive: 1 });
  }, [state.queStatus]);

  const openCloseModal = useCallback((name, value, type) => {
    // if (name === 'viewOpen' && type !== 'open' && value === true)  //on close event clear state
    //   callQueAnsApi();
    if (type !== undefined || name === 'productViewOpen' || name === 'imgOpen') {
      changeNameValue({ [name]: !value }); //state modal openClose
    }
    changeNameValue({ errMessage: {} });
  }, [state.queStatus]);

  const handleImportData = () => {
    const csvdata = state.csvData;
    if (csvdata !== '') {
      let dataObj = [];
      csvdata.forEach(element => {
        if (element.question !== "" && element.question !== undefined && element.customer_name !== "" && element.customer_name !== undefined && element.customer_email !== "" && element.customer_email !== undefined) {

          const obj = {
            ti: localStorage.getItem('ti') ? localStorage.getItem('ti') : '',
            shp: localStorage.getItem('shp') ? localStorage.getItem('shp') : '',
            que: element.question,
            ans: element.answer,
            ia: element.active == "TRUE" ? true : false,
            product_id: element.product_id,
            product_handle: element.product_handle,
            cstnm: element.customer_name,
            csteml: element.customer_email,
            quedt: element.question_date,
            ansdt: element.answer_date,
            src: state.src
          }
          dataObj.push(obj);
        }
        else {
          dispatch(toastify({ type: 'error', msg: 'Select Proper Data File !' }));
        }
      });
      if (dataObj.length > 0) {
        dispatch(queAnsDucks.importQueAnsCSV(dataObj));
      }
      openCloseModal('importOpen', state.importOpen, 'open')
      changeNameValue({ csvData: '' })
    }
    else {
      dispatch(toastify({ type: 'error', msg: 'Select Data File !' }));
    }
  }

  const actionListClick = (typ) => {
    let pid = [];
    selectedResources.forEach((id) => {
      let data = QuestionData.find((x) => x._id === id);
      if (data) {
        for (let i = 0; i < data.arrpid.length; i++) {
          pid.push(data.arrpid[i].pid || '');
        }
      }
    });

    // Remove duplicates from arrays
    pid = Array.from(new Set(pid));

    const data = {
      'arr_id': state.queAnsDeleteId !== '' ? state.queAnsDeleteId : selectedResources,
      'ti': localStorage.getItem('ti') ? localStorage.getItem('ti') : '',
      'typ': typ,
      'pidarr': pid
    }
    dispatch(queAnsDucks.updateQueAns(data));
    selectedResources.splice(0, selectedResources.length);
    if (typ == 'delete') {
      openCloseModal('queAnsDeleteOpen', state.queAnsDeleteOpen, 'close');
    }
    if (state.queAnsDeleteId !== '') {
      openCloseModal('viewOpen', state.viewOpen, 'close');
    }
    changeNameValue({ queAnsDeleteId: '' });
  }

  const handleTextChange = (obj) => {
    const { addQueAnsobj } = { ...state };
    let currentState = JSON.stringify(addQueAnsobj);
    currentState = JSON.parse(currentState);
    currentState = { ...currentState, ...obj };
    changeNameValue({ addQueAnsobj: currentState });
  }

  //ADD question answer
  const handleAddQueAns = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    }
    else {
      dispatch(queAnsDucks.createQueAns(state.addQueAnsobj));
      openCloseModal('addQueAnsOpen', state.addQueAnsOpen, 'close');
      changeNameValue({ groupViewProOpen: false });
      changeNameValue({ createProSelectedItem: [] });
      changeNameValue({ verticalTags: '' });
      handleTextChange({
        que: '',
        ans: '',
        ia: true,
        arr: [],
        pidarr: []
      })
    }
  }

  //update question answer
  const handleUptQueAns = (data) => {
    handleTextChange({ 'que': data.que, 'ans': data.ans, 'ia': data.ia });
    const obj = {
      curUserName: data.cstnm,
      curUserEmail: data.csteml,
      curUserDate: data.quedt
    }
    changeNameValue(obj);
    changeNameValue({ udtQueAnsDataId: data._id });
    let newArr = [];
    let dataArr = data.arrpid
    dataArr && dataArr.length > 0 && dataArr.map((x) => {
      newArr.push(x.pid + '|' + x.pn);
      return newArr;
    });
    changeNameValue({ createProSelectedItem: newArr })
  }

  const handleUptQueAnsSuccess = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    }
    else {
      const data = {
        '_id': state.udtQueAnsDataId,
        'ti': localStorage.getItem('ti') ? localStorage.getItem('ti') : '',
        'que': state.addQueAnsobj.que,
        'ans': state.addQueAnsobj.ans,
        'ia': state.addQueAnsobj.ia,
        'arrpid': state.addQueAnsobj.arr,
        'pidarr': state.addQueAnsobj.pidarr,
      }
      dispatch(queAnsDucks.updateQueAns(data));
      openCloseModal('viewOpen', state.viewOpen, 'close')
      handleTextChange({
        que: '',
        ans: '',
        ia: true,
        arr: [],
        pidarr: []
      });
      changeNameValue({ udtQueAnsDataId: '' });
      changeNameValue({ groupViewProOpen: false });
    }
  }

  const updQueAnsStatus = (ia) => {
    const data = {
      '_id': state.udtQueAnsDataId,
      'ti': localStorage.getItem('ti') ? localStorage.getItem('ti') : '',
      'ia': ia,
      'pidarr': state.addQueAnsobj.pidarr
    }
    dispatch(queAnsDucks.updateQueAns(data));
    handleTextChange({ ia: ia });
  }

  useEffect(() => {
    let obj = {};

    if (groupPageInfo) {
      if (groupPageInfo && groupPageInfo.startCursor !== undefined)
        obj['startCursor'] = groupPageInfo.startCursor;

      if (groupPageInfo && groupPageInfo.endCursor !== undefined)
        obj['endCursor'] = groupPageInfo.endCursor;

      if (groupPageInfo && groupPageInfo.hasPreviousPage !== undefined)
        obj['hasPreviousPage'] = groupPageInfo.hasPreviousPage;

      if (groupPageInfo && groupPageInfo.hasNextPage !== undefined)
        obj['hasNextPage'] = groupPageInfo.hasNextPage;
    }

    changeNameValue(obj);
  }, [groupPageInfo]);

  useEffect(() => { // call product search api on tab click 'Grouping'
    if (state.groupNewSearch !== undefined) {
      let groupNewSearch = setTimeout(() => handleSearchGroupProduct(), 700);
      return () => clearTimeout(groupNewSearch);
    }
  }, [state.groupNewSearch]);

  const handleSearchGroupProduct = (value) => {
    dispatch(groupDucks.getProductName({ title: value ? value : state.groupNewSearch }));
    changeNameValue({ groupProdOptions: state.groupProdOptions });
  };

  const selectProducts = (name, value) => {

    let stateItemsCount = state.createProSelectedItem.length + 1;
    let stateItemsCount2 = state.createProSelectedItem.length - 1;

    if (state.hasPreviousPage === true) { //if there is more than 250 data(next pagination is on)
      if (stateItemsCount !== value.length && stateItemsCount2 !== value.length) {
        let meregedArr = state.createProSelectedItem.concat(value);
        value = [...new Set(meregedArr)];
      }
    }
    changeNameValue({ [name]: value });
  }

  const removeTag = useCallback((option, tagId) => () => {
    const options = [...state.createProSelectedItem];
    options.splice(options.indexOf(option), 1);
    changeNameValue({ createProSelectedItem: options });

    state.groupProRmvArr.push(tagId);
    changeNameValue({ groupProRmvArr: state.groupProRmvArr });
  }, [state.createProSelectedItem]);

  useEffect(() => {
    const val = state.addQueAnsobj.arr;
    if (val !== '') {
      const productId = val?.map(elm => {
        return elm.pid;
      });
      handleTextChange({ pidarr: productId })
    }
  }, [state.addQueAnsobj.arr])

  useEffect(() => { //added multiple product tag
    let verticalTags = state.createProSelectedItem.length > 0 ? (
      <InlineStack gap={200}>
        {state.createProSelectedItem && state.createProSelectedItem.length > 0 &&
          state.createProSelectedItem.map((option) => {
            let tagLabel = option.split('|');
            if (tagLabel && tagLabel.length > 1) {
              return (<Tag key={option} onRemove={removeTag(option, tagLabel[0])}>{tagLabel[1]}</Tag>);
            }
          })}
      </InlineStack>
    ) : '';
    changeNameValue({ verticalTags: verticalTags });
    const val = state.createProSelectedItem;
    if (val !== '') {
      const selectedProd = val?.map(item => item?.split('|'));
      const mainprod = selectedProd?.map(elm => {
        return { 'pid': elm[0], 'pn': elm[1] }
      })
      handleTextChange({ arr: mainprod })
    }
  }, [state.createProSelectedItem, state.groupProdOptions]);

  const openGroupProModal = (name, value) => {
    changeNameValue({ [name]: value });
    openCloseModal('groupViewProOpen', state.groupViewProOpen, 'open');
  }

  const convertLblIdImg = (opt) => {
    let newArr = [];
    for (let i = 0; i < opt.length; i++) {
      const data = opt[i];
      let optArr = {};
      optArr['id'] = data.pid.toString() + '|' + data.pnm;
      optArr['label'] = data.pnm;
      optArr['media'] = data.pimg ?
        <Thumbnail source={data.pimg} size='small' alt="Product Image" />
        : <Thumbnail source={ImageIcon} size='small' />;
      newArr.push(optArr);
    }
    return newArr;
  }

  useEffect(() => {
    if (groupProduct_options !== state.proStateOption) {
      changeNameValue({ proStateOptio: groupProduct_options });
      let opt = convertLblIdImg(groupProduct_options);
      changeNameValue({ groupProdOptions: opt });
    }
  }, [groupState.ProductName]);

  return (
    <QnaCard
      state={state}
      tabs={tabs}
      mode={mode}
      setMode={setMode}
      filters={filters}
      pageNumber={pageNumber}
      onPaggiCall={onPaggiCall}
      paggiLabel={paggiLabel}
      changeNameValue={changeNameValue}
      appliedFilters={appliedFilters}
      handleFiltersClearAll={handleFiltersClearAll}
      selectedResources={selectedResources}
      handleSelectionChange={handleSelectionChange}
      openCloseModal={openCloseModal}
      QuestionData={QuestionData}
      handleImportData={handleImportData}
      handleAddQueAns={handleAddQueAns}
      handleTextChange={handleTextChange}
      validator={validator}
      handleUptQueAns={handleUptQueAns}
      handleUptQueAnsSuccess={handleUptQueAnsSuccess}
      selectProducts={selectProducts}
      openGroupProModal={openGroupProModal}
      handleSearchGroupProduct={handleSearchGroupProduct}
      actionListClick={actionListClick}
      updQueAnsStatus={updQueAnsStatus}
    />
  );
}

export default QueAns;