import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/ugc';
import { toastify } from '../ducks/toast';

function* fbauth(action) {
	try {
		yield put(load());
		const res = yield call(api.POST, '/fbauth', action.payload);
		if (res) {
			yield put(actions.getUGCInt())
		} else {
			yield put(toastify({ type: 'error', msg: res.m }));
		}
		yield put(loaded());
	} catch (error) {
		yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
	}
}

function* getUGCInt(action) {
	try {
		yield put(load());
		const res = yield call(api.GET, '/getUGCInt', action.payload);

		if (res.status === 'success') {
			yield put(actions.getUGCIntSuccess(res.data));
		}
		else {
			yield put(toastify({ type: 'error', msg: res.m }));
		}
		yield put(loaded());
	} catch (error) {
		yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
	}
}

function* updPageStatus(action) {
	try {
		yield put(load());
		const res = yield call(api.POST, '/updPageStatus', action.payload);

		if (res.status === 'success') {
			yield put(actions.getUGCInt())
		}
		else {
			yield put(toastify({ type: 'error', msg: res.m }));
		}
		yield put(loaded());
	} catch (error) {
		yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
	}
}

function* delFbInt(action) {
	try {
		yield put(load());
		const res = yield call(api.POST, '/delFbInt', action.payload);

		if (res.status === 'success') {
			yield put(toastify({ type: 'success', msg: res.m }));
			yield put(actions.getUGCInt())
		}
		else {
			yield put(toastify({ type: 'error', msg: res.m }));
		}
		yield put(loaded());
	} catch (error) {
		yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
	}
}

function* getInstaPost(action) {
	try {
		yield put(load());
		const res = yield call(api.POST, '/getInstaPost', action.payload);

		if (res.status === 'success') {
			yield put(actions.getInstaPostSuccess(res.data));
		}
		else {
			yield put(toastify({ type: 'error', msg: res.m }));
		}
		yield put(loaded());
	} catch (error) {
		yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
	}
}

function* ugcAddProduct(action) {
    try {
        yield put(load());
        const res = yield call(api.POST, '/ugcAddProduct', action.payload);

        if (res.status === 'success') {
            yield put(toastify({ type: 'success', msg: res.m }));
            yield put(actions.getInstaPost(global.modeObj));
        }
        else {
            yield put(toastify({ type: 'error', msg: res.m }));
        }
        yield put(loaded());
    } catch (error) {
        yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
}

function* changePostStatus(action) {
    try {
        yield put(load());
        const res = yield call(api.POST, '/changePostStatus', action.payload);

        if (res.status === 'success') {
            yield put(toastify({ type: 'success', msg: res.m }));
            yield put(actions.getInstaPost(global.modeObj))
        }
        else {
            yield put(toastify({ type: 'error', msg: res.m }));
        }
        yield put(loaded());
    } catch (error) {
        yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
}

function* updtInstaPost(action) {
    try {
        yield put(load());
        const res = yield call(api.POST, '/updtInstaPost', action.payload);

        if (res.status === 'success') {
            yield put(actions.getInstaPost(global.modeObj));
        }
        else {
            yield put(toastify({ type: 'error', msg: res.m }));
        }
        yield put(loaded());
    } catch (error) {
        yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
}


function* getSocialPush(action) {
	try {
		yield put(load());
		const res = yield call(api.GET, '/getSocialPush', action.payload);

		if (res.status === 'success') {
			yield put(actions.getSocialPushSuccess(res.data));
		}
		else {
			yield put(toastify({ type: 'error', msg: res.m }));
		}
		yield put(loaded());
	} catch (error) {
		yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
	}
}

function* updtSocialPush(action) {
    try {
        yield put(load());
        const res = yield call(api.POST, '/updtSocialPush', action.payload);

        if (res.status === 'success') {
			yield put(actions.getSocialPush());
            yield put(toastify({ type: 'success', msg: res.m }));
        }
        else {
            yield put(toastify({ type: 'error', msg: res.m }));
        }
        yield put(loaded());
    } catch (error) {
        yield put(loaded());
		yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
}

export function* watchFbAuth() {
	yield takeLatest(actions.fbauth.type, fbauth);
}
export function* watchgetUserFbIntegration() {
	yield takeLatest(actions.getUGCInt.type, getUGCInt);
}
export function* watchUpdatePageStatus() {
	yield takeLatest(actions.updPageStatus.type, updPageStatus);
}
export function* watchdelFbIntegration() {
	yield takeLatest(actions.delFbInt.type, delFbInt);
}
export function* watchgetInstaPost() {
	yield takeLatest(actions.getInstaPost.type, getInstaPost);
}
export function* watchugcAddProduct() {
    yield takeLatest(actions.ugcAddProduct.type, ugcAddProduct);
}
export function* watchchangePostStatus() {
    yield takeLatest(actions.changePostStatus.type, changePostStatus);
}
export function* watchupdtInstaPost() {
    yield takeLatest(actions.updtInstaPost.type, updtInstaPost);
}
export function* watchgetSocialPush() {
    yield takeLatest(actions.getSocialPush.type, getSocialPush);
}
export function* watchupdtSocialPush() {
    yield takeLatest(actions.updtSocialPush.type, updtSocialPush);
}

export default function* rootSaga() {
    yield fork(watchFbAuth);
    yield fork(watchgetUserFbIntegration);
    yield fork(watchUpdatePageStatus);
    yield fork(watchdelFbIntegration);
    yield fork(watchgetInstaPost);
    yield fork(watchugcAddProduct);
    yield fork(watchchangePostStatus);
    yield fork(watchupdtInstaPost);
    yield fork(watchgetSocialPush);
    yield fork(watchupdtSocialPush);
}