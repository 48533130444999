// import { fromJS } from 'immutable';

// export const getIncentive = (data) => ({ type: "GET_INCENTIVE", data });
// export const getIncentiveSuccess = (data) => ({ type: "GET_INCENTIVE_SUCCESS", data });

// export const createIncentive = (data) => ({ type: "CREATE_INCENTIVE", data });

// export const updateIncentive = (data) => ({ type: "UPDATE_INCENTIVE", data });

// export const deleteIncentive = (data) => ({ type: "DELETE_INCENTIVE", data });

// const initialState = fromJS({

// });

// function incentive(state = initialState, action) {
//   switch (action.type) {

//     case "GET_INCENTIVE_SUCCESS":
//       return { ...state, IncentiveList: action.data };

//     default:
//       return { ...state };
//   }
// }

// export default incentive;


import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const incentive = createSlice({
  name: 'incentive',
  initialState,
  reducers: {
    getIncentive: (state, action) => { },
    getIncentiveSuccess: (state, action) => {
      return { ...state, IncentiveList: action.payload };
    },
    createIncentive: (state, action) => { },
    updateIncentive: (state, action) => { },
    deleteIncentive: (state, action) => { },
  }
});

export const {
  getIncentive,
  getIncentiveSuccess,
  createIncentive,
  updateIncentive,
  deleteIncentive,
} = incentive.actions;



export default incentive.reducer;