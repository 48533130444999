import { Badge, Page, ButtonGroup, Button, Box, SkeletonBodyText, TextField, Text, BlockStack, Layout, InlineStack, Divider, Icon, Collapsible, Card } from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';
import { useEffect } from 'react';

const GoogleReviewCollector = ({ props }) => {
  const { state, validator, handleTextChange, handleSave, changeNameValue, handleBackToMain } = props;

  let _ia = false;
  if (state.currUserInt && state.currUserInt.ia !== undefined) {
    _ia = state.currUserInt.ia;
  }

  useEffect(() => {  // if 'd' obj is empty, add custom 'd' obj  
    if (state.dObj && Object.keys(state.dObj).length === 0) {
      let obj = { mid: '', estdd: '' };
      changeNameValue({ 'dObj': obj });
    }
  }, [state.dObj, changeNameValue]);

  let queArr = [
    {
      field: 'firstTabCollapse',
      question: 'What is the opt-in prompt?',
      answer: 'After completing a purchase, customers are invited to opt in to receive an email survey from Google, allowing them to rate their shopping experience.'
    },
    {
      field: 'secondTabCollapse',
      question: 'When will customers see the opt-in prompt?',
      answer: 'The prompt appears immediately after a purchase is completed on your Shopify store, typically on the order confirmation page.'
    },
    {
      field: 'thirdTabCollapse',
      question: 'Is participation in the survey mandatory for customers?',
      answer: 'No, participation is entirely voluntary. Customers can choose whether or not to opt in to receive the survey email.'
    },
    {
      field: 'fourthTabCollapse',
      question: 'What happens if a customer opts in?',
      answer: 'Customers who opt in will receive an email from Google a few days after their order is delivered, inviting them to rate their purchase experience.'
    },
    {
      field: 'fifthTabCollapse',
      question: 'How does google ensure the reviews are genuine?',
      answer: 'Google sends surveys only to customers verified to have made purchases, reducing the likelihood of fake reviews'
    },
  ];

  return (
    <Page
      title={(state.currMasterObj && state.currMasterObj.dn) || <SkeletonBodyText lines={1} />}
      subtitle={
        <Text tone='base' >
          {state.currMasterObj.dsc || <SkeletonBodyText lines={2} />}
        </Text>
      }
      titleMetadata={_ia === true ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">Active</Text></Badge>
        : <Badge><Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text></Badge>}
      secondaryActions={
        <div className='btn-group btn-head-flex'>
          <ButtonGroup gap='extraTight'>
            <Button variant='plain'>View article</Button>
            {_ia === true ?
              <Button variant='primary' tone='critical' onClick={() => handleSave(false)}>Turn off</Button>
              :
              <Button variant='primary' tone='success' onClick={() => handleSave(true)}>Turn on</Button>
            }
          </ButtonGroup>
        </div>
      }
      backAction={{ onAction: () => handleBackToMain() }}>

      <Box padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockEnd={500} paddingInlineStart={800}>
        <BlockStack gap={600}>
          <Layout>
            <Layout.Section variant="oneThird">
              <BlockStack gap={200}>
                <Text variant="headingMd" as="h2" fontWeight='semibold'>Configure</Text>
                <Text variant='headingSm' as="p" fontWeight='regular'>
                  Submit your google merchant ID & add estimated order delivery days so google sends review survey forms via email.
                </Text>
              </BlockStack>
            </Layout.Section>

            <Layout.Section>
              <BlockStack gap={400}>
                <Card>
                  <BlockStack gap={300}>
                    <Text variant='headingSm' as='p' fontWeight='regular'>Connect your google merchant account for smooth functionality. Validate all products with correct GTIN or SKUs</Text>
                    <TextField
                      label="Merchant ID"
                      value={state.dObj.mid}
                      onChange={(e) => handleTextChange('mid', e)}
                      autoComplete="off" />
                    <TextField
                      type='number'
                      label="Estimated days to deliver order"
                      value={state.dObj.estdd}
                      onChange={(e) => handleTextChange('estdd', e)}
                      error={state.errMessage.days ? state.errMessage.days : false}
                    />
                    {validator.message('days', state.dObj.estdd, 'required|min:1,num')}
                  </BlockStack>
                  <Box paddingBlockStart={400}>
                    <InlineStack align='end'>
                      <Button id='primary_btn' variant='primary' onClick={() => handleSave(_ia)}>Save</Button>
                    </InlineStack>
                  </Box>
                </Card>

                <Card padding={0}>
                  <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                    <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
                  </Box>
                  <Divider borderColor='border-tertiary' />
                  <Box paddingInline={400} paddingBlockEnd={300}>

                    {queArr && queArr.map((data, i) => {
                      return (
                        <div key={i} id='cursorPoint' onClick={() => changeNameValue({ tabEvent: data.field })}>
                          <Box paddingBlock={300}>
                            <InlineStack wrap={false} blockAlign='center' align='space-between'>
                              <Text variant='headingSm' as='p' fontWeight='medium'>{data.question}</Text>
                              <Box><Icon source={data.field === state.tabEvent ? ChevronUpIcon : ChevronDownIcon}></Icon></Box>
                            </InlineStack>
                            <Collapsible open={data.field === state.tabEvent} id="db_collaspe"
                              transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                              <Box paddingBlockStart={100}>
                                <Text variant='headingSm' as='p' fontWeight='regular' tone="subdued">{data.answer}</Text>
                              </Box>
                            </Collapsible>
                          </Box>
                          {i !== queArr.length - 1 && <Divider />}
                        </div>
                      )
                    })}

                  </Box>
                </Card>
              </BlockStack>

            </Layout.Section>
          </Layout>

          {/* <Divider borderColor="border" />
          <Layout>
            <Layout.Section variant="oneThird">
              <BlockStack gap={200}>
                <Text variant="headingMd" as="h2" fontWeight='semibold'>Upload to Merchant Center</Text>
                <Text variant='headingSm' as="p" fontWeight='regular'>
                  Copy your product review feed XML from Google Merchant Center and paste it into your review feeds.
                </Text>
              </BlockStack>
            </Layout.Section>

            <Layout.Section>
              <Card>
                <Box>
                  <Box paddingBlockStart={100} paddingBlockEnd={400}>
                    <Text variant="headingSm" as="p" fontWeight='semibold'>Product reviews XML file</Text>
                    <TextField
                      readOnly
                      multiline={3}
                      value={xmlUrl}
                      onFocus={(e) => e.target.select()}
                      connectedRight={<Tooltip width='wide' padding="400" dismissOnMouseOut content='copy to clipboard'>
                        <Button icon={ClipboardIcon} variant='plain' onClick={() => handleCopyCode(xmlUrl, 'XML URL')} />
                      </Tooltip>}
                    />
                  </Box>

                  <Box paddingBlockEnd={500}>
                    <Text variant="headingSm" as="p" fontWeight='semibold'>Filename:</Text>
                    <TextField
                      readOnly
                      value={fileNm}
                      onFocus={(e) => e.target.select()}
                      connectedRight={<Tooltip width='wide' padding="400" dismissOnMouseOut content='copy to clipboard'>
                        <Button icon={ClipboardIcon} variant='plain' onClick={() => handleCopyCode(fileNm, 'Filename')} />
                      </Tooltip>}
                    />
                  </Box>
                </Box>
              </Card>
            </Layout.Section>
          </Layout> */}
        </BlockStack>
      </Box >

    </Page >
  );
}

export default GoogleReviewCollector;