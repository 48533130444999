// import { fromJS } from "immutable";

// export const getQueAns = (data) => ({ type: "GET_QUE_ANS", data });
// export const getQueAnsSuccess = (data) => ({ type: "GET_QUE_ANS_SUCESS", data });

// export const createQueAns = (data) => ({ type: "CREATE_QUE_ANS", data });

// export const updateQueAns = (data) => ({ type: "UPDATE_QUE_ANS", data });

// export const deleteQueAns = (data) => ({ type: "DELETE_QUE_ANS", data });

// const initialState = fromJS({

// });

// function queAns(state = initialState, action) {
//   switch (action.type) {
//     case "GET_QUE_ANS_SUCESS":
//       return { ...state, QuestionAnsList: action.data };

//     default:
//       return { ...state }
//   }
// }

// export default queAns;



import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const queAns = createSlice({
  name: 'queAns',
  initialState,
  reducers: {
    getQueAns: (state, action) => { },
    getQueAnsSuccess: (state, action) => {
      return { ...state, QuestionAnsList: action.payload };
    },
    createQueAns: (state, action) => { },
    updateQueAns: (state, action) => { },
    importQueAnsCSV: (state, action) => { },
  }
});

export const {
  getQueAns,
  getQueAnsSuccess,
  createQueAns,
  updateQueAns,
  importQueAnsCSV
} = queAns.actions;



export default queAns.reducer;