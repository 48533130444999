
import { Text, OptionList, Button, Checkbox, Label, Box, Popover, InlineStack, Thumbnail, TextField, RadioButton, BlockStack, InlineGrid } from '@shopify/polaris';
import { ThemeTemplateIcon, EyeCheckMarkIcon, ImageWithTextOverlayIcon, CodeIcon, OrderDraftIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const nameOpt = [
  { label: 'Jenny Karl', value: 0 },
  { label: 'J. karl', value: 1 },
  { label: 'Jenny K.', value: 2 },
  { label: 'J. K.', value: 3 }
];

const TextProductCarousel = ({ props }) => {
  let { state, handleTextChange, changeNameValue } = props;
  let cnf = state.cnf;

  let verticalTabsArr = [
    { title: 'Review curation', img: OrderDraftIcon },
    { title: 'Theme layouts', img: ThemeTemplateIcon },
    { title: 'Reviewer cards', img: ImageWithTextOverlayIcon },
    { title: 'Color combination', img: EyeCheckMarkIcon },
    { title: 'Add custom css', img: CodeIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <Box>

          {cnf && cnf.iautotstmnl !== undefined &&
            <Box paddingBlock={100}>

              <Text variant="bodyMd" fontWeight='bold'>Set how reviews show in the carousel</Text>
              <Box paddingBlockStart={200}>
                <RadioButton
                  label={<Text fontWeight='medium' as='span'>Feature reviews automatically</Text>}
                  id="featureReviews"
                  checked={cnf.iautotstmnl}
                  onChange={() => handleTextChange({ iautotstmnl: true })}
                  helpText="Up to 15 (5-star) reviews will be shown."
                />

                {cnf.iautotstmnl === true &&
                  <Box paddingBlockStart={300} paddingInlineStart={600}>
                    <Label><Text fontWeight='medium' as='span'>Select data type</Text></Label>
                    <Box paddingBlockStart={100} paddingInlineStart={200}>
                      <BlockStack>
                        <RadioButton
                          label={<Text tone='subdued' as='span'>All</Text>}
                          id="all"
                          value='1'
                          checked={cnf.imediatstmnl === 1}
                          onChange={() => handleTextChange({ imediatstmnl: 1 })}
                        />
                        <RadioButton
                          label={<Text f tone='subdued' as='span'>With media</Text>}
                          id="withMedia"
                          value='2'
                          checked={cnf.imediatstmnl === 2}
                          onChange={() => handleTextChange({ imediatstmnl: 2 })}
                        />
                        <RadioButton
                          label={<Text tone='subdued' as='span'>Without media</Text>}
                          id="withoutMedia"
                          value='3'
                          checked={cnf.imediatstmnl === 3}
                          onChange={() => handleTextChange({ imediatstmnl: 3 })}
                        />
                      </BlockStack>
                    </Box>
                  </Box>
                }

                <Box paddingBlockStart={400}>
                  <RadioButton
                    label={<Text fontWeight='medium' as='span'>Choose reviews manually</Text>}
                    id="chooseReviews"
                    checked={!cnf.iautotstmnl}
                    onChange={() => handleTextChange({ iautotstmnl: false })}
                    helpText="Use the heart button on the reviews dashboard to select the reviews you want to show. You need to pick at least 1 review for the carousel to appear."
                  />
                </Box>
              </Box>
            </Box>
          }

        </Box>
        :
        state.sidebarActiveIndex === 1 ?
          <Box>

            <InlineGrid columns={2} gap={300}>
              {state?.mainLayoutArr?.length > 0 && state.mainLayoutArr.map((val, i) => {
                return (
                  <div key={i} onClick={() => changeNameValue({ currLayoutId: val._id }, 'isEditable')}
                    className={val._id === state.currLayoutId ? 'select_block active' : 'select_block'}>
                    <InlineStack align='center' blockAlign='center'>
                      <Checkbox tone={val._id === state?.currLayoutId ? 'magic' : ''}
                        checked={val._id === state.currLayoutId} />
                      <Text id='text_capitalize' fontWeight='medium' alignment='center'>{val.dn}</Text>
                    </InlineStack>
                    <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                  </div>
                );
              })}
            </InlineGrid>

          </Box>
          :
          state.sidebarActiveIndex === 2 ?
            <Box>

              <InlineGrid columns={2} gap={300}>
                {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
                  return (
                    <div key={i} className={val._id === state.currCardId ? 'select_block active' : 'select_block'}
                      onClick={() => changeNameValue({ currCardId: val._id }, 'isEditable')}>
                      <InlineStack align='center' blockAlign='center'>
                        <Checkbox tone={val._id === state.currCardId ? 'magic' : ''}
                          checked={val._id === state.currCardId} />
                        <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                          {val.dn}</Text>
                      </InlineStack>
                      <img className='border_NoneImg' src={val.iu} size="large" alt="Layout" />
                    </div>
                  );
                })}
              </InlineGrid>

              {cnf && cnf.crdirvwnm !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show reviewer name</Text>}
                    checked={cnf.crdirvwnm}
                    onChange={(e) => handleTextChange({ crdirvwnm: e })}
                  />
                  <Box paddingBlockStart={200} paddingInlineStart={600}>
                    <Text as='span'>Select name format</Text>
                    <Box paddingBlockStart={100}>
                      {cnf.crdirvwnm !== true ?
                        <TextField value={nameOpt[cnf.crdrvwnmfrmt].label} disabled={!cnf.crdirvwnm} />
                        :
                        <Popover
                          fullWidth
                          active={state.isNmFrmt}
                          onClose={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                          activator={<Button textAlign="left" fullWidth disclosure
                            onClick={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {nameOpt.find(({ value }) => value === cnf.crdrvwnmfrmt)?.label || 'Select'}
                            </Text></Button>}>
                          <OptionList
                            options={nameOpt}
                            selected={[cnf.crdrvwnmfrmt]}
                            onChange={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                            onFocusOption={(e) => handleTextChange({ crdrvwnmfrmt: e })} />
                        </Popover>
                      }
                    </Box>
                  </Box>
                </Box>
              }

              {cnf && cnf.crdittl !== undefined &&
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show review title</Text>}
                  checked={cnf.crdittl}
                  onChange={(e) => handleTextChange({ crdittl: e })}
                />
              }

              {cnf && cnf.crslicta !== undefined &&
                <Box paddingBlockStart={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show button</Text>}
                    checked={cnf.crslicta}
                    onChange={(e) => handleTextChange({ crslicta: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.crslicta}
                      onChange={(e) => handleTextChange({ crslctatxt: e })}
                      value={cnf.crslctatxt}
                    />
                  </Box>
                </Box>
              }

              {cnf && cnf.ivrfd !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show verified badge</Text>}
                    checked={cnf.ivrfd}
                    onChange={(e) => handleTextChange({ ivrfd: e })}
                  />
                  <Box paddingBlockStart={400}>
                    <Checkbox
                      disabled={!cnf.ivrfd}
                      label={<Text fontWeight='medium' as='span'>Show verified text on hover</Text>}
                      checked={cnf.ivrfdtxthvr}
                      onChange={(e) => handleTextChange({ ivrfdtxthvr: e })}
                    />
                    <Box paddingBlockStart={100} paddingInlineStart={600}>
                      <TextField
                        disabled={!cnf.ivrfd}
                        onChange={(e) => handleTextChange({ vrfdtxt: e })}
                        value={cnf.vrfdtxt} />
                    </Box>
                  </Box>
                </Box>
              }

            </Box>
            :
            state.sidebarActiveIndex === 3 ?
              <Box>

                <Text variant="bodyMd" fontWeight='bold'>Reviewer card</Text>
                {cnf &&
                  <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                    <InlineStack direction={'column'} gap={500}>
                      {cnf.crdbgclr !== undefined &&
                        <ColorPick
                          labelName='Background color'
                          colorKey="crdbgclr"
                          colorValue={cnf.crdbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdttlclr !== undefined &&
                        <ColorPick
                          labelName='Review title color'
                          colorKey="crdttlclr"
                          colorValue={cnf.crdttlclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdtxtclr !== undefined &&
                        <ColorPick
                          labelName='Review text color'
                          colorKey="crdtxtclr"
                          colorValue={cnf.crdtxtclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdrvwnmclr !== undefined &&
                        <ColorPick
                          labelName='Name color'
                          colorKey="crdrvwnmclr"
                          colorValue={cnf.crdrvwnmclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crdstrclr !== undefined &&
                        <ColorPick
                          labelName='Star color'
                          colorKey="crdstrclr"
                          colorValue={cnf.crdstrclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crslctabgclr !== undefined &&
                        <ColorPick
                          labelName='Button background color'
                          colorKey="crslctabgclr"
                          colorValue={cnf.crslctabgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crslctatxtclr !== undefined &&
                        <ColorPick
                          labelName='Button text color'
                          colorKey="crslctatxtclr"
                          colorValue={cnf.crslctatxtclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vrfdbgclr !== undefined &&
                        <ColorPick
                          labelName='Verified bg color'
                          colorKey="vrfdbgclr"
                          colorValue={cnf.vrfdbgclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vrfdtxtclr !== undefined &&
                        <ColorPick
                          labelName='Verified text color'
                          colorKey="vrfdtxtclr"
                          colorValue={cnf.vrfdtxtclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vtngclrfll !== undefined &&
                        <ColorPick
                          labelName='Vote fill color'
                          colorKey="vtngclrfll"
                          colorValue={cnf.vtngclrfll}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.vtngclrntfll !== undefined &&
                        <ColorPick
                          labelName='Vote not-fill color'
                          colorKey="vtngclrntfll"
                          colorValue={cnf.vtngclrntfll}
                          changeNameValue={handleTextChange} />
                      }
                    </InlineStack>
                  </Box>
                }
                <Text variant="bodyMd" fontWeight='bold'>Slider arrow</Text>
                {cnf &&
                  <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={2400}>
                    <InlineStack direction={'column'} gap={500}>
                      {cnf.crslarwclr !== undefined &&
                        <ColorPick
                          labelName='Arrow color'
                          colorKey="crslarwclr"
                          colorValue={cnf.crslarwclr}
                          changeNameValue={handleTextChange} />
                      }
                      {cnf.crslarwbgclr !== undefined &&
                        <ColorPick
                          labelName='Arrow background color'
                          colorKey="crslarwbgclr"
                          colorValue={cnf.crslarwbgclr}
                          changeNameValue={handleTextChange} />
                      }
                    </InlineStack>
                  </Box>
                }

              </Box>
              :
              state.sidebarActiveIndex === 4 &&
              <Box>

                {cnf && cnf.icstcss !== undefined &&
                  <Box>
                    <Checkbox
                      label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                      checked={cnf.icstcss}
                      onChange={(e) => handleTextChange({ icstcss: e })}
                    />
                    <Box paddingBlockStart={200} paddingInlineStart={600}>
                      <TextField
                        label={<Text tone='subdued' as='span'>Custom css block</Text>}
                        disabled={!cnf.icstcss}
                        multiline={4}
                        value={cnf.cstcss}
                        onChange={(e) => handleTextChange({ cstcss: e })}
                        autoComplete="off" />
                    </Box>
                  </Box>
                }

              </Box>
      }

    </Box>
  );
}

export default TextProductCarousel;