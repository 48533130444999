import { Button, Popover, Divider, Icon, Card, Tooltip, InlineStack, Checkbox, OptionList, Scrollable, Box, DatePicker, InlineGrid, Text, TextField } from '@shopify/polaris';
import { useState, useEffect } from 'react';
import { useBreakpoints } from '@shopify/polaris';
import { CalendarIcon, ArrowRightIcon } from '@shopify/polaris-icons';

const TriggerCard = ({ props }) => {
  let { state, changeNameValue } = props;

  const { mdDown, lgUp } = useBreakpoints();
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const yesterday = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0, 0));

  const ranges = [
    {
      title: "Today",
      alias: "today",
      period: {
        since: yesterday,
        until: today,
      },
    },
    {
      title: "Last 7 days",
      alias: "last7days",
      period: {
        since: new Date(new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0)),
        until: yesterday,
      },
    },
    {
      title: "Last 30 days",
      alias: "last30days",
      period: {
        since: new Date(new Date(new Date().setDate(today.getDate() - 30)).setHours(0, 0, 0, 0)),
        until: yesterday,
      },
    }
  ];

  const [inputValues, setInputValues] = useState({});
  const [activeDateRange, setActiveDateRange] = useState(ranges[0]);
  const [{ month, year }, setDate] = useState({
    month: activeDateRange.period.since.getMonth(),
    year: activeDateRange.period.since.getFullYear()
  });

  function formatDToY(date) {
    const year = String(date.getFullYear());
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    if (month.length < 2) {
      month = String(month).padStart(2, "0");
    }
    if (day.length < 2) {
      day = String(day).padStart(2, "0");
    }
    return [year, month, day].join("-");
  }

  const activeRangeChange = (state) => {
    setActiveDateRange(state);
    let obj = {
      frmdt: state.period.since.toDateString(),
      tdt: state.period.until.toDateString()
    }
    changeNameValue(obj);
  }

  function handleCalendarChange({ start, end }) {
    const newDateRange = ranges.find((range) => {
      return (
        range.period.since.valueOf() === start.valueOf() &&
        range.period.until.valueOf() === end.valueOf()
      );
    }) || {
      alias: "custom",
      title: "Custom",
      period: {
        since: start,
        until: end,
      },
    };
    let obj = {
      frmdt: newDateRange.period.since.toDateString(),
      tdt: newDateRange.period.until.toDateString()
    }
    changeNameValue(obj, 'isShowMessage');
    activeRangeChange(newDateRange);
  }

  useEffect(() => {
    if (activeDateRange) {
      setInputValues({
        since: formatDToY(activeDateRange.period.since),
        until: formatDToY(activeDateRange.period.until),
      });
      function monthDiff(referenceDate, newDate) {
        return (newDate.month - referenceDate.month + 12 * (referenceDate.year - newDate.year));
      }
      const monthDifference = monthDiff(
        { year, month },
        {
          year: activeDateRange.period.until.getFullYear(),
          month: activeDateRange.period.until.getMonth(),
        }
      );
      if (monthDifference > 1 || monthDifference < 0) {
        setDate({
          month: activeDateRange.period.until.getMonth(),
          year: activeDateRange.period.until.getFullYear()
        });
      }
    }
  }, [activeDateRange]);

  useEffect(() => {
    let start = new Date(state.frmdt);
    let end = new Date(state.tdt);

    const newDateRange = ranges.find((range) => {
      return (
        range.period.since.valueOf() === start.valueOf() &&
        range.period.until.valueOf() === end.valueOf()
      );
    }) || {
      alias: "custom",
      title: "Custom",
      period: {
        since: start,
        until: end,
      }
    };

    activeRangeChange(newDateRange);
  }, [state.frmdt, state.tdt]);

  const buttonValue = state.frmdt + " - " + state.tdt;

  return (
    <div>
      {props.state.emailTyp !== 'one_time_campaign' ?
        <Card padding={'0'}>
          <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
            <Text variant='bodyLg' fontWeight='medium' as='h1'>Timely requests</Text>
          </Box>
          <Divider borderColor='border-tertiary' />
          <Box padding={400}>

            <InlineStack gap={400} direction={'column'}>
              <InlineStack align='space-between'>
                <InlineStack gap='200' align='start' blockAlign='start' direction={'column'}>
                  <Text variant='headingSm' fontWeight='medium' as='h6'>
                    {props.state.emailTyp === 'on_order_fulfill' ?
                      `After the order is fulfilled, send the review request after ${props.state.ddValue} days.`
                      : props.state.emailTyp === 'on_followup' ?
                        `Follow-up requests and reminders for reviews are sent after ${props.state.ddValue} days.`
                        :
                        `Follow-up requests and reminders for media upload  are sent after ${props.state.ddValue} days.`
                    }
                  </Text>
                  <TextField
                    type='number'
                    suffix='days'
                    value={props.state.ddValue}
                    onChange={(e) => props.changeNameValue({ ddValue: parseInt(e) }, 'isShowMessage')}
                    error={state.errMessage.daysField ? state.errMessage.daysField : false} />
                  {props.validator.message('daysField', state.ddValue, 'required|numeric|min:1,num|max:60,num')}
                </InlineStack>
              </InlineStack>

              <InlineGrid columns={{ sm: 8, xs: 4 }}>
                <Button id='primary_btn' onClick={() => props.updDaysField()}>Save</Button>
              </InlineGrid>
            </InlineStack>

          </Box>
        </Card>
        :
        <Card padding={'0'}>
          <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
            <Text variant='bodyLg' fontWeight='medium' as='h1'>Specify the campaign's duration</Text>
          </Box>
          <Divider borderColor='border-tertiary' />

          <Box padding={400}>
            <Text variant='bodyMd' fontWeight='medium' as='h6'>WiserReviews will request reviews from customers who made purchases within that timeframe, considering only fulfilled orders.</Text>

            <Box paddingBlockStart={300}>
              <Popover
                fluidContent
                fullHeight
                active={state.popoverActive}
                preferredAlignment="left"
                preferredPosition="below"
                activator={<Button onClick={() => changeNameValue({ popoverActive: !state.popoverActive })}
                  icon={CalendarIcon}>{buttonValue}</Button>}
                onClose={() => changeNameValue({ popoverActive: false })}>

                <Popover.Pane fixed>
                  <InlineGrid
                    columns={{
                      xs: "1fr",
                      mdDown: "1fr",
                      md: "max-content max-content",
                    }}
                    gap={0}>

                    <Box  // today/week/month selection
                      maxWidth={mdDown ? "516px" : "160px"}
                      width={mdDown ? "100%" : "160px"} >
                      <Scrollable>
                        <OptionList
                          options={ranges.map((x) => ({
                            value: x.alias,
                            label: x.title
                          }))}
                          selected={activeDateRange.alias}
                          onChange={(value) => {
                            const result = ranges.find((range) => range.alias === value[0]);
                            activeRangeChange(result);
                          }} />
                      </Scrollable>
                    </Box>

                    <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>
                      <InlineStack gap="400">
                        <InlineStack gap="200">
                          <div style={{ flexGrow: 1 }}>
                            <TextField
                              readOnly
                              prefix={<Icon source={CalendarIcon} />}
                              value={inputValues.since} />
                          </div>
                          <Icon source={ArrowRightIcon} />
                          <div style={{ flexGrow: 1 }}>
                            <TextField
                              readOnly
                              prefix={<Icon source={CalendarIcon} />}
                              value={inputValues.until} />
                          </div>
                        </InlineStack>
                        <div>
                          <DatePicker
                            month={month}
                            year={year}
                            selected={{
                              start: activeDateRange.period.since,
                              end: activeDateRange.period.until,
                            }}
                            onMonthChange={(month, year) => setDate({ month, year })}
                            onChange={handleCalendarChange}
                            multiMonth={lgUp}
                            allowRange />
                        </div>
                      </InlineStack>
                    </Box>
                  </InlineGrid>
                </Popover.Pane>

                <Box padding={200}>
                  <InlineStack align="end">
                    <Button variant='tertiary' onClick={() => changeNameValue({ popoverActive: false })}>Close</Button>
                  </InlineStack>
                </Box>
              </Popover>

              <Box paddingBlockStart={200} paddingBlockEnd={400}>
                <Checkbox
                  checked={state.iexclitem}
                  onChange={(e) => changeNameValue({ iexclitem: e }, 'isShowMessage')}
                  label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Exclude already reviewed items</Text>} />
              </Box>

              <InlineGrid columns={{ sm: 8, xs: 4 }}>
                {localStorage.getItem('csub') === 'free' ?
                  <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                    <Button disabled id='primary_btn'>Save</Button>
                  </Tooltip>
                  :
                  <Button id='primary_btn' onClick={() => props.updDaysField('one_time_campaign')}>Save</Button>
                }
              </InlineGrid>
            </Box>

          </Box>
        </Card>
      }

    </div >
  );
}

export default TriggerCard;